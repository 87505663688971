<h1 mat-dialog-title>
  Ausgewählte Haltestelle:  {{data.stop.stopFacilityName}}
</h1>


<h1 mat-dialog-title>Route auswählen</h1>

<div mat-dialog-content>

  <mat-radio-group [(ngModel)]="selectedRoute" (change)="onRouteSelect()">
    <mat-radio-button *ngFor="let route of stopFacilityTransitRoutes" [value]="route">
      {{ route }}
    </mat-radio-button>
  </mat-radio-group>

  <div *ngIf="departures && departures.length > 0">
    <h2  mat-dialog-title >Abfahrtszeit wählen</h2>
    <mat-radio-group [(ngModel)]="selectedDeparture">
      <mat-radio-button *ngFor="let departure of departures" [value]="departure">
        {{ departure }}
      </mat-radio-button>
    </mat-radio-group>
  </div>


  <div *ngIf="dataSource.length > 0" style="margin-bottom:10px">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
      </ng-container>

      <!-- Time Column -->
      <ng-container matColumnDef="n">
        <th mat-header-cell *matHeaderCellDef>Time</th>
        <td mat-cell *matCellDef="let element">{{element.n}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="warn" (click)="onCancel()">Schließen</button>
  <button mat-raised-button color="primary" [disabled]="!selectedDeparture" (click)="onConfirm()">Bestätigen </button>
</div>

<mat-progress-bar style="position: absolute;top:0px" *ngIf="popSpinner" mode="indeterminate"></mat-progress-bar>