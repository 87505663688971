
  
  <div style="padding-top:40px">
    <div class="folder-sticker">
      <span class="mainDatahub"> Prognose. Nachfrage nach medizinischer Infrastruktur.</span>
    </div>
    

    
    <div style="display: flex; flex-direction: column; justify-content: space-between; ;" >
      

    

    
    
    
    
   <div class="auswahl" >

    <div  id="second-page">

      <app-nachfrageberechnung-item  *ngIf="start && selectedItem  && !edit" [item]="selectedItem"> </app-nachfrageberechnung-item>
      <!--<app-nachfrageberechnung-edit *ngIf="start && selectedItem  && edit" [selectedItem]="selectedItem"> </app-nachfrageberechnung-edit>-->


      
  </div>
       
    <div style="height: 50vh;  display: flex; flex-direction: row; " id="first-page" *ngIf="start" > 
    
    
      <div style="display: flex; justify-content:center; align-items:center;flex-direction:column;gap:10px; border-right:1px solid #ccc; padding-right:10px;"> 
     



        <div>
          <input id="adress2" [(ngModel)]="searchBox" />
      </div>

      <button  mat-fab extended color="primary" (click)="selectedItem = undefined; startAnalyse()"> <mat-icon>add</mat-icon> Analyse hinzufügen </button>

      <button  mat-fab extended color="primary" *ngIf="selectedItem"  (click)="startAnalyse()" > <mat-icon>edit</mat-icon> {{ selectedItem?.name }} bearbeiten </button>
      <button  mat-fab extended color="primary" *ngIf="selectedItem" (click)="openDeleteDialog()"> <mat-icon>delete</mat-icon> {{ selectedItem?.name }} löschen  </button>
      <button  mat-fab extended color="primary" *ngIf="selectedItem" (click)="openDialogShare()"> <mat-icon>share</mat-icon> {{ selectedItem?.name }} teilen  </button>

    
      </div>

    
      <div id="left-container">
       

        <div style="padding: 1%; display: flex;     overflow-y: scroll;" > 

          <div *ngFor="let item of storedObject" (click)="clickItem(item)" id="tile-background" [style.background-color]="selectedItem  == item ? 'lightgray' : 'white'"> 
            <div class="pad2" *ngIf="filterItem(item)" [style.background-color]="selectedItem  == item ? '#D4E5C6' : 'white'">
                  
<div style="border-bottom: 3px solid gray;"> 
              <mat-icon>analytics</mat-icon>  
              <span class="span3">{{item.name }}  </span>
           
            </div>

<div style="height: 25vh; width: 20vh; display: flex; flex-direction: column; align-items: center; justify-content: center; overflow-y: scroll;"> 


              <span><mat-icon>groups</mat-icon> </span>
              <ng-container *ngFor="let pop of item.selectedPop"> 

           

                <span>  {{ pop }}  </span> <br> 
               </ng-container>
        

             <span><mat-icon>medication</mat-icon> </span>
            <ng-container *ngFor="let scen of item.selectedScenarios"> 
              <span>{{ scen.name }}  </span> <br> 
               </ng-container>
       
              </div>

       
            </div>

          </div>



        </div>

 

    
    
    
        </div>

 
        </div>

        <div  id="second-page" *ngIf="!start && !edit ">

            <app-nachfrageberechnung-select [existingItem]="selectedItem"> </app-nachfrageberechnung-select>

            
        </div>





  
          
    
        </div>
    
        
    
   
    
    
    
      </div>
    

      <dialog id="delete-dialog">
       <h1> Nachfragemodell {{ this.selectedItem?.name }} löschen? </h1>
      

       <div style="display: flex; justify-content: space-around;"> 
  
        <button  mat-fab extended color="primary" (click)="deleteSelectedItem()"> Ja </button>
        <button  mat-fab extended color="secondary" (click)="closeDeleteDialog()"> Nein </button>
      </div>


      
      
        </dialog>

    
    

  </div>
  
