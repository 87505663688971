<mat-icon style="margin: 5px;"(click)="    this.dialogRef.close({});" >close</mat-icon>

<div style="display: flex; flex-direction: row; align-items: space-between; padding: 30px">

    <div class="tableContainer">
        <div class="tableHeader" *ngFor="let loc of locations; let i = index">
 <ng-container > 
            <div class="tableCell1">
                <button *ngIf="locations.length > 1" mat-icon-button color="warn"
                    (click)="remove(loc)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
            <div class="tableCell2">
                <input name="name" placeholder="{{ 'Marker ' + i}}"  style="width: 100%; " [(ngModel)]="loc.name " />
            </div>
            <div class="tableCell3">
                <input name="adress" style="width: 100%; margin-left: 20px" [(ngModel)]="loc.address"
                    placeholder="Beispieladresse..." (click)="completeDropNew(loc, $event)"
                    (keyup)="completeDropNew(loc.address, $event); selectedIndex = i + locations.length "
                    />
                <div>
                    <div *ngIf="filteredAdressList.length > 0 && (i + locations.length )== selectedIndex"
                        style="max-height: 100px; width: 100%; overflow-y: scroll; border-bottom-right-radius: 15px; border-bottom-left-radius: 15px; border: 1px solid #10684E; padding-left: 8px;border-top: none; ">
                        <div *ngFor="let address of filteredAdressList"
                            (click)="loc.address = address.properties.label; filteredAdressList = []; loc.lat = address.geometry.coordinates[0] ; loc.lng = address.geometry.coordinates[1]; addRow()"
                            class="dropAd">
                            {{ address.properties.label }}
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        </div>
<!--
        <div class="tableHeader" >
            <div class="tableCell1">
               
            </div>
            <div class="tableCell2">
                <input name="name" placeholder="Name..." style="width: 100%; " [(ngModel)]="formData.name " />
            </div>
            <div class="tableCell3">

                <div>
                    <input name="adress" style="width: 100%; margin-left: 20px" [(ngModel)]="formData.address" placeholder="Adresse..."
                        (keyup)="completeDropNew(formData, $event);" />
                </div>
                <div *ngIf="filteredAdressList.length > 0"
                    style="max-height: 100px; width: 100%; overflow-y: scroll; border-bottom-right-radius: 15px; border-bottom-left-radius: 15px; border: 1px solid #10684E; padding-left: 8px;border-top: none; ">
                    <div *ngFor="let address of filteredAdressList"
                        (click)="formData.address = address.properties.label; filteredAdressList = []; formData.latlng = address.geometry.coordinates;saveFormData(); "
                        class="dropAd">
                        {{ address.properties.label }} 
                    </div>
                </div>
            </div>

        </div>

        <div class="tableHeader" >
            <div class="tableCell1">

            
        
            </div>
            <div class="tableCell2">
                <button mat-mini-fab style="background-color: #10684E; color: white"
                (click)="addRow()">
                <mat-icon >add</mat-icon>
              </button>

         
            </div>
            <div class="tableCell3">

             
            </div>
        </div>

-->
    </div>


    <div class="controlContainer">
        <div>
            <div class="center">

                <button mat-mini-fab style="background-color: #10684E; color: white"
                    (click)="closeWLocation();">
                    <mat-icon>my_location </mat-icon>
                </button> Punkte auf Karte auswählen

            </div>

            <div class="center">

                <b> oder </b> <br>
            </div>

            <div class="center">
                <input style="display: none;" type="file" (change)="onFileChange($event)" accept=".xlsx" #fileUpload
                    class="file-input">


                <button mat-mini-fab style="background-color: #10684E; color: white" (click)="fileUpload.click()">
                    <mat-icon>attach_file </mat-icon> </button> Datei (.csv) hochladen <br>

                <button mat-mini-fab style="background-color: #10684E; color: white" (click)="exportData();">
                    <mat-icon>file_download </mat-icon> </button> Vorlage (.csv) herunterladen
                <br>
                <br>


                <button mat-mini-fab style="background-color: #10684E; color: white" (click)="closeWLocation();">
                        <mat-icon>save </mat-icon> </button> Übernehmen & Weiter

                

            </div>

        </div>

    </div>

</div>