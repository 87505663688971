<div class="map-container" style="height: 100%">
    <div class="map" id="map" leaflet [leafletLayers]="layers"></div>
</div>


<div class="options" style="display: none;"> 
    
    <button (click)="openSaveDialog()"  class="grafik-btn">Speichern</button> <br><br><br>
    <button (click)="open('.options')"  class="grafik-btn">Teilen</button>


</div> 

<div id="bottom-slide-container">


    <div id="playContainer">


        <div id="slide-container">

            <input type="range" id="customRange1" value="1" min="2024" max="2034" (change)="updateNextDay($event)" />

            <div class="ticks">

                <ng-container *ngFor="let e of [].constructor(11);let i = index;">
                    <span class="tick"> {{ 2024 + i }} </span>
                </ng-container>

            </div>
        </div>


    </div>

   

    <button  style='margin-left: 40vw' class="grafik-btn" (click)="setLayer()">Suche </button>

    <button  [ngStyle]="{ 'background-color': checkIfOpen('.schule-display') ? 'grey' : ' #3fb5ab' }"
    (click)="showSchool(undefined)" style='margin-left: 48vw' class="grafik-btn">Schulen </button>


    <button [ngStyle]="{ 'background-color': checkIfOpen('.bezirke-display') ? 'grey' : ' #3fb5ab' }" 
    (click)=" open('.bezirke-display') " style='margin-left: 56vw' class="grafik-btn">Schulbezirke </button>


    <button
    (click)="openDialogAnalyse()" style='margin-left: 67vw' class="grafik-btn">Analysen </button>

    <button *ngIf ="!checkIfOpen('.options')" (click)="open('.options')" style='margin-left: 75vw; background-color: #3fb5ab' class="grafik-btn">Optionen</button>

    <button  *ngIf ="checkIfOpen('.options')" (click)="close('.options')" style='margin-left: 75vw; background-color: grey ' class="grafik-btn">Optionen</button>


    <button 
    [ngStyle]="{ 'background-color': checkIfOpen('.grfikChart2') ? 'grey' : ' #3fb5ab' }"
    
    (click)="showGrafik(true)" style='margin-left: 83vw' class="grafik-btn">Grafik
   </button>



</div>
<div class="addSchool" *ngIf="addSchoolMode" >
    <div class="addSchool-btn">
        Klicken Sie auf die Karte um Schulen hinzufügen
        <u (click)="endSchoolMode()"> beenden </u>
    </div>

</div>

<div class="addSchool" *ngIf="bezirkNeedSchool.length > 0" >
    <div class="addSchool-btn">
        Ordnen Sie den Bezirken eine neue Schule hinzu
        <u (click)="bezirkNeedSchool = []; deselectBezirke()"> beenden </u>
    </div>

</div>

<div class="addSchool" *ngIf="changeSchoolOfBezirk">
    <div class="addSchool-btn">
        Klicken Sie auf eine Schule um sie dem Bezirk   {{ selectedBezirk?.properties.gemarkung }} zuzuordnen 
        <u (click)="changeSchoolOfBezirk = false; addSchoolMode = false"> beenden </u>
    </div>

</div>


<div class="addSchool" *ngIf="changeBezirkOfSchool">
    <div class="addSchool-btn">
      <span *ngIf="highlightedLayerMulti.length == 0"> 
        Klicken Sie Bezirke die Sie zu {{ selectedSchoolTemp.properties.Schulname }} zuordnen wollen

      </span>  
      <span *ngIf="highlightedLayerMulti.length> 0"> 
        
        <span *ngFor="let bez of highlightedLayerMulti">  {{ bez.raw.properties.gemarkung }} </span>
         zu {{ selectedSchoolTemp.properties.Schulname }} zuordnen.
      </span>  
      

        <u  style="margin-right: 5px"(click)="methChgBezirkOfSchool(true)" > beenden </u>
        <u  (click)="methChgBezirkOfSchool(false)"> anwenden </u>

    </div>

</div>


<!--<div class="grfikChart" style="display: none">
   <canvas id="chartCanvas"></canvas>
</div>-->


<div class="grfikChart2" style="display: none">

    <div style="height: 120px; overflow-y: auto; flex-direction: row; align-items: center; justify-content: center; "> 
        <button style="margin-left: 60%;"class="grafik-btn" (click)="showLine = !showLine">  Grafik wechseln </button>
        <button style="margin-left: 60%; margin-top:50px"class="grafik-btn" (click)="leaveGefahr()">
             Gefahranalyse 
             <br>
             Verlassen 
        </button>

   
    <div *ngFor="let school of schools; let i = index" style="display: flex; align-items: center; flex-direction: row;">

        <div  *ngIf="school.raw.checked" style="display: flex; align-items: center;"> 
        
          <div style="width: 15px; height: 5px;  margin-right: 2px;" [style.background-color]="stringToColor(school.raw.properties.Schulname)"> 

          </div>


          <span style="font-size: 15px;">  {{ school.raw.properties.Schulname }} {{getLabelForSchool(school)}}</span>
          

         
        </div>
      </div>

    </div>


    <canvas id="chartCanvas" [ngStyle]="{ 'display': showLine ? 'none' : 'block' }"></canvas>

    <canvas id="chartCanvas2" [ngStyle]="{ 'display': showLine ? 'block' : 'none' }"></canvas>
</div>


<div class="newSchool" style="display: none">
    <h3> Neue Schule anlegen </h3>


        <label for="glm_id">Name:</label><br>
        <input type="text" id="glm_id" name="glm_id" style="width: 100%" [(ngModel)]="newSchoolName"
            value="Neue Schule"><br><br>


        <label for="address">Addresse:</label><br>
        <input type="text" id="address" name="address" style="width: 100%" value="{{ selectedSchoolTemp['properties'].Adresse }}"><br><br>

        <div class="button-group">
            <button mat-raised-button color="primary" style="margin-right:100px" (click)="showIsochrone()">Erreichbarkeitsanalyse</button>
            <button mat-raised-button color="warn" style="margin-left:220px" (click)="removeIsochrone()">Isochrone Entfernen</button>
        
        </div>
        <button class="grafik-btn" (click)="saveNewSchool()" *ngIf="getBezirke(selectedSchoolTemp['properties'].glm_id)?.length>0">Speichern </button>

        <br>
        <br>



        <div style="height: 60%; width: 90%; overflow-y: auto;">

            <h3 class="section-title">Schulbezirk</h3>
            <u class="assign-link" (click)="addBezirkForNewSchool()">Schulbezirke zuweisen</u>
            

            
            


   
<div *ngIf="getBezirke(selectedSchoolTemp['properties'].glm_id)?.length>0"> 
        Der Schulbezirk der Schule {{ selectedSchoolTemp['properties'].Schulname }} setzt sich aus den Ortsteilen 
        <span *ngFor="let bezirk of getBezirke(selectedSchoolTemp['properties'].glm_id)"> 

            {{ bezirk.raw.properties.gemarkung }}
         </span>
        zusammen.

    </div>
   <h3 *ngIf="affectedSchools.length > 0">  Affektierte Schulen im Umkreis:  </h3>
     <span *ngFor="let sch of affectedSchools"> 

        {{ sch.raw.properties.Schulname }} <br>
     </span> 
     <u *ngIf="affectedSchools.length > 0" (click)="drawAffectedSchools()"> auf Karte einzeichnen </u>

             
        <!--<a *ngIf = "getBezirke(selectedSchoolTemp['properties'].glm_id)?.length < 1" (click)="addBezirkForNewSchool()">Schulbezirk zuweisen </a> <br>-->
        <br>
        <br>
        

        <table border="1" cellpadding="3" cellspacing="0">
            <thead>
              <tr>
                <th>Schuljahr</th>
                <th>Klassengröße</th>
                <th>Regelzügigkeit</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of tableData">
                <td>{{ row.schuljahr }}</td>
                <td><input type="number" [(ngModel)]="row.klassengroesse" /></td>
                <td><input type="number" [(ngModel)]="row.regelzuegigkeit" /></td>
              </tr>
            </tbody>
          </table>
 

<br>
    </div>


</div>



<div class="bezirke-display" style="display: none"   [ngStyle]="{ 'right': checkIfOpen('.newSchool') ? '450px' : '20px' }"  >


    <div style="display: flex;justify-content: space-between; ">
        <input style="width: 80%" type="search" placeholder="Suche.." [(ngModel)]="bezirkeSearch" />


        <u style="margin-right: 0" (click)="selectBezirke()"> alle auswählen </u>
        <u style="margin-right: 0" (click)="deselectBezirke()"> alle abwählen </u>

        <mat-icon (click)="close('.bezirke-display')">close</mat-icon>
    </div>




    <div style=" margin-top: 10px; height: 14vh;  display: flex;flex-direction: row;flex-wrap: wrap; overflow-y: auto;">
        <div *ngFor="let bezirk of bezirke; let i = index">

            <div style="width: 10vw; display: flex; align-items: center; flex-direction: row "  *ngIf="bezirkeSearch == '' || bezirk.raw.properties.gemarkung?.toLowerCase().includes(bezirkeSearch?.toLowerCase())">

                <mat-checkbox (click)="clickBezirk(bezirk)" [(ngModel)]="bezirke[i].raw.checked">


                </mat-checkbox>


                <a *ngIf="highlightedLayer != bezirk.layer" (click)="openBezirk(bezirk.layer, bezirk.raw)"> {{
                    bezirk.raw.properties.gemarkung }} </a>
                <b *ngIf=" highlightedLayer == bezirk.layer" (click)="openBezirk(bezirk.layer, bezirk.raw)"> {{
                    bezirk.raw.properties.gemarkung }} </b>

            </div>

        </div>

    </div>

</div>

<div class="schule-display" style="display: block" >
    <div style="display: flex; flex-direction: column; justify-content: space-between;"> 
    <div class="left-schul">
        <div>

            <mat-icon (click)="close('.schule-display')">close </mat-icon>
        </div>
      
            <input  style="margin-left: 10px; height: 5vh"  type="search" placeholder="Suche.." [(ngModel)]="schoolSearch" />
  
            <u style="margin-left: 2px"(click)="selectSchool()"> alle auswählen </u> 
            <u style="margin-left: 2px" (click)="deselectSchool()"> alle abwählen </u> <br>
       
     
            <button style="margin-left: 21vw" class="share-btn" (click)="setAddSchoolMode()">Schule hinzufügen</button>


      </div>
    <div class="right-schul"> 

        <div style="    height: 16vh; align-items: flex-start; justify-content: flex-start;display: flex; flex-direction: column; overflow-y: auto;">
          <div *ngFor="let school of schools; let i = index" style="display: flex; align-items: center;">

            <div  *ngIf="schoolSearch == '' || school.raw.properties.Schulname?.toLowerCase().includes(schoolSearch?.toLowerCase())" style="display: flex; align-items: center;"> 
              <mat-checkbox [(ngModel)]="schools[i].raw.checked" (click)="toggleSchool(schools[i])"
                 >
                
  
              </mat-checkbox>
              <div style="width: 20px; height: 10px;  margin-right: 2px;" [style.background-color]="stringToColor(school.raw.properties.Schulname)"> 

              </div>

              <s *ngIf="getKlassengroesse(school, currentYear ) < 1"> {{ school.raw.properties.Schulname }} {{getLabelForSchool(school)}} </s>

              <a *ngIf='getKlassengroesse(school, currentYear ) > 0 &&school.raw.properties.Schulname  != selectedSchool?.raw.properties.Schulname ' (click)="clickSchool(school)" 
              [ngStyle]="{ 'color': school.raw.inDanger ? 'red' : 'black' }">   {{ school.raw.properties.Schulname }} {{getLabelForSchool(school)}}  </a>
              <b *ngIf='getKlassengroesse(school, currentYear ) > 0 && school.raw.properties.Schulname  == selectedSchool?.raw.properties.Schulname  '(click)="clickSchool(school)"     [ngStyle]="{ 'color': school.raw.inDanger ? 'red' : 'black' }" >  {{ school.raw.properties.Schulname }} {{getLabelForSchool(school)}}</b>
              

             
            </div>
          </div>
  
      </div>
    </div>
  
</div>

</div>




<div class="bezirk" style="display: none"  [ngStyle]="{ 'right': checkIfOpen('.newSchool') ? '450px' : '20px' }" >
<div style="display: flex; align-items: center; justify-content: space-between;">
  


    <h1> Ortsteil: {{ selectedBezirk?.properties.gemarkung }} </h1> 

    <mat-icon (click)="close('.bezirk')">close </mat-icon>
</div>

    <b> {{ selectedBezirk?.properties.gemeinde }}, {{ selectedBezirk?.properties.kreis }} </b> <br>
    <b> Schule:     </b> {{ getSchoolForglm(selectedBezirk?.properties.glm_id )?.raw?.properties?.Schulname}}       
    <mat-checkbox (click)="setMittelschule($event, getSchoolForglm(selectedBezirk?.properties.glm_id ) )" [checked]=" getSchoolForglm(selectedBezirk?.properties.glm_id )?.raw?.properties?.Mittelzentrum"> Zuordnung Mittelzentrum? </mat-checkbox>
    <div style="display: flex; align-items: center; justify-content: center;">
    
        <mat-icon>east</mat-icon>
    <u extended color="primary" style="width: 90%; " (click)="setNewSchoolForBezirk(selectBezirke)" >
      
        Andere Schule zuordnen </u> 

    </div>





</div> 

<dialog id="afterNewSchool">
   <h1> Durch die Einrichtung von {{  selectedSchoolTemp?.properties.glm_id  }} ist mit veränderten SuS-Zahlen zu rechnen. <br>

   </h1> 
   <h3> 
    
    Möchten Sie die Berechnung jetzt durchführen oder weitere Modifikationen in der Grundschullandschaft vornehmen?
</h3>
    <br>
    <br>
    <button
    style="
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    margin-right: 8px;
    background-color: #126F62;
    color: white;
    left: 35vw;"
    
    
    
    >Berechnung durchführen</button> 

    <button 
    style="
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    margin-right: 8px;
    background-color: #a21914;
    color: white;
    right: 35vw; "

    
    
    
    >weitere Modifikationen</button> 

    
    <br>
    <br>

    <br>
    <br>


 
</dialog>
<!--
<div class="banner" >
    <div class="addSchool-btn">
        Klicken Sie auf die Karte um Schulen hinzufügen
        <u (click)="endSchoolMode()"> beenden </u>
    </div>

</div>-->

