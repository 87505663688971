import { Component, NgModule } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MarkdownModule } from 'ngx-markdown';
import { LocationPickerComponent } from '../../reusable-components/location-picker/location-picker.component';
interface Location {
  name: string;
  address: string;
  lat: number;
  lng: number;
}
@Component({
  selector: 'app-vivi',
  templateUrl: './vivi.component.html',
  styleUrls: ['./vivi.component.scss'],
})
export class ViviComponent {

// globale css mit btns class names and colors
// a card styling
// search
// add a clear feature to input 
// add a search input
// add input with dropdown
// add search with dropdown
// add button round
// add button icon + text
// info btn
// stepper
// tabs
// slider all options
// dialoge 
// leaflet legende
// legende rasten
// mat buttons
// input with checkbox



  exampleName:string='';

  constructor(public dialog: MatDialog) {}

  exampleFun() {
    alert('du hast geklickt!');
  }

  copyToClipboard(code: string): void {
    navigator.clipboard.writeText(code).then(
      () => alert('Code copied to clipboard!'),
      () => alert('Failed to copy code!')
    );
  }

  async openLocationDialog() {
    var h: Location = {
      name: 'Test_Marker',
      address: '',
      lat: 51.04962,
      lng: 12.1369,
    };

    const dialogRef = this.dialog.open(LocationPickerComponent, {
      data: { locations: [h] },
      width: '1200px',
      height: '800px',
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
      }
    });
  }

  htmlCodeBtn: string = `<button class="btn" (click)="exampleFun()">Button 1</button> Button mit Text`;

  cssCodeBtn: string = `
    .btn {
      background-color: #3fb5ab;
      color: #ffffff;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 18px;
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      transition: box-shadow 0.3s ease;
      width: 150px;
    }

    .btn:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    }`;


  
    htmlCodeKurz: string = `
                  <button
                    mat-mini-fab
                    style="background-color: #10684e; color: white"
                    (click)="exampleFun()"
                  >
                    <mat-icon>add</mat-icon>
                  </button>`;


    cssCodeKurz: string = `
      .btn {
          background-color: #3fb5ab;
          color: #ffffff;
          font-family: Arial, Helvetica, sans-serif;
          font-size: 18px;
          padding: 10px 20px;
          border: none;
          border-radius: 4px;
          transition: box-shadow 0.3s ease;
          width: 150px;
        }
  
      .btn:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
      }`;

      htmlCodeInput: string = `
      <button
        mat-mini-fab
        style="background-color: #10684e; color: white"
        (click)="exampleFun()"
      >
        <mat-icon>add</mat-icon>
      </button>`;

      TSCodeInput: string = `
  	   exampleName:string='';`;


        bestPracticeTS: string = `import { Component, OnInit } from '@angular/core';
    
    @Component({
      selector: 'app-beispiel',
      templateUrl: './beispiel.component.html',
    })
    export class BeispielComponent implements OnInit {
      constructor() {}
    
      ngOnInit(): void {
        console.log('Komponente initialisiert');
      }
    }`

    serviceCode: string =`ng g s beispielService`;



}
