<div style="padding: 30px;">

<mat-icon >close</mat-icon>

    <h1> Schulplanung speichern </h1>

    <b> Name </b>

    <input id="adress" name="adress" [(ngModel)]="popName" />



    <br> <br>

    <b> Sichtbarkeit </b>

    <mat-chip-listbox [(ngModel)]="selectedSichtbarkeit">

        <div *ngFor="let cat of ['public', 'protected', 'privat']">
            <mat-chip-option> {{ cat }} </mat-chip-option>
        </div>
    </mat-chip-listbox>


    <br>



    <div *ngIf="selectedSichtbarkeit == 'public'">
        Jeder kann bearbeiten und lesen
    </div>

    <div *ngIf="selectedSichtbarkeit == 'protected'">
        Sie können auswählen, wer welche Rechte besitzt:



        <mat-chip-listbox [multiple]="true" >

            <div *ngFor="let user of userList" style="display: flex; flex-direction: column;">
                <div>
                    <mat-chip-option disabled> {{user.email}}
                    </mat-chip-option>
                    <mat-chip-option [selected]="user.canRead"
                        (click)="user.canRead? user.canRead = false:  user.canRead = true">
                        darf lesen </mat-chip-option>
                    <mat-chip-option [selected]="user.canWrite "
                        (click)="user.canWrite? user.canWrite = false:  user.canWrite = true"> darf schreiben
                    </mat-chip-option>
                </div> <br> <br>

            </div> <br>
            <br> 
        </mat-chip-listbox>



    </div>
    <div *ngIf="selectedSichtbarkeit == 'privat'">
        Nur Sie sehen und bearbeiten {{ popName }}
    </div>

<br>
<br>


    <button (click)="save()"  class="share-btn">Speichern</button> <br><br><br>

</div>