import { latLng, tileLayer } from 'leaflet';
import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-draw';
import 'leaflet-editable';
import { RegioService } from 'src/app/service/regioservice/regioservice.component';
import { Storageservice } from 'src/app/service/storageservice-component/storageservice-component.component';
import { GebieteDialogComponent } from './gebiete-dialog/gebiete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { v4 as uuidv4 } from 'uuid'; // Import UUID generator
import { GibietParamsDialogComponent } from './gibiet-params-dialog/gibiet-params-dialog.component';
import { Chart } from 'chart.js';

// Interface to store polygon information
interface PolygonInfo {
    id: string;
    geoJSON: any;
    count: number;
}

@Component({
  selector: 'app-gebiete',
  templateUrl: './gebiete.component.html',
  styleUrls: ['./gebiete.component.css'],
})
export class GebieteComponent implements OnInit{
  layerControl: any;
  layers: any = [];
  map: any;
  selectedObject: any;
  anzahl: any = 0;
  pointMarkers: any;
  polygons: PolygonInfo[] = [];
  totalPoints: number = 0;
  chartInstance: Chart | null = null; 

  constructor(
    public storageService: Storageservice,
    public dialog: MatDialog,
    private regioService: RegioService
  ) {
    this.selectedObject = this.storageService.getObject();
  }

  private drawnItems!: L.FeatureGroup;

  async ngOnInit() {
    this.openDialogGebieteParams();
    this.initializeMap();
  
    const response = await this.regioService.getSchoolPoints();
    const points = response.points;
    this.plotPointsOnMap(points);
 
  }

  openDialogGebieteParams() {
    const dialogRef = this.dialog.open(GibietParamsDialogComponent, {
      data: {},
      width: '1200px',
      height: '800px',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
       console.log(result);
      }
    });
  }

  openDialogGebiete() {
    const dialogRef = this.dialog.open(GebieteDialogComponent, {
      data: {},
      width: '700px',
      height: '400px',
      panelClass: 'min-max',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.drawEditAbleShapes();
      }
    });
  }

  async drawEditAbleShapes() {
      const res = await this.regioService.getSchulBezirk();
      console.warn(res.features);
    
      res.features.forEach(async (shape: { geometry: { type: string, coordinates: any[][][][] } }) => {
          shape.geometry.coordinates.forEach(async (polygonCoordinates: any[][]) => {
              let obj: any[] = [];
            
              polygonCoordinates[0].forEach((element: any[]) => {obj.push([element[1], element[0]]);});

              const polygonId = uuidv4();

              const polygon = L.polygon(obj).addTo(this.map);
              polygon.enableEdit();
              let polygonGeoJSON = polygon.toGeoJSON();

              const count = await this.checkSchoolsInPolygon(polygonGeoJSON);

              this.polygons.push({ id: polygonId, geoJSON: polygonGeoJSON, count });
              this.totalPoints+= count;

              polygon.on('editable:vertex:dragend', async (e: any) => {
                  polygonGeoJSON = polygon.toGeoJSON();
                  await this.updatePolygonCount(polygonId, polygonGeoJSON);
                  this.updateChart(); 
                  console.log('Editing finished, polygon updated.');
              });
          });
      });
  }

async checkSchoolsInPolygon(polygonGeoJSON: any): Promise<number> {
    const response = await this.regioService.checkSchoolsInPolygon(polygonGeoJSON);
    const count = Math.round(response.count * 0.12);
    return count;
}

async updatePolygonCount(polygonId: string, polygonGeoJSON: any): Promise<void> {
    const polygonIndex = this.polygons.findIndex(p => p.id === polygonId);
    if (polygonIndex !== -1) {
        const newCount = await this.checkSchoolsInPolygon(polygonGeoJSON);
        this.totalPoints -= this.polygons[polygonIndex].count; 
        this.polygons[polygonIndex].count = newCount; 
        this.totalPoints += newCount;
    }
    console.log('Total number of schools in all polygons:', this.totalPoints);
    this.updateChart();
}


plotPointsOnMap(points: any) {
  if (this.pointMarkers) {
    this.pointMarkers.forEach((marker: L.Marker) => this.map.removeLayer(marker));
  }

  this.pointMarkers = [];

  points.forEach((point: any) => {
    const coordinates = point.coordinates;

    if (coordinates && coordinates.length === 2) {
      const lat = coordinates[1]; // Latitude
      const lng = coordinates[0]; // Longitude

      if (!isNaN(lat) && !isNaN(lng)) {
        const marker = L.marker([lat, lng]).addTo(this.map);

        const popupContent = `
          <style>
            .popup-button {
              padding: 8px 16px;
              border: none;
              border-radius: 4px;
              font-size: 14px;
              cursor: pointer;
              margin-right: 8px;
            }
            .primary-button {
              background-color: #126F62;
              color: white;
            }
            .warn-button {
              background-color: #f44336;
              color: white;
            }
            .popup-container {
              display: flex;
              flex-direction: column;
              align-items: start;
            }
          </style>
          <div class="popup-container">
            <strong>Address:</strong> ${point.address}<br>
            <p>Schulbezirk anzeigen?</p>
            <div>
              <button id="yesButton" class="popup-button primary-button">Ja</button>
              <button id="noButton" class="popup-button warn-button">Nein</button>
            </div>
          </div>
        `;

        marker.bindPopup(popupContent);

        this.pointMarkers.push(marker);

        marker.on('popupopen', () => {
          const popupElement = document.querySelector('.leaflet-popup-content');

          popupElement?.querySelector('#yesButton')?.addEventListener('click', () => {
            this.drawEditAbleShapes();
            marker.closePopup();
          });

          popupElement?.querySelector('#noButton')?.addEventListener('click', () => {
            marker.closePopup();
          });
        });
      } else {
        console.error('Invalid LatLng:', lat, lng);
      }
    } else {
      console.error('Invalid coordinates for point:', point);
    }
  });
}

  private initializeMap(): void {
    const osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    const osmAttrib =
      '&copy; <a href="http://openstreetmap.org/copyright">OpenStreetMap</a> contributors';
    const osm = L.tileLayer(osmUrl, { maxZoom: 18, attribution: osmAttrib });

    this.map = L.map('map', {
      center: latLng(51.04962, 12.1369),
      zoom: 14,
      layers: [osm],
    });

    this.drawnItems = new L.FeatureGroup();
    this.map.addLayer(this.drawnItems);

    this.map.editTools = new L.Editable(this.map);

  }


  showGrafik() {
    const grafikChart = document.querySelector('.grfikChart') as HTMLElement;
    grafikChart.style.display = 'block';
  
    this.updateChart();
  }

  private updateChart() {
    const years = Array.from({ length: 11 }, (_, i) => 2024 + i);
    const baseValue = this.totalPoints;
    const totalPointsValues = years.map((year, index) => {
      if (index === 0) return baseValue;
      else return this.randomizeTotalPoints(baseValue);
    });

    const ctx:any = (document.getElementById('chartCanvas') as HTMLCanvasElement).getContext('2d');
    if (this.chartInstance) {
      this.chartInstance.data.datasets[0].data = totalPointsValues;
      this.chartInstance.update();
    } else {
      this.chartInstance = new Chart(ctx, {
        type: 'line',
        data: {
          labels: years,
          datasets: [{
            label: 'Anzahl der Schüler prognostiziert',
            data: totalPointsValues,
            borderColor: '#007bff',
            fill: false,
            tension: 0.1
          }]
        },
        options: {
          responsive: true,
          scales: {
            x: {
              title: {
                display: true,
                text: 'Jahre'
              }
            },
            y: {
              title: {
                display: true,
                text: 'Total Points'
              }
            }
          }
        }
      });
    }
  }

  randomizeTotalPoints(points: number): number {
    const variation = points * 0.1; // 10% of total points
    return Math.floor(points + (Math.random() * 2 - 1) * variation); // Randomize +/- 10%
  }
  

}
