import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { DatahubService } from 'src/app/service/datahubservice/datahubservice.component';
import { KeycloakOperationService } from 'src/app/service/keycloakService/keycloak-operation.service';
import { ShareDialogComponent } from '../../raum-analysen/share-dialog/share-dialog.component';
import { NotfallinfrastrukturEditComponent } from '../notfallinfrastruktur-edit/notfallinfrastruktur-edit.component';
import { RoutingserviceComponent } from 'src/app/service/routingservice/routingservice.component';


@Component({
  selector: 'app-notfallinfrastruktur',
  templateUrl: './notfallinfrastruktur.component.html',
  styleUrls: ['./notfallinfrastruktur.component.css']
})
export class NotfallinfrastrukturComponent  implements OnInit{
  existingScenarios: any = [];

startAnalyse() {
  this.openDialogEdit()


  

     //@ts-ignore
   //document.getElementById('new-dialog').showModal();


}
openDeleteDialog() {
   //@ts-ignore
   document.getElementById('delete-dialog').showModal();
   return; 

}

closeDeleteDialog() {
  //@ts-ignore
  document.getElementById('delete-dialog').close();
  return; 

}


  async deleteSelectedItem() {
       


  var data = {
    name: this.selectedItem.name
  }
  await this.datahubService.deleteNachfrageData(data)

  setTimeout(()=>{
    window.location.reload()

  }, 2000)
}
  endNewMode(name: any) {



    this.selectedItem = undefined;
    this.edit = false;
    this.start = true;

    window.location.reload()


    return; 


  }
selectedItem: any;
edit = false; 
clickItem(item: any) {
  this.selectedItem = item;

  if(item._id){



  this.router.navigateByUrl("analysen/notfallinfrastruktur/" + item._id)

  } else {

  this.router.navigateByUrl("analysen/notfallinfrastruktur/" + item.name)
  }

  return; 


  if(this.selectedItem == item) {
    this.selectedItem = undefined;
    return; 
  }


}


filterItem(item: any): any {
if(!this.searchBox) {
  return true
}


  if (item.name.toLowerCase().includes(this.searchBox.toLowerCase())) {
    return true; 
  }

}
storedObject: any[] = []
  currentUser: any;

   ngOnInit(){
}

async getStoredItems() {
  //@ts-ignore
var storedObjectAll =  await this.datahubService.getPrognoseData();
  //@ts-ignore
storedObjectAll = storedObjectAll.reverse(); 
//@ts-ignore
storedObjectAll.forEach((element: { owner: any; visibility: string; canRead: string | any[]; canWrite: string | any[]; }) => {

  
  if(this.currentUser && element.owner  == this.currentUser.id) {

    this.storedObject.push(element);
  } else {
    if(element.visibility == 'public') {
      this.storedObject.push(element);
    } else {
      if( this.currentUser &&  element.visibility == 'protected' &&(element.canRead.indexOf(this.currentUser.id) > -1 || element.canWrite.indexOf(this.currentUser.id) > -1 )) {
        this.storedObject.push(element);

      }
    }

  }
  
});

}


constructor(private datahubService: DatahubService, private keyCloakService: KeycloakOperationService,private router: Router,
  private route: ActivatedRoute , public dialog: MatDialog,
private routingservice: RoutingserviceComponent) {
  this.keyCloakService.getUsersList().subscribe((users: any) => {
    
    //this.userList=users
  });

  this.keyCloakService.getUserProfile().then(async (data: any) => {
    this.currentUser= data;


   this.existingScenarios = await this.datahubService.getDocData( this.currentUser.id);

    await this.getStoredItems(); 

    this.route.params.subscribe((params: any) => {
      if (Object.keys(params).length !== 0) {


      var id = params['id'];
  
      var t =  this.storedObject.filter(el => el._id ==  id )
      if(t.length != 0) {
        this.clickItem(t[0]); 

      } else {

       // alert("Fehler: unbekannte ID: " + id);  

     
      }
      }
  
  
  
    })
  

  });


  

}
searchBox: any;
public start: any = true;

  async openDialogEdit() {
  var pop= await this.routingservice.getPopulation();



  const dialogRef =this.dialog.open(NotfallinfrastrukturEditComponent,{
    data: { 
      pop: pop
    },
    width:'1200px',
    height:'680px',
  });
  dialogRef.afterClosed().subscribe((result:any) => {
   
    if (result) {
      this.addTempItem(result)
    }
  });
}

addTempItem(item: any) {
  this.storedObject.unshift(item)
 // this.selectedItem = item; 
  this.clickItem(item); 

}
openDialogShare(){
  var additionalText = "Schaue dir folgende Prognose an: " +window.location.href  + "/" + this.selectedItem._id 


  const dialogRef =this.dialog.open(ShareDialogComponent,{
    data: { title:  "Prognose "  +this.selectedItem.name + " teilen", additionalText: additionalText , id:  this.selectedItem._id,
      skipCheck: true
    },
    width:'600px',
    height:'320px',
  });
  dialogRef.afterClosed().subscribe((result:any) => {
    
  
    if (result) {

    }
  });
}

}
