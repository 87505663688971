<div class="row1">
    <div id="map-container" > 
        <div id="map" >
            
        </div>
    </div>



    <div id="chart-container">
      <div [ngSwitch]="radarChartIndex">

        <div *ngSwitchCase="0">
          <h2>Absolute Indikatorenwerte</h2>
        </div>
      
        <div *ngSwitchCase="1">
          <h2>Veränderung Indikatorenwert zum Vorjahr</h2>
        </div>
      
      </div>
      
        <div style=" display:flex;justify-content:flex-start;align-items:center;flex-direction:column">
              <div >
                <canvas id="radarChart"  width="700" height="400">
                  {{radarChart}}
              </canvas>
              </div>
              <!-- <div *ngFor="let indi of minMaxObject">
                    <div style="margin-top:20px">
                      {{indi.indicator.Name}}
                      <div style="width: 400px;display:flex; justify-content:space-between;align-items:center; gap:8px">
                        {{indi.minValue}}
                        <div style="height:4px; width:250px; background:rgb(20, 161, 255);
                        border-radius:15px;
                        border:1px solid deepskyblue">

                        </div>
                        {{indi.maxValue}}
                      </div>
                    </div>
              </div> -->

              <!-- <button (click)="testen()">xfh</button> -->

            <div  style="margin-top: 15px; width: 100%;display:flex;justify-content:center;align-items:center;gap:10px; flex-wrap:wrap">
              <div>
                <!-- Mat Card with radio buttons -->
                <mat-card style="width: 400px; margin-top:-40px">
                  <mat-card-content>
                    <mat-radio-group>
                      <mat-radio-button value="selected" (change)="onRadioChange($event)" checked>
                        Arithmetisches Mittel über die gewählten Landkreise
                      </mat-radio-button>
                      <br />
                      <mat-radio-button value="all" (change)="onRadioChange($event)">
                        Arithmetisches Mittel über alle 14 Landkreise
                      </mat-radio-button>
                    </mat-radio-group>
                  </mat-card-content>
                </mat-card>
              </div>

                <div>
                  <mat-form-field appearance="fill"style="width:350px">
                    <mat-label>Indikator</mat-label>
                    <mat-select>
                      <mat-option  *ngFor="let ind of indiForRadar" [value]="ind.Name" (click)="onIndicatorSelected(ind)">
                        {{ind.Name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div *ngIf="selectedIndicator">
                  <mat-form-field appearance="fill"style="width:100px">
                    <mat-label>Jahr</mat-label>
                    <mat-select >
                      <mat-option *ngFor="let year of selectedIndicator.years" [value]="year" (click)="onIndicatorSelectedYear(selectedIndicator,year)">
                        {{  formatYearLabel(year) }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

            </div>
        </div>
    </div>
</div>
