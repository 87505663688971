<mat-icon style="margin: 5px;"(click)="    this.dialogRef.close({});" >close</mat-icon>

<div style="padding: 15px">
<h3> Startzeit auswählen </h3>

<mat-chip-listbox class="mat-mdc-chip-set-stacked" aria-label="Cutest dog breeds" [(ngModel)]="selTime">
  
      <mat-chip-option  *ngFor = "let dog of bestBoys" >
        {{dog}}</mat-chip-option>
    
  </mat-chip-listbox>

  <br> 
  <br> 
  <br> 

  <button *ngIf="selTime"class="share-btn" (click)="endDialog()">Anwenden</button> <br><br><br>

</div>