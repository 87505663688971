import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart } from 'chart.js/auto';
import MapLibreGL, { Popup } from 'maplibre-gl';
import { DatahubService } from 'src/app/service/datahubservice/datahubservice.component';
import { KeycloakOperationService } from 'src/app/service/keycloakService/keycloak-operation.service';
import { SimulationService } from 'src/app/service/simulationservice/simulation.service';
import { Storageservice } from 'src/app/service/storageservice-component/storageservice-component.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { tileLayer, latLng, Draggable } from 'leaflet';
import { RegioService } from 'src/app/service/regioservice/regioservice.component';
import L from 'leaflet';
import { DialogBreaksComponent } from '../../data-regio/dashboards/dialog-breaks/dialog-breaks.component';
import { MatDialog } from '@angular/material/dialog';
import { last } from 'rxjs';
declare var require: any
const classyBrew = require('classybrew');

export type MarkerObj = {
  leaflet: L.Marker;
  plain: any;
};

@Component({
  selector: 'app-data-doc-leaflet',
  templateUrl: './data-doc-leaflet.component.html',
  styleUrls: ['./data-doc-leaflet.component.css']

})




export class DataDocLeafletComponent {
deselectAll() {
  this.categoriesSelected = []
}
selectedAll() {
  this.categoriesSelected = this.categories
}

  userList: any;
closeDialog() {
  //@ts-ignore
  document.getElementById('dialog').close();

}
  pieChartValid: any = true;



updatePieChart(type: string) {
  this.chart?.destroy();
  if(this.pieType == 'Alter' ) {
    this.createChart(); 
  

    var pop = this.filterActiveDoctorsByTypeAndYear(this.docPop, this.categoriesSelected, this.currentYear)

    if (!this.renteMode) {
      this.breaksFin = [30, 35, 40, 45, 50, 55, 60, 65,70 ];
    } else {
      this.breaksFin = [1, 2, 3, 4, 5, 6, 7, 8];

    }



    //2. Create Pie Chart 
    this.updateChartData(pop, this.breaksFin);
    return; 
  }
 

    // Gruppieren nach Typ und Durchschnitt der Kapazitäten berechnen
    const groupedData = this.docPop.reduce((acc: any, item: any) => {
      if (!acc[item.Typ]) {
        acc[item.Typ] = { totalCapacity: 0, count: 0 };
      }
    //  console.warn(item.capacity, isNaN(item.capacity))
      acc[item.Typ].totalCapacity = acc[item.Typ].totalCapacity + parseFloat(item.capacity);
      acc[item.Typ].count += 1;
      return acc;
    }, {});

    console.warn("grouped", groupedData)

    const labels = Object.keys(groupedData);
    const averageCapacities = labels.map(label => {
      const { totalCapacity, count } = groupedData[label];
      return (totalCapacity / count).toFixed(2);
    });

    console.warn("DA", averageCapacities)

    this.chart = new Chart('myChart', {
      type: 'pie',
      data: {
        labels: labels,
        datasets: [{
          label: 'Durchschnittliche Kapazität',
          data: averageCapacities,
          borderWidth: 1
        }]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false 
          },
          title: {
            display: false,
            text: 'Durchschnittliche Kapazität pro Einrichtungstyp'
          }
        }}
    });


  


}
pieType: any = 'Alter';
modelChangeFn($event: any) {

}
  chooseLocationMode: boolean = false;
  locationMarker: L.Marker<any> | undefined;
  newDocKapa: any = 0;
  endDeleteMode() {
    this.mapDeletionMode = false;
    var map = document.getElementById('map')
    //@ts-ignore
    map!.style.cursor = 'pointer';

  }
  selectedMarker!: L.Marker<any>;
  mapDeletionMode: boolean = false;
  checkIfYear(year: number, active: any) {

    return active.indexOf(year) != -1

  }

  newKapa: any;
  zeitRaum = [2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034]
  newInactivity: any;
  hideLineChart: any = false;
  hideBarChart: any  = true;
  setBreaks: any;

  chosenBreaks: any = [];
  valuesArray: any = [];
  valuesStored: any = [];
  valuesBreaks: any = [];
  breaksAmount: any = [];
  breaks: any = [];
  breaksFin: any = [];
  docPop: any = [];
  newActivity: any;


  activateDeleteMode() {

    this.mapDeletionMode = true;
    if (this.selectedMarker)
      this.map.removeLayer(this.selectedMarker)
    var map = document.getElementById('map')
    //@ts-ignore
    map!.style.cursor = 'not-allowed';
    //@ts-ignore
    document.getElementById('deletePop').showModal();







  }
  updateDoc(einrichtung: any) {
    //alert(this.newDocAge + ":" +(this.currentYear - this.selectedEinrichtung.plain.birth ))
    //einrichtung.plain.birth = this.currentYear - this.newDocAge;
    einrichtung.plain.Name = this.newDocName;
    einrichtung.plain.Adresse = this.formData.address;
    einrichtung.plain.capacity[this.currentYear - 2024] = parseInt(this.newKapa);
//update years after

    for(var i = this.currentYear - 2024; i < 11 ; i++) {
      einrichtung.plain.capacity[i] = parseInt(this.newKapa);
    }
 

    einrichtung.plain.active = this.newActivity;
    var item = this.docPop.filter((ein: { Name: any; }) => ein.Name == einrichtung.plain.Name)

    if (item[0]) {
      item[0].birth = this.currentYear - this.newDocAge;
      item[0].Name = this.newDocName;
      if (this.formData.address)
        item[0].Adresse = this.formData.address;
      if (this.formData.latlng.lng)
        item[0].geometry.coordinates = [this.formData.latlng.lng, this.formData.latlng.lat];
      item[0].active = this.newActivity;
      item[0].capacity[this.currentYear - 2024]  = this.newKapa;
    }



    this.chooseLocationMode = false;
    if (this.locationMarker)
      this.map.removeLayer(this.locationMarker)

    this.update()

    this.clickEinrichtung(this.newDocName)
  }

  markersObjRefMap = new Map<string, MarkerObj>();


  options = {
    layers: [
      tileLayer('https://m.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
    ],
    zoom: 9,
    minZoom: 9,
    center: latLng(51.04962, 11.8369)
  };
  markerClusterGroup: any;
  layers: any = [];
  layerControl: any;
  selectedObject: any;
  isExtended: any = true;
  selectedDoc: any;
  topContainerExtended: boolean = true;
  newDocAge: any;
  newDocAdress: any;

  nextStep(url: string) {
    this.closeModal('dialog4');
    if (url == "/datahub2/docs") {
      window.location.reload();
    } else {


      this.router.navigate([url]);

     
     

    }





  }
  newBirthYear: any;
  sourceID: any = [];
  layerID: any = [];
  rentenAlter: any;
  renteMode: boolean = false;
  formData: any =
    {
      name: "",
      address: "",
      latlng: []
    }

  onMapReady(map: any) {
    // Do stuff with map
    this.map = map;

    this.markerClusterGroup = L.markerClusterGroup({
      disableClusteringAtZoom: 13
    }



    );


    this.map?.on('click', async (e: any) => {

      if (this.chooseLocationMode) {


        var res = await this.datahubService.getAdressForCoordinates(e.latlng.lat, e.latlng.lng)
        //@ts-ignore
        if (res.features[0]) {
          var icon = L.divIcon({
            className: 'custom-div-icon',
            html: "<div class='marker-pin'></div><i  style='color:black;'  class='material-icons'>   fiber_manual_record </i>",
            iconSize: [24, 36],
            iconAnchor: [12, 36],

          });

          this.locationMarker = L.marker([e.latlng.lat, e.latlng.lng], { icon: icon, draggable: true })
          this.locationMarker.addTo(this.map);
          this.locationMarker.on('dragend', async (ek: any) => {


            var latlng = ek.target.getLatLng();

            var res = await this.datahubService.getAdressForCoordinates(latlng.lat, latlng.lng)
            //@ts-ignore
            if (res.features[0]) {
              //@ts-ignore
              this.formData.address = res.features[0].properties.label
              this.formData.latlng = latlng;
            } else {
              alert("Standort nicht bestimmbar!")
            }
          })



          //@ts-ignore
          this.formData.address = res.features[0].properties.label
          this.formData.latlng = e.latlng;
          if (!this.selectedEinrichtung)
            //@ts-ignore
            document.getElementById('dialog').showModal();
        } else {
          alert("Standort nicht bestimmbar!")
        }


      }
    })



    this.setLayer()

  }


  addMarker() {
    throw new Error('Method not implemented.');
  }
  selectedAddressStr: any = '';
  graphType: any = 'Anzahl Einrichtungen';
  showStats() {
    throw new Error('Method not implemented.');
  }







  newDocName: any;
  selectedSichtbarkeit: any;

  mockUser = [{
    id: "862233ca-4af4-4391-8bd4-be7d26e4f202",
    firstname: "ahmed",
    lastname: "jab",
    email: 'a@jab.de',
    canRead: false,
    canWrite: false,



  }, {
    id: "03b316c6-24ec-4c36-b23d-f6cbf8a7b180",
    firstname: "Nico",
    lastname: "Test",
    email: 'nicorud@icloud.com',
    canRead: false,
    canWrite: false,
  }



  ]
  selectedSichtbarkeit2: any;
  userID: any;
  metaScenData: any;
  saveNewDoc() {

    var newDoc = {
      Name: this.newDocName,
      Typ: this.categoriesSelectedForNew,
      Kategorie: "Gesundheit",
      Heimatgemeinde: "15084",
      einrichtungs_id: 999,
      active: [2025, 2026],
      geometry: {
        type: "Point",
        coordinates: this.selectedAddress.geometry.coordinates
      },
      birth: 1945
    }

    this.docPop.push(newDoc)


    //add locally 
    //this.allDocData[this.currentYear - 2024].einrichtung.push(newDoc);
    //for (var i = this.currentYear + 1; i < 2035; i++) {this.allDocData[i - 2024].einrichtung.push(newDoc); }
    this.update()
    this.closeModal('dialog')
  }
  categoriesSelectedForNew: any;
  sortArray(array: any): any {
    var selectedItems = array.filter((el: any) => { return this.selectedEinrichtung && el.einrichtungs_id == this.selectedEinrichtung.einrichtungs_id })
    //var toBeDeletedItems = array.filter((el: any) => { return el.markedForDel ==  true})
    //var deletedItems     = array.filter((el: any) => { return el.inactive ==  true})
    var normalItems = array.filter((el: any) => { return !(this.selectedEinrichtung && el.einrichtungs_id == this.selectedEinrichtung.einrichtungs_id) })

    var sortedArray = selectedItems.concat(normalItems)

    return sortedArray;
  }


  colorPalette = [
    "#30123b",
    "#5f01a3",
    "#9e1795",
    "#cb3d70",
    "#e9573f",
    "#f79c1d",
    "#f7e43b",
    "#a8e630",
    "#34c76d",
    "#10684E"
  ];

  reversedColorPalette = [
    "#10684E",
    "#34c76d",
    "#a8e630",
    "#f7e43b",
    "#f79c1d",
    "#e9573f",
    "#cb3d70",
    "#9e1795",
    "#5f01a3",
    "#30123b",
];

  openDialogBreaks() {
    var selectedChartObject: any = {};


    var filteredPop = this.getFilteredPop(this.categoriesSelected);
    filteredPop.forEach(el => {
      var restZeit = 100000;
      if (el.birth) {
        restZeit = this.rentenAlter - (this.currentYear - el.birth)
      }
      if (restZeit >= 0)
        this.valuesStored.push(restZeit)

    });
    this.valuesStored = this.valuesStored.sort((a: number, b: number) => a - b);
    this.valuesArray = this.valuesStored


    //filter((el: any) => { return (this.rentenAlter - (this.currentYear - el.birth)) < 1 || el.inactive});



    const dialogRef = this.dialog.open(DialogBreaksComponent, {
      data: { chosenBreaks: this.chosenBreaks, valuesArray: this.valuesArray, valuesStored: this.valuesStored, valuesBreaks: this.valuesBreaks, breaksAmount: this.breaksAmount, selectedChartObject: selectedChartObject },
      width: '700px',
      height: '400px',
      panelClass: 'min-max'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.warn("RES", result);
      if (result) {
        this.setBreaks = result.setBreaks;
        this.breaksAmount = parseInt(result.breaksAmount);
        this.valuesArray = result.valuesArray;
        this.valuesStored = result.valuesStored;
        this.valuesBreaks = result.valuesBreaks;
        setTimeout(() => {
          this.breaks = result;

          var breaksFin: any;
          switch (result.setBreaks) {
            case "Gleichmäßig":
              breaksFin = this.legendeEqual();
              break;
            case 'Benutzerdefiniert':
              breaksFin = this.legendeBenutzerDef();
              break;
            case 'jenks':
              breaksFin = this.legendeJenks();
              break;
            case 'quantile':
              breaksFin = this.legendeQuentile();
              break;
          }
          this.breaksFin = breaksFin
          this.update()



          // this.updateChartWithBreaks(result); 
        }, 10);
      }
    });
  }

  markedAll: boolean = false;
  docPopID: any;
  modeSelected: any;
  activeOnly: any = true;
  inActiveOnly: any = true;
  markAll(bool: boolean) {
    this.markedAll = bool;

    this.docPop.forEach((einrichtung: any) => {


      if (this.categoriesSelected.indexOf(einrichtung.Typ) > -1 &&
        (einrichtung.Name.toLowerCase().includes(this.searchBox2.toLowerCase()) || this.searchBox2 == '' || einrichtung.Typ.toLowerCase().includes(this.searchBox2.toLowerCase())
          || einrichtung.Kategorie.toLowerCase().includes(this.searchBox2.toLowerCase()) || (this.currentYear - einrichtung.birth) == this.searchBox2)) {
        einrichtung.markedForDel = bool;
        if (einrichtung.inactive && bool == false) {
          einrichtung.inactive = false;
        }
      }


    });

  }

  legendeBenutzerDef() {
    let combinedValues: any = [];
    this.valuesBreaks.forEach((obj: { from: string; to: string; }) => {
      combinedValues.push(parseFloat(obj.from));
      combinedValues.push(parseFloat(obj.to));
    });
    let uniqueValues = Array.from(new Set(combinedValues));
    uniqueValues.sort((a: any, b: any) => a - b);
    console.log(uniqueValues);
    return uniqueValues;
  }

  legendeJenks() {
    console.log(this.valuesStored);
    const brew = new classyBrew();
    brew.setSeries(this.valuesStored);
    brew.setNumClasses(this.breaksAmount);
    brew.setColorCode("BuGn");
    brew.classify('jenks');
    const values: number[] = brew.getBreaks();
    return values
  }

  legendeQuentile() {
    console.log(this.valuesStored);
    const brew = new classyBrew();
    brew.setSeries(this.valuesStored);
    brew.setNumClasses(this.breaksAmount);
    brew.setColorCode("BuGn");
    brew.classify('quantile');
    const values: number[] = brew.getBreaks();
    return values
  }

  legendeEqual() {
    console.log(this.valuesStored);

    var a = Math.min.apply(null, this.valuesStored)
      , b = Math.max.apply(null, this.valuesStored)
      , c = []
      , d = a
      , e = (b - a) / this.breaksAmount;
    for (var i = 0; i <= this.breaksAmount; i++)
      c[i] = d,
        d += e;
    return c[this.breaksAmount] = b,

      c.sort(function (a, b) {
        return a - b
      }),
      c
  }


  getFilteredPop(categoriesSelected: any) {
    var filteredPop: any[] = []

    this.docPop.forEach((einrichtung: any) => {
      if (categoriesSelected.indexOf(einrichtung.Typ) > -1 &&
        (einrichtung.Name.toLowerCase().includes(this.searchBox2.toLowerCase()) || this.searchBox2 == '' || einrichtung.Typ.toLowerCase().includes(this.searchBox2.toLowerCase())
          || einrichtung.Kategorie.toLowerCase().includes(this.searchBox2.toLowerCase()) || (this.currentYear - einrichtung.birth) == this.searchBox2)) {

        var age = this.currentYear - einrichtung.birth;
        if (age <= this.maxAge && age >= this.minAge) {
          if ((this.activeOnly && !einrichtung.inactive) || (this.inActiveOnly && einrichtung.inactive))
            filteredPop.push(einrichtung)
        }
      }
    });
    return filteredPop;


  }

  getTotalNumberForCategory(categoriesSelected: any, isDeleted: boolean) {

    return this.filterInactiveDoctorsByTypeAndYear(this.docPop, categoriesSelected, this.currentYear).length
    var popDeleted = this.getFilteredPop(categoriesSelected).filter(el => { return el.inactive == true });
    var popActive = this.getFilteredPop(categoriesSelected).filter(el => {
      if (!el.inactive) {
        return true;
      }
      if (el.inactive == true) {
        return false;
      }

      return true;


    });
    if (!isDeleted) {
      return popActive.length;
    } else {
      return popDeleted.length;
    }


  }

  checkForDeleteMode() {
    var h = this.docPop.filter((el: { markedForDel: any; }) => el.markedForDel == true)
    return h.length;
  }
  searchBox2: any = '';
  filterResult() {

  }
  filterActiveDoctorsByTypeAndYear(doctors: any, types: string[], year: number) {
    return this.docPop.filter((doctor: {
      birth: any; Typ: string; active: number[]; 
}) => {
  var checkAge = true; 

      if(doctor.birth) {
        var age = this.currentYear - doctor.birth
        var checkAge  = age >= this.minAge && age <= this.maxAge
        
      }
      
      
      
      
      
      return types.includes(doctor.Typ) && doctor.active.includes(year) && checkAge });
  }


  filterActiveDoctorsByType(doctors: any, types: string[]) {
    return doctors.filter((doctor: { Typ: string; active: number[]; }) => types.includes(doctor.Typ));
  }


  filterInactiveDoctorsByTypeAndYear(doctors: any, types: string[], year: number) {
    return doctors.filter((doctor: { Typ: string; active: number[]; }) => types.includes(doctor.Typ) && !doctor.active.includes(year));
  }

  filterActiveDoctorsByTypeAndRestYear(doctors: any, types: string[], restYear: number) {
    return doctors.filter((doctor: { Typ: string; active: number[]; }) => {
      //get last active year
      var lastYear = doctor.active[doctor.active.length - 1]
      return types.includes(doctor.Typ) && ((lastYear - this.currentYear) == restYear)
    });
  }

  isDoctorActive(doctor: any): boolean {
    return doctor.active.includes(this.currentYear)
  }




  newDocCat: any;

  idLayerCounter: number = 0;
  checkName: boolean = false;
  showDropdown: boolean = false;
  selectedAddress: any;
  filteredAdressList: any = [];
  adressList: any = [];
  adressListCor: any = [];
  meta: any; age: any;
  minAge: any = 99;
  maxAge: any = 0;
  popName: any = '';
  existingScenarios: any = [];
  searchExtended: any = false;
  searchBox: any = '';
  public currentYear: any = 2024;
  public map: any;
  public sachsenAnhaltShape: any;
  public burgenlandkreisShape: any;
  public chart: any;
  public lineChart: any;
  public barChart: any;
  einrichtungen: any = [];
  deleteMode: any = false;
  detailMode: any = true;
  selectedEinrichtung: any;
  //allDocData: any = [];
  categories: any = [];;
  categoriesSelected: any = [];




  async focusOutFunction() {
    /*if(this.selectedAddress.length % 4 == 0 && this.selectedAddress.length > 3) {
    var hh = await this.datahubService.getCoordinatesForAdress(this.selectedAddress);
    console.warn(hh);
    }
    //@ts-ignore
    hh.features[0].geometry.coordinates*/
  }


  savePop() {
    //@ts-ignore
    document.getElementById('dialog2').showModal();
  }


  getColorForItem(item: any) {
    if (item == this.selectedEinrichtung) { return '#bfff80'; }
    if (item.markedForDel) { return '#ffb3b3' }
    if (!this.isDoctorActive(item)) { return 'grey' }
    return 'white'
  }



  async saveDoc() {

    var canRead: string[] = [];
    var canWrite: string[] = []

    this.userList.forEach((us: { canRead: any; canWrite: any; id: string; }) => {
      if (us.canRead || us.canWrite) {
        canRead.push(us.id)
      }
      if (us.canWrite) {
        canWrite.push(us.id)
      }

    })


    //permission check:

    if (!this.popName) {
      alert("Name darf nicht leer sein!");
      return;
    }

    if (this.popName == this.metaScenData.name) {
      //we might to check if user has right to do that
      if (!this.metaScenData.canWrite || this.metaScenData.canWrite.indexOf(this.userID) == -1) {
        alert("Keine Rechte " + this.metaScenData.name + " zu bearbeiten. Bitte anderen Namen wählen");
        return
      }
    }


    var data = {
      name: this.popName,
      createdBy: this.userID,  //UserID Nico - fix e61d7714-314e-451a-8e15-10a383010ae5'
      //user Ahmed "862233ca-4af4-4391-8bd4-be7d26e4f202"
      createdAt: Date.now(),
      access: this.selectedSichtbarkeit,
      canRead: canRead,
      canWrite: canWrite,
      pop: this.docPop

    }


    await this.datahubService.addDocData(data)
    //@ts-ignore
    document.getElementById('dialog2').close();
    //@ts-ignore
    document.getElementById('dialog4').showModal();
  }
  closeModal(id: string) {


    //@ts-ignore
    document.getElementById(id).close();
  }


  addNewDoc() {
  
    this.chooseLocationMode = false;
    if (this.locationMarker)
      this.map.removeLayer(this.locationMarker)

    const endYear = 2034;
    var yearArray = Array.from({ length: endYear - this.currentYear + 1 }, (_, i) => this.currentYear + i);
    var birthYear = this.currentYear - parseInt(this.newDocAge)
    var endYearRente = birthYear + parseInt(this.rentenAlter)
    if (this.rentenAlter) {
    
      yearArray = yearArray.filter(y => y <= endYearRente)

    }
    var docObj = {
      Kategorie: "Gesundheit",
      Typ: this.newDocCat,
      Name: this.newDocName,
      Adresse: this.formData.address,
      geometry: {
        type: "Point",
        coordinates: [this.formData.latlng.lng, this.formData.latlng.lat]
      },
      Heimatgemeinde: '',
      einrichtungs_id: '',
      birth: birthYear,
      active: yearArray,
      capacity:  new Array(10).fill( this.newDocKapa )
    }


    this.docPop.push(docObj)


    console.warn("DOC", docObj)

    //reset

    this.newDocAdress = ''
    this.newDocName = ''
    this.newBirthYear = ''
    this.newDocKapa = 0
    this.newDocAge = ''
    this.newDocCat = ''
    this.formData.address = ''



    this.closeModal('dialog')
    this.update()
  }
  getLogo(sTyp: any) {
    var icon = '';

    if (this.meta.length = 0) {
      return;
    }
    this.meta.forEach((bereich: any) => {
      bereich.Typen.forEach((typ: any) => {
        if (typ.Typ == sTyp) {
          icon = typ.Icon;
        }
      })
    });
    return "../../../../assets/icon/" + icon;
  }

  addDoc() {
    this.selectedEinrichtung = undefined;
    this.newDocAdress = ''
    this.newDocName = ''
    this.newBirthYear = ''
    this.newDocKapa = 0
    this.newDocAge = ''
    this.newDocCat = ''
    this.formData.address = ''

    //@ts-ignore
    document.getElementById("dialog").showModal();

  }

  checkDate(date: any): any {
    var dateParsed = new Date(date);
    var dateformatted = dateParsed.toLocaleString('en-GB', { timeZone: 'UTC' });
    var tempSearchBox = this.searchBox.replaceAll(".", "/");
    tempSearchBox = tempSearchBox.replaceAll("-", "/");
    return dateformatted.includes(tempSearchBox)
  }




  selectedCoordinatesLatLng: any;
  async getCoordinates() {

    this.checkName = false;
    this.showDropdown = false;
    if (this.selectedAddress.length % 4 == 0 && this.selectedAddress.length > 3) {

      var hh = await this.datahubService.getCoordinatesForAdress(this.selectedAddress);

      //@ts-ignore
      hh.features[0].geometry.coordinates

      //@ts-ignore
      if (hh.features.length > 0) {
        this.selectedCoordinatesLatLng = {
          //@ts-ignore
          lat: hh.features[0].geometry.coordinates[1],
          //@ts-ignore
          lng: hh.features[0].geometry.coordinates[0]
        }
      }
    }
    console.log(this.selectedCoordinatesLatLng)
    this.storageService.setSelectedMarker(this.selectedCoordinatesLatLng);
    //this.storageService.setStreetName(this.selectedAddress)
  }

  formatAdress(address: any) {
    var returnString = ''

    var streetName = address.properties.street + ' ' + address.properties.housenumber + ", ";
    var cityName = address.properties.postalcode + ' ' + address.properties.county + ' ' + address.properties.Region + + ", ";
    var country = address.properties.country

    if (streetName) {
      returnString = streetName;
    }

    if (cityName) {
      returnString = returnString + cityName;
    }

    returnString = returnString + country;
    return returnString;
  }

  async completeDrop() {
    this.filteredAdressList = [];
    this.showDropdown = true;
    if (this.selectedAddressStr.length < 4) {
      return;
    }
    var res = await this.datahubService.getAutocompleteList(this.selectedAddressStr);

    console.warn(res)
    //@ts-ignore
    this.filteredAdressList = res.features;
  }
  saveFormData() {

    if (this.formData.latlng) {
      // this.reachComponent.addMarkerWithMapRef(undefined, undefined, this.formData.name, this.formData.address)
      //  this.formData.name = ''
      // this.formData.address = ''
      // this.formData.latlng = {}
    } else {
      alert("Addresse nicht gefunden!")
    }
  }
  async completeDropNew(pos: any, event: any) {

    if (event.key == 'Enter') {
      this.filteredAdressList = [];
      this.saveFormData();
      return;
    }


    this.filteredAdressList = [];
    this.showDropdown = true;
    if (pos.address.length < 4) {
      return;
    }
    var res = await this.datahub.getAutocompleteList(pos.address);
    console.warn("res", res);
    //@ts-ignore
    this.filteredAdressList = res.features;
  }

  clickEinrichtung(einrichtungsName: any) {


    if (this.selectedMarker)
      this.map.removeLayer(this.selectedMarker)

    var einrichtung = this.markersObjRefMap.get(einrichtungsName)
    if (!einrichtung) { return }

    this.selectedEinrichtung = einrichtung;
    this.newDocAdress = einrichtung.plain.Adresse;

    this.formData.address = einrichtung.plain.Adresse;

    this.newDocAge = this.currentYear - einrichtung.plain.birth;
    this.newDocName = einrichtung.plain.Name;

    this.newKapa = einrichtung.plain.capacity[this.currentYear - 2024];
   // this.newDocAge = this.currentYear - this.selectedEinrichtung.plain.birth


    this.newActivity = einrichtung.plain.active;
    var icon = L.divIcon({
      className: 'custom-div-icon',
      html: "<div class='marker-pin'></div><i  style='color: black ;'  class='material-icons'>   radio_button_unchecked </i>",
      iconSize: [24, 36],
      iconAnchor: [12, 36],

    });


    this.selectedMarker = L.marker([einrichtung.plain.geometry.coordinates[1], einrichtung.plain.geometry.coordinates[0]], { icon: icon }).addTo(this.map);


    this.map.flyTo([einrichtung.plain.geometry.coordinates[1], einrichtung.plain.geometry.coordinates[0]])
  }

  activateSingleEinrichtung(selEinrichtung: any) {
    // delete elements after that year also
    for (var i = this.currentYear; i < 2035; i++) {
      // var elements = this.allDocData[i - 2024].einrichtung.filter((el: any) => { return el.Name == selEinrichtung.Name && el.Typ == selEinrichtung.Typ })
      //if (elements.length > 0)
      // elements[0].inactive = false;
    }
    this.update()
  }

  deleteSingleEinrichtung(selEinrichtung: any) {
    // delete elements after that year also
    for (var i = this.currentYear; i < 2035; i++) {
      // var elements = this.allDocData[i - 2024].einrichtung.filter((el: any) => { return el.Name == selEinrichtung.Name && el.Typ == selEinrichtung.Typ })
      //if (elements.length > 0)
      //  elements[0].inactive = true;
    }
    this.update()
  }


  deleteMultipleEinrichtung() {
    this.docPop.forEach((element: any) => {
      if (element.markedForDel == true) {
        element.active = element.active.filter((year: any) => year < this.currentYear)
        element.markedForDel = false

      }
    })


    this.update()
  }





  async checkSteetName() {
    console.log('Überprüfen');
    if (this.checkName) {
      //@ts-ignore
      await this.storageService.setStreetName(this.reachComponent.newMarker?._latlng);
      console.log('Überprüfen');
      if (this.storageService.imagePath !== '') {
        console.log('Überprüfen 2');
        this.selectedAddress = await this.storageService.getStreetName();
        console.log(this.selectedAddress);

        if (this.selectedAddress !== undefined) {
          if (this.selectedAddress == '') {
            this.selectedAddress = 'Straßennamen nicht gefunden'
          }

        }
      }

    }

  }

  async chooseLocation() {
    this.checkName = true;
    this.chooseLocationMode = !this.chooseLocationMode ;
    this.selectedAddress = undefined
    //@ts-ignore
    document.getElementById("dialog").close();
    //this.reachComponent.addMarker();

  }

  fillList() {
    this.simulationsService.getAdress().subscribe((options: any) => {
      options.features.forEach((feature: any) => {
        const s = feature.properties?.street_name;
        const c = feature.geometry?.coordinates;
        this.adressListCor!.push({
          adress: s,
          coord: c
        });
        this.adressList.push(s);
      });
      this.adressList = Array.from(new Set(this.adressList));
    });
  }


  constructor(private simulationService: SimulationService, private keyCloakService: KeycloakOperationService, private datahub: DatahubService, private router: Router,
    private regioService: RegioService, public dialog: MatDialog,
    private datahubService: DatahubService, public storageService: Storageservice, private simulationsService: SimulationService, private route: ActivatedRoute,) {
    this.selectedObject = this.storageService.getObject()
    this.setLayer();
      


  }





  async ngOnInit(): Promise<void> {


    this.keyCloakService.getUsersList().subscribe((users: any) => {
      console.log(users);
      this.userList=users
      console.warn("USERLIST", this.userList)
    });




    this.keyCloakService.getUserProfile().then(async (data: any) => {
      this.userID = data.id;




      //UserID Nico - fix e61d7714-314e-451a-8e15-10a383010ae5'
      //user Ahmed "862233ca-4af4-4391-8bd4-be7d26e4f202"
      this.existingScenarios = await this.datahubService.getDocData(this.userID);


      this.fillList();

      this.route.params.subscribe((params: any) => {
        if (Object.keys(params).length !== 0) {
          this.docPopID = params['id'];
        }



      })

      //@ts-ignore
      document.getElementById('dialog3').showModal();

        //@ts-ignore
        document.getElementById('dialog3').addEventListener('cancel', (event) => {
     
          setTimeout(()=> {
           
               //@ts-ignore
            document.getElementById('dialog3').showModal();

          },100)
           
      });
      // this.initMap();
      // this.initSachsenAnhalt();
      // this.initBurgenlandkreisShape();


      this.setPop(this.existingScenarios[0].pop);



    });

  }


  setPop(pop: any) {
    //this.currentYear = 2024;
    //this.allDocData = pop;  
    var presentCategories: any[] = []

    pop.forEach((element: { Typ: any; }) => {
      if (presentCategories.indexOf(element.Typ) == -1) {
        presentCategories.push(element.Typ);
      }
    });
    console.warn("p", presentCategories);


    this.categories = presentCategories;
    this.categoriesSelected = presentCategories;
    this.createChart();
    this.createLineChart()
    this.update();


  }


   getColor(value: any, bk: any, colorPalette: any) {

    const findInsertionIndex = (arr: number[], target: number): number => arr.findIndex(n => n > target) === -1 ? arr.length : arr.findIndex(n => n > target);
    var index = findInsertionIndex(bk, value)
    var color = colorPalette[index]

    if(value <= bk[0]) {
      console.warn("Restlaufzeit " + value, index ,colorPalette[0] )
      return colorPalette[0]
    }

    if(value > bk[bk.length-1]) {
      return colorPalette[colorPalette.length-1]
    }

   // console.warn("Restlaufzeit " + value, index , colorPalette[index])

    return color; 

   }


  getIcon(einrichtung: any) {


    if (this.renteMode) {

      var val = this.calculateRemainingTime(einrichtung)

      //const findInsertionIndex = (arr: number[], target: number): number => arr.findIndex(n => n > target) === -1 ? arr.length : arr.findIndex(n => n > target);
      //var index = findInsertionIndex(this.breaksFin, leftYears)
      //var color = this.colorPalette[index]
      //console.warn(leftYears, index, this.breaksFin, color)
      var palette = this.colorPalette


   

    } else {
   
      val = this.currentYear - einrichtung.birth; 

     // const findInsertionIndex = (arr: number[], target: number): number => arr.findIndex(n => n > target) === -1 ? arr.length : arr.findIndex(n => n > target);
      //var index = findInsertionIndex(this.breaksFin, age)
      palette = this.reversedColorPalette


    }

    var color = this.getColor(val, this.breaksFin, palette)



    return L.divIcon({
      className: 'custom-div-icon',
      html: "<div class='marker-pin'></div><i  style='color:" + color + ";'  class='material-icons'>   fiber_manual_record </i>",
      iconSize: [24, 36],
      iconAnchor: [12, 36],

    });

  }
  clearMapNew() {

    if (this.selectedMarker)
      this.map.removeLayer(this.selectedMarker)

    if (this.locationMarker)
      this.map.removeLayer(this.locationMarker)


    this.markersObjRefMap.forEach(mark => {

      this.map.removeLayer(mark.leaflet)


    })

    this.markersObjRefMap.clear();

  }

  deactivateLocation(einrichtung: any) {
    if (einrichtung.active.length > 0) {
      einrichtung.active.sort()
      einrichtung.active = einrichtung.active.filter((year: any) => year < this.currentYear)
    }
  }


  updateMap() {
    var pop = this.filterActiveDoctorsByTypeAndYear(this.docPop, this.categoriesSelected, this.currentYear)

    pop.forEach((einrichtung: {
      active: any; geometry: { coordinates: number[]; }; Name: string;
    }) => {
      var newMarker = L.marker([einrichtung.geometry.coordinates[1], einrichtung.geometry.coordinates[0]], { icon: this.getIcon(einrichtung) })
      newMarker.addTo(this.map);

      newMarker.on('click', async (e: any) => {
        if (this.mapDeletionMode) {

          this.deactivateLocation(einrichtung)



          this.update()

        } else {


          this.clickEinrichtung(einrichtung.Name)
        }
      });

      if(this.markersObjRefMap.has(einrichtung.Name))  {
        this.map.removeLayer(newMarker)
      } else { this.markersObjRefMap.set(einrichtung.Name, { leaflet: newMarker, plain: einrichtung }) }
    })
  }

  goTo() {
    this.map.flyTo({
      center: [12.1500056, 51.0448898],
      zoom: 20,
      pitch: 150,
      bearing: -17.6,
    });

  }

  zoomBLK() {


    this.map?.flyTo([51.1448898, 11.99000],

    );

  }


  ///////////////////////////////////////////////////////////initialisation////////////////////////////////////////////////////////////////////////////

  initSachsenAnhalt() {
    this.simulationService
      .getSachsenAnhaltShape()
      .subscribe((sachsenAnhaltShape) => {
        this.sachsenAnhaltShape = sachsenAnhaltShape;
        //console.log(this.sachsenAnhaltShape.features);

        this.map.on('load', () => {
          this.map?.addSource(`SA-source1`, {
            type: 'geojson',
            data: this.sachsenAnhaltShape,
          });

          this.map?.addLayer({
            id: `SA-layer1`,
            type: 'line',
            source: `SA-source1`,
            layout: {},
            paint: {
              'line-color': '#1b8113',
              'line-opacity': 1,
              'line-width': 4,
            },
          });
        });
      });
  }

  initBurgenlandkreisShape() {

    this.simulationService
      .getBurgenlandkreisShape()
      .subscribe((burgenlandkreisShape) => {
        this.burgenlandkreisShape = burgenlandkreisShape;
        //console.log(this.burgenlandkreisShape.features);
        this.map.on('load', () => {
          this.map?.addSource(`B-source1`, {
            type: 'geojson',
            data: this.burgenlandkreisShape,
          });
          this.map?.addLayer({
            id: `B-layer1`,
            type: 'line',
            source: `B-source1`,
            layout: {},
            paint: {
              'line-color': 'black',
              'line-opacity': 1,
              'line-width': 2,
            },
          });
        });
      });

  }





  createLineChart() {
    //if (!this.allDocData[this.currentYear - 2024]) {return;}

    var lineObj: any = {};
    var lineArr: any[] = [];
    var pop = this.filterActiveDoctorsByType(this.docPop, this.categoriesSelected)

    this.hideBarChart = true;
    this.hideLineChart = false;

    if(this.barChart) {
      this.barChart.destroy(); 
    }


    if (this.graphType == 'Anzahl Einrichtungen') {
      this.categoriesSelected.forEach((cat: any) => {
        lineObj.label = cat;
        var dataPerCat = []
        var startYear = 2024
        for (var i = 0; i < this.zeitRaum.length; i++) {
          var year = startYear + i
          dataPerCat.push(pop.filter((el: any) => { return el.Typ == cat && ((el.active.indexOf(year)) >= 0) }).length);
        }
        lineObj.data = dataPerCat;
        lineArr.push(lineObj)
        lineObj = {}
        dataPerCat = []



      });
    } else {
      if (this.graphType == 'Versorgungskapazität') {
      
        this.categoriesSelected.forEach((cat: any) => {
          lineObj.label = cat;
          var dataPerCat = [];
          var startYear = 2024
          for (var i = 0; i < this.zeitRaum.length; i++) {
            var year = startYear + i;
            var filteredData = pop.filter((el: any) => { return ((el.active.indexOf(year)) > 0) && el.Typ == cat })
            dataPerCat.push(filteredData.reduce((sum: any, location: { capacity: any; }) => sum + location.capacity[i], 0));
          }
          lineObj.data = dataPerCat;
          lineArr.push(lineObj)
          lineObj = {}
          dataPerCat = []



        });


      }
      else {


        this.categoriesSelected.forEach((cat: any) => {
          lineObj.label = cat;
          var dataPerCat = [];
          var startYear = 2024
          for (var i = 0; i < this.zeitRaum.length; i++) {
            var year = startYear + 2024

            var filteredCategoryData = pop.filter((el: any) => { return ((el.active.indexOf(year)) > 0) && el.Typ == cat })
            var avgAge = 0
            if (filteredCategoryData.length != 0) {
              const sum = filteredCategoryData.reduce((total: any, location: { birth: any; }) => total + (year - location.birth), 0);
              avgAge = sum / filteredCategoryData.length;
            }


            dataPerCat.push(parseFloat(avgAge.toFixed(1)))



          }
          lineObj.data = dataPerCat;
          lineArr.push(lineObj)
          lineObj = {}
          dataPerCat = []
        });
      }
    }


    this.lineChart = new Chart('myLineChart', {
      type: 'line',

      data: {
        labels: [2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034],
        datasets: lineArr,
      },
      options: {
        plugins: {
          legend: {
            display: true,
            position: 'bottom'

          }
        }
      }
    });
  }

  updateLineChartData() {

    var pop = this.filterActiveDoctorsByType(this.docPop, this.categoriesSelected)

    var lineObj: any = {};
    var lineArr: any[] = [];
   
    this.hideBarChart = true;
    this.hideLineChart = false;
  
    if (this.graphType == 'Anzahl Einrichtungen') {

      this.categoriesSelected.forEach((cat: any) => {
        lineObj.label = cat;
        var dataPerCat = []
        var startYear = 2024
        for (var i = 0; i < this.zeitRaum.length; i++) {
          var year = startYear + i
          dataPerCat.push(pop.filter((el: any) => { return el.Typ == cat && ((el.active.indexOf(year)) >= 0) }).length);
        }
        lineObj.data = dataPerCat;
        lineArr.push(lineObj)
        lineObj = {}
        dataPerCat = []
      });

    } else {
      if (this.graphType == 'Gesamtkapazität') {
  
        this.categoriesSelected.forEach((cat: any) => {
          lineObj.label = cat;
          var dataPerCat = [];
          var startYear = 2024
          for (var i = 0; i < this.zeitRaum.length; i++) {
            var year = startYear + i;
            var filteredData = pop.filter((el: any) => { return ((el.active.indexOf(year)) >= 0) && el.Typ == cat })
            console.warn("FILTER", filteredData)
            dataPerCat.push(filteredData.reduce((sum: any, location: { capacity: any; }) => sum + parseInt(location.capacity[ i ]), 0));

            console.warn("dataPerCat", dataPerCat)
          }
          lineObj.data = dataPerCat;
          lineArr.push(lineObj)
          lineObj = {}
          dataPerCat = []



        });


      }
 
      if(this.graphType == 'Durchschnittskapazität') {
        this.hideBarChart = false;
       this.hideLineChart = true;
  
           // Gruppieren nach Typ und Durchschnitt der Kapazitäten berechnen
    const groupedData = pop.reduce((acc: any, item: any) => {
      if (!acc[item.Typ]) {
        acc[item.Typ] = { totalCapacity: 0, count: 0 };
      }
    //  console.warn(item.capacity, isNaN(item.capacity))
      acc[item.Typ].totalCapacity = acc[item.Typ].totalCapacity + parseFloat(item.capacity);
      acc[item.Typ].count += 1;
      return acc;
    }, {});


    const labels = Object.keys(groupedData);
    const averageCapacities = labels.map(label => {
      const { totalCapacity, count } = groupedData[label];
      return (totalCapacity / count).toFixed(2);
    });




  // Chart.js-Konfiguration
const data = {
  labels: labels,
  datasets: [{
      label: 'Durchschnittliche Kapazität',
      data: averageCapacities,
      borderWidth: 1
  }]
};

// Chart-Konfiguration
const config = {
  type: 'bar',
  data: data,
  options: {
      scales: {
          y: {
              beginAtZero: true
          }
      }
  }
};


if(this.barChart) {
  this.barChart.destroy(); 
}
// Bar-Chart erstellen
this.barChart = new Chart(
  //@ts-ignore
  document.getElementById('myBarChart'),
  config
);


    

      
      return}
      else {


        this.categoriesSelected.forEach((cat: any) => {
          lineObj.label = cat;
          var dataPerCat = [];
          var startYear = 2024
          for (var i = 0; i < this.zeitRaum.length; i++) {
            var year = startYear + i

            var filteredCategoryData = pop.filter((el: any) => { return ((el.active.indexOf(year)) >= 0) && el.Typ == cat })
            var avgAge = 0
            if (filteredCategoryData.length != 0) {
              var sum = filteredCategoryData.reduce((total: any, location: { birth: any; }) => total + (year - location.birth), 0);
              avgAge = sum / filteredCategoryData.length;
            }

            //console.warn("NN", cat, filteredCategoryData, year, avgAge)
            if (!isNaN(avgAge)) {
              dataPerCat.push(parseFloat(avgAge.toFixed(1)))
            }



          }
          lineObj.data = dataPerCat;
          lineArr.push(lineObj)
          lineObj = {}
          dataPerCat = []
        });
      }
    }




    if (this.lineChart) {
      this.lineChart.data.datasets = lineArr;
      this.lineChart.update();
    }
  }



  createChart() {

    var filteredPop = this.getFilteredPop(this.categoriesSelected);
    var under30 = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) <= 30 && !el.inactive });
    var under35 = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) > 30 && (this.currentYear - el.birth) <= 35 && !el.inactive });
    var under40 = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) > 35 && (this.currentYear - el.birth) <= 40 && !el.inactive });
    var under45 = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) > 40 && (this.currentYear - el.birth) <= 45 && !el.inactive });
    var under50 = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) > 45 && (this.currentYear - el.birth) <= 50 && !el.inactive });
    var under55 = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) > 50 && (this.currentYear - el.birth) <= 55 && !el.inactive });
    var under60 = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) > 55 && (this.currentYear - el.birth) <= 60 && !el.inactive });
    var rest = filteredPop.filter((el: any) => { return (this.currentYear - el.birth) > 60 && !el.inactive });


    this.chart = new Chart('myChart', {
      type: 'pie',
      data: {
        datasets: [
          {
            data: [
              under30.length,
              under35.length,
              under40.length,
              under45.length,
              under50.length,
              under55.length,
              under60.length,
              rest.length,

            ],
            backgroundColor: this.colorPalette,
          },
        ],
      },
    });
  }

  countObjectsInBreaks(objects: any, breakpoints: any) {
    var filteredPop = this.getFilteredPop(this.categoriesSelected);
    // Sicherstellen, dass die Breakpoints sortiert sind
    breakpoints.sort((a: number, b: number) => a - b);

    // Initialisiere ein Array, um die Zähler für jeden Bereich zu speichern
    const counts = new Array(breakpoints.length).fill(0);

    // Durchlaufe alle Objekte
    filteredPop.forEach((obj: { activeUntil: any; }) => {
      if (obj.activeUntil) {
        var restYear = obj.activeUntil - this.currentYear;
      } else {
        restYear = 666666;

      }

      // Finde den passenden Bereich für das Geburtsjahr
      for (let i = 0; i < breakpoints.length - 1; i++) {
        if (restYear >= breakpoints[i] && restYear < breakpoints[i + 1]) {
          counts[i]++;
          break; // Breche die Schleife, da das Geburtsjahr in einem Bereich liegt
        }
      }


    });

    var rest = filteredPop.filter(el => {
      if (el.activeUntil) {
        var restYear = el.activeUntil - this.currentYear;
      } else {
        restYear = 666666;

      }
      return restYear < breakpoints[0]
    })

    counts[breakpoints.length] = rest.length;

    return counts;
  }





  calculateRemainingTime(location: any): number {

    if (!location.birth) {
      return 10000
    }

    if (location?.active.length > 0) {
      const lastActiveYear = Math.max(...location.active);
      return lastActiveYear - this.currentYear;
    }
    return 0;
  }

  categorizeByBreaks(remainingTime: number, breaks: number[]): number {
    if (remainingTime <= 0) {
      return -1; // Wert kleiner oder gleich 0 wird als besondere Kategorie eingeordnet
    }
    const maxBreak = Math.max(...breaks);
    if (remainingTime > maxBreak) {
      return maxBreak + 1; // Werte größer als der größte Break-Wert erhalten eine besondere Kategorie
    }
    let category = 0;
    for (let i = 0; i < breaks.length; i++) {
      if (remainingTime <= breaks[i]) {
        category = breaks[i];
        break;
      }
    }
    return category;
  }

  groupLocationsByRemainingTime(locations: any[], breaks: number[]): Map<number, number> {
    const remainingTimeCounts = new Map<number, number>();

    locations.forEach(location => {
      const remainingTime = this.calculateRemainingTime(location);
      const category = this.categorizeByBreaks(remainingTime, breaks);
      if (remainingTimeCounts.has(category)) {
        remainingTimeCounts.set(category, remainingTimeCounts.get(category)! + 1);
      } else {
        remainingTimeCounts.set(category, 1);
      }
    });

    return remainingTimeCounts;
  }

  sortByFrequency(remainingTimeCounts: Map<number, number>): [number, number][] {
    return Array.from(remainingTimeCounts.entries()).sort((a, b) => a[0] - b[0]); // Sortiere nach Break-Werten (aufsteigend)
  }

  countLocationsByBirthAndActive(locations: any[], breaks: number[]) {
    // Füge am Ende einen unendlich großen Wert hinzu, um alle Werte abzudecken
    breaks = [...breaks, Infinity];

    // Zähle die Locations basierend auf den Breaks
    const activeCounts = Array(breaks.length).fill(0);
    var inactiveCounts = 0;

    locations.forEach(location => {
      // const restlaufzeit = location.active.length ? Math.max(...location.active) - this.currentYear : -1;
      const isActive = location.active.includes(this.currentYear)

      if (!isActive) {

        inactiveCounts++;
      } else {

        for (let i = 0; i < breaks.length; i++) {
          if ((this.currentYear - location.birth) <= breaks[i]) {
            if (isActive) {
              activeCounts[i]++;
            }
            break; // Da die breaks aufeinanderfolgend sind, können wir abbrechen, sobald der passende break gefunden wurde
          }
        }
      }
    });

    return { activeCounts, inactiveCounts };
  }




  generateChartData(sortedByFrequency: [number, number][], colorPalette: string[]): { labels: string[], data: number[], backgroundColor: string[] } {
    const labels = sortedByFrequency.map(([breakPoint]) => breakPoint.toString());
    const data = sortedByFrequency.map(([, count]) => count);

    // Sort colors by ascending breaks
    const sortedBreaks = sortedByFrequency.map(([breakPoint]) => breakPoint).sort((a, b) => a - b);
    const colorMapping: { [key: number]: string } = {};

    // Create a mapping of breaks to colors
    for (let i = 0; i < sortedBreaks.length; i++) {
      console.warn("BREAKS", sortedBreaks[i])
      colorMapping[sortedBreaks[i]] = this.getColor(sortedBreaks[i], this.breaksFin, colorPalette)
    }

    const backgroundColor = sortedByFrequency.map(([breakPoint]) => colorMapping[breakPoint]);

    return { labels, data, backgroundColor };
  }


  updateChartData(filteredPop: any, breaks: any) {
    
    var dataset: any;
    if (this.renteMode) {
  

      var remainingTimeCounts = this.groupLocationsByRemainingTime(filteredPop, breaks);
      var sortedByFrequency = this.sortByFrequency(remainingTimeCounts);
      var chartData = this.generateChartData(sortedByFrequency, this.colorPalette);

      console.warn("Chart.js Dataset:", chartData);
     // console.log(this.reversedColorPalette, chartData);
      //chartData.backgroundColor =  this.colorPalette
      // Chart.js-Datenbeispiel:
      dataset = {
        labels: chartData.labels,
        datasets: [{
          label: 'Anzahl',
          data: chartData.data,
          backgroundColor:  chartData.backgroundColor,
          borderColor: 'rgba(0, 0, 0, 0.1)',
          borderWidth: 1
        }]
      };




    } else {





      const { activeCounts, inactiveCounts } = this.countLocationsByBirthAndActive(filteredPop, breaks);

      const labels = breaks.slice(0, -1).map((breakPoint: any, index: any) => `<= ${breakPoint}`);
      var tempColors = breaks.map((el: any) => 
        el = this.getColor(el,breaks,this.reversedColorPalette)
      )

    
      const activeData = activeCounts.slice(0, -1);
      //const inactiveData = inactiveCounts.slice(0, -1);

       
      labels.push( ">" +  breaks[breaks.length - 1]); //for the last
      
      //for inactive
      activeData.push(inactiveCounts)
      tempColors.push("black")
      labels.push("Inaktiv")
 
      console.warn(labels, tempColors)
   
      dataset = {
        labels: labels,
        datasets: [
          {
            label: 'Anzahl',
            data: activeData,
            backgroundColor: tempColors,
            borderWidth: 1
          },

        ]
      };

      console.warn("D", dataset)

    }





    if (this.chart) {
      this.chart.data = dataset
      this.pieChartValid =  this.chart.data.datasets[0].data.some((value: number) => value !== 0)

      this.chart.options = {
        plugins: {
          legend: {
            display: false // Verhindert die Anzeige der Legende
          }
        }
      },
        this.chart.update();
    }
  }








  toggleChart() {
    let chartDisplay = document.getElementById('chartDisplay');
    let pieChartContainer = document.getElementById('pieChartContainer');
    let pieChartDisplay = document.getElementById('pieChartDisplay');
    let legende = document.getElementById('legende');
    let pieChartTitle = document.getElementById('pieChartTitle');
    const icon = document.getElementById('collapseIcon');
    if (icon?.classList.contains('bi-arrows-angle-contract')) {
      icon?.classList.remove('bi-arrows-angle-contract');
      icon?.classList.add('bi-arrows-angle-expand');
      legende!.style.display = 'none';
      pieChartTitle!.style.display = 'none';
      chartDisplay!.style.transition = 'height 1s ease, width 1s ease';
      pieChartContainer!.style.transition = 'height 1s ease, width 1s ease';
      chartDisplay!.style.height = '40px';
      chartDisplay!.style.width = '80px';
      pieChartContainer!.style.height = '40px';
      pieChartContainer!.style.width = '80px';
      chartDisplay!.style.alignItems = 'flex-start';
      pieChartContainer!.style.justifyContent = 'flex-start';
    } else if (icon?.classList.contains('bi-arrows-angle-expand')) {
      icon?.classList.remove('bi-arrows-angle-expand');
      icon?.classList.add('bi-arrows-angle-contract');
      setTimeout(() => {
        legende!.style.display = 'flex';
        pieChartTitle!.style.display = 'flex';
        //pieChartDisplay!.style.alignItems = 'flex-start';
      }, 1000);
      chartDisplay!.style.transition = 'height 1s ease, width 1s ease';
      chartDisplay!.style.height = '310px';
      chartDisplay!.style.width = '550px';
      pieChartContainer!.style.transition = 'height 1s ease, width 1s ease';
      pieChartContainer!.style.height = '250px';
      pieChartContainer!.style.width = '250px';
    }
  }

  toggleLineChart() {
    let chartDisplay = document.getElementById('chartDisplay3');
    let pieChartContainer = document.getElementById('lineChartContainer');
    let pieChartDisplay = document.getElementById('myLineChart');
    // let legende = document.getElementById('legende');
    //let pieChartTitle = document.getElementById('pieChartTitle');



    //collapseIconLine
    const icon = document.getElementById('collapseIconLine');
    if (icon?.classList.contains('bi-arrows-angle-contract')) {
      icon?.classList.remove('bi-arrows-angle-contract');
      icon?.classList.add('bi-arrows-angle-expand');

      this.hideLineChart = true;
      this.hideBarChart = true;
      // legende!.style.display = 'none';
      //pieChartTitle!.style.display = 'none';
      chartDisplay!.style.transition = 'height 1s ease, width 1s ease';
      pieChartContainer!.style.transition = 'height 1s ease, width 1s ease';
      chartDisplay!.style.height = '40px';
      chartDisplay!.style.width = '80px';
      pieChartContainer!.style.height = '200px';
      pieChartContainer!.style.width = '450px';
      chartDisplay!.style.alignItems = 'flex-start';
      pieChartContainer!.style.justifyContent = 'flex-start';




    }
    else if (icon?.classList.contains('bi-arrows-angle-expand')) {
      this.hideLineChart = false;

      icon?.classList.remove('bi-arrows-angle-expand');
      icon?.classList.add('bi-arrows-angle-contract');
      setTimeout(() => {
        //  legende!.style.display = 'flex';
        //  pieChartTitle!.style.display = 'flex';
        this.update();




      }, 1000);
      chartDisplay!.style.transition = 'height 1s ease, width 1s ease';
      chartDisplay!.style.height = '310px';
      chartDisplay!.style.width = '450px';
      pieChartContainer!.style.transition = 'height 1s ease, width 1s ease';
      pieChartContainer!.style.height = '250px';
      pieChartContainer!.style.width = '450px';
      pieChartDisplay!.style.height = '200px';

    }







  }

  toggleBarChart() {

    let barDisplay = document.getElementById('barDisplay');
    let barchart = document.getElementById('myBarChart');
    let slideBar = document.getElementById('slideBar');
    const icon = document.getElementById('collapseIconBar');
    if (icon?.classList.contains('bi-arrows-angle-contract')) {
      this.topContainerExtended = false;
      icon?.classList.remove('bi-arrows-angle-contract');
      icon?.classList.add('bi-arrows-angle-expand');
      barDisplay!.style.transition = 'height 1s ease, width 1s ease';
      barDisplay!.style.height = '40px';
      barDisplay!.style.width = '300px';
      slideBar!.style.width = '150px';
      slideBar!.style.left = '50px';
      barchart!.style.display = 'none';
    } else if (icon?.classList.contains('bi-arrows-angle-expand')) {

      this.topContainerExtended = true;
      icon?.classList.remove('bi-arrows-angle-expand');
      icon?.classList.add('bi-arrows-angle-contract');
      barDisplay!.style.transition = 'height 1s ease, width 1s ease';
      barDisplay!.style.height = '100px';
      barDisplay!.style.width = '545px';
      slideBar!.style.width = '300px';
      slideBar!.style.left = '80px';


      barchart!.style.display = 'flex';
    }
  }


  toggleCalender() {

    let calender = document.getElementById('calenderContainer');
    let calenderDay = document.getElementById('calenderDay');
    let calenderNavigation = document.getElementById('calender-navigation');
    const icon = document.getElementById('collapseIconCalender');
    if (icon?.classList.contains('bi-arrows-angle-contract')) {
      this.isExtended = false;
      icon?.classList.remove('bi-arrows-angle-contract');
      icon?.classList.add('bi-arrows-angle-expand');
      calenderNavigation!.style.display = 'none';
      calenderDay!.style.fontSize = '0';
      calenderDay!.style.transition = 'height 1s ease, width 1s ease';
      calenderDay!.style.height = '40px';
      calenderDay!.style.width = '20px';
      calender!.style.transition = 'height 1s ease, width 1s ease';
      calender!.style.height = '40px';
      calender!.style.width = '80px';
    } else if (icon?.classList.contains('bi-arrows-angle-expand')) {
      this.isExtended = true;
      icon?.classList.remove('bi-arrows-angle-expand');
      icon?.classList.add('bi-arrows-angle-contract');
      setTimeout(() => {
        calenderNavigation!.style.display = 'flex';
        calenderDay!.style.fontSize = '40px';
      }, 1000);
      calenderDay!.style.transition = 'height 1s ease, width 1s ease';
      calenderDay!.style.height = '100px';
      calenderDay!.style.width = '210px';
      calender!.style.transition = 'height 1s ease, width 1s ease';
      calender!.style.height = '100px';
      calender!.style.width = '550px';
    }
  }


  getStyleForLayer(element: any) {

    var circleColor = this.colorPalette[0];
    var age = this.currentYear - element.birth;
    var breaks = 12; //100/8;
    var index = Math.floor(age / breaks);
    var circleColor = this.colorPalette[index]

    if (this.selectedEinrichtung && element.einrichtungs_id == this.selectedEinrichtung.einrichtungs_id) {
      return {
        "circle-color": circleColor,
        "circle-stroke-width": 2,
        "circle-stroke-color": "red",
        "circle-radius": ["case", ["get", "cluster"], 10, 10] // 10 pixels for clusters, 5 pixels otherwise
      }
    }

    if (element.inactive || (this.rentenAlter - (this.currentYear - element.birth) == 0)) {

      return {
        "circle-color": "red",
        "circle-stroke-width": 1.5,
        "circle-stroke-color": "red",
        "circle-radius": ["case", ["get", "cluster"], 5, 5] // 10 pixels for clusters, 5 pixels otherwise
      }
    }
    return {
      "circle-color": circleColor,
      "circle-stroke-width": 1.5,
      "circle-stroke-color": "white",
      "circle-radius": ["case", ["get", "cluster"], 5, 5] // 10 pixels for clusters, 5 pixels otherwise
    }

  }

  //////////////////////////////////////////////////////////////////////select any day using slider//////////////////////////////////////////////////////////////////
  updateProgress(progress: any) {
    const rangeInput = document.getElementById(
      'customRange1'
    ) as HTMLInputElement;
    rangeInput.value = progress;
  }

  async updateNextDay(event: Event): Promise<void> {

    const target = event.target as HTMLInputElement;
    this.currentYear = parseInt(target.value);
    
    this.update();
    if(this.selectedEinrichtung) {

        this.newKapa =  this.selectedEinrichtung.plain.capacity[this.currentYear-2024]
        this.newDocAge = this.currentYear - this.selectedEinrichtung.plain.birth

      }
  
  }


  getColorForTicks(i: number) {



    if ((2024 + i) == this.currentYear) {
      return 'green';
    }

    /*
        var deleted = this.allDocData[i].einrichtung.filter((el: { deleted: boolean; }) => { return el.inactive == true })
        if (deleted.length > 0) {
          return 'red';
        } */

    return 'white';



  }



  ///////////////////////////////////////////////////////remove all layers and sources////////////////////////////////////////////////////////////////////
  async removeAllLayersAndSources() {
    const style = this.map.getStyle();
    if (!style || !style.layers) {
      return;
    }

    for (let i = 1; i <= this.idLayerCounter; i++) {
      const layerId = `persons-layer${i}`;
      if (this.map?.getLayer(layerId)) {
        this.map?.removeLayer(layerId);
      }

      const sourceName = `persons-source${i}`;
      if (this.map?.getSource(sourceName)) {
        this.map?.removeSource(sourceName);
      }

      const layerIdDay = `day-layer${i}`;
      if (this.map?.getLayer(layerIdDay)) {
        this.map?.removeLayer(layerIdDay);
      }

      const sourceNameDay = `day-source${i}`;
      if (this.map?.getSource(sourceNameDay)) {
        this.map?.removeSource(sourceNameDay);
      }
    }
  }
  setRentenEintrittsAlter(rentenAlter: number) {
    this.rentenAlter = rentenAlter;
    this.renteMode = true;
  }


  async setLayer() {

 
    if (!this.selectedObject) {
      alert("Bitte Landkreis auswählen!")
      return;
    }
  
    this.layerControl = L.control.layers( {}, {},
      {position: 'bottomleft'}
    ).addTo(this.map);

    var resMul = [];
    var res1 = await this.regioService.getShapefileByIdAndLevel(this.selectedObject.Object_Key, 5);
    var res = await this.regioService.getShapefileByIdAndLevel(this.selectedObject.Object_Key, 4);
    //this.storageService.setCurrentShapes(res[0]);

    var res2 = await this.regioService.getShapefileByIdAndLevel(this.selectedObject.Object_Key, 3);
   // this.storageService.setCurrentShapes(res2[0]);
   resMul.push(res1)
   // resMul.push(res)
   // resMul.push(res2)

    resMul.forEach((res => {

  
    //@ts-ignore
    res[0].forEach((element: any) => {
      var l = L.geoJSON(element.geometry,
        {
          style: {
            fillColor: 'none',


            color: 'black'
          },
          onEachFeature: (f, l) => {
            var out: string[] = [];

            out.push("Name : " + element.Object_Nam + "<br>"

            );

            l.bindPopup("<span class='span0'> " + element.Object_Nam + " </span> ");
          }
        });

 


        
      this.layers.push(l);
      this.layerControl.addOverlay(l, element.Object_Nam,  {position: 'topleft'}) 
 
     
    })
  }))
  
  }





  setScenario(scen: any, rentenAlter: any, popName: any) {
    this.popName = popName
    // this.initSachsenAnhalt();
    //this.initBurgenlandkreisShape();

    this.categories = [];

    this.metaScenData = scen;
    //this.popName = scen.name;
    this.rentenAlter = rentenAlter;

    if(scen.pop[0].einrichtung) {
    this.docPop = scen.pop[0].einrichtung;
    } else {
      this.docPop = scen.pop
    }

    this.docPop.forEach((doc: {
      Name: any;
      capacity: number[]; birth: any; active: number[]; Typ: any;
    }) => {


      doc.capacity =  new Array(11).fill( Math.floor(Math.random() * (120 - 40 + 1)) + 40); //TODO: delete

      var endYear = 2034;

      if (this.rentenAlter && doc.birth) { //wir sind im Rentenmodus und haben ein Alter
        endYear = Number(doc.birth) + Number(this.rentenAlter)
      }

      if(doc.birth) {
        if(this.minAge > (this.currentYear - doc.birth) ) {
          this.minAge = (this.currentYear - doc.birth) 
        }

        if(this.maxAge < (this.currentYear - doc.birth) ) {
          this.maxAge = (this.currentYear - doc.birth) 
        }


      }

      if (endYear >= 0) {
        doc.active = Array.from({ length: endYear - 2024 + 1 }, (_, i) => 2024 + i);
      } else {
        doc.active = []
      }


      if (this.categories.indexOf(doc.Typ) == -1) {
        this.categories.push(doc.Typ);
      }

    });


    this.update()
    //this.createChartFix()
    //this.zoomBLK(); 

    this.closeModal('dialog3')

    return;

  }


  update( ) {
    //alert("update")

    this.clearMapNew();

   

    //0. Filter Doc by year and category

    var pop = this.filterActiveDoctorsByTypeAndYear(this.docPop, this.categoriesSelected, this.currentYear)

    console.warn("Update", pop, this.categoriesSelected)
    if(this.categoriesSelected.length) {
      this.clearMapNew();
    }



    if (!this.renteMode) {
      this.breaksFin = [30, 35, 40, 45, 50, 55, 60, 65,70 ];
    } else {
      this.breaksFin = [1, 2, 3, 4, 5, 6, 7, 8];

    }



    //2. Create Pie Chart 
    this.updateChartData(pop, this.breaksFin);

    //3. Add markersx
    this.updateMap();

    //4. create Line Charts
    this.updateLineChartData();

  }




}