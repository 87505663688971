import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-nachfrageberechnung-edit',
  templateUrl: './nachfrageberechnung-edit.component.html',
  styleUrls: ['./nachfrageberechnung-edit.component.css']
})
export class NachfrageberechnungEditComponent {
  @Input() selectedItem: any;

}
