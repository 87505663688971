import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { HospitalService } from '../service/hospitalService/hospital.service';
import { KrankheitenComponent } from './dialog/krankheiten/krankheiten.component';
import { MatDialog } from '@angular/material/dialog';
import { KrankenhausDialogComponent } from './dialog/krankenhaus-dialog/krankenhaus-dialog.component';


interface Hospital {
  Object_key: string;
  name: string;
  only_day_clinic: boolean;
  anzahl_betten_total: number;
  ambulant_fallzahlen_total: number;
  stationaer_fallzahlen_total: number;
  anzahl_fachabteilungen: number;
  geometry: {
    type: string;
    coordinates: number[];
  };
}

interface Disease {
  Unterkategorie: string;
  Hauptkategorie: string;

}

@Component({
  selector: 'app-krankenhausreform',
  templateUrl: './krankenhausreform.component.html',
  styleUrls: ['./krankenhausreform.component.css']
})
export class KrankenhausreformComponent implements OnInit{
  customIconRed = L.icon({
    iconUrl: '../assets/icon/hospital-solid-red.svg', 
    iconSize: [32, 32], 
    iconAnchor: [16, 32], 
    popupAnchor: [0, -32] ,

  });
  customIcon = L.icon({
    iconUrl: '../assets/icon/hospital-solid.svg', 
    iconSize: [32, 32], 
    iconAnchor: [16, 32], 
    popupAnchor: [0, -32] ,

  });

  locationIcon = L.icon({
    iconUrl: '../assets/icon/standort.png', 
    iconSize: [32, 32], 
    iconAnchor: [16, 32], 
    popupAnchor: [0, -32] ,

  });

  map:any;
  isLoading:boolean=false;
  mapSelection:boolean=false;
  MarkerArray:any[]=[];
  diseases: Disease[] = [];
  radiusSelection: boolean = false;
  locationMarker: L.Marker<any> | undefined;

  constructor(
    private hospitalService:HospitalService,
    public dialog: MatDialog
  )
  {}

  async ngOnInit(): Promise<void> {
    this.initMap();
    await this.getHospitals()
    this.diseases = this.hospitalService.getDiseases()
  }
  openKrankenhaus() {
    this.mapSelection = false;
    const dialogRef = this.dialog.open(KrankenhausDialogComponent, {
      data: { krankenhaus: this.MarkerArray  },
      width: '80vw',
      height: '70vh',
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result.mapSelection) {
        this.mapSelection = true
      }

      if (result.radiusSelection) {
        this.radiusSelection = true
      }
    });}
  openKrankheiten() {
    const dialogRef = this.dialog.open(KrankheitenComponent, {
      data: {  },
      width: '1200px',
      height: '800px',
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
      }
    });}

  private initMap(): void {
    this.map = L.map('map', {
      center: [51.165195078, 11.97547],
      zoom: 13,
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);

    this.map.on('click', (e: any) => {
      if(!this.radiusSelection) return
      
            const lat = e.latlng.lat;
            const lng = e.latlng.lng;
           this.locationMarker = L.marker([lat, lng], { draggable: true, icon: this.locationIcon }).addTo(this.map)
           this.locationMarker.on('dragend', () => {alert('ha')})

     // L.marker([lat, lng]).addTo(this.map).bindPopup("Marker bei Klick").openPopup();


    })
  }

  async getHospitals(): Promise<void> {
    const hospitals: { success: boolean; data: Hospital[] } = await this.hospitalService.getHospitals();
      console.log("HH", hospitals);
  
     
  
      hospitals.data.forEach((hospital: Hospital) => {
        if (hospital.geometry.type === 'Point') {
          const [lng, lat] = hospital.geometry.coordinates;
          
          let marker = L.marker([lat, lng], { icon: this.customIcon })
          .addTo(this.map)
          .bindPopup(`
            <b>${hospital.name}</b>
            <br><b>Betten:</b> ${hospital.anzahl_betten_total}
          `);

          var obj = {
            leafletMarker:marker,
            raw: hospital,
            checked: false
          }

          marker.on("click", () => {if(this.mapSelection) {obj.checked = true
            marker.setIcon(this.customIconRed);



          }})

          this.MarkerArray.push(obj)
        }
      });
  }


  updateMarkerAsSelect(){
    var checkedArrays = this.MarkerArray.filter(el =>  el.checked )
    checkedArrays.forEach(element => {
      element.leafletMarker
      
    });



  }
} 
