<div *ngIf="needDialog" id="errorDialog">
    <div id="dialogMessage">
      <div id="dialogTileMsg">
        {{ dialogTitle }}
      </div>
      <div>
        {{ dialogMessage }}
      </div>
      <div id="iconDialog">!</div>
      <button id="confirmDialog" (click)="closeDialog()">OK</button>
      <img id="imgDialog" src="../../assets/Wappen_Burgenlandkreis.svg" width="40px">
    </div>
  </div>
  