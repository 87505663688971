<div class="page" id="0" *ngIf="selectedIndex == 0"> 




<div class="content">

   <div class="left"> 

        <h3> 1. Auswahl Prognosetyp  </h3> 
     

     
        <div class="tile"  [style.background-color]="selectedPrognoseTyp  == 'Allgemeinmedizin' ? 'lightgray' : 'white'">
            <div>
              <span class="span2" (click)="selectedPrognoseTyp  = 'Allgemeinmedizin'; navigateForward(1)"  >Allgemeinmedizin</span>
            </div>
            </div>
     

     
     
       <div class="tile"  [style.background-color]="selectedPrognoseTyp  == 'Pflegeheime' ? 'lightgray' : 'white'">
       <div>
         <span class="span2"   style="text-indent: 0;color: grey;" >Pflegeheime</span>
       </div>
       </div>
     
     
       <div class="tile" >
         
           <div style="display: flex; justify-content: center; align-items: center;flex-direction: column;">
             <span class="span2" style="text-indent: 0;color: grey;">Ambulante</span>
             <span class="span2" style="text-indent: 0;color: grey;">Pflegedienste</span>
           </div>
         
       </div>
       
       <div class="tile">
         <div><span class="span2" style="color: grey;">Fachärzte</span></div>
     
       </div>
       <div class="tile">
         <div><span class="span2" style="color: grey;">Operationen</span> </div>
       </div>
    
    </div>

    <div class="right"> 

      <!--  <div *ngIf="selectedPrognoseTyp =='Allgemeinmedizin'">

        <doc-popup> </doc-popup>

    </div>
-->

    </div>

</div>




<div class="progress-bar-container" >
<div class="progress-bar">
    <div class="progress-pointU" style="background-color: rgb(58, 102, 223);">1</div>
    <div class="progress-pointU" style="background-color: gainsboro;">2</div>
    <div class="progress-pointU" style="background-color: gainsboro;">3</div>
    <div class="progress-pointU" style="background-color: gainsboro;">4</div>
    <div class="progress-bar-fill" [ngStyle]="{'width.%': 0.2* 100}" style="background: linear-gradient(90deg, yellowgreen 0%, yellowgreen 83%, rgb(58, 102, 223) 100%);"></div>
  </div>

</div>

</div>



<div class="page" id="1" *ngIf="selectedIndex == 1"> 




    <div class="content">
        <div> 
        <h3> 2. Auswahl Prognosebausteine </h3>
    </div>
<div style="display: flex; justify-content: space-around; align-items: center;"> 

        <div id="tile-container">
            <h3> Einen oder mehrere Szenarien auswählen</h3>
            <div class="scroll">
            <ng-container *ngFor="let scen of existingScenarios">
              <ng-container >
      
                <div class="tile-doc" (click)="setDocScenario(scen)" *ngIf="scen.name != 'basis_test' " [style.background]="!check(scen._id) ? 'white' : 'grey'">
                  <h3> {{ scen.name }} </h3>
      
                  <br> 
                  <br> 
                  <i class="bi bi-pencil-fill"> von {{ scen.createdBy }} </i> <br>
                  <br> 
                  <br> 
                  <i class="bi bi-calendar-week">
                    {{scen.createdAt | date: 'dd-MM-yyyy'}}
                  </i> <br>
      
      
      
                </div>
              </ng-container>
      
            </ng-container>
        </div>
      
      
      
          </div>

          <button class="btn3" style="width: 18vw;"[style.background]="(selectedScenarios.length  > 0 && selectedPop.length > 0) ? '#10684E' : 'grey'"
            (click) = "selectedIndex = 3; showChartTable()">
            Weiter
          </button>



          <div class="tile-container">
            <h3 *ngIf="selectedScenarios.length == 1"> Eine oder mehrere modellierte Bevölkerungen auswählen </h3> 
            <h3 *ngIf="selectedScenarios.length > 1"> Eine Bevölkerung auswählen </h3> 
            <div class="scroll">
            <div  *ngFor="let p of pop" >
                <div class="tile" (click) = "setPop(p)" [style.background-color]="checkPop(p) ? 'lightgray' : 'white'"> 
                <div>
                    <span class="span2"> {{ p.pop_name }} </span> 
                </div>
            </div>
            </div>
            </div>
            
        </div>

    </div>
        
    
    </div>
    
    
    
    
  

<div class="progress-bar-container" >
    <div class="progress-bar">
        <div class="progress-pointU" style="background-color: gainsboro;">1</div>
        <div class="progress-pointU" style="background-color: rgb(58, 102, 223);">2</div>
      
        <div class="progress-pointU" style="background-color: gainsboro;" (click)="selectedIndex = 3">3</div>
        <div class="progress-pointU" style="background-color: gainsboro;">4</div>
     
        <div class="progress-bar-fill" [ngStyle]="{'width.%': 0.4* 100}" style="background: linear-gradient(90deg, yellowgreen 0%, yellowgreen 83%, rgb(58, 102, 223) 100%);"></div>
      </div>
    
    </div>
    
    </div>



<div class="page" id="3" *ngIf="selectedIndex == 3"> 




        <div class="content">
            <div> 
            <h3> 3. Parametersetzung</h3>
            <div >
                <div style="display: flex; justify-content: center;">
                    <span class="span2">  {{ chartTitle }} </span> 
                </div>
            
                <br>
                <br>
                
            
                <div style="display: flex; justify-content: space-around;">
            
                   
                    <div style="width: 30vw;">
            
            
                        <div>
            
                            <div style="display: flex; flex-direction: row;">
                              <div class="cellHead">
                                <b> </b>
                                <b> Altersklasse  </b>
                              </div>
                  
                              <div class="cellHead">
                                <b> </b>
                                <b> Weiblich  </b>
                              </div>
            
                              <div class="cellHead">
                                <b> </b>
                                <b> Männlich  </b>
                              </div>
                            </div>
                  
                            <div class="scroll"> 
                            <div style="display: flex; flex-direction: row;" 
                              *ngFor="let res of demandTable.chartData.labels; let i = index; ">
                  
                            
                                <div class="cell" >
                                  <b>
                                    {{ res }}
                                  </b>
                                </div>
                  
                                <div class="cell" [ngStyle]="{'color': changedIndizesF.indexOf(i) > - 1   ? '#65ab96' : 'black'}">
                                  <b>
                                    {{  demandTable.chartData.datasets[0].data[i]}}
                  
                                  </b>
                  
                                </div>
            
                                <div class="cell" [ngStyle]="{'color': changedIndizesM.indexOf(i) > - 1    ? '#65ab96' : 'black'}">
                                    <b>
                                        {{  demandTable.chartData.datasets[1].data[i]}}
                    
                                    </b>
                    
                                  </div>
                  
                         
                  
                            </div>
                        </div>

                        <div style="display: flex; "> 
                  
                            <button class="btn2" style="width: 18vw;" (click)="reset()">
                              Zurücksetzen
                            </button>
                            
                            <button class="btn2" style="width: 18vw;" (click)="setUpLastPage()">
                                Weiter
                              </button>

                            </div>
                          </div>
                    </div>
            
                    <div style="width: 65vw; height: 40vh; margin-top: 5vh; ">
                        <canvas  id="canvasGeneral" >
                            {{ chartGeneral }}
                          </canvas>
                    </div>
            
            
            
            
            
                </div>
            </div>
            


            
        </div>

            
        
        </div>
        
        
        
        
      
    
    <div class="progress-bar-container" >
        <div class="progress-bar">
            <div class="progress-pointU" style="background-color: gainsboro;">1</div>
                  
            <div class="progress-pointU" style="background-color: gainsboro;">2</div>
            <div class="progress-pointU" style="background-color: rgb(58, 102, 223);">3</div>
    
            <div class="progress-pointU" style="background-color: gainsboro;">4</div>
        
            <div class="progress-bar-fill" [ngStyle]="{'width.%': 0.6* 100}" style="background: linear-gradient(90deg, yellowgreen 0%, yellowgreen 83%, rgb(58, 102, 223) 100%);"></div>
          </div>
        
        </div>
        
        </div>



        <div class="page" id="4" *ngIf="selectedIndex == 4"> 




          <div class="content">
              <div> 
              <h3> 4. Szenario speichern/umbenennen</h3>

              <div style="padding: 40px;">
                <br>
            
                <span  class="span0"> Wählen Sie einen Namen aus: </span>
                <br>
                <br>
                <div>
            
                  <input  name="name" placeholder="Name..." style="width: 100%; " [(ngModel)]="selectedName " />
            
                </div>

                <br>
                <br>

                  
  
            
                <b> Sichtbarkeit </b>
            
                <mat-chip-listbox [(ngModel)]="selectedSichtbarkeit">
            
                    <div *ngFor="let cat of ['public', 'protected', 'private']">
                        <mat-chip-option> {{ cat }} </mat-chip-option>
                    </div>
          
                </mat-chip-listbox>

                <div *ngIf="selectedSichtbarkeit == 'protected'" style="display: flex"> 
                  <div> 
                <h4> Leserechte </h4>
                <div class="user-list">
                  <div class="user-list-inner">
                    <ng-container *ngFor="let user of userList" >
                      <mat-checkbox *ngIf="currentUser.id !== user.id"  (change)="toggleUserRead(user.id, $event.checked)">
                        {{ user.username }}
                      </mat-checkbox>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div>
                <h4> Schreibrechte </h4>
                <div class="user-list">
                  <div class="user-list-inner">
                    <ng-container *ngFor="let user of userList">
                      <mat-checkbox *ngIf="currentUser.id !== user.id"  (change)="toggleUserWrite(user.id, $event.checked)">
                        {{ user.username }}
                      </mat-checkbox>
                    </ng-container>
                  </div>
                </div>
              </div>

              <div> 
                <p><mat-slide-toggle labelPosition="before"> Personen benachrichtigen? </mat-slide-toggle></p>

         

              </div>

     

              </div>


            
              <mat-spinner *ngIf="showBuffer"
              ></mat-spinner>
            
            
            
              <div style="margin-right: 20px;">
            
                <span  style="margin-right: 0; " class="span2" (click)="saveItem()" *ngIf="!existingItem && selectedName && selectedSichtbarkeit"> Speichern
                  <button mat-mini-fab style="background-color: #10684E; color: white">
                    <mat-icon>save </mat-icon>
                  </button>
          
                </span>

                <span  style="margin-right: 0; " class="span2" (click)="updateItem()" *ngIf="existingItem"> Updaten
                  <button mat-mini-fab style="background-color: #10684E; color: white">
                    <mat-icon>refresh </mat-icon>
                  </button>
          
                </span>


              </div>
              <br>
              <br>
          
            
              </div>
              

          
              
  
  
              
          </div>
  
              
          
          </div>
          
          
          
          
        
      
      <div class="progress-bar-container" >
          <div class="progress-bar">
              <div class="progress-pointU" style="background-color: gainsboro;">1</div>
                    
              <div class="progress-pointU" style="background-color: gainsboro;">2</div>
                             
              <div class="progress-pointU" style="background-color: gainsboro;">3</div>
              <div class="progress-pointU" style="background-color: rgb(58, 102, 223);">4</div>
      
           
            
              <div class="progress-bar-fill" [ngStyle]="{'width.%': 1* 100}" style="background: linear-gradient(90deg, yellowgreen 0%, yellowgreen 83%, rgb(58, 102, 223) 100%);"></div>
            </div>
          
          </div>
          
          </div>