<h2 mat-dialog-title>Warnung</h2>
<div mat-dialog-content>
  <p>
    Wenn Sie auf <strong>Bestätigen</strong> klicken, wird das Thema 
    <strong>{{ data.topicName }}</strong> samt der Indikatoren entfernt.
  </p>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Abbrechen</button>
  <button mat-raised-button color="warn" [mat-dialog-close]="true">Bestätigen</button>
</div>
