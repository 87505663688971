import { Component, ViewChild } from '@angular/core';
import { AiServiceComponent } from '../re-chart/ai-service/ai-service.component';
import { ReChartComponent } from '../re-chart/re-chart.component';

@Component({
  selector: 'app-test2',
  templateUrl: './test2.component.html',
  styleUrls: ['./test2.component.css']
})
export class Test2Component {
  @ViewChild(ReChartComponent) chartComponent: ReChartComponent | undefined;
  buffer = false;

  userText: string = 'Zeige mir eine Bar Chart' // Initialize the variable to bind with textarea
  dataText: string = `[
    {
        "id": "9df99701a2f3bf80",
        "Object_Key": "150020000000",
        "name": "Universitätsklinikum Halle (Saale)",
        "only_day_clinic": false,
        "anzahl_betten_total": 911,
        "ambulant_fallzahlen_total": 161113,
        "stationaer_fallzahlen_total": 34826,
        "anzahl_fachabteilungen": 27,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.9365,
                51.5018
            ]
        }
    },
    {
        "id": "d252ba69f58b281d",
        "Object_Key": "150030000000",
        "name": "Universitätsklinikum Magdeburg A. ö. R.",
        "only_day_clinic": false,
        "anzahl_betten_total": 893,
        "ambulant_fallzahlen_total": 144133,
        "stationaer_fallzahlen_total": 33268,
        "anzahl_fachabteilungen": 33,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.6183,
                52.1001
            ]
        }
    },
    {
        "id": "8ca58950de963556",
        "Object_Key": "150030000000",
        "name": "KLINIKUM MAGDEBURG gemeinnützige GmbH",
        "only_day_clinic": false,
        "anzahl_betten_total": 791,
        "ambulant_fallzahlen_total": 40823,
        "stationaer_fallzahlen_total": 24496,
        "anzahl_fachabteilungen": 21,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.5821,
                52.1573
            ]
        }
    },
    {
        "id": "3dda0b152f1cd34a",
        "Object_Key": "150010000000",
        "name": "Städtisches Klinikum Dessau",
        "only_day_clinic": false,
        "anzahl_betten_total": 717,
        "ambulant_fallzahlen_total": 53833,
        "stationaer_fallzahlen_total": 24548,
        "anzahl_fachabteilungen": 20,
        "geometry": {
            "type": "Point",
            "coordinates": [
                12.1991,
                51.8176
            ]
        }
    },
    {
        "id": "f0f85aeb64bf9a45",
        "Object_Key": "150020000000",
        "name": "BG Klinikum Bergmannstrost Halle",
        "only_day_clinic": false,
        "anzahl_betten_total": 571,
        "ambulant_fallzahlen_total": 43920,
        "stationaer_fallzahlen_total": 10746,
        "anzahl_fachabteilungen": 10,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.9867,
                51.4601
            ]
        }
    },
    {
        "id": "14c7386cd724ab17",
        "Object_Key": "150020000000",
        "name": "Krankenhaus St. Elisabeth und St. Barbara Halle (Saale) GmbH / St. Elisabeth - Mauerstraße",
        "only_day_clinic": false,
        "anzahl_betten_total": 519,
        "ambulant_fallzahlen_total": 20225,
        "stationaer_fallzahlen_total": 19108,
        "anzahl_fachabteilungen": 14,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.9691,
                51.4772
            ]
        }
    },
    {
        "id": "41801d951be637bc",
        "Object_Key": "150020000000",
        "name": "Krankenhaus Martha-Maria Halle-Dölau",
        "only_day_clinic": false,
        "anzahl_betten_total": 503,
        "ambulant_fallzahlen_total": 24115,
        "stationaer_fallzahlen_total": 17494,
        "anzahl_fachabteilungen": 14,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.9768,
                51.4886
            ]
        }
    },
    {
        "id": "9adb9b96d24c3df5",
        "Object_Key": "150840355355",
        "name": "SRH Klinikum Klinikum Naumburg",
        "only_day_clinic": false,
        "anzahl_betten_total": 468,
        "ambulant_fallzahlen_total": 15789,
        "stationaer_fallzahlen_total": 12028,
        "anzahl_fachabteilungen": 13,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.8079,
                51.1555
            ]
        }
    },
    {
        "id": "b718f92f3e93556b",
        "Object_Key": "150880220220",
        "name": "Carl-von-Basedow-Klinikum Saalekreis gGmbH",
        "only_day_clinic": false,
        "anzahl_betten_total": 413,
        "ambulant_fallzahlen_total": 31249,
        "stationaer_fallzahlen_total": 13733,
        "anzahl_fachabteilungen": 16,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.9941,
                51.3666
            ]
        }
    },
    {
        "id": "8ec5bf27fb21891d",
        "Object_Key": "150820015015",
        "name": "Gesundheitszentrum Bitterfeld/Wolfen gGmbH",
        "only_day_clinic": false,
        "anzahl_betten_total": 412,
        "ambulant_fallzahlen_total": 12571,
        "stationaer_fallzahlen_total": 12593,
        "anzahl_fachabteilungen": 9,
        "geometry": {
            "type": "Point",
            "coordinates": [
                12.3341,
                51.6333
            ]
        }
    },
    {
        "id": "99a2e1079fd46a10",
        "Object_Key": "150840550550",
        "name": "Asklepios Klinik Weißenfels",
        "only_day_clinic": false,
        "anzahl_betten_total": 410,
        "ambulant_fallzahlen_total": 19663,
        "stationaer_fallzahlen_total": 14328,
        "anzahl_fachabteilungen": 6,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.9564,
                51.1947
            ]
        }
    },
    {
        "id": "004f598f664899de",
        "Object_Key": "150850235235",
        "name": "Harzklinikum Dorothea Christiane Erxleben, Standort Quedlinburg",
        "only_day_clinic": false,
        "anzahl_betten_total": 355,
        "ambulant_fallzahlen_total": 37638,
        "stationaer_fallzahlen_total": 13842,
        "anzahl_fachabteilungen": 13,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.1625,
                51.7942
            ]
        }
    },
    {
        "id": "d1556e183c4bcf7d",
        "Object_Key": "150850135135",
        "name": "AMEOS Klinikum Halberstadt",
        "only_day_clinic": false,
        "anzahl_betten_total": 331,
        "ambulant_fallzahlen_total": 2227,
        "stationaer_fallzahlen_total": 15442,
        "anzahl_fachabteilungen": 13,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.0542,
                51.901
            ]
        }
    },
    {
        "id": "77eba37a0623aa63",
        "Object_Key": "150850370370",
        "name": "Harzklinikum Dorothea Christiane Erxleben, Standort Wernigerode",
        "only_day_clinic": false,
        "anzahl_betten_total": 314,
        "ambulant_fallzahlen_total": 34017,
        "stationaer_fallzahlen_total": 13604,
        "anzahl_fachabteilungen": 13,
        "geometry": {
            "type": "Point",
            "coordinates": [
                10.7746,
                51.8356
            ]
        }
    },
    {
        "id": "573df86538047487",
        "Object_Key": "150900535535",
        "name": "Salus gGmbH Fachklinikum Uchtspringe",
        "only_day_clinic": false,
        "anzahl_betten_total": 306,
        "ambulant_fallzahlen_total": 6129,
        "stationaer_fallzahlen_total": 3924,
        "anzahl_fachabteilungen": 7,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.8573,
                52.6043
            ]
        }
    },
    {
        "id": "922415dbe6d531b8",
        "Object_Key": "150910375375",
        "name": "Paul Gerhardt Diakonie Krankenhaus und Pflege GmbH",
        "only_day_clinic": false,
        "anzahl_betten_total": 304,
        "ambulant_fallzahlen_total": 22365,
        "stationaer_fallzahlen_total": 14118,
        "anzahl_fachabteilungen": 12,
        "geometry": {
            "type": "Point",
            "coordinates": [
                12.6558,
                51.8702
            ]
        }
    },
    {
        "id": "6882d25561fa3786",
        "Object_Key": "150840590590",
        "name": "SRH Klinikum Zeitz",
        "only_day_clinic": false,
        "anzahl_betten_total": 293,
        "ambulant_fallzahlen_total": 11978,
        "stationaer_fallzahlen_total": 8742,
        "anzahl_fachabteilungen": 9,
        "geometry": {
            "type": "Point",
            "coordinates": [
                12.1597,
                51.0315
            ]
        }
    },
    {
        "id": "b0d03f883d49c97c",
        "Object_Key": "150900535535",
        "name": "Johanniter GmbH - Zweigniederlassung Stendal",
        "only_day_clinic": false,
        "anzahl_betten_total": 290,
        "ambulant_fallzahlen_total": 26190,
        "stationaer_fallzahlen_total": 12203,
        "anzahl_fachabteilungen": 14,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.8541,
                52.6112
            ]
        }
    },
    {
        "id": "3f5b8b8366632c85",
        "Object_Key": "150870220220",
        "name": "Helios Klinik Hettstedt",
        "only_day_clinic": false,
        "anzahl_betten_total": 272,
        "ambulant_fallzahlen_total": 9270,
        "stationaer_fallzahlen_total": 6981,
        "anzahl_fachabteilungen": 8,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.4936,
                51.6603
            ]
        }
    },
    {
        "id": "ecd424089e49f32c",
        "Object_Key": "150030000000",
        "name": "Klinikum in den Pfeifferschen Stiftungen",
        "only_day_clinic": false,
        "anzahl_betten_total": 270,
        "ambulant_fallzahlen_total": 10464,
        "stationaer_fallzahlen_total": 8604,
        "anzahl_fachabteilungen": 7,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.6672,
                52.1158
            ]
        }
    },
    {
        "id": "064bb763f5eaa253",
        "Object_Key": "150870370370",
        "name": "HELIOS Klinik Sangerhausen",
        "only_day_clinic": false,
        "anzahl_betten_total": 268,
        "ambulant_fallzahlen_total": 15568,
        "stationaer_fallzahlen_total": 11081,
        "anzahl_fachabteilungen": 9,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.3096,
                51.4722
            ]
        }
    },
    {
        "id": "2cb86530f0978d36",
        "Object_Key": "150870130130",
        "name": "Helios Klinik Lutherstadt Eisleben",
        "only_day_clinic": false,
        "anzahl_betten_total": 247,
        "ambulant_fallzahlen_total": 7668,
        "stationaer_fallzahlen_total": 8355,
        "anzahl_fachabteilungen": 9,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.5398,
                51.5325
            ]
        }
    },
    {
        "id": "a09a3499570fca3a",
        "Object_Key": "150860015015",
        "name": "Helios Klinik Jerichower Land",
        "only_day_clinic": false,
        "anzahl_betten_total": 240,
        "ambulant_fallzahlen_total": 8991,
        "stationaer_fallzahlen_total": 10213,
        "anzahl_fachabteilungen": 8,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.8335,
                52.2644
            ]
        }
    },
    {
        "id": "ce27a4aa55843ae8",
        "Object_Key": "150890030030",
        "name": "Salus gGmbH, Fachklinikum Bernburg",
        "only_day_clinic": false,
        "anzahl_betten_total": 225,
        "ambulant_fallzahlen_total": 3098,
        "stationaer_fallzahlen_total": 2488,
        "anzahl_fachabteilungen": 6,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.7289,
                51.7864
            ]
        }
    },
    {
        "id": "fbbfbc4143a9521d",
        "Object_Key": "150890015015",
        "name": "AMEOS Klinikum Aschersleben",
        "only_day_clinic": false,
        "anzahl_betten_total": 215,
        "ambulant_fallzahlen_total": 10974,
        "stationaer_fallzahlen_total": 10444,
        "anzahl_fachabteilungen": 12,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.4586,
                51.7478
            ]
        }
    },
    {
        "id": "23e3339a649924c7",
        "Object_Key": "150880305305",
        "name": "Carl-von-Basedow-Klinikum Saalekreis gGmbH",
        "only_day_clinic": false,
        "anzahl_betten_total": 213,
        "ambulant_fallzahlen_total": 8161,
        "stationaer_fallzahlen_total": 4694,
        "anzahl_fachabteilungen": 5,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.6078,
                51.3707
            ]
        }
    },
    {
        "id": "803625e48f131f98",
        "Object_Key": "150820180180",
        "name": "Helios Klinik Köthen GmbH",
        "only_day_clinic": false,
        "anzahl_betten_total": 210,
        "ambulant_fallzahlen_total": 18398,
        "stationaer_fallzahlen_total": 9566,
        "anzahl_fachabteilungen": 6,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.9711,
                51.7468
            ]
        }
    },
    {
        "id": "edbec2daadc662c5",
        "Object_Key": "150850055055",
        "name": "Harzklinikum Dorothea Christiane Erxleben GmbH, Standort Blankenburg",
        "only_day_clinic": false,
        "anzahl_betten_total": 204,
        "ambulant_fallzahlen_total": 1580,
        "stationaer_fallzahlen_total": 1501,
        "anzahl_fachabteilungen": 2,
        "geometry": {
            "type": "Point",
            "coordinates": [
                10.9556,
                51.792
            ]
        }
    },
    {
        "id": "6a21fbd95a6aece3",
        "Object_Key": "150890030030",
        "name": "AMEOS Klinikum Bernburg",
        "only_day_clinic": false,
        "anzahl_betten_total": 201,
        "ambulant_fallzahlen_total": 15078,
        "stationaer_fallzahlen_total": 5776,
        "anzahl_fachabteilungen": 6,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.7333,
                51.7822
            ]
        }
    },
    {
        "id": "43325149e3c9f348",
        "Object_Key": "150810455455",
        "name": "Altmark Klinikum Salzwedel",
        "only_day_clinic": false,
        "anzahl_betten_total": 200,
        "ambulant_fallzahlen_total": 28401,
        "stationaer_fallzahlen_total": 8301,
        "anzahl_fachabteilungen": 8,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.1453,
                52.8561
            ]
        }
    },
    {
        "id": "1254a4783e0e9724",
        "Object_Key": "150020000000",
        "name": "Diakoniekrankenhaus Halle gGmbH",
        "only_day_clinic": false,
        "anzahl_betten_total": 198,
        "ambulant_fallzahlen_total": 1366,
        "stationaer_fallzahlen_total": 4805,
        "anzahl_fachabteilungen": 7,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.9768,
                51.4886
            ]
        }
    },
    {
        "id": "41ad17ef2f8c783d",
        "Object_Key": "150830270270",
        "name": "AMEOS Klinikum Haldensleben",
        "only_day_clinic": false,
        "anzahl_betten_total": 196,
        "ambulant_fallzahlen_total": 1789,
        "stationaer_fallzahlen_total": 8230,
        "anzahl_fachabteilungen": 9,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.3763,
                52.2854
            ]
        }
    },
    {
        "id": "ecfd3c9f9fe000e0",
        "Object_Key": "150830270270",
        "name": "AMEOS Klinikum Haldensleben GmbH",
        "only_day_clinic": false,
        "anzahl_betten_total": 188,
        "ambulant_fallzahlen_total": 4836,
        "stationaer_fallzahlen_total": 1800,
        "anzahl_fachabteilungen": 5,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.3706,
                52.2822
            ]
        }
    },
    {
        "id": "9db76a3985459550",
        "Object_Key": "150810135135",
        "name": "Altmark Klinikum Gardelegen",
        "only_day_clinic": false,
        "anzahl_betten_total": 187,
        "ambulant_fallzahlen_total": 28136,
        "stationaer_fallzahlen_total": 6486,
        "anzahl_fachabteilungen": 6,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.3817,
                52.5224
            ]
        }
    },
    {
        "id": "4db331b2c3bc286d",
        "Object_Key": "150830415415",
        "name": "Helios Bördeklinik",
        "only_day_clinic": false,
        "anzahl_betten_total": 182,
        "ambulant_fallzahlen_total": 6704,
        "stationaer_fallzahlen_total": 9052,
        "anzahl_fachabteilungen": 7,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.1907,
                52.0657
            ]
        }
    },
    {
        "id": "b0ab4955d3a352f9",
        "Object_Key": "150860145145",
        "name": "Lungenklinik Lostau",
        "only_day_clinic": false,
        "anzahl_betten_total": 170,
        "ambulant_fallzahlen_total": 5743,
        "stationaer_fallzahlen_total": 3255,
        "anzahl_fachabteilungen": 4,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.7444,
                52.2093
            ]
        }
    },
    {
        "id": "6fec4378c1515816",
        "Object_Key": "150860080080",
        "name": "AWO Fachkrankenhaus Jerichow",
        "only_day_clinic": false,
        "anzahl_betten_total": 170,
        "ambulant_fallzahlen_total": 1492,
        "stationaer_fallzahlen_total": 1343,
        "anzahl_fachabteilungen": 3,
        "geometry": {
            "type": "Point",
            "coordinates": [
                12.0368,
                52.5006
            ]
        }
    },
    {
        "id": "759ff91eb3efb985",
        "Object_Key": "150030000000",
        "name": "Krankenhaus St. Marienstift Magdeburg GmbH",
        "only_day_clinic": false,
        "anzahl_betten_total": 169,
        "ambulant_fallzahlen_total": 1933,
        "stationaer_fallzahlen_total": 7744,
        "anzahl_fachabteilungen": 14,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.6005,
                52.1372
            ]
        }
    },
    {
        "id": "5d31709d98a1cba7",
        "Object_Key": "150910375375",
        "name": "Klinik Bosse Wittenberg",
        "only_day_clinic": false,
        "anzahl_betten_total": 164,
        "ambulant_fallzahlen_total": 1632,
        "stationaer_fallzahlen_total": 2483,
        "anzahl_fachabteilungen": 2,
        "geometry": {
            "type": "Point",
            "coordinates": [
                12.6313,
                51.8702
            ]
        }
    },
    {
        "id": "34d1f25caed28c91",
        "Object_Key": "150820430430",
        "name": "Helios Klinik Zerbst/Anhalt",
        "only_day_clinic": false,
        "anzahl_betten_total": 157,
        "ambulant_fallzahlen_total": 4668,
        "stationaer_fallzahlen_total": 6695,
        "anzahl_fachabteilungen": 5,
        "geometry": {
            "type": "Point",
            "coordinates": [
                12.0942,
                51.9602
            ]
        }
    },
    {
        "id": "cca8d3eb05830c5b",
        "Object_Key": "150860055055",
        "name": "Helios Fachklinik Vogelsang-Gommern",
        "only_day_clinic": false,
        "anzahl_betten_total": 155,
        "ambulant_fallzahlen_total": 9212,
        "stationaer_fallzahlen_total": 4765,
        "anzahl_fachabteilungen": 4,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.8213,
                52.0747
            ]
        }
    },
    {
        "id": "84e61e396f38b219",
        "Object_Key": "150890305305",
        "name": "AMEOS Klinikum Schönebeck",
        "only_day_clinic": false,
        "anzahl_betten_total": 132,
        "ambulant_fallzahlen_total": 0,
        "stationaer_fallzahlen_total": 4119,
        "anzahl_fachabteilungen": 9,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.7228,
                51.9981
            ]
        }
    },
    {
        "id": "cbd3c3790eb05180",
        "Object_Key": "150905053520",
        "name": "AGAPLESION DIAKONIEKRANKENHAUS SEEHAUSEN",
        "only_day_clinic": false,
        "anzahl_betten_total": 114,
        "ambulant_fallzahlen_total": 7766,
        "stationaer_fallzahlen_total": 4392,
        "anzahl_fachabteilungen": 4,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.7474,
                52.8821
            ]
        }
    },
    {
        "id": "03fa62ac42368b12",
        "Object_Key": "150890305305",
        "name": "AMEOS Klinikum Schönebeck, Standort Bad Salzelmen",
        "only_day_clinic": false,
        "anzahl_betten_total": 113,
        "ambulant_fallzahlen_total": 441,
        "stationaer_fallzahlen_total": 5218,
        "anzahl_fachabteilungen": 2,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.7164,
                51.9962
            ]
        }
    },
    {
        "id": "13deb2c8c62f2567",
        "Object_Key": "150910060060",
        "name": "MediClin Herzzentrum Coswig",
        "only_day_clinic": false,
        "anzahl_betten_total": 113,
        "ambulant_fallzahlen_total": 382,
        "stationaer_fallzahlen_total": 4116,
        "anzahl_fachabteilungen": 3,
        "geometry": {
            "type": "Point",
            "coordinates": [
                12.4774,
                51.878
            ]
        }
    },
    {
        "id": "ad56c213f95ef7d7",
        "Object_Key": "150030000000",
        "name": "Uniklinik Magdeburg",
        "only_day_clinic": false,
        "anzahl_betten_total": 111,
        "ambulant_fallzahlen_total": 9312,
        "stationaer_fallzahlen_total": 5865,
        "anzahl_fachabteilungen": 2,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.6178,
                52.1295
            ]
        }
    },
    {
        "id": "32e4497fff8e9dd0",
        "Object_Key": "150850228228",
        "name": "Diakonie-Krankenhaus Elbingerode",
        "only_day_clinic": false,
        "anzahl_betten_total": 108,
        "ambulant_fallzahlen_total": 1881,
        "stationaer_fallzahlen_total": 1670,
        "anzahl_fachabteilungen": 3,
        "geometry": {
            "type": "Point",
            "coordinates": [
                10.7915,
                51.7726
            ]
        }
    },
    {
        "id": "2f5330df87633fc6",
        "Object_Key": "150890055055",
        "name": "Saale-Krankenhaus Calbe",
        "only_day_clinic": false,
        "anzahl_betten_total": 108,
        "ambulant_fallzahlen_total": 0,
        "stationaer_fallzahlen_total": 1793,
        "anzahl_fachabteilungen": 1,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.7678,
                51.904
            ]
        }
    },
    {
        "id": "c48242a085816db0",
        "Object_Key": "150890310310",
        "name": "AMEOS Klinikum Staßfurt",
        "only_day_clinic": false,
        "anzahl_betten_total": 106,
        "ambulant_fallzahlen_total": 0,
        "stationaer_fallzahlen_total": 1892,
        "anzahl_fachabteilungen": 4,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.5923,
                51.8528
            ]
        }
    },
    {
        "id": "eb6a86bac769ca3c",
        "Object_Key": "150020000000",
        "name": "Universitätsklinikum Halle (Saale) - Medizin-Campus Steintor",
        "only_day_clinic": false,
        "anzahl_betten_total": 105,
        "ambulant_fallzahlen_total": 13298,
        "stationaer_fallzahlen_total": 1266,
        "anzahl_fachabteilungen": 10,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.9797,
                51.485
            ]
        }
    },
    {
        "id": "d2e18c9bd2a69e44",
        "Object_Key": "150010000000",
        "name": "Städtisches Klinikum Dessau (Standort Gropiusallee 3)",
        "only_day_clinic": false,
        "anzahl_betten_total": 105,
        "ambulant_fallzahlen_total": 3774,
        "stationaer_fallzahlen_total": 5159,
        "anzahl_fachabteilungen": 4,
        "geometry": {
            "type": "Point",
            "coordinates": [
                12.2238,
                51.8444
            ]
        }
    },
    {
        "id": "51a18fc29d47fbf8",
        "Object_Key": "150020000000",
        "name": "AWO Psychiatriezentrum Halle GmbH",
        "only_day_clinic": false,
        "anzahl_betten_total": 100,
        "ambulant_fallzahlen_total": 1358,
        "stationaer_fallzahlen_total": 1362,
        "anzahl_fachabteilungen": 1,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.9176,
                51.4734
            ]
        }
    },
    {
        "id": "a41b0e9b5de1ade6",
        "Object_Key": "150010000000",
        "name": "St. Joseph-Krankenhaus Dessau",
        "only_day_clinic": false,
        "anzahl_betten_total": 100,
        "ambulant_fallzahlen_total": 17,
        "stationaer_fallzahlen_total": 1135,
        "anzahl_fachabteilungen": 1,
        "geometry": {
            "type": "Point",
            "coordinates": [
                12.1973,
                51.8181
            ]
        }
    },
    {
        "id": "61cbcba9255d2cc9",
        "Object_Key": "150030000000",
        "name": "MEDIAN Klinik NRZ Magdeburg",
        "only_day_clinic": false,
        "anzahl_betten_total": 100,
        "ambulant_fallzahlen_total": 0,
        "stationaer_fallzahlen_total": 592,
        "anzahl_fachabteilungen": 2,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.6373,
                52.086
            ]
        }
    },
    {
        "id": "9e7e694340fd4ebe",
        "Object_Key": "150850040040",
        "name": "Lungenklinik Ballenstedt/Harz GmbH",
        "only_day_clinic": false,
        "anzahl_betten_total": 99,
        "ambulant_fallzahlen_total": 0,
        "stationaer_fallzahlen_total": 2038,
        "anzahl_fachabteilungen": 1,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.2321,
                51.714
            ]
        }
    },
    {
        "id": "a3cca16ce4365347",
        "Object_Key": "150020000000",
        "name": "Krankenhaus St. Elisabeth und St. Barbara Halle (Saale) GmbH / St. Barbara - Barbarastraße",
        "only_day_clinic": false,
        "anzahl_betten_total": 82,
        "ambulant_fallzahlen_total": 1008,
        "stationaer_fallzahlen_total": 407,
        "anzahl_fachabteilungen": 3,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.9782,
                51.4596
            ]
        }
    },
    {
        "id": "539019587c9be09f",
        "Object_Key": "150900535535",
        "name": "Johanniter GmbH - Zweigniederlassung Stendal",
        "only_day_clinic": false,
        "anzahl_betten_total": 73,
        "ambulant_fallzahlen_total": 0,
        "stationaer_fallzahlen_total": 3505,
        "anzahl_fachabteilungen": 2,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.8596,
                52.5962
            ]
        }
    },
    {
        "id": "44b91a8df77cfe17",
        "Object_Key": "150850330330",
        "name": "Ev. Fachkrankenhaus für Psychiatrie &quot;Hildegard von Bingen&quot;",
        "only_day_clinic": false,
        "anzahl_betten_total": 62,
        "ambulant_fallzahlen_total": 2701,
        "stationaer_fallzahlen_total": 338,
        "anzahl_fachabteilungen": 2,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.0889,
                51.7515
            ]
        }
    },
    {
        "id": "c77708281803a747",
        "Object_Key": "150890030030",
        "name": "Waldklinik Bernburg",
        "only_day_clinic": false,
        "anzahl_betten_total": 57,
        "ambulant_fallzahlen_total": 0,
        "stationaer_fallzahlen_total": 765,
        "anzahl_fachabteilungen": 1,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.7245,
                51.7967
            ]
        }
    },
    {
        "id": "14b616890d68ca90",
        "Object_Key": "150010000000",
        "name": "St. Joseph-Krankenhaus Dessau / Psychiatrische Tagesklinik Dessau",
        "only_day_clinic": false,
        "anzahl_betten_total": 40,
        "ambulant_fallzahlen_total": 1379,
        "stationaer_fallzahlen_total": 0,
        "anzahl_fachabteilungen": 1,
        "geometry": {
            "type": "Point",
            "coordinates": [
                12.2461,
                51.8389
            ]
        }
    },
    {
        "id": "c49e7744458e79ff",
        "Object_Key": "150810455455",
        "name": "Salus gGmbH Tagesklinik für Kinder- und Jugendpsychiatrie, Psychosomatik und Psychotherapie Salzwedel",
        "only_day_clinic": false,
        "anzahl_betten_total": 39,
        "ambulant_fallzahlen_total": 610,
        "stationaer_fallzahlen_total": 0,
        "anzahl_fachabteilungen": 2,
        "geometry": {
            "type": "Point",
            "coordinates": [
                10.9625,
                52.8187
            ]
        }
    },
    {
        "id": "6ef0724509700595",
        "Object_Key": "150900535535",
        "name": "Salus gGmbH Tagesklinik für Erwachsenenpsychiatrie/-psychotherapie Stendal",
        "only_day_clinic": false,
        "anzahl_betten_total": 21,
        "ambulant_fallzahlen_total": 583,
        "stationaer_fallzahlen_total": 0,
        "anzahl_fachabteilungen": 1,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.8527,
                52.6041
            ]
        }
    },
    {
        "id": "93ad53544ec1eab8",
        "Object_Key": "150910375375",
        "name": "Fachklinikum Bernburg Tagesklinik Wittenberg",
        "only_day_clinic": false,
        "anzahl_betten_total": 20,
        "ambulant_fallzahlen_total": 165,
        "stationaer_fallzahlen_total": 0,
        "anzahl_fachabteilungen": 1,
        "geometry": {
            "type": "Point",
            "coordinates": [
                12.6355,
                51.8693
            ]
        }
    },
    {
        "id": "a397a92b3c91f91f",
        "Object_Key": "150905053520",
        "name": "Salus gGmbH Tagesklinik für Erwachsenenpsychiatrie/-psychotherapie Seehausen",
        "only_day_clinic": false,
        "anzahl_betten_total": 18,
        "ambulant_fallzahlen_total": 1123,
        "stationaer_fallzahlen_total": 0,
        "anzahl_fachabteilungen": 1,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.7474,
                52.8821
            ]
        }
    },
    {
        "id": "fd99c78fcc016719",
        "Object_Key": "150010000000",
        "name": "Fachklinikum Bernburg Tagesklinik Dessau",
        "only_day_clinic": false,
        "anzahl_betten_total": 18,
        "ambulant_fallzahlen_total": 29,
        "stationaer_fallzahlen_total": 0,
        "anzahl_fachabteilungen": 1,
        "geometry": {
            "type": "Point",
            "coordinates": [
                12.2236,
                51.8294
            ]
        }
    },
    {
        "id": "c3351e6b504a8551",
        "Object_Key": "150850370370",
        "name": "Harzklinikum Dorothea Christiane Erxleben GmbH, Standort Kinderklinik Wernigerode",
        "only_day_clinic": false,
        "anzahl_betten_total": 17,
        "ambulant_fallzahlen_total": 0,
        "stationaer_fallzahlen_total": 835,
        "anzahl_fachabteilungen": 1,
        "geometry": {
            "type": "Point",
            "coordinates": [
                10.7464,
                51.8164
            ]
        }
    },
    {
        "id": "1606468652ee9bf6",
        "Object_Key": "150820180180",
        "name": "Salus gGmbH, Tagesklinik Köthen",
        "only_day_clinic": false,
        "anzahl_betten_total": 17,
        "ambulant_fallzahlen_total": 0,
        "stationaer_fallzahlen_total": 0,
        "anzahl_fachabteilungen": 1,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.9777,
                51.7515
            ]
        }
    },
    {
        "id": "9543ef8863743b76",
        "Object_Key": "150900535535",
        "name": "Salus gGmbH Tagesklinik für Kinder- und Jugendpsychiatrie, Psychosomatik und Psychotherapie Stendal",
        "only_day_clinic": false,
        "anzahl_betten_total": 14,
        "ambulant_fallzahlen_total": 202,
        "stationaer_fallzahlen_total": 0,
        "anzahl_fachabteilungen": 1,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.8593,
                52.5996
            ]
        }
    },
    {
        "id": "505dfc1c73061af4",
        "Object_Key": "150820430430",
        "name": "St. Joseph-Krankenhaus Dessau/ Psychiatrische Tagesklinik Zerbst",
        "only_day_clinic": false,
        "anzahl_betten_total": 12,
        "ambulant_fallzahlen_total": 322,
        "stationaer_fallzahlen_total": 0,
        "anzahl_fachabteilungen": 1,
        "geometry": {
            "type": "Point",
            "coordinates": [
                12.0887,
                51.9545
            ]
        }
    },
    {
        "id": "43f9812ec3135999",
        "Object_Key": "150840355355",
        "name": "Carl-von-Basedow-Klinikum Saalekreis gGmbH",
        "only_day_clinic": false,
        "anzahl_betten_total": 10,
        "ambulant_fallzahlen_total": 294,
        "stationaer_fallzahlen_total": 0,
        "anzahl_fachabteilungen": 1,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.8149,
                51.161
            ]
        }
    },
    {
        "id": "af63541943a4b9a3",
        "Object_Key": "150030000000",
        "name": "Klinik des Westens GmbH",
        "only_day_clinic": false,
        "anzahl_betten_total": 10,
        "ambulant_fallzahlen_total": 0,
        "stationaer_fallzahlen_total": 294,
        "anzahl_fachabteilungen": 2,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.6247,
                52.1374
            ]
        }
    },
    {
        "id": "51e3a65c628e3de5",
        "Object_Key": "150900225225",
        "name": "AWO Tagesklinik Havelberg",
        "only_day_clinic": false,
        "anzahl_betten_total": 0,
        "ambulant_fallzahlen_total": 826,
        "stationaer_fallzahlen_total": 0,
        "anzahl_fachabteilungen": 1,
        "geometry": {
            "type": "Point",
            "coordinates": [
                12.0719,
                52.8197
            ]
        }
    },
    {
        "id": "168648a05f3f3342",
        "Object_Key": "150860015015",
        "name": "AWO Tagesklinik Burg",
        "only_day_clinic": false,
        "anzahl_betten_total": 0,
        "ambulant_fallzahlen_total": 376,
        "stationaer_fallzahlen_total": 0,
        "anzahl_fachabteilungen": 1,
        "geometry": {
            "type": "Point",
            "coordinates": [
                11.8466,
                52.2738
            ]
        }
    },
    {
        "id": "7ca7a857899dd033",
        "Object_Key": "150820015015",
        "name": "Gesundheitszentrum Bitterfeld/Wolfen gGmbH",
        "only_day_clinic": false,
        "anzahl_betten_total": 0,
        "ambulant_fallzahlen_total": 0,
        "stationaer_fallzahlen_total": 0,
        "anzahl_fachabteilungen": 2,
        "geometry": {
            "type": "Point",
            "coordinates": [
                12.2687,
                51.6612
            ]
        }
    }
]`
  
  
  
  chartConfig: any; 

  constructor(private aiService: AiServiceComponent) {
    
  }


  async updateChart(dataString: string, instructionString:string) {

    var msg = 'Data is ' + dataString + '. Instruction is: ' + instructionString
    let cf = await this.aiService.sendMessage(msg);
    
    console.log('tr',msg )

    try{
    var chartConfig = JSON.parse(cf);
    this.chartComponent?.updateChart(chartConfig)

    } catch(error) {

      alert(error)
    }



  }


  async sendMessage() {
    this.buffer = true;

    var msg = 'Data is ' + this.dataText.replace(/"geometry":\s*{[^}]*},?/g, ''); + '. Instruction is: ' + this.userText
    let cf = await this.aiService.sendMessage(msg);

    console.log('ant',msg )

    try{
    var chartConfig = JSON.parse(cf);
    this.chartComponent?.updateChart(chartConfig)

    } catch(error) {

      alert(error)
    }



  this.buffer = false;
  }
}
