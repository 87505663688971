import { Storageservice } from '../service/storageservice-component/storageservice-component.component'
export class RequestBuilder {
    private chartLegend: any[];
    private selectedMinYear: number;
    private selectedMaxYear: number;
    private selectedMinVal: number;
    private selectedMaxVal: number;
    private selectedShape: string;
    private selectedLevel: number;
    private breaksAmount: number;
    private datahubPath: number;
    private setColor: boolean;
    private colorScheme: string[];
    private selectedChartObject: any;
    private upload: any;
    private neighbours: any[] = [];
  
    constructor(
      chartLegend: any[],
      selectedMinYear: number,
      selectedMaxYear: number,
      selectedMinVal: number,
      selectedMaxVal: number,
      selectedShape: string,
      selectedLevel: number,
      breaksAmount: number,
      datahubPath: number,
      setColor: boolean,
      colorScheme: string[],
      private storageService: Storageservice, 
      selectedChartObject: any,
      neighbours: any[],
      upload?: any,
  
    ) {
      this.chartLegend = chartLegend;
      this.selectedMinYear = selectedMinYear;
      this.selectedMaxYear = selectedMaxYear;
      this.selectedMinVal = selectedMinVal;
      this.selectedMaxVal = selectedMaxVal;
      this.selectedShape = selectedShape;
      this.selectedLevel = selectedLevel;
      this.breaksAmount = breaksAmount;
      this.datahubPath = datahubPath;
      this.setColor = setColor;
      this.colorScheme = colorScheme;
      this.storageService = storageService;
      this.selectedChartObject = selectedChartObject;
      this.upload = upload;
      this.neighbours = neighbours

      
    }
  
    buildRequestObject() {
        try {
          var disableRegions = this.chartLegend.filter((c) => c.disabled == true);
          var disableKeys: any[] = [];
          disableRegions.forEach((el) => disableKeys.push(el.Object_Key));
    
          if (!this.selectedMinYear &&(this.datahubPath == 1 || this.datahubPath == 3)) this.selectedMinYear = 0;
          else if (!this.selectedMinYear && this.datahubPath == 2) this.selectedMinYear = 2000;
    
          if (!this.selectedMaxYear &&(this.datahubPath == 1 || this.datahubPath == 3)) this.selectedMaxYear = 400000;
          else if (!this.selectedMaxYear && this.datahubPath == 2) this.selectedMaxYear = 2024;
          
          if (!this.selectedMinVal) this.selectedMinVal = -1000000;
          if (!this.selectedMaxVal) this.selectedMaxVal = 1000000;
          if (!this.selectedShape)  this.selectedShape = this.storageService.getObject().Object_Key;
          
          var selObjKey = this.storageService.getObject().Object_Key;
        //  if (this.selectedLevel == 3) selObjKey = '15';
          if (this.breaksAmount > 8) this.breaksAmount = 8;
          var baseBody = {
            objectKey: selObjKey,
            focusedKey: this.selectedShape,
            tableKey: this.selectedChartObject.Source + this.selectedChartObject.Tabellen_ID,
            admin_level: Number(this.selectedLevel),
            minTime: this.selectedMinYear,
            maxTime: this.selectedMaxYear,
            minValue: this.selectedMinVal,
            maxValue: this.selectedMaxVal,
            filterKeys: disableKeys,
            datahubPath: this.datahubPath,
            amountBreaks: this.breaksAmount,
            setColor:this.setColor,
            colorScheme:this.colorScheme,
            neighbours: this.neighbours
          };
          
          var body = {...baseBody,...(this.datahubPath === 2 || this.datahubPath === 3) && { upload: this.upload },};
          
          return body;      
    
        } catch (error) {
          console.warn('req obj failed');
          return {};
        }
      }
  }
  