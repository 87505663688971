import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ChartOptions, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { AiServiceComponent } from './ai-service/ai-service.component';

interface ChartConfig {
  type: ChartType;
  data: {
    labels: string[];
    datasets: Array<{
      label: string;
      data: number[];
      backgroundColor?: string | string[];
      borderColor?: string | string[];
      borderWidth?: number;
    }>;
  };
  options?: {
    responsive?: boolean;
    scales?: any;
    animation?: any;
    elements?: any;
  };
}

@Component({
  selector: 're-chart',
  templateUrl: './re-chart.component.html',
  styleUrls: ['./re-chart.component.css']
})
export class ReChartComponent implements AfterViewInit {
  @ViewChild('chartCanvas') chartCanvas: ElementRef | undefined;
  
  @Input() chartWidth: number = 600;  // Input for chart width
  @Input() chartHeight: number = 400; // Input for chart height
  @Input() chartConfig : ChartConfig = {
    "type": "bar",
    "data": {
      "labels": ["January", "February", "March", "April", "May", "June"],
      "datasets": [
        {
          "label": "Sales",
          "data": [65, 59, 80, 81, 56, 55],
          "backgroundColor": "rgba(77, 83, 96, 0.2)",
          "borderColor": "rgba(77, 83, 96, 1)",
          "borderWidth": 1
        }
      ]
    },
    "options": {
      "responsive": true,
      "scales": {
        // "x": {
        //   "beginAtZero": true,
        //   "ticks":{
        //     autoSkip:false,
        //     maxRotation:90,
        //     minRotation:90
        //   }

          
        // },
        "y": {
          "beginAtZero": true
        }
      }
    }
  }


  
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;




 isValidChartConfig(chartConfig: ChartConfig): boolean {
  const validChartTypes: ChartType[] = ['line', 'bar', 'radar', 'pie', 'doughnut', 'polarArea'];

  // 1. Check for valid type
  if (!chartConfig.type || !validChartTypes.includes(chartConfig.type)) {
    console.error('Invalid chart type:', chartConfig.type);
    return false;
  }

  // 2. Check for required data properties
  if (!chartConfig.data || !Array.isArray(chartConfig.data.labels) || chartConfig.data.labels.length === 0) {
    console.error('Invalid or missing data labels');
    return false;
  }
  if (!chartConfig.data.datasets || chartConfig.data.datasets.length === 0) {
    console.error('Invalid or missing datasets');
    return false;
  }

  // 3. Check each dataset for required properties
  for (let dataset of chartConfig.data.datasets) {
    if (!dataset.label || !Array.isArray(dataset.data) || dataset.data.length === 0) {
      console.error('Invalid dataset:', dataset);
      return false;
    }
  }

  // 4. Optionally validate other properties in options
  if (chartConfig.options) {
    if (chartConfig.options.responsive && typeof chartConfig.options.responsive !== 'boolean') {
      console.error('Invalid responsive option');
      return false;
    }
    if (chartConfig.options.scales) {
      // Validate scales structure if necessary (can be customized for each chart type)
      if (typeof chartConfig.options.scales !== 'object') {
        console.error('Invalid scales structure');
        return false;
      }
    }
  }

  // If all checks pass, return true
  return true;
}




  constructor() {}
  ngAfterViewInit(): void {
    if(this.chartCanvas) {


      //this.chartCanvas.nativeElement.width = this.chartWidth;
        //  this.chartCanvas.nativeElement.height = this.chartHeight;
 }  }
  getChartData() {
    return  this.chart?.chart?.data;
  }

  updateChart(newConfig: any) {
    this.chartConfig = newConfig;



   if(this.chart?.chart) {

    this.chart.chart.update();

    let maxValues: number[] = []
    let minValues: number[] = []
    let datasetLabels: string[] = []
    setTimeout(()=>{   
   //@ts-ignore
    let labels = this.chart.chart.data?.labels;
       //@ts-ignore

    this.chart.chart.data?.datasets.forEach((element : any) => {
      datasetLabels.push(element.label)
      minValues.push(Math.min(...element.data)); // Berechnet den minimalen Wert
      maxValues.push(Math.max(...element.data));
      
    });
    let minValue= Math.min(...minValues)
    let maxValue= Math.min(...maxValues)


  console.log({
      labels: labels,
      datasetLabels: datasetLabels,
      minValue: minValue,
      maxValue: maxValue
    })
  })

  
   } 
 



  }

  // Methode zum Umgang mit dem Click-Ereignis auf dem Diagramm
  public async chartClicked(event: any): Promise<void> {


  return;

  }


  }




