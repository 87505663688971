import { Component } from '@angular/core';

@Component({
  selector: 'app-loading-overlay',
  template: `
    <div class="overlay">
      <div class="sand-timer">
        <div class="top">
          <div class="sand"></div>
        </div>
        <div class="bottom">
          <div class="sand"></div>
        </div>
      </div>

    </div>
  `,
  styles: [`
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.6);
      z-index: 1010;
      font-family: Arial, sans-serif;
    }

    .sand-timer {
      position: relative;
      width: 50px;
      height: 100px;
      border: 5px solid #000;
      border-radius: 10px;
      overflow: hidden;
      animation: flip 2s infinite;
    }

    .top, .bottom {
      position: absolute;
      width: 100%;
      height: 50%;
      background: #f0e68c; /* Sand color */
    }

    .top {
      top: 0;
      animation: pour 2s infinite;
    }

    .bottom {
      bottom: 0;
      animation: fill 2s infinite;
    }

    .sand {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #f0e68c; /* Sand color */
      top: 100%;
    }

    @keyframes pour {
      0% { top: 0%; }
      50% { top: 50%; }
      100% { top: 0%; }
    }

    @keyframes fill {
      0% { top: 100%; }
      50% { top: 50%; }
      100% { top: 100%; }
    }

    @keyframes flip {
      0% { transform: rotate(0deg); }
      50% { transform: rotate(180deg); }
      100% { transform: rotate(0deg); }
    }

    .loading-text {
      margin-top: 20px;
      font-size: 20px;
      color: #333;
      text-align: center;
    }
  `]
})
export class LoadingOverlayComponent {}
