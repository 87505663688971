import { Component, OnInit } from '@angular/core';
import { VorsorgeserviceComponent } from '../daseinsvorsorge/vorsorgeservice/vorsorgeservice.component';
import { Chart } from 'chart.js';
import { LlamaComponent } from 'src/app/service/llama/llama.component';

@Component({
  selector: 'app-ai-chart',
  templateUrl: './ai-chart.component.html',
  styleUrls: ['./ai-chart.component.css']
})
export class AiChartComponent implements OnInit{
updateChartPlot() {
this.graph = JSON.parse(this.graphString);

}
  messages: any = [];
  shoPlotly = true;
  chartString =       "{\n  \"type\": [\"bar\"],\n  \"data\": {\n    \"labels\": [\"004f598f664899de\", \"9df99701a2f3bf80\"],\n    \"datasets\": [\n      {\n        \"label\": [\"Intra-Krankenhaus-Spezialisierung\"],\n        \"data\": [0, 0.0003],\n        \"stack\": [\"Stack 0\"]\n      }\n    ]\n  },\n  \"options\": {\n    \"plugins\": {\n      \"title\": {\n        \"display\": [true],\n        \"text\": [\"Intra-Krankenhaus-Spezialisierung\"]\n      }\n    },\n    \"responsive\": [true],\n    \"interaction\": {\n      \"intersect\": [false]\n    },\n    \"scales\": {\n      \"x\": {\n        \"stacked\": [false]\n      },\n      \"y\": {\n        \"beginAtZero\": [false],\n        \"min\": [0],\n        \"max\": [0.0004]\n      }\n    }\n  }\n}"
  graphString = `{
    "data": [
      {
        "x": [1, 2, 3],
        "y": [2, 6, 3],
        "type": "scatter",
        "mode": "lines+points",
        "marker": {
          "color": "red"
        }
      },
      {
        "x": [1, 2, 3],
        "y": [2, 5, 3],
        "type": "bar"
      }
    ],
    "layout": {
      "width": 640,
      "height": 480,
      "title": "A Fancy Plot"
    }
  }`;
  
  chartResultConfig: any = {

  };
  public config = {
    scrollZoom: true
  };
  public graph = {
    data: [
        { x:  [1, 2, 3], y: [2, 6, 3], type: 'scatter', mode: 'lines+points', marker: {color: 'red'} },
        { x: [1, 2, 3], y: [2, 5, 3], type: 'bar' },
    ],
    layout: {width: 640, height: 480, title: 'A Fancy Plot'}
};

  constructor(private vorsorgeService: VorsorgeserviceComponent, private llamaService: LlamaComponent) {
  }


  
  async ngOnInit(): Promise<void> {
    this.results = await this.vorsorgeService.getInitialResultBuffer();

  this.chartResultConfig = JSON.parse( this.chartString
  )

    console.warn(this.results[0])
    this.createChart("h")

  }
  input: any;
  embedding: any;
  additional: any;
  title: any
  chart: any;
  results: any;


  async updateChart() {
  if (this.chart) {
    this.chart.destroy();
  }

  alert("hh")
 var t = await this.nextStepAI();
 console.warn(t)



}  

updateChart2() {
  this.chartResultConfig = JSON.parse( this.chartString
  )
}

async nextStepAI() {

  alert('hh')

  var string1 = "Ich habe folgende JSON Datei: " + JSON.stringify(this.results["Plot_params"][0]) 
  var string2 = " kannst du eine chart js json datei erstellen, so dass ich die direkt in angular einbinden kann?"

  console.warn('input', string1 + string2)
  var t =  await this.llamaService.addChat(string1 + string2) ;
  alert("h")
  console.warn("answer", t)
  this.messages.push({
    content: string1 ,
    role: 'user'
  })

  //@ts-ignore
  var printMessage2 = t.message.content ; 
  return printMessage2


  




}



createChart(title: any) {


  console.warn(this.results)
  if (this.chart) {
    this.chart.destroy();
  }

  var mDataset = [];
  mDataset.push({
    data: this.results["Plot_params"][0].share_class,
    fill: true,
    label: "Initiales Szenario"

  })




  if(this.results["Plot_params"][1]) {
    mDataset.push({
      data: this.results["Plot_params"][1].share_class,
      fill: true,
      label: title ? title :  "Neues Szenario"

    })

  }
  var chart = {
    type: 'bar',
    data: {
      labels: this.results["Plot_params"][0].class_plot,
      datasets: mDataset
    },
    options: {
      plugins: {
        legend: {
          display: true,
        }
      },
      scales: {
        y: {
          //beginAtZero: true,
          title: {
            display: true,
            text: "Anteil an Bevölkerung in %",

          }
        },
      },

    },
  };

  console.warn(chart)
  //@ts-ignore
  this.chart = new Chart("canvas0", chart);
}
}
