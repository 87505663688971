<app-loading-overlay *ngIf="isLoading"></app-loading-overlay>


<div class="map-container" style="height: 100%">
    <div class="map" id="map"></div>
</div>
  
  
  <div class="schoolIndi">
    <h3>
      Auswahlmöglichkeiten
    </h3>
  
    <mat-radio-group [(ngModel)]="mode" (ngModelChange)="onModeChange($event)">
      <mat-radio-button value="FahrplanAnsehen">
        Fahrplan anpassen
      </mat-radio-button>
      <mat-radio-button value="NeueRouteErstellen">
        Neue Route erstellen
      </mat-radio-button>
      <mat-radio-button value="Variantenvergleich" [disabled]="!variante1 && !variante2">
        Variantenvergleich
      </mat-radio-button>
    </mat-radio-group>
  </div>
  
  <div class="schoolIndi4"  *ngIf="mode === 'NeueRouteErstellen'" >
    <div id="arrival-time">
      <h3>Ankunftszeit</h3>
      <input type="time" [(ngModel)]="routeTime" placeholder="Enter time" />
    </div>
  </div>

  <div class="schoolIndi2" *ngIf="mode === 'NeueRouteErstellen'">
    <h3>Haltestellen</h3>
  
    <div *ngIf="selectedMarkers.length > 0" class="scrollable-table">
      <table style="width: 100%; border-collapse: collapse;">
        <thead>
          <tr>
            <th style="text-align: left; padding: 8px; border-bottom: 2px solid #ddd;">Haltestelle</th>
            <th *ngIf="Uhrzeit == 'delete'" style="text-align: center; padding: 8px; border-bottom: 2px solid #ddd;">Haltestelle entfernen</th>
            <th *ngIf="Uhrzeit == 'Umbau'" style="text-align: center; padding: 8px; border-bottom: 2px solid #ddd;">Uhrzeit</th>
            <th *ngIf="Uhrzeit == 'Umbau'" style="text-align: center; padding: 8px; border-bottom: 2px solid #ddd;">Uhrzeit nach umbau</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let marker of selectedMarkers; let i = index" [class.table-row-enter]="true">
            <td style="padding: 8px; border-bottom: 1px solid #ddd;">
              {{ marker.properties.stopFacilityName }}
            </td>
            <td *ngIf="Uhrzeit == 'delete'" style="padding: 8px; border-bottom: 1px solid #ddd; text-align: center;">
              <div class="delete-button" (click)="removeMarker(i)">
                Löschen
              </div>
            </td>
            <td *ngIf="Uhrzeit == 'Umbau'" style="padding: 8px; border-bottom: 1px solid #ddd; text-align: center;">
              <div>
                {{schedule[i].time}}
              </div>
            </td>

            <td *ngIf="Uhrzeit == 'Umbau'" style="padding: 8px; border-bottom: 1px solid #ddd; text-align: center;">
              <div>
                {{scheduleUmbau[i].time}}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  
    <div *ngIf="selectedMarkers.length > 0 && isNotclicked" style="height: 80px; width: 100%; display: flex; justify-content: space-around; align-items: center; margin-right: 140px;">
      <div>
        <button mat-raised-button color="primary" (click)="removeTransitAddNetwork()">Strecke festlegen</button>
      </div>
      <div>
        <button mat-raised-button color="warn" (click)="clearTable()">Tabelle löschen</button>
      </div>
    </div>
  </div>
  
  <div class="schoolIndi3"  *ngIf="mode == 'NeueRouteErstellen' && Uhrzeit == 'Umbau'" >
    <canvas id="myChart"></canvas>
  </div>

  <div class="antwort"  *ngIf="antwort==true && mode == 'NeueRouteErstellen' && Uhrzeit == 'Umbau'" >
   <h3> Antwort PVG:</h3>
   <p>Die geschätzten Kosten in Höhe von  lassen sich wie folgt aufschlüsseln:</p>
   <ul>
    <li><span style="color: orangered;">330.000€</span>pro Straßenkilometer bei einer Verbreiterung von derzeit 4.5m auf 6 m bei einer Straßenlänge von 6km:<span style="color: orangered;">1,96 Millionen</span> </li>
    <li>jährliche Aufwand für Winterdienst in Höhe von mindestens <span style="color: orangered;">6.820 €</span> (220 EUR pro Winterdienst-Tag, wobei im Schnitt 31 Winterdienst- Tage im Jahr anfallen)</li>
    <li>Schneefangzaun:</li>  
      <ul>
        <li>einmalige Investition von <span style="color: orangered;">56.000€</span></li>
        <li> jährliche Anbringungs- und Abbaukosten von <span style="color: orangered;">3.500€</span></li>
      </ul>
      <li>Die Beförderung muss mit zwei zusätzlichen Omnibussen erfolgen. Kosten: ca. <span style="color: orangered;">200.000€</span></li>
   </ul>
   <p>Die durchschnittlichen Jahreskosten über einen Zeitraum von 10 Jahren betragen <span style="color: orangered;">411.920€</span></p>
  </div>


  <div class="Fahrplan">
    <h2>Fahrplan 53min (Status Quo)</h2>
    <div class="transit-line">
      <!-- <h3>Bus 794 - Weißenfels, Busbahnhof</h3> -->
      <h4>Obernessa - Weißenfels, Busbahnhof</h4>
      <p><strong>Fahrtdauer:</strong> 22 min (6 Haltestellen)</p>
      
      <ul class="stop-list" style="color: orange;">
        <li><strong>5:35 AM</strong> - Obernessa</li>
        <li><strong>5:37 AM</strong> - Nessa, Ort</li>
        <li><strong>5:38 AM</strong> - Dippelsdorf</li>
        <li><strong>5:41 AM</strong> - Wernsdorf (Nessa) Ort - Teuchern</li>
        <li><strong>5:46 AM</strong> - Weißenfels, Kaserne</li>
        <li><strong>5:47 AM</strong> - Weißenfels, Zeitzer Str.</li>
        <li><strong>5:57 AM</strong> - Weißenfels, Busbahnhof</li>
      </ul>
    </div>
  
    <div class="transit-line">
      <h4>Weißenfels, Busbahnhof - Prittitz, Ort</h4>
      <p><strong>Fahrtdauer:</strong> 14 min (6 Haltestellen)</p>
  
      <ul class="stop-list" style="color: orangered;">
        <li><strong>6:00 AM</strong> - Weißenfels, Busbahnhof</li>
        <li><strong>6:03 AM</strong> - Weißenfels, Goethe-Gymnasium</li>
        <li><strong>6:04 AM</strong> - Weißenfels, Naumburger Str.</li>
        <li><strong>6:05 AM</strong> - Weißenfels, Krankenhaus</li>
        <li><strong>6:09 AM</strong> - Leißling, EKZ</li>
        <li><strong>6:13 AM</strong> - Prittitz, Abzweig Bahnhof</li>
        <li><strong>6:14 AM</strong> - Prittitz, Ort</li>
      </ul>
    </div>
  
    <div class="transit-line">
      <h4>Prittitz, Ort - Plotha, Schule (Prittitz)</h4>
      <p><strong  style="color: #cc0000;">Fußweg:</strong> Ungefähr 14 min, 1100 m</p>
      <p><strong>6:28 AM</strong> - Plotha, Schule (Prittitz)</p>
    </div>
  </div>
  