<div *ngIf="step==1" class="main">
    <div>
        <h1> Schulplanung-Szenario</h1>
    </div>

    <div class="nav-btn">
        <button mat-fab extended color="primary" style="width: 22%;" (click)="goToParams()">
            <mat-icon>add</mat-icon>
            Neues Szenario erstellen
        </button>

        <button mat-fab extended color="primary" style="width: 22%;">
            <mat-icon>search</mat-icon>
            Suche
        </button>
    </div>

    <div class="scenarien">
        <div class="boxScenarien">
            <div class="tile" *ngFor="let scenario of formerSelections" (click)="clickSelection(scenario)">
                <h2 style="text-align: center;">
                    {{scenario.name}}
                </h2>
            </div>
        </div>
    </div>

</div>

<div *ngIf="step==2" class="main2">


    <div class="nav-btn">
        <button mat-fab color="primary"  (click)="goBack()">
            <mat-icon>arrow_back_ios</mat-icon>
        </button>

   
    </div>

    <div class="row">
        <div>
            <p>Wählen Sie eine Bevölkerung aus:</p>

            <mat-radio-group [(ngModel)]="selectedPopulation" class="flexColum" id="populationList">
                <mat-radio-button *ngFor="let pop of populations" [value]="pop">
                    {{ pop }}
                </mat-radio-button>
            </mat-radio-group>

        </div>
        <div>
            <p>Wählen Sie einen Mobilitätsnetzwerke aus:</p>
            <mat-radio-group [(ngModel)]="selectedMobi" class="flexColum" id="schoolList">
                <mat-radio-button *ngFor="let mobi of mobis" [value]="mobi">
                    {{ mobi }}
                </mat-radio-button>
            </mat-radio-group>
        </div>

        

        <div>
            <p>Wählen Sie eine Schulart aus:</p>
            <mat-radio-group [(ngModel)]="selectedSchool" class="flexColum" id="schoolList">
                <mat-radio-button *ngFor="let school of schools" [value]="school">
                    {{ school }}
                </mat-radio-button>
            </mat-radio-group>
        </div> 

        <div *ngIf="selectedSchool == 'Gymnasien'">
            <p>Wählen Sie eine Grundschullandschaft:</p>
            <mat-radio-group [(ngModel)]="selectedPrimary" class="flexColum" id="schoolList">
                <mat-radio-button *ngFor="let scenario of formerSelections" >
                    {{ scenario.name }}
                </mat-radio-button>
            </mat-radio-group>
        </div>

    </div>
 
    <div class="row">

        <div *ngIf="selectedSchool == 'Gymnasien'">
            <p>Übergangsparameter Grundschule zu Gymnasium</p>
            <p> Durchschnittswert: {{getSum().toFixed(2)}}%</p>

            <div class="table-container">
                <table class="styled-table">
                  <tbody>
                    <tr *ngFor="let item of tableData | slice:0:5"
                        [ngStyle]="{ 'background-color': item.checked ? 'white' : 'grey' }"
                        (click)="item.checked = !item.checked">
                      <td>{{ item.Schuljahr }}</td>
                      <td>{{ item.Prozent }}%</td>
                    </tr>
                  </tbody>
                </table>
              
                <table class="styled-table">
                  <tbody>
                    <tr *ngFor="let item of tableData | slice:5:10"
                        [ngStyle]="{ 'background-color': item.checked ? 'white' : 'grey' }"
                        (click)="item.checked = !item.checked">
                      <td>{{ item.Schuljahr }}</td>
                      <td>{{ item.Prozent }}%</td>
                    </tr>
                  </tbody>
                </table>
              </div>

        </div>
     

    
    </div>

    <!--
    <div class="row">

             
        <div style=" width: 40%">
           
            <p> Mindestklassengröße (außerhalb Mittelzentren) </p>
            <div class="slider-box">
                <mat-slider class="year-slider" (change)="onYearChange($event)" min="12" max="20" step="1"
                    showTickMarks discrete>
                    <input matSliderThumb [(ngModel)]="sizeClassOutside">
                </mat-slider>
                <span class="year-display">{{ sizeClassOutside }} SuS</span>
            </div>
        </div>
  

    
        <div style=" width: 40%">
            <p> Höchsttransportdauer für Schüler:
            </p>
            <div class="slider-box">
                <mat-slider class="year-slider" (change)="onYearChange($event)" min="25" max="40" step="1"
                    showTickMarks discrete>
                    <input matSliderThumb [(ngModel)]="maxTransportTime">
                </mat-slider>
                <span class="year-display">{{ maxTransportTime }} min</span>
            </div>
        </div>

    
    </div>-->




        <button mat-fab extended color="primary" (click)="storeSelection()" style="font-size: 20px;"> Szenario anlegen </button>
