import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RegioService } from 'src/app/service/regioservice/regioservice.component';

@Component({
  selector: 'app-all-reports-dialog',
  templateUrl: './all-reports-dialog.component.html',
  styleUrls: ['./all-reports-dialog.component.css']
})
export class AllReportsDialogComponent {

  reports: any[] = [];
  selectedReport: any = null;
  
  constructor(
    public dialogRef: MatDialogRef<AllReportsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private regioservice: RegioService,
  ) {  
    console.log(data);
    this.reports= this.data
  }


  async fetchReportDetails(reportId: string): Promise<void> {
    const res = await this.regioservice.fetchReportDetails(reportId);
    console.log(res);
    this.selectedReport= res
    console.log(this.selectedReport);
  }

  close(){
    this.dialogRef.close(this.selectedReport)
  }
}