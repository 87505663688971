import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.css']
})
export class ErrorDialogComponent {
  @Input() needDialog: boolean = false;
  @Input() dialogTitle: string = '';
  @Input() dialogMessage: string = '';
  @Output() needDialogChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dialogClosed: EventEmitter<void> = new EventEmitter<void>();

  closeDialog() {
    this.needDialog = false;
    this.needDialogChange.emit(this.needDialog);
    this.dialogClosed.emit(); 
  }
}
