import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-hospital-umkreis',
  templateUrl: './hospital-umkreis.component.html',
  styleUrls: ['./hospital-umkreis.component.css']
})
export class HospitalUmkreisComponent {
  @Input() json: any | undefined;

}
