import { Component, OnInit } from '@angular/core';
import { Storageservice } from '../../service/storageservice-component/storageservice-component.component';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/service/dashboardService/dashboard.service';
import { ChatService } from 'src/app/service/chatService/chat.service';
import { KeycloakOperationService } from 'src/app/service/keycloakService/keycloak-operation.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
      private storageService: Storageservice,
      private keyCloakService: KeycloakOperationService,
      private router: Router,
      private dashboardService: DashboardService,
      private chatService: ChatService
  ) {}

intro:any;
SA:any;
DG:any;
fixSA:any;
mlUni:any;
notificationCount= 2;
filteredUsers: any[] = [];
users!: any[];
searchQuery: string = '';
starredUsers: any[] = [];
currentUser!: any;
totalNotificationCount = 0; // Initialize total count

hideDisplay(){
    
  this.intro=document.getElementById('intro');
  this.SA=document.getElementById('SA');
  this.DG=document.getElementById('DG');
  this.fixSA=document.getElementById('fixSA');
  this.mlUni=document.getElementById('mlUni');


  this.DG.style.display = "none";
  this.fixSA.style.display = "none";
  this.mlUni.style.display = "none";
  this.SA.style.transition =
    "position 1s ease, top 1s ease, left 1s ease, width 1s ease,height 1s ease, transform 1s ease"; // Hier wurde die Breite (width) hinzugefügt.
    this.SA.style.position = "fixed";
    this.SA.style.top = "1%";
    this.SA.style.right = "10%";
    this.SA.style.width = "50px";
    this.SA.style.height = "60px";
    this.SA.style.transform = "none";
    this.intro.style.transition = "background-color 1s ease"; // Übergang für die Hintergrundfarbe
    this.intro.style.backgroundColor = "transparent"; // Transparente Hintergrundfarbe

      setTimeout(() => {
        this.SA.style.display = "none";
        this.fixSA.style.display = "flex";
      }, 900);

    setTimeout(() => {
      this.intro.style.display = "none";
    }, 1000);

};

click(viewMode: string){
  this.router.navigateByUrl(viewMode); 
}

  navigateToNotifications(){
    this.router.navigateByUrl('nachrichten'); 
  }

  ngOnInit(){
  
    this.keyCloakService.getUserProfile().then((data: any) => {
      this.currentUser = data;

      this.keyCloakService.getUsersList().subscribe((users: any) => {
        this.chatService.loadNotifications().subscribe((notificationsContUserObject: any) => {  


          this.users = users.map((user: any) => {
            const notification = notificationsContUserObject.find((notification: any) => notification.sender === user.id);
            const userNotificationsCount = notification ? notification.count[this.currentUser.id] ?? 0 : 0;
            
            this.totalNotificationCount += userNotificationsCount; // Accumulate notifications count
    
            return {
              ...user,
              notificationsCount: userNotificationsCount
            };
          });
    
          console.log("Total Notifications Count:", this.totalNotificationCount);

        });
      
      });
      
    });


   
  }

}
