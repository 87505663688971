import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js/auto';
import * as MarkerClusterGroup from 'leaflet.markercluster';

import { latLng, tileLayer } from 'leaflet';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import { DatahubService } from '../../../service/datahubservice/datahubservice.component';
import { RegioService } from '../../../service/regioservice/regioservice.component';
import { RoutingserviceComponent } from '../../../service/routingservice/routingservice.component';
import { Storageservice } from '../../../service/storageservice-component/storageservice-component.component';
import { SimulationService } from 'src/app/service/simulationservice/simulation.service';
import * as htmlToImage from 'html-to-image';
import { LlamaComponent } from 'src/app/service/llama/llama.component';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakOperationService } from 'src/app/service/keycloakService/keycloak-operation.service';
import { ActivatedRoute } from '@angular/router';




type MarkerPlain =  {
    name: string,
    address: string,
    latlng: number[]
  }



type MarkerObj = {
  leaflet: L.Marker;
  plain: MarkerPlain;
};



@Component({
  selector: 'app-reach-matrix',
  templateUrl: './reach-matrix.component.html',
  styleUrls: ['./reach-matrix.component.css']
})
export class ReachMatrixComponent implements OnInit {
aitext: any;
  userID: any;
showAIModal(arg0: boolean) {
    //@ts-ignore
    document.getElementById("dialog-AI").showModal();
}
  ToBeDeleted: any;
  layerControl:any;
  allMode: boolean = false;

closeDeleteDialog() {
    //@ts-ignore
    document.getElementById("delete").close();
    this.ToBeDeleted = undefined; 

}
  markers: any = [];






  map: any;
  newMarker: L.Marker<any> | undefined;
  myIcon = L.icon({
    iconUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAFgUlEQVR4Aa1XA5BjWRTN2oW17d3YaZtr2962HUzbDNpjszW24mRt28p47v7zq/bXZtrp/lWnXr337j3nPCe85NcypgSFdugCpW5YoDAMRaIMqRi6aKq5E3YqDQO3qAwjVWrD8Ncq/RBpykd8oZUb/kaJutow8r1aP9II0WmLKLIsJyv1w/kqw9Ch2MYdB++12Onxee/QMwvf4/Dk/Lfp/i4nxTXtOoQ4pW5Aj7wpici1A9erdAN2OH64x8OSP9j3Ft3b7aWkTg/Fm91siTra0f9on5sQr9INejH6CUUUpavjFNq1B+Oadhxmnfa8RfEmN8VNAsQhPqF55xHkMzz3jSmChWU6f7/XZKNH+9+hBLOHYozuKQPxyMPUKkrX/K0uWnfFaJGS1QPRtZsOPtr3NsW0uyh6NNCOkU3Yz+bXbT3I8G3xE5EXLXtCXbbqwCO9zPQYPRTZ5vIDXD7U+w7rFDEoUUf7ibHIR4y6bLVPXrz8JVZEql13trxwue/uDivd3fkWRbS6/IA2bID4uk0UpF1N8qLlbBlXs4Ee7HLTfV1j54APvODnSfOWBqtKVvjgLKzF5YdEk5ewRkGlK0i33Eofffc7HT56jD7/6U+qH3Cx7SBLNntH5YIPvODnyfIXZYRVDPqgHtLs5ABHD3YzLuespb7t79FY34DjMwrVrcTuwlT55YMPvOBnRrJ4VXTdNnYug5ucHLBjEpt30701A3Ts+HEa73u6dT3FNWwflY86eMHPk+Yu+i6pzUpRrW7SNDg5JHR4KapmM5Wv2E8Tfcb1HoqqHMHU+uWDD7zg54mz5/2BSnizi9T1Dg4QQXLToGNCkb6tb1NU+QAlGr1++eADrzhn/u8Q2YZhQVlZ5+CAOtqfbhmaUCS1ezNFVm2imDbPmPng5wmz+gwh+oHDce0eUtQ6OGDIyR0uUhUsoO3vfDmmgOezH0mZN59x7MBi++WDL1g/eEiU3avlidO671bkLfwbw5XV2P8Pzo0ydy4t2/0eu33xYSOMOD8hTf4CrBtGMSoXfPLchX+J0ruSePw3LZeK0juPJbYzrhkH0io7B3k164hiGvawhOKMLkrQLyVpZg8rHFW7E2uHOL888IBPlNZ1FPzstSJM694fWr6RwpvcJK60+0HCILTBzZLFNdtAzJaohze60T8qBzyh5ZuOg5e7uwQppofEmf2++DYvmySqGBuKaicF1blQjhuHdvCIMvp8whTTfZzI7RldpwtSzL+F1+wkdZ2TBOW2gIF88PBTzD/gpeREAMEbxnJcaJHNHrpzji0gQCS6hdkEeYt9DF/2qPcEC8RM28Hwmr3sdNyht00byAut2k3gufWNtgtOEOFGUwcXWNDbdNbpgBGxEvKkOQsxivJx33iow0Vw5S6SVTrpVq11ysA2Rp7gTfPfktc6zhtXBBC+adRLshf6sG2RfHPZ5EAc4sVZ83yCN00Fk/4kggu40ZTvIEm5g24qtU4KjBrx/BTTH8ifVASAG7gKrnWxJDcU7x8X6Ecczhm3o6YicvsLXWfh3Ch1W0k8x0nXF+0fFxgt4phz8QvypiwCCFKMqXCnqXExjq10beH+UUA7+nG6mdG/Pu0f3LgFcGrl2s0kNNjpmoJ9o4B29CMO8dMT4Q5ox8uitF6fqsrJOr8qnwNbRzv6hSnG5wP+64C7h9lp30hKNtKdWjtdkbuPA19nJ7Tz3zR/ibgARbhb4AlhavcBebmTHcFl2fvYEnW0ox9xMxKBS8btJ+KiEbq9zA4RthQXDhPa0T9TEe69gWupwc6uBUphquXgf+/FrIjweHQS4/pduMe5ERUMHUd9xv8ZR98CxkS4F2n3EUrUZ10EYNw7BWm9x1GiPssi3GgiGRDKWRYZfXlON+dfNbM+GgIwYdwAAAAASUVORK5CYII=',
    iconSize: [24, 40], 
    iconAnchor: [12, 40], 
  })

  mapPNG: string | undefined;
selectedMode: any = 1;
closeLocation() {
 //@ts-ignore
 document.getElementById("dialog").close();
 //@ts-ignore
 document.getElementById("dialog_mini").close();
  //@ts-ignore
  document.getElementById("mini-dialog").close();

  this.newAnalyseMode = false; 
}
saveLocation(arg0: boolean) {
  this.addMarkerMode = false;

  if(this.newAnalyseMode) //@ts-ignore
  document.getElementById("dialog").showModal();
  
  

}
  singleMode: boolean = false;
  markersObjRef : any = [];
  
  markersObjRefMap =  new Map<string, MarkerObj>();


  async startAI( ) {
   // alert(this.aitext)

   var answer = await this.llama.addChat(''); 
   console.log("ANSWER", answer)

  }
  
searchFilter(res: any): any {
 var typeBool = false;
 if(!res.Meta) {
  return true; 
 }



if(res?.Meta[0].visibility == 'privat' && res.Meta[0].owner != this.userID) {
  return false; 
}


if(this.isRaster && (res.Meta[0].Typ == "Rasteranalyse")) {
  if(!this.searchBox) return true;
  return   res.Meta[0].Name.toLowerCase().includes(this.searchBox.toLowerCase()) 

} 



if(this.isRaster && (res.Meta[0].Typ != "Rasteranalyse")) {
  return false
 } 

 if(!this.isRaster && (res.Meta[0].Typ == "Rasteranalyse")) {
  return false
 } 

if(!this.searchBox) return true;

return   res.Meta[0].Name.toLowerCase().includes(this.searchBox.toLowerCase()) 
}
selectedStep: any = 1;
searchBox: any;
showMiniModal() {
  this.selectedStep = 2; 
  this.singleMode = true; 
  //@ts-ignore
  document.getElementById("mini-dialog").showModal();
}
  newAnalyseMode: boolean = true;



clickResult(startpoint: any, res: any) {
//  alert("res")

console.log("RES ist" , res);
console.log("startpoint ist" , startpoint);
//startpoint = undefined




this.clearMap(true); 
this.markersObjRefMap.clear();
this.addMarkerMode = false; 
this.orgObj = res; 


this.setLayer();

if(!startpoint) {
  this.allMode = true;

  // clicking the top level result
  var zoomPoint = res.Meta[0].Zoom_Point[0].geometry.coordinates

  this.selectedResult = res; 
  this.selectedResult.Starting_Point =  res.Results_All.All_Starting_Points
  this.selectedResult.Einrichtungen_Naehe = res.Results_All.All_Einrichtungen_Naehe_Unique[0]
  this.selectedResult.Isochron = res.Results_All.All_Isochrones
  this.selectedResult.Meta_Einrichtungen = res.Results_All.All_Einrichtungen_Naehe_Stats[0]

this.map.flyTo(zoomPoint)
this.setMapResult(); 

} else {
  this.allMode = false;


if(startpoint.Error) {
  alert(startpoint.Error);
  startpoint.Einrichtungen_Naehe = [];
  startpoint.Meta_Einrichtungen = []

}
this.selectedResult = startpoint; 
this.map.flyTo(startpoint.Starting_Point[0].geometry.coordinates)
this.setMapResult(); 
}
setTimeout(() => { this.map.invalidateSize(true)}, 200);

console.warn("RESYY", this.selectedResult)


}

  async clickRasterResult(startpoint: any, res: any, index: number) {
  this.clearMap(false); 
  this.markersObjRefMap.clear();
  this.addMarkerMode = false; 

  
  if(startpoint.Error) {
    alert(startpoint.Error);
    startpoint.Einrichtungen_Naehe = [];
    startpoint.Meta_Einrichtungen = []
  
  }

  this.selectedResult = startpoint; 
  this.selectedResult.Einrichtung = res.Meta[0].Einrichtungen[index] 
  this.updateLayerRaster(startpoint)  

  console.warn("RESXX", this.selectedResult)
  //this.mapPNG = await this.getMapImg(); 

  this.orgObj = res; 

  }

  deleteFinally() {

   
    if( this.ToBeDeleted.isTemp) {
      this.storageService.deleteResult( this.ToBeDeleted )
    } else {
     this.resultsFromDB = this.resultsFromDB.filter((el: any) => el !=  this.ToBeDeleted ); 
     this.datahubService.removeAnalyseData( this.ToBeDeleted.Meta[0].Name)
     //TODO: API CALL 
    }
     //@ts-ignore
   document.getElementById("delete").close();

  }
delete(res: any) {
  this.ToBeDeleted = res; 
   //@ts-ignore
   document.getElementById("delete").showModal();




}
  clickedName: any;

  async setMapResult() {
  this.clearMap(false); 
  this.clickedEinrichtung = undefined;  
  this.updateLayerNew(this.selectedResult);  

 
  this.mapPNG = await this.getMapImg(); 


 
}

public getMapImg(): Promise < string | undefined > {

  return new Promise((resolve, reject) => {

    const domMap = document.getElementById('map');


    if (domMap) {
      htmlToImage.toPng(domMap)
        .then(function (dataUrl) {
          resolve(dataUrl);
       
        })
        .catch(function (error) {
          console.error('Error:', error);
          reject();
        });
    }
  });

}
  orgObj: any;
sMode: any;
isUmkreis: any;
isRaster: any = false;

  startMarker!: L.Marker<any>;

  legend: any;


  radiusMode = '';

  clickedEinrichtung: any;

  public chart: any;
  isMinute: any = false;
  radius: any = '';
  public layers: any = [];
  selectedObjKey: any;
  selectedObject: any;
  meta: any;
  spinner2 = false;
  toggle: boolean = false;
  typeSelected: any = [];
  markerClusterGroup!: L.MarkerClusterGroup;
  selectedResult: any;
  newRadius = 0;
  oldRadius: any;
  colorMap: any = [] ;
  latlngCenter: L.LatLng = latLng(0,0);
  ///////Ahmed Variables///////
  markerPosition:any;
  urlRoute:any
  geoJsonLayer:any;
  einrichtungId:any;
  shape:any
  myChart:any
  selectedMarker:any
  shareArray: number[] = [];
  adressList:any = [];
  //street:any;
  
  geojsons:any=[]
  addMarkerMode: boolean = false;
  oldPosition: any;
  resultsFromDB: any = [];

 

  removeGeoJsonLayer() {
    if (this.geoJsonLayer) {
      this.map.removeLayer(this.geoJsonLayer);
    }
  }

  createChart() {
        const data =  this.storageService.reachResult['Filter'][0]['data']
        const backgroundColors = [];
        const category=[];
        const numberEinrichtungen=this.storageService.reachResult['Filter'][0]['n']
        const nEinrichtung=document.getElementById('nEinrichtung');
        const nEinrichtungOne=document.getElementById('nEinrichtungOne');
        const numberEinrichtungenOne=this.storageService.reachResult['Filter'][1]['Typ']

        if(nEinrichtung)
        {nEinrichtung!.innerHTML=`Einrichtungen im Umkreis: ${numberEinrichtungen}`}
        else if(nEinrichtungOne)
        {nEinrichtungOne!.innerHTML=`${numberEinrichtungenOne} im Umkreis: ${numberEinrichtungen}`}

        for (let i = 0; i < data.length; i++) {
          this.shareArray.push(data[i].share);
          const randomColor = `rgba(${Math.floor(Math.random() * 200) + 55}, ${Math.floor(Math.random() * 200) + 55}, ${Math.floor(Math.random() * 200) + 55}, 0.6)`;
          backgroundColors.push(randomColor);
            category.push(data[i].category)
        }
        console.log(this.shareArray);
        this.myChart = new Chart('myChart', {
          type: 'pie',
          data: {
            labels: category,
            datasets: [
              {
                data: this.shareArray,
                backgroundColor:backgroundColors,
              },
            ],
          },
        });
      
  }


  removeRoute(){
    if (this.geoJsonLayer) {
      this.map.removeLayer(this.geoJsonLayer);
  }
  }



  renderAllMarkers(){
    if(this.typeSelected.length==0){
      this.storageService.reachResult["Einrichtungen in der Nahe"].forEach((element: any) => {
          var ownIcon = L.icon({
            iconUrl: this.getLogo(element.Typ),
            iconSize: [24, 24],
            shadowSize: [50, 64],
            iconAnchor: [12, 24],
            shadowAnchor: [4, 62],
          });
    
          var latlng = latLng(element.geometry.coordinates[1], element.geometry.coordinates[0]);
          var newMarker = L.marker(latlng, { icon: ownIcon }).bindPopup(
            element.Name + "<br>" +
            element.Distanz + "Meter"
          );
    
          this.markerClusterGroup.addLayer(newMarker);
          this.markers.push(newMarker);
      });
    }
else
{    this.storageService.reachResult["Einrichtungen in der Nahe"].forEach((element: any) => {
      if (this.typeSelected.includes(element.Typ)) {
        var ownIcon = L.icon({
          iconUrl: this.getLogo(element.Typ),
          iconSize: [24, 24],
          shadowSize: [50, 64],
          iconAnchor: [12, 24],
          shadowAnchor: [4, 62],
        });
  
        var latlng = latLng(element.geometry.coordinates[1], element.geometry.coordinates[0]);
        var newMarker = L.marker(latlng, { icon: ownIcon }).bindPopup(
          element.Name + "<br>" +
          element.Distanz + "Meter"
        );
  
        this.markerClusterGroup.addLayer(newMarker);
        this.markers.push(newMarker);
      }
    });}
  }

  rendeSelectedMarker(clickedEinrichtung:any){
    const selectedIcon = L.icon({
      iconUrl: this.getLogo(clickedEinrichtung.Typ),
      iconSize: [24, 24],
      shadowSize: [50, 64],
      iconAnchor: [12, 24],
      shadowAnchor: [4, 62],
  });
  
  const selectedLatLng = L.latLng(
      clickedEinrichtung.geometry.coordinates[1],
      clickedEinrichtung.geometry.coordinates[0]
  );
  
  return this.selectedMarker = L.marker(selectedLatLng, { icon: selectedIcon }).bindPopup(
      clickedEinrichtung.Name + "<br>" +
      clickedEinrichtung.Distanz + "Meter"
  );
  }

  createRoute(data:any){
    const lines = data.map((item:any) => ({
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates: item.coordinates
      }
    }));
   
    const featureCollection: GeoJSON.FeatureCollection<GeoJSON.LineString> = {
      type: "FeatureCollection",
      features: lines
    };

    return this.geoJsonLayer = L.geoJSON(featureCollection, {
      style: {
        color: 'blue',
        opacity: 0.7
      }
    })
  }




  resetMap(){
    this.map.fitBounds(this.shape.getBounds());
    this.einrichtungId=''
    this.renderAllMarkers();
  }

  routeApi(einrichtung:any){
    this.map.flyTo([einrichtung.geometry.coordinates[1], einrichtung.geometry.coordinates[0]], 15)

    const einrichtungLat = einrichtung.geometry.coordinates[1];
    const einrichtungLng = einrichtung.geometry.coordinates[0];
    const markerLat = this.startMarker.getLatLng().lat;
    const markerLng = this.startMarker.getLatLng().lng;
    //const url=(this.storageService.reachResult['resultMeta']['url']);
    //const urlParts = new URL(url);
    const mode = "CAR" //urlParts.searchParams.get("mode");
    //TODO: Where to get mode??
   // console.warn("ROUTE",  `https://api.cephlabs.de/erreichbarkeit/get_route_between_points?coordinates1=${markerLng}&coordinates1=${markerLat}&coordinates2=${einrichtungLng}&coordinates2=${einrichtungLat}&mode=${mode}`)
    return this.urlRoute = `https://api.cephlabs.de/erreichbarkeit/get_route_between_points?coordinates1=${markerLng}&coordinates1=${markerLat}&coordinates2=${einrichtungLng}&coordinates2=${einrichtungLat}&mode=${mode}`;

  }

  removeRestIcons(){
    this.map.eachLayer( (layer:any) => {
      if (layer instanceof L.Marker && layer !== this.startMarker && layer !== this.selectedMarker) {
          this.map.removeLayer(layer);
      }
    });
  }

  clickEinrichtung(einrichtung: any, analyse: any) {
    this.map.invalidateSize();
    this.clickedEinrichtung = einrichtung;
    this.removeRoute();
  

    if(analyse == "Umkreissuche"){

      if(this.einrichtungId==einrichtung.Name){
        this.resetMap();
      }

      else{
        fetch(this.routeApi(einrichtung))
        .then(response => response.json())
        .then(data => {
          this.einrichtungId = einrichtung.Name;
          console.warn("DATA", data)
          this.createRoute(data).addTo(this.map);
          this.map.fitBounds(this.geoJsonLayer.getBounds());
        });
  
        this.rendeSelectedMarker(this.clickedEinrichtung).addTo(this.map);
        this.removeRestIcons();
        this.clearMap(false);
      }
    }

    else{
      if(this.einrichtungId==einrichtung['geometry']){
          // @ts-ignore
          let bounds = L.latLngBounds();

          this.geojsons.forEach((einrichtung: any) => {
            bounds.extend(einrichtung.getBounds());
          });
        
          this.map.fitBounds(bounds);
          this.einrichtungId=''
      }
      else{
        this.map.flyTo([einrichtung.geometry.coordinates[1], einrichtung.geometry.coordinates[0]], 18)
        this.einrichtungId = einrichtung['geometry'];
        console.log(this.storageService.reachResult)
        console.log(this.storageService.results)
      
      }
      
    }
  }

 
  createNewUrl (oldURL : any) {
 var newstr = oldURL; 
    if (this.radiusMode == 'min') {

      if (oldURL.includes("max_distance=")) {
        //we have a switch
        newstr = newstr.replace("https://api.cephlabs.de/erreichbarkeit/get_umkreis_einrichtungen_distance?", "https://api.cephlabs.de/erreichbarkeit/get_einrichtungen_and_isochrone_from_point_fahrzeit?");
        newstr = newstr.replace("max_distance=" + this.oldRadius, "max_fahrzeit=" + this.radius);
      } else {
        newstr = newstr.replace("max_fahrzeit=" + this.oldRadius, "max_fahrzeit=" + this.radius);
      }

    } else {
      if (oldURL.includes("max_fahrzeit=")) {
        newstr = oldURL.replace("https://api.cephlabs.de/erreichbarkeit/get_einrichtungen_and_isochrone_from_point_fahrzeit?", "https://api.cephlabs.de/erreichbarkeit/get_umkreis_einrichtungen_distance?");
        newstr = newstr.replace("max_fahrzeit=" + this.oldRadius, "max_distance=" + this.radius * 1000);
      } else {

        newstr = oldURL.replace("max_distance=" + this.oldRadius, "max_distance=" + this.radius * 1000);

      }
    }

    return newstr;

  }

  needDialog:boolean=false;




  selectType(type: any) {
    this.removeGeoJsonLayer();
    var index = this.typeSelected.indexOf(type)
    if (index == -1) {
      this.typeSelected = [];
      this.typeSelected.push(type);

      this.map.eachLayer( (layer:any) => {
        if (layer instanceof L.Marker && layer !== this.startMarker) {
            this.map.removeLayer(layer);
        }
      });
      this.clearMap(false);

      this.storageService.reachResult["Einrichtungen in der Nahe"].forEach((element: any) => {
        if (this.typeSelected.includes(element.Typ)) {
          var ownIcon = L.icon({
            iconUrl: this.getLogo(element.Typ),
            iconSize: [24, 24],
            shadowSize: [50, 64],
            iconAnchor: [12, 24],
            shadowAnchor: [4, 62],
          });
    
          var latlng = latLng(element.geometry.coordinates[1], element.geometry.coordinates[0]);
          var newMarker = L.marker(latlng, { icon: ownIcon }).bindPopup(
            element.Name + "<br>" +
            element.Distanz + "Meter"
          );
    
          this.markerClusterGroup.addLayer(newMarker);
          this.markers.push(newMarker);
        }
      });
      
      

      if(this.myChart)
      {  this.myChart.destroy();}
      this.shareArray=[];

    this.storageService.reachResult['Filter'][0]['data'];
   
    const filteredData = this.storageService.reachResult['Filter'].filter((element:any) => element.Typ == type);
    const dataR=filteredData[0]['data']
    const selectedTyp= filteredData[0]['n']
    console.log(type)
    console.log(filteredData)
    console.log(dataR)

    document.getElementById('nEinrichtung')!.innerHTML=`${type} im Umkreis: ${selectedTyp}`
    const backgroundColors = [];
    const category=[];
    for (let i = 0; i < dataR.length; i++) {
      this.shareArray.push(dataR[i].share);
      const randomColor = `rgba(${Math.floor(Math.random() * 200) + 55}, ${Math.floor(Math.random() * 200) + 55}, ${Math.floor(Math.random() * 200) + 55}, 0.6)`;
      backgroundColors.push(randomColor);
        category.push(dataR[i].category)
    }
    console.log(this.shareArray);
    this.myChart = new Chart('myChart', {
      type: 'pie',
      data: {
        labels: category,
        datasets: [
          {
            data: this.shareArray,
            backgroundColor:backgroundColors,
          },
        ],
      },
    });
   
      

    } else {
      this.typeSelected.splice(index, 1);
      this.clearMap(false);
      this.storageService.reachResult["Einrichtungen in der Nahe"].forEach((element: any) => {
          var ownIcon = L.icon({
            iconUrl: this.getLogo(element.Typ),
            iconSize: [24, 24],
            shadowSize: [50, 64],
            iconAnchor: [12, 24],
            shadowAnchor: [4, 62],
          });
    
          var latlng = latLng(element.geometry.coordinates[1], element.geometry.coordinates[0]);
          var newMarker = L.marker(latlng, { icon: ownIcon }).bindPopup(
            element.Name + "<br>" +
            element.Distanz + "Meter"
          );
  
          this.markerClusterGroup.addLayer(newMarker);
          this.markers.push(newMarker);
      });

      if(this.myChart)
      {  this.myChart.destroy();}
      this.shareArray=[];

      this.createChart();
    }
  }

  addLegend(clusterMeta: any, title: any) { 
    if (this.legend instanceof L.Control) { this.map.removeControl(this.legend); }


    //@ts-ignore
    this.legend = L.control({ position: 'bottomright' });
    //@ts-ignore
    this.legend.onAdd = () => {

      var div = L.DomUtil.create('div', 'info legend');
      var labels = [' <h3> ' + title +' </h3>  '];
    
      for (var i = 0; i < clusterMeta.length; i++) {
        div.innerHTML +=
          labels.push(
            '<div style="display: flex; flex-direction: row" > <div class="circle" style="background-color:' + clusterMeta[i].color_map  + '; width:40px; height: 20px"></div> ' +
            '<div style="margin-left: 5px;" > <b> ' + clusterMeta[i].class_map + ' </b> </div> </div>');}

       

      var l = labels.join('<br>');
      div.innerHTML = '<div style="background-color: rgba(0, 0, 0, 0.1);padding: 10px;border-radius: 20px;color: green;font-family: Arial, sans-serif;font-size: 14px;box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); border-bottom:1px solid green;border-top:1px solid green;border-right:7px solid green;border-left:7px solid green; display:flex;jutify-content:center; align-items:center; height:30px;"> ' + l + '</div>'
      return div;
    };
    this.legend.addTo(this.map);

  }


  updateLayerRaster(rasterResult: any) {
    

    if (this.chart) {
      this.chart.destroy();
    }

    console.warn("META", this.meta)

    rasterResult["Point_Params"].forEach((element: any) => {
      var iconUrl = this.getLogo(element.Typ);
      if(element.Typ != "Schulkinderhort ") {
      iconUrl = "../../../../assets/icon/Kinderhort.png"; 
      }
  
      var ownIcon = L.icon({
        iconUrl: iconUrl,
        iconSize: [24, 24], // size of the icon  
      });
      var latlng = latLng(element.geometry.coordinates[1], element.geometry.coordinates[0]);
      var newMarker = L.marker(latlng, { icon: ownIcon }).bindPopup(
        "<b>" + element.Name + "</b> <br>"
        + element.Typ + " | " + element.Kategorie + "<br>"
        + " Nächste Raster: " + element.closest_raster.splice(0, 4) + "<br>"
        + element.closest_raster.splice(4, 8) + "<br>"
        + element.closest_raster.splice(8, 12) + "<br>"
        + element.closest_raster.splice(12, 16) + "<br>"
        + element.closest_raster.splice(16, 20) + "<br>"
        + element.closest_raster.splice(20, element.closest_raster.length) + "<br>"
        + "Anteil an Bevölkerung: " + element.share_population + "<br>"

      )//.addTo(this.map);

      this.markerClusterGroup.addLayer(newMarker);

      this.markers.push(newMarker);


    })


    this.markerClusterGroup.addTo(this.map);

    rasterResult["Raster_Params"].forEach((raster: any) => {


      var l = L.geoJSON(raster.geometry,
        {
          style: {
            fillColor: raster.color_map,
            fillOpacity: 0.2,

            color: raster.color_map,
            weight: 0.2
          }
        })
      this.geojsons.push(l);
      l.bindPopup("<b> Raster ID: " + raster.raster_id + "</b> <br> Zeit zur nächsten Einrichtung: " + raster.shortest_travel_time)
      this.layers.push(l);

      
    });

    
    this.addLegend(rasterResult.Raster_Colors_Legend[0]      , 'Rasteranalyse Fahrzeit in Minuten ');
  }






  markerIcon = {
    icon: L.icon({
      iconSize: [25, 41],
      iconAnchor: [10, 41],
      popupAnchor: [2, -40],
      // specify the path here
      iconUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png",
      shadowUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-shadow.png"
    })

  };


  zoomOut(){
 

    this.map?.flyTo([51.0209,12.1488,], 12, {animate: true, duration: 2});
setTimeout(() => { this.map?.setZoom(12);}, 3000);
  }







  deleteMarker() {
    if (this.newMarker) {
      this.map.eachLayer((layer:any) => {
        if (layer == this.shape) {
          this.map.removeLayer(layer);
        }
      });
  
      this.newMarker.remove();
      this.newMarker = undefined;
    }
  }

  deleteCreatedMarker(marker: any) {

   var name = marker.key;

    if(this.markersObjRefMap.has(name)) {

      this.map.removeLayer(this.markersObjRefMap.get(name)?.leaflet)
      this.markersObjRefMap.delete(name)
  
     } else {

     }


     
  }


 markerArray: L.Marker[] = [];
 // use thi to draw all


  resetmap() {
    if (this.newMarker) {
    
      //this.newMarker.setIcon(this.getAddLocationIcon());
      this.map.eachLayer((layer:any) => {
        
        if (layer !== this.newMarker) {
          
          if (layer === this.shape) {
            this.map.removeLayer(layer); 
          } else if (layer instanceof L.Marker) {
            if (this.markerClusterGroup && this.markerClusterGroup.hasLayer(layer)) {
              this.markerClusterGroup.removeLayer(layer); 
            }
          }
        }
      });
      
      this.newMarker.removeFrom(this.map);
      this.newMarker = undefined;
      
    }
  }

  zeichneMarkerAufKarte() {
    const bereitsGerenderteKoordinaten:any = [];
  
    for (const marker of this.markerArray) {
      const latLng = marker.getLatLng();
      console.log(this.markerArray.length);
  
      const koordinatenKey = `${latLng.lat}-${latLng.lng}`;

      if (!bereitsGerenderteKoordinaten.includes(koordinatenKey)) {
        
        L.marker([latLng.lat, latLng.lng], { icon: this.myIcon }).addTo(this.map);
        bereitsGerenderteKoordinaten.push(koordinatenKey);
      }
    }
  }
  
  
  

  
  
  getAddLocationIcon() {
    return L.icon({
      iconUrl: 'assets/loc.svg',
      iconSize: [30, 45], 
      iconAnchor: [15, 41], 
      popupAnchor: [1, -34], 

      shadowSize: [41, 41],
      shadowAnchor: [12, 41] 
    });
  }
  
  

  addMarker() {
   
    this.addMarkerMode = true; 
  }

  onMapReady(map: any) {
    // Do stuff with map
    this.map = map;
    this.markerClusterGroup = L.markerClusterGroup({
      disableClusteringAtZoom: 13
    }

    );
    this.setLayer()

  }

  options = {
    layers: [
      tileLayer('https://m.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
    ],
    zoom: 12,
    minZoom: 9,
    center: latLng(51.04962, 12.1369)
  };


  constructor(private datahubService: DatahubService, private regioService: RegioService, public storageService: Storageservice, private keyCloakService: KeycloakOperationService,private simulationsService: SimulationService, private llama: LlamaComponent,
    private route: ActivatedRoute
  ) {
    this.selectedObject = this.storageService.getObject()
    this.setLayer();
  }
  async ngOnInit(): Promise<void> {
    this.keyCloakService.getUserProfile().then(async (data: any) => {
      this.userID = data.id; })

    


    this.resultsFromDB = await this.datahubService.getAnalyseData(); 

    console.warn("res", this.resultsFromDB)

    this.route.params.subscribe((params: any) => {
      if (Object.keys(params).length !== 0) {

       var item = this.resultsFromDB.filter((el: { _id: any; }) => el._id ==params['id'] )
       if(item.length > 0) {
        this.clickResult(item[0], item[0]); 
       } else {
        alert(params['id'] = " ist unbekannt"); 
       }

      }



    })
    this.meta = await this.datahubService.getEinrichtungenMeta();
  }



  clearMap(clearLocationFlg: boolean) {
 if(clearLocationFlg) {
    for (let [key, value] of this.markersObjRefMap) {
      this.map.removeLayer(value.leaflet)
  }
  this.markersObjRefMap.clear();

}



    this.markers.forEach((mark: any) => {
      this.map.removeLayer(mark)

    });
    
    if (this.newMarker) {
      this.map.removeLayer(this.newMarker)
    }

    if (this.startMarker) {
      this.map.removeLayer(this.startMarker)
    }
    this.map.removeLayer(this.markers)
    this.map.removeLayer(this.layers)
    if (this.markerClusterGroup) {
      this.markerClusterGroup.clearLayers();
    }
    this.layers = []
    this.removeRoute();

  }

updateLayerNew(result : any) {
  console.warn("XXX", result)

  var umkreissuche = result


  if (!umkreissuche["Einrichtungen_Naehe"]) {
    return;
  }
  var i = 0;

  umkreissuche.Starting_Point.forEach((sp: {
    Name: any; geometry: { coordinates: number[]; }; 
}) => {
  
  this.startMarker = L.marker([sp.geometry.coordinates[1], sp.geometry.coordinates[0]], { draggable: false, icon: this.myIcon }).addTo(this.map);
  
  this.startMarker.on('click', async (e: any) => {
    
    var selRes = result.Results.filter((x: any) => x.Starting_Point[0].Name == sp.Name)

       this.clickResult(selRes[0], result)


    }) 
  this.markers.push(this.startMarker);
  i++;
})





  umkreissuche["Einrichtungen_Naehe"].forEach((element: any) => {
    var ownIcon = L.icon({
      iconUrl: this.getLogo(element.Typ),
      iconSize: [24, 24], // size of the icon
      shadowSize: [50, 64], // size of the shadow
      iconAnchor: [12, 24], // point of the icon which will correspond to marker's location
      shadowAnchor: [4, 62],  // the same for the shadow

    });

    var latlng = latLng(element.geometry.coordinates[1], element.geometry.coordinates[0])
    //this.map?.flyTo(latlng)
    var newMarker = L.marker(latlng, { icon: ownIcon }).bindPopup(
      element.Name + "<br>" +
      element.Distanz + "Meter"
    )//.addTo(this.map);

  
    newMarker?.on('click', async (e: any) => { this.clickedName = element.Name })

    

    this.markerClusterGroup.addLayer(newMarker);
    this.markers.push(newMarker);


  })



  this.markerClusterGroup.addTo(this.map);


  umkreissuche["Isochron"].forEach((raster: any) => {
    var l = L.geoJSON(raster.geometry,
      {
        style: {
          fillColor: 'green',
          fillOpacity: 0.2,

          color: "black",
          weight: 0.3
        }
      })
    var umkreisString;
    if (raster.Umkreis) {
      umkreisString = raster.Umkreis + " Meter"
    } else {
      umkreisString = (raster.time / 60) + " Minuten "
    }
   
    l.bindPopup("<b> Umkreis: " + umkreisString + "</b>")
   
    const street= umkreissuche.Starting_Point[0].Street_Name

    if(result.Starting_Point.length > 1) {
      this.addLegend([], 'Umkreissuche: ' +  umkreisString + '<br> '+ result.Starting_Point.length + ' Start-Standorte')

    } else {
    if(street && street!='Straßennamen nicht gefunden'&& street!='empty'){
      
    this.addLegend([], 'Umkreissuche: ' +  umkreisString + '<br> Straße: '+ street)
    } else{
      this.addLegend([], 'Umkreissuche: ' +  umkreisString) 
    } }
    
    this.layers.push(l);


    this.map.fitBounds(l.getBounds());

    this.shape=l
 
  });


 




  }

  getLogo(sTyp: any) {
    var icon = '';
    this.meta?.forEach((bereich: any) => {
      bereich.Typen.forEach((typ: any) => {
        if (typ.Typ == sTyp) {
          icon = typ.Icon;
        }
      })
    });
    return "../../../../assets/icon/" + icon;
  }




  async addMarkerForLocation(lat: any, lng: any, name: any, address: any) {

      var icon = L.icon({
        iconUrl: 'assets/loc.svg',
        iconSize: [30, 45], 
      });

      if(address) {
        var coordinates = await this.datahubService.getCoordinatesForAdress(address);

        //@ts-ignore
        if (coordinates.features?.length > 0) {
      
            //@ts-ignore
            lat = coordinates.features[0].geometry.coordinates[1],
            //@ts-ignore
            lng = coordinates.features[0].geometry.coordinates[0]
      } else {
        alert("Addresse für " + name + " nicht bestimmbar!")
      }
      } else {
      //no address provided

      if(lat && lng) {
        address = 'Adresse nicht bestimmbar'
        var tempAddress     = await this.datahubService.getAdressForCoordinates(lat, lng);
        
    //@ts-ignore
        if(tempAddress.features[0])
          //@ts-ignore
        address = tempAddress.features[0].properties.label
    }}

      
      if(!name) {
         //new marker
        name = 'Marker_' + (this.markersObjRefMap.size + 1);
      
          
      }

     
      var markerObj = {
        name: name,
        address: address,
        latlng: [ lat, lng]
      }


      var lMarkerObj = L.marker([ lat, lng], { draggable: true, icon: icon });
       
      if(!this.markersObjRefMap.has(name)) 
        lMarkerObj.addTo(this.map); 
      
      this.markersObjRefMap.set(name, {leaflet: lMarkerObj, plain: markerObj})
    

        
      return {leaflet: lMarkerObj, plain: markerObj}
    
  }



 async addMarkerWithMapRef(lat: any, lng: any, name: any, address: any) {


  var markerObj = await this.addMarkerForLocation(lat, lng, name, address);
  console.warn("MARKER", markerObj)
  this.map.flyTo([markerObj.leaflet.getLatLng().lat ,markerObj.leaflet.getLatLng().lng])
    
  const el = document.createElement('div');
  el.innerHTML = "<h3> "+ markerObj.plain.name + "</h3>";
  var buttonDiv = document.createElement('div');
  buttonDiv.innerHTML = "<button id='test' style='padding: 10px; margin: 3%; height: 10%;  letter-spacing: 3px; border: 3px solid grey; border-radius: 15px;background-color: white;' > Löschen </button>"
  el.appendChild(buttonDiv); 


buttonDiv.addEventListener("click", (e) => { 
  this.deleteMarkerForLocation(markerObj); 
 // this.map.removeLayer(markerObj.plain); 

})

  markerObj.leaflet.bindPopup(el).openPopup();
  markerObj.leaflet.on('dragend', async (event) => {


    var obj = this.markersObjRefMap.get(markerObj.plain.name);
    if(obj) {
    obj.plain.latlng = event.target.getLatLng();
    var add = await this.datahubService.getAdressForCoordinates(event.target.getLatLng().lat, event.target.getLatLng().lng);
    //@ts-ignore
    obj.plain.address =  add.features[0].properties.label
    }


  });



 }

  async setLayer() {

 
    if (!this.selectedObject) {
      alert("Bitte Landkreis auswählen!")
      return;
    }

    if(this.layerControl) {
      this.layerControl.remove(this.map)
    } 
    var prevLatLng: any; 
    this.layerControl = L.control.layers().addTo(this.map);

   
    this.map.on('click', async (e: any) => {

      if(this.addMarkerMode && prevLatLng != e.latlng) {
        prevLatLng = e.latlng
        await this.addMarkerWithMapRef(e.latlng.lat, e.latlng.lng, undefined, undefined);}
        
      }) 
  
    var resMul = [];
    var res = await this.regioService.getShapefileByIdAndLevel(this.selectedObject.Object_Key, 4);
    this.storageService.setCurrentShapes(res[0]);
    console.warn("RES", res);
    var res2 = await this.regioService.getShapefileByIdAndLevel(this.selectedObject.Object_Key, 3);
    this.storageService.setCurrentShapes(res2[0]);
    resMul.push(res)
    resMul.push(res2)

    resMul.forEach((res => {

  
    //@ts-ignore
    res[0].forEach((element: any) => {
      var l = L.geoJSON(element.geometry,
        {
          style: {
            fillColor: 'none',


            color: 'black'
          },
          onEachFeature: (f, l) => {
            var out: string[] = [];

            out.push("Name : " + element.Object_Nam + "<br>"

            );

            l.bindPopup("<span class='span0'> " + element.Object_Nam + " </span> ");
          }
        });

        l?.on('click', async (e: any) => {
     
     
          if(this.addMarkerMode) {
          var icon = L.icon({
            iconUrl: 'assets/loc.svg',
            iconSize: [30, 45], 
          });
          var newMarker = L.marker(e.latlng, { draggable: true, icon: icon });
          newMarker.addTo(this.map);     
          
          var markerObj = await this.storageService.addMarker(newMarker, e.latlng, '');
          this.markersObjRef[markerObj.name] = newMarker
    
    
          const el = document.createElement('div');
          el.innerHTML = "<h3> "+ markerObj.name + "</h3> <b>" + markerObj.address +"</b>";
          var buttonDiv = document.createElement('div');
          buttonDiv.innerHTML = "<button id='test' style='padding: 10px; margin: 3%; height: 10%;  letter-spacing: 3px; border: 3px solid grey; border-radius: 15px;background-color: white;' > Löschen </button>"
          el.appendChild(buttonDiv); 
    
    
        buttonDiv.addEventListener("click", (e) => { 
          this.storageService.deleteMarker(markerObj); 
          this.map.removeLayer(newMarker); 
    
        })
    
          newMarker.bindPopup(el).openPopup();
          newMarker.on('dragend', async (event) => {
      
            var updatedMarker = await this.storageService.updateMarker(markerObj.name, event.target.getLatLng())
            newMarker.bindPopup("<h3> "+ markerObj.name + "</h3> <b>" + updatedMarker.address +"</b>").openPopup();
          });
        }
    
        
    
          
    
    
        } ) 


        
      this.layers.push(l);
      this.layerControl.addOverlay(l, element.Object_Nam) 
      var bounds = l.getBounds()
      var center = bounds.getCenter()
      this.map.setView(center, 9);
     
    })
  }))
  setTimeout(() => { this.map.invalidateSize(true)}, 200);

  
  }

  clickOnMarker(marker: any) {
  
    this.map.flyTo([marker.leaflet.getLatLng().lat ,marker.leaflet.getLatLng().lng  ])
    marker.leaflet.openPopup(); 

  }

  clearUmkreisResults(){
    this.clearMap(false);
    this.layers = []
    if(this.startMarker){
    this.map?.removeLayer(this.startMarker)}

  }

  async showModal(mode : boolean) {
   
    this.clearMap(true)
    this.selectedStep = 1; 
    this.selectedMode = this.isRaster? 1: 0;



 
    this.addMarkerMode = false; 
    this.toggle = true;

    if (this.geoJsonLayer) {
      this.map.removeLayer(this.geoJsonLayer);
  }


  this.newAnalyseMode = true;

    //@ts-ignore
    document.getElementById("dialog").showModal();


  }

  deleteMarkerForLocation(markerObj: { leaflet: import("leaflet").Marker<any>; plain: { name: any; address: any; latlng: any[]; }; }) {

    console.warn("BEFORE",this.markersObjRefMap )

    if(this.markersObjRefMap.delete(markerObj.plain.name)) {
      this.map.removeLayer(markerObj.leaflet); 
    } else {
      console.warn("ERROR, marker object doesnt exist")
    }
  
    console.warn("After",this.markersObjRefMap )
  }


  showSpinner() {
    this.spinner2 = true;

    //@ts-ignore
    document.getElementById("dialog2").showModal();


  }

  closeSpinner() {
    this.spinner2 = false;
    //@ts-ignorest
    document.getElementById("dialog2").close();
  }

}

function pulsateMarker() {
  throw new Error('Function not implemented.');
}

