<div class="layout">
  <div class="header">
    <button mat-icon-button (click)="toggleDrawer()">
      <mat-icon>{{ drawer.opened ? "close" : "menu" }}</mat-icon>
    </button>
    <h3 id="DigiZwi" (click)="backLogin()">Digitaler Planungszwilling</h3>
    <div class="img-container"  style="position: relative;" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()">
      <div *ngIf="selectedObject && !selectedObject.Logo_URL && !routeCheck">
        <button mat-raised-button style="background-color: #d4e5c6; color: black" (click)="click('/start')"*ngIf="selectedObject">
          Landkreis festlegen
        </button>
      </div>

      <div *ngIf="selectedObject" >
        <img src="{{ selectedObject.Logo_URL }}" width="40px" />
      </div>

      <div *ngIf="selectedObject" class="landkreis" >
        {{ selectedObject.Object_Name }}

      </div>

      <button  style="position: absolute; top:0 ;right:0px" *ngIf="iconVisible" mat-mini-fab color="primary" aria-label="Example icon button with a filter list icon"  (click)="changeLandkreis()">
        <mat-icon>edit_location_alt</mat-icon>
      </button>

    </div>
  </div>

  <div style="display: flex;justify-content: center;align-items: center;gap: 10px;">
    <div style="display: flex;justify-content: center;align-items: center;flex-direction: column; margin-top: 0px;margin-right: 28px;padding-right: 20px;border-right: 1px solid #ccc;">
      <img  id="fixSA"src="https://upload.wikimedia.org/wikipedia/commons/5/53/Wappen_Sachsen-Anhalt.svg" width="40px"/>
      <h3 style="margin-top: 0px; font-weight:400 ; margin-block-start: 0em;margin-block-end: 0em;">{{ userName }}</h3>
    </div>
    <div style="display: flex;justify-content: center;align-items: center;flex-direction: column; margin-top: 0px;margin-right: 28px;padding-right: 20px;border-right: 1px solid #ccc;">
      <a style="display: flex; justify-content: center; align-items: center"  (click)="openFeedback()"  class="btn" >
        <img style="width: 35px" src="../../../assets/icon/Clippy.png"/>
       Feedback
      </a>
    </div>
    <div>
      <a style="display: flex; justify-content: center; align-items: center" (click)="logout()" class="btn" >
        <mat-icon>logout</mat-icon> logout
      </a>
      
    </div>
  </div>
</div>






<dialog id="feedback-dialog">

  <div style="width: 1200px; display: flex; align-items: center; justify-content: space-between; flex-direction: row;"> 

  <button  mat-fab extended style="background-color: #10684E; color: white; margin-right: 10px;" disabled>
    <mat-icon>   smart_toy </mat-icon> Feedback-Tool
  </button>
  <button style=" background-color: #10684E; color: white" mat-mini-fab
  aria-label="Example icon button with a home icon" (click)="closeDialog()">
  <mat-icon>close </mat-icon>
  </button>
</div>

 



   


<div style="width: 100%; display: flex; align-items: center; flex-direction: column;"  > 

  <h2> Sie möchten ein Feedback geben? Gerne! </h2>
  <mat-chip-listbox [(ngModel)] = "category">
    <mat-chip-option >Lob    </mat-chip-option>
    <mat-chip-option > Kritik</mat-chip-option>
      <mat-chip-option >  Frage/Unklarheit</mat-chip-option>
        <mat-chip-option >  Technischer Fehler</mat-chip-option>
          <mat-chip-option >  Wünsche/Vorschläge</mat-chip-option>


        </mat-chip-listbox>


  <p><mat-slide-toggle labelPosition="before"> Anonym bleiben </mat-slide-toggle></p>




  <div>
    <div class="loader-container" *ngIf="wait">

      <div class="bouncing-dots">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
      </div>
  </div>
  </div>

<textarea id="adress" name="adress" [(ngModel)]="textinput" (click)="textinput = ''" >

</textarea>

<button id="sendBtn" [disabled]=" textinput == '' || textinput == 'Hier Feedback eintippen.. '" mat-fab (click)="sendFeedback()">
  <mat-icon>   send </mat-icon> 
</button>
</div>




</dialog>