<div style="padding: 20px">
  <h1>Moodboard/Best Practices für Morizon</h1>

  <mat-tab-group>
    <mat-tab label="Styled Elements">
      <div style="padding: 20px">


        <mat-card class="section-card">
          <h2>Color Palette</h2>
          <div style="display: flex; align-items: flex-start; justify-content:center; flex-direction:column">

            <!-- First Color -->
            <div
              class="color-item"
              style="display: flex; align-items: center; margin-bottom: 10px; justify-content:center"
            >
              <div
                class="color-swatch"
                style="
                  width: 50px;
                  height: 50px;
                  background-color: #10684e;
                  border: 1px solid #ddd;
                  margin-right: 15px;
                "
              ></div>
              <span
                style="font-size: 14px; font-weight: bold; margin-right: 10px "
                >#10684E</span
              >
              <button
                mat-raised-button
                color="primary"
                (click)="copyToClipboard('#10684E')"
              >
                Copy
              </button>
            </div>

            <!-- Second Color -->
            <div class="color-item" style="display: flex; align-items: center; justify-content:center">
              <div
                class="color-swatch"
                style="
                  width: 50px;
                  height: 50px;
                  background-color: #3fb5ab;
                  border: 1px solid #ddd;
                  margin-right: 15px;
                "
              ></div>
              <span
                style="font-size: 14px; font-weight: bold; margin-right: 10px"
                >#3FB5AB</span
              >
              <button
                mat-raised-button
                color="primary"
                (click)="copyToClipboard('#3FB5AB')"
              >
                Copy
              </button>
            </div>

             <!-- Third Color -->
            <div class="color-item" style="display: flex; align-items: center; justify-content: center; margin-top: 15px;">
              <div
                class="color-swatch"
                style="
                  width: 50px;
                  height: 50px;
                  background-color: #f44336; /* Default Material Design warn color */
                  border: 1px solid #ddd;
                  margin-right: 15px;
                "
              ></div>
              <span style="font-size: 14px; font-weight: bold; margin-right: 10px">#F44336</span>
              <button mat-raised-button color="primary" (click)="copyToClipboard('#F44336')">
                Copy
              </button>
            </div>
          </div>
        </mat-card>






        <mat-card class="section-card">
          <h2>Überschriften</h2>
        </mat-card>






        <mat-card class="section-card">
          <h2>Fonts</h2>
        </mat-card>






        <mat-card class="section-card">
          <h2>Input Komponenten</h2>
          <div class="tab-container">
            <mat-tab-group>

              <!-- Icon Button Tab -->
              <mat-tab label="mat Input">
                <div class="example-tab">
                  <mat-form-field appearance="outline" style="width: 400px;">
                    <mat-label>Bezeichnung des Dashboards speichern</mat-label>
                    <input matInput placeholder="Geben Sie den Dashboard-Namen ein..." [(ngModel)]="exampleName" name="name">            
                </mat-form-field>
                </div>
              </mat-tab>

              <!-- HTML Tab -->
              <mat-tab label="HTML">
                <div class="code-snippet">
                  <h3>HTML Code</h3>
                  <pre><code>{{ htmlCodeInput }}</code></pre>
                  <button
                    mat-raised-button
                    color="primary"
                    (click)="copyToClipboard(htmlCodeInput)"
                  >
                    Copy HTML
                  </button>
                </div>
              </mat-tab>

              <!-- TS Tab -->
              <mat-tab label="TS">
                <div class="code-snippet">
                  <h3>TS Code</h3>
                  <pre><code>{{ TSCodeInput }}</code></pre>
                  <button
                    mat-raised-button
                    color="primary"
                    (click)="copyToClipboard(TSCodeInput)"
                  >
                    Copy TS
                  </button>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </mat-card>




        <mat-card class="section-card">
          <h2>Buttons</h2>
          <div class="tab-container">
            <mat-tab-group>

              <!-- Icon Button Tab -->
              <mat-tab label="Icon Button">
                <div class="example-tab">
                  <h2>Icon Button</h2>
                  <button
                    mat-mini-fab
                    style="background-color: #10684e; color: white"
                    (click)="exampleFun()"
                  >
                    <mat-icon>add</mat-icon>
                  </button>
                  <p>Icon button with a "Kurzform" style</p>
                </div>
              </mat-tab>

              <!-- HTML Tab -->
              <mat-tab label="HTML">
                <div class="code-snippet">
                  <h3>HTML Code</h3>
                  <pre><code>{{ htmlCodeKurz }}</code></pre>
                  <button
                    mat-raised-button
                    color="primary"
                    (click)="copyToClipboard(htmlCodeKurz)"
                  >
                    Copy HTML
                  </button>
                </div>
              </mat-tab>

              <!-- CSS Tab -->
              <mat-tab label="CSS">
                <div class="code-snippet">
                  <h3>CSS Code</h3>
                  <pre><code>{{ cssCodeKurz }}</code></pre>
                  <button
                    mat-raised-button
                    color="primary"
                    (click)="copyToClipboard(cssCodeKurz)"
                  >
                    Copy CSS
                  </button>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>

          <div class="tab-container">
            <mat-tab-group>
              <!-- Example Tab -->
              <mat-tab label="Example">
                <div class="example-tab">
                  <h2>Example Button</h2>
                  <button class="btn" (click)="exampleFun()">Button 1</button>
                  <p>Button with text</p>
                </div>
              </mat-tab>

              <!-- HTML Tab -->
              <mat-tab label="HTML">
                <div class="code-snippet">
                  <h3>HTML Code</h3>
                  <pre><code>{{ htmlCodeBtn }}</code></pre>
                  <button
                    mat-raised-button
                    color="primary"
                    (click)="copyToClipboard(htmlCodeBtn)"
                  >
                    Copy HTML
                  </button>
                </div>
              </mat-tab>

              <!-- CSS Tab -->
              <mat-tab label="CSS">
                <div class="code-snippet">
                  <h3>CSS Code</h3>
                  <pre><code>{{ cssCodeBtn }}</code></pre>
                  <button
                    mat-raised-button
                    color="primary"
                    (click)="copyToClipboard(cssCodeBtn)"
                  >
                    Copy CSS
                  </button>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </mat-card>





        <mat-card class="section-card">
          <h2>Dialoge</h2>
          Wir nutzen Mat-Dialog
        </mat-card>



        <mat-card class="section-card">
          <h2>Loading ...</h2>
          Wir nutzen Mat spinner or Mat progress bar
        </mat-card>


      </div>
    </mat-tab>

    <!-- Reusable Components Tab -->
    <mat-tab label="Reusable Components">
      <div style="padding: 20px"></div>

      <h4>Location Picker</h4>
      Diese Komponente erlaubt LatLng Koordinaten zu setzen. Die Auswahl erfolgt
      über Adresseingabe oder als CSV Files und Geoencoding. Es ist auch möglich
      die LatLng Werte von gesetzen Markern zu übergeben, damit die Adressse
      gespeichert wird. Parameter: [multi] - gibt an ob eine oder mehrere Marker
      gewählt werden dürfen [locationsIn] - gibt die bereits gesetzten Locations
      rein [locationsOut] - gibt die gesetzen Locations zurück

      <br />
      <button class="btn" (click)="openLocationDialog()">
        Location Picker
      </button>
    </mat-tab>

    <!-- Best Practice Tab -->
    <mat-tab label="Best Practice">
      <div style="padding: 20px; font-family: Arial, sans-serif;">
    
        <h4 style="margin-top: 20px;">Leaflet-Objekte und normale Objekte</h4>
        Wir hatten Schwierigkeiten Leaflet Objekte (=Objekte die auf der Karte erscheinen) und normale Objekte zu synchronisieren. Beispiel:

        Eine Schule besteht aus Name, Anzahl Schüler, LatLng Standort. Sobald wir die Schule auf Leaflet plotten mit ihren Eigenschaften, dann sind diese permanent und Änderung dessen schwierig.
        
        Lösungsvorschlag:
        
        Objekte die auf Leaflet gepackt werden sollen, speichern wir immer in einem Array, das wie folgt aussieht:
        <br>
        <br>
        `[{{ '{' }} leaflet: //hier: leafletObject, raw: pureObjekt {{ '}' }}]`
        <br>
        <br>
        
        Was sind die Vorteile davon?
        
        Angeommen wir haben die Schule ABC erzeugt, dann haben wir ein komisches LeafletObjekt was man sowieso nicht versteht und das raw-Objekt mit Name ABC.
        Wenn wir jetzt den Namen von ABC zu XYZ ändern wollen, dann können wie folgt durchgehen:  <br>
        
        <br>
        
        1. Suche im Array, where .raw.Schulname == ABC <br>
        2. Entferne das gefundene Objekt aber .leaflet von der Karte <br>
        3. Ändere .raw.Schulname auf XYZ <br>
        4. Erzeuge einen neuen Marker und speichere ihn unter .leaflet <br>
        
        <br><br>
        **Der Vorteil ist: das pure Objekt und leaflet Objekt sind immer in SYNC**


        <h4 style="margin-top: 20px;">Einheiten</h4>
        <p>
          Für Konsistenz sollten für <code>padding</code> und <code>margin</code> nur 
          <code>px</code>-Werte verwendet werden. Alle anderen Längeneinheiten sollten 
          mit <code>vh</code> und <code>vw</code> umgesetzt werden, um ein responsives 
          Design zu gewährleisten.
        </p>
    
        <h4 style="margin-top: 20px;">Lifecycle Hooks verwenden</h4>
        <p>
          Wenn Angular-Lifecycle-Hooks verwendet werden (z. B. <code>ngOnInit</code>), 
          muss sichergestellt sein, dass die Klassendeklaration das entsprechende Interface 
          implementiert.
        </p>
        <pre class="code-snippet" style=" padding: 10px; border-radius: 5px;">
    <code>
      {{bestPracticeTS}}
    </code>
        </pre>
    
        <h4 style="margin-top: 20px;">Service generieren</h4>
        <p>
          Um einen Service zu generieren, verwenden Sie folgenden Angular-CLI-Befehl:
        </p>
        <pre class="code-snippet" style=" padding: 10px; border-radius: 5px;">
          <code>{{serviceCode}}</code>
        </pre>
    
        <h4 style="margin-top: 20px;">Vermeiden Sie die Verwendung von <code>any</code></h4>
        <p>
          Statt <code>any</code> sollten passende TypeScript-Interfaces definiert und 
          verwendet werden. Dies verbessert die Lesbarkeit, Sicherheit und Wartbarkeit 
          des Codes.
        </p>
    
        <h4 style="margin-top: 20px;">Vermeiden Sie die Verwendung von <code>var</code></h4>
        <p>
          Verwenden Sie <code>const</code> für Variablen, die sich nicht ändern, und 
          <code>let</code> für Variablen, die neu zugewiesen werden können. Vermeiden 
          Sie die Verwendung von <code>var</code>, da es aufgrund der Funktions-Scope-Problematik 
          zu unerwartetem Verhalten führen kann.
        </p>
    
        <h4 style="margin-top: 20px;">Projektabhängigkeiten</h4>
        <p>
          Verwenden Sie <strong>Bootstrap</strong> in diesem Projekt nicht, um Konflikte 
          mit bestehenden Stilen und Frameworks zu vermeiden.
        </p>
    
        <h4 style="margin-top: 20px;">Dateistruktur und Reihenfolge</h4>
        <p>
          In TypeScript-Dateien sollte eine konsistente Reihenfolge eingehalten werden:
        </p>
        <ul>
          <li>Variablen zuerst deklarieren.</li>
          <li>Danach den Konstruktor platzieren.</li>
          <li>Funktionen am Ende definieren.</li>
        </ul>
    
        <h4 style="margin-top: 20px;">Datenbank-Imports</h4>
        <p>
          Importieren Sie Daten immer direkt aus der Datenbank (z. B. MongoDB) und nicht 
          lokal, um sicherzustellen, dass die Daten aktuell und korrekt sind.
        </p>
    
        <h4 style="margin-top: 20px;">Funktionen kürzer halten</h4>
        <p>
          Halten Sie Funktionen so kurz wie möglich. Streben Sie eine maximale Länge 
          von 20 Codezeilen pro Funktion an, um die Lesbarkeit und Wartbarkeit zu erhöhen.
        </p>
      </div>
    </mat-tab>
    
  </mat-tab-group>
</div>
