
<app-loading-overlay *ngIf="isLoading"></app-loading-overlay>
<div class="main" *ngIf="step==2 || step==3">

    <div>
        <h1>Ursprünglicher Plan</h1>

        <div class="Fahrplan">
            <h2>Fahrplan 53min</h2>
            <div class="transit-line">
            <!-- <h3>Bus 794 - Weißenfels, Busbahnhof</h3> -->
            <h4>Obernessa - Weißenfels, Busbahnhof</h4>
            <p><strong>Fahrtdauer:</strong> 22 min (6 Haltestellen)</p>
            
            <ul class="stop-list" style="color: orange;">
                <li><strong>5:35 AM</strong> - Obernessa</li>
                <li><strong>5:37 AM</strong> - Nessa, Ort</li>
                <li><strong>5:38 AM</strong> - Dippelsdorf</li>
                <li><strong>5:41 AM</strong> - Wernsdorf (Nessa) Ort - Teuchern</li>
                <li><strong>5:46 AM</strong> - Weißenfels, Kaserne</li>
                <li><strong>5:47 AM</strong> - Weißenfels, Zeitzer Str.</li>
                <li><strong>5:57 AM</strong> - Weißenfels, Busbahnhof</li>
            </ul>
            </div>
        
            <div class="transit-line">
            <h4>Weißenfels, Busbahnhof - Prittitz, Ort</h4>
            <p><strong>Fahrtdauer:</strong> 14 min (6 Haltestellen)</p>
        
            <ul class="stop-list" style="color: orangered;">
                <li><strong>6:00 AM</strong> - Weißenfels, Busbahnhof</li>
                <li><strong>6:03 AM</strong> - Weißenfels, Goethe-Gymnasium</li>
                <li><strong>6:04 AM</strong> - Weißenfels, Naumburger Str.</li>
                <li><strong>6:05 AM</strong> - Weißenfels, Krankenhaus</li>
                <li><strong>6:09 AM</strong> - Leißling, EKZ</li>
                <li><strong>6:13 AM</strong> - Prittitz, Abzweig Bahnhof</li>
                <li><strong>6:14 AM</strong> - Prittitz, Ort</li>
            </ul>
            </div>
        
            <div class="transit-line">
            <h4>Prittitz, Ort - Plotha, Schule (Prittitz)</h4>
            <p><strong  style="color: #cc0000;">Fußweg:</strong> Ungefähr 14 min, 1100 m</p>
            <p><strong>6:28 AM</strong> - Plotha, Schule (Prittitz)</p>
            </div>
        </div>
    </div>

    <div>
        <h1>Aktualisierter Plan</h1>

        <div class="Fahrplan">
            <h2>Fahrplan 40min</h2>
            <div class="transit-line">
        
            <h4>Obernessa - Prittitz, Ort</h4>
            <p><strong>Fahrtdauer:</strong> 40 min (14 Haltestellen)</p>
            
            <ul class="stop-list" style="color: orange;">
                <li><strong>6:50 AM</strong> - Obernessa</li>
                <li><strong>6:52 AM</strong> - Nessa, Ort</li>
                <li><strong>6:53 AM</strong> - Dippelsdorf</li>
                <li><strong>6:56 AM</strong> - Wernsdorf (Nessa) Ort - Teuchern</li>
                <li><strong>7:01 AM</strong> - Weißenfels, Kaserne</li>
                <li><strong>7:02 AM</strong> - Weißenfels, Zeitzer Str.</li>
                <li><strong>7:12 AM</strong> - Weißenfels, Busbahnhof</li>
                <li><strong>7:15 AM</strong> - Weißenfels, Goethe-Gymnasium</li>
                <li><strong>7:16 AM</strong> - Weißenfels, Naumburger Str.</li>
                <li><strong>7:17 AM</strong> - Weißenfels, Krankenhaus</li>
                <li><strong>7:21 AM</strong> - Leißling, EKZ</li>
                <li><strong>7:25 AM</strong> - Prittitz, Abzweig Bahnhof</li>
                <li><strong>7:27 AM</strong> - Plennschütz</li>
                <li><strong>7:28 AM</strong> - Plotha, Ort (Prittitz)</li>
                <li><strong>7:30 AM</strong> - Plotha, Schule (Prittitz)</li>
            </ul>
            </div>
        
        </div>
    </div>


    <div *ngIf="step!=3" style=" width:400px">
      <mat-form-field appearance="fill">
        <mat-label>Benutzer auswählen</mat-label>
        <mat-select [(ngModel)]="selectedUserId">
          <mat-option *ngFor="let user of userList" [value]="user.id">
            {{ user.username }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      
      <button *ngIf="selectedUserId" mat-raised-button color="warn" (click)="pvgAntwort()">Anfrage an PVG schicken</button>
    </div>

    <div *ngIf="step==3" style=" width:400px">
      <div class="aporedv-style">
        <span class="icon material-icons">done</span>
        <h3>Der aktualisierte Plan ist plausibel</h3>
      </div>

      <p>Das Anlegen einer neuen Busroute ist vsl. mit einem finanziellen Mehraufwand ca. <span style="color: orangered;">100.000€</span> im Jahr verbunden.</p>

      <p> Kostenaufteilung:</p>

        <ul>
        <li>Zusätzliches Fahrzeug  mit Abschreibung, Finanzierung, Reparatur und Betriebskosten: <span style="color: orangered;">50.000€</span> </li>
        <li>Personalkosten für den Betrieb dieses zusätzlichen Fahrzeugs: ca. <span style="color: orangered;">50.000€</span></li>
        </ul>
    </div>


</div>


<div class="main" *ngIf="step==1">
    <mat-horizontal-stepper orientation="vertical" [linear]="true" #stepper>
      
    
        <mat-step label="Plan umwandeln">
            <div>
                <h3>Möchten Sie diesen Plan in einen Plan ohne Umstieg umwandeln?</h3>
                <button mat-raised-button color="primary" (click)="confirmConversion(stepper)">Ja</button>
                <button mat-button color="warn" (click)="stepper.next()">Nein</button>
            </div>
        </mat-step>
    
      
        <mat-step label="Fußweg und Haltestops entfernen?">
            <div>
                <h3>Sollen die letzten Haltestellen und der Fußweg entfernt werden?</h3>
                <mat-form-field appearance="fill">
                    <mat-label>Anzahl zu entfernender Haltestellen</mat-label>
                    <input matInput type="number" [(ngModel)]="stopsToRemove" min="1">
                </mat-form-field>
                <button mat-button color="primary" (click)="removeStopsAndFootpath(stepper)">Bestätigen</button>
            </div>
        </mat-step>
        
        
        <mat-step label="Neue Haltestellen hinzufügen">
            <div>
                <h3>Neue Haltestelle auswählen</h3>

                <!-- <mat-form-field appearance="fill">
                    <mat-label>Available Stops</mat-label>
                    <mat-select [(ngModel)]="selectedStop" (selectionChange)="updateStopList()">
                        <mat-option *ngFor="let stop of availableStops" [value]="stop">{{ stop }}</mat-option>
                    </mat-select>
                </mat-form-field> -->
                <div *ngIf="suggestedStops.length > 0">
                    <h4>Vorgeschlagene Haltestellen:</h4>
                    <ul>
                        <li *ngFor="let stop of suggestedStops">{{ stop }}</li>
                    </ul>
                </div>
                <div id="map4" style="height: 300px;"></div>
                <button mat-button color="primary" (click)="test(stepper)">Weiter</button>
            </div>
        </mat-step>
    
    
        <mat-step label="Ankunftszeit festlegen">
            <div>
                <h3>Ankunftszeit einstellen</h3>
                <mat-form-field appearance="fill">
                    <mat-label>Ankunftszeit</mat-label>
                    <input matInput [(ngModel)]="departureTime" type="time">
                </mat-form-field>
                <button mat-button color="primary" (click)="finalizePlan(stepper)">Änderungen speichern</button>
                <button mat-button color="warn" (click)="comp()">Vergleichen mit Anfangsplan</button>
            </div>
        </mat-step>

    
    </mat-horizontal-stepper>
  
    <div class="Fahrplan" *ngIf = "plan==1">
        <h2>Fahrplan 53min</h2>
        <div class="transit-line">
          <!-- <h3>Bus 794 - Weißenfels, Busbahnhof</h3> -->
          <h4>Obernessa - Weißenfels, Busbahnhof</h4>
          <p><strong>Fahrtdauer:</strong> 22 min (6 Haltestellen)</p>
          
          <ul class="stop-list" style="color: orange;">
            <li><strong>5:35 AM</strong> - Obernessa</li>
            <li><strong>5:37 AM</strong> - Nessa, Ort</li>
            <li><strong>5:38 AM</strong> - Dippelsdorf</li>
            <li><strong>5:41 AM</strong> - Wernsdorf (Nessa) Ort - Teuchern</li>
            <li><strong>5:46 AM</strong> - Weißenfels, Kaserne</li>
            <li><strong>5:47 AM</strong> - Weißenfels, Zeitzer Str.</li>
            <li><strong>5:57 AM</strong> - Weißenfels, Busbahnhof</li>
          </ul>
        </div>
      
        <div class="transit-line">
          <h4>Weißenfels, Busbahnhof - Prittitz, Ort</h4>
          <p><strong>Fahrtdauer:</strong> 14 min (6 Haltestellen)</p>
      
          <ul class="stop-list" style="color: orangered;">
            <li><strong>6:00 AM</strong> - Weißenfels, Busbahnhof</li>
            <li><strong>6:03 AM</strong> - Weißenfels, Goethe-Gymnasium</li>
            <li><strong>6:04 AM</strong> - Weißenfels, Naumburger Str.</li>
            <li><strong>6:05 AM</strong> - Weißenfels, Krankenhaus</li>
            <li><strong>6:09 AM</strong> - Leißling, EKZ</li>
            <li><strong>6:13 AM</strong> - Prittitz, Abzweig Bahnhof</li>
            <li><strong>6:14 AM</strong> - Prittitz, Ort</li>
          </ul>
        </div>
      
        <div class="transit-line">
          <h4>Prittitz, Ort - Plotha, Schule (Prittitz)</h4>
          <p><strong  style="color: #cc0000;">Fußweg:</strong> Ungefähr 14 min, 1100 m</p>
          <p><strong>6:28 AM</strong> - Plotha, Schule (Prittitz)</p>
        </div>
      </div>
  
      <div class="Fahrplan" *ngIf="plan==2">
        <h2>Fahrplan 50min</h2>
        <div class="transit-line">
    
          <h4>Obernessa - Prittitz, Ort</h4>
          <p><strong>Fahrtdauer:</strong> 36 min (12 Haltestellen)</p>
          
          <ul class="stop-list" style="color: orange;">
            <li><strong>5:35 AM</strong> - Obernessa</li>
            <li><strong>5:37 AM</strong> - Nessa, Ort</li>
            <li><strong>5:38 AM</strong> - Dippelsdorf</li>
            <li><strong>5:41 AM</strong> - Wernsdorf (Nessa) Ort - Teuchern</li>
            <li><strong>5:46 AM</strong> - Weißenfels, Kaserne</li>
            <li><strong>5:47 AM</strong> - Weißenfels, Zeitzer Str.</li>
            <li><strong>5:57 AM</strong> - Weißenfels, Busbahnhof</li>
            <li><strong>6:00 AM</strong> - Weißenfels, Goethe-Gymnasium</li>
            <li><strong>6:01 AM</strong> - Weißenfels, Naumburger Str.</li>
            <li><strong>6:02 AM</strong> - Weißenfels, Krankenhaus</li>
            <li><strong>6:06 AM</strong> - Leißling, EKZ</li>
            <li><strong>6:10 AM</strong> - Prittitz, Abzweig Bahnhof</li>
            <li><strong>6:11 AM</strong> - Prittitz, Ort</li>
          </ul>
        </div>
      
        <div class="transit-line">
          <h4>Prittitz, Ort - Plotha, Schule (Prittitz)</h4>
          <p><strong  style="color: #cc0000;">Fußweg:</strong> Ungefähr 14 min, 1100 m</p>
          <p><strong>6:28 AM</strong> - Plotha, Schule (Prittitz)</p>
        </div>
    </div>

    <div class="Fahrplan" *ngIf="plan==3">
        <h2>Fahrplan 35min</h2>
        <div class="transit-line">
    
          <h4>Obernessa - Prittitz, Ort</h4>
          <p><strong>Fahrtdauer:</strong> 35 min (12 Haltestellen)</p>
          
          <ul class="stop-list" style="color: orange;">
            <li><strong>5:35 AM</strong> - Obernessa</li>
            <li><strong>5:37 AM</strong> - Nessa, Ort</li>
            <li><strong>5:38 AM</strong> - Dippelsdorf</li>
            <li><strong>5:41 AM</strong> - Wernsdorf (Nessa) Ort - Teuchern</li>
            <li><strong>5:46 AM</strong> - Weißenfels, Kaserne</li>
            <li><strong>5:47 AM</strong> - Weißenfels, Zeitzer Str.</li>
            <li><strong>5:57 AM</strong> - Weißenfels, Busbahnhof</li>
            <li><strong>6:00 AM</strong> - Weißenfels, Goethe-Gymnasium</li>
            <li><strong>6:01 AM</strong> - Weißenfels, Naumburger Str.</li>
            <li><strong>6:02 AM</strong> - Weißenfels, Krankenhaus</li>
            <li><strong>6:06 AM</strong> - Leißling, EKZ</li>
            <li><strong>6:10 AM</strong> - Prittitz, Abzweig Bahnhof</li>
          </ul>
        </div>
      
    </div>

    <div class="Fahrplan" *ngIf="plan==4">
        <h2>Fahrplan 40min</h2>
        <div class="transit-line">
    
          <h4>Obernessa - Prittitz, Ort</h4>
          <p><strong>Fahrtdauer:</strong> 40 min (14 Haltestellen)</p>
          
          <ul class="stop-list" style="color: orange;">
           
            <li><strong>5:35 AM</strong> - Obernessa</li>
            <li><strong>5:37 AM</strong> - Nessa, Ort</li>
            <li><strong>5:38 AM</strong> - Dippelsdorf</li>
            <li><strong>5:41 AM</strong> - Wernsdorf (Nessa) Ort - Teuchern</li>
            <li><strong>5:46 AM</strong> - Weißenfels, Kaserne</li>
            <li><strong>5:47 AM</strong> - Weißenfels, Zeitzer Str.</li>
            <li><strong>5:57 AM</strong> - Weißenfels, Busbahnhof</li>
            <li><strong>6:00 AM</strong> - Weißenfels, Goethe-Gymnasium</li>
            <li><strong>6:01 AM</strong> - Weißenfels, Naumburger Str.</li>
            <li><strong>6:02 AM</strong> - Weißenfels, Krankenhaus</li>
            <li><strong>6:06 AM</strong> - Leißling, EKZ</li>
            <li><strong>6:10 AM</strong> - Prittitz, Abzweig Bahnhof</li>
            <li><strong>6:12 AM</strong> - Plennschütz</li>
            <li><strong>6:13 AM</strong> - Plotha, Ort (Prittitz)</li>
            <li><strong>6:15 AM</strong> - Plotha, Schule (Prittitz)</li>
          </ul>
        </div>
      
    </div>

    <div class="Fahrplan" *ngIf="plan==5">
        <h2>Fahrplan 40min</h2>
        <div class="transit-line">
    
          <h4>Obernessa - Prittitz, Ort</h4>
          <p><strong>Fahrtdauer:</strong> 40 min (14 Haltestellen)</p>
          
          <ul class="stop-list" style="color: orange;">
            <li><strong>6:50 AM</strong> - Obernessa</li>
            <li><strong>6:52 AM</strong> - Nessa, Ort</li>
            <li><strong>6:53 AM</strong> - Dippelsdorf</li>
            <li><strong>6:56 AM</strong> - Wernsdorf (Nessa) Ort - Teuchern</li>
            <li><strong>7:01 AM</strong> - Weißenfels, Kaserne</li>
            <li><strong>7:02 AM</strong> - Weißenfels, Zeitzer Str.</li>
            <li><strong>7:12 AM</strong> - Weißenfels, Busbahnhof</li>
            <li><strong>7:15 AM</strong> - Weißenfels, Goethe-Gymnasium</li>
            <li><strong>7:16 AM</strong> - Weißenfels, Naumburger Str.</li>
            <li><strong>7:17 AM</strong> - Weißenfels, Krankenhaus</li>
            <li><strong>7:21 AM</strong> - Leißling, EKZ</li>
            <li><strong>7:25 AM</strong> - Prittitz, Abzweig Bahnhof</li>
            <li><strong>7:27 AM</strong> - Plennschütz</li>
            <li><strong>7:28 AM</strong> - Plotha, Ort (Prittitz)</li>
            <li><strong>7:30 AM</strong> - Plotha, Schule (Prittitz)</li>
          </ul>
        </div>
      
    </div>


</div>


  
