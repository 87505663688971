import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SynthService } from 'src/app/service/synthService/synth.service';

@Component({
  selector: 'app-popup-dialog',
  templateUrl: './popup-dialog.component.html',
  styleUrls: ['./popup-dialog.component.css']
})
export class PopupDialogComponent {
  populations: string[] = [];
  years: number[] = [];
  selectedPopulation: string = '';
  selectedYearRange1: number = 0;
  selectedYearRange2: number = 0;
  popSpinner: boolean = false;  
  isConfirmed: boolean = false; 
  
  constructor(
    public dialogRef: MatDialogRef<PopupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private synthService: SynthService
  ) {}

  async ngOnInit() {
    const res: any = await this.synthService.getPopuationNames();
    this.populations = res.popNames;

    const initialData: any = await this.synthService.getInitialChartData(this.data.selectedIndicator, this.populations);
    console.log(initialData);
    
    this.years = initialData.labels.map((year: string) => parseInt(year));
    this.selectedYearRange1 = this.years[0];
    this.selectedYearRange2 = this.years[this.years.length - 1];
  }

  confirmAddition() {
    this.popSpinner = true; 

    setTimeout(() => {
      console.log(`Selected Population: ${this.selectedPopulation}, Year Range: ${this.selectedYearRange1} - ${this.selectedYearRange2}`);
      this.popSpinner = false;
      this.isConfirmed = true;
    }, 1750); 

    setTimeout(() => {
      this.dialogRef.close();
    },3000);
  }
}
