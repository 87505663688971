import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-breaks',
  templateUrl: './dialog-breaks.component.html',
  styleUrls: ['./dialog-breaks.component.css']
})
export class DialogBreaksComponent {
  setBreaks:string='';
  constructor(
    public dialogRef: MatDialogRef<DialogBreaksComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {chosenBreaks:number,valuesArray:number[],valuesStored:number[],valuesBreaks:any[],breaksAmount:number,selectedChartObject:any},
  ) {
    this.data.chosenBreaks=5;
  }

  fillArray() {
    this.data.valuesStored.sort((a, b) => a - b);
 
    this.data.valuesArray = [];
    this.data.valuesArray = [this.data.valuesStored[0],this.data.valuesStored[this.data.valuesStored.length - 1],];
    this.data.valuesBreaks = [];

    this.data.valuesBreaks.push({ from: '', to: '' });
    this.data.valuesBreaks[0].from = this.data.valuesStored[0];

    this.data.valuesBreaks.push({ from: '', to: '' });
    this.data.valuesBreaks[this.data.valuesArray.length - 1].to =
    this.data.valuesStored[this.data.valuesStored.length - 1];
  }

  add(index: number, value: any): void {
    if (this.data.breaksAmount < 8) {
      const savedValue = this.data.valuesBreaks[index].to;
      if (
        index !== 0 &&
        (value < this.data.valuesStored[0] ||
          value > this.data.valuesStored[this.data.valuesStored.length - 1] ||
          this.data.valuesBreaks[index].to < this.data.valuesStored[0] ||
          this.data.valuesBreaks[index].to >
            this.data.valuesStored[this.data.valuesStored.length - 1] ||
          this.data.valuesBreaks[index - 1].to < this.data.valuesStored[0] ||
          this.data.valuesBreaks[index - 1].to >
            this.data.valuesStored[this.data.valuesStored.length - 1])
      ) {
        alert('Invalid value. Value must be within the range.');
        value = '';
      } else if (
        index == 0 &&
        (this.data.valuesBreaks[index].to < this.data.valuesStored[0] ||
          this.data.valuesBreaks[index].to >
            this.data.valuesStored[this.data.valuesStored.length - 1])
      ) {
        alert('Invalid value. Value must be within the range.');
      } else if (
        index !== 0 &&
        (this.data.valuesBreaks[index].to < value ||
          value < this.data.valuesBreaks[index - 1].from)
      ) {
        alert('Invalid value. Value must be greater than the previous value.');
      } else {

        this.data.valuesArray.splice(index + 1, 0, value);
        this.data.valuesBreaks.splice(index + 1, 0, { from: '', to: '' });



        if (index !== this.data.valuesArray.length - 2) {
          this.data.valuesBreaks[index].to = '';
          this.data.valuesBreaks[index + 1].from = '';
          this.data.valuesBreaks[index + 1].to = savedValue;
        }

        if (index === this.data.valuesArray.length - 2) {
          this.data.valuesBreaks[index].to = '';
          this.data.valuesBreaks[index + 1].from = '';
          this.data.valuesBreaks[this.data.valuesArray.length - 1].to =
            this.data.valuesStored[this.data.valuesStored.length - 1];
        }

        this.data.breaksAmount++;
      }
    }
  }

  remove(i: number, value: number): void {
    this.data.breaksAmount--;
    this.data.valuesBreaks[i].from = '';
    this.data.valuesBreaks[i - 1].to = value;
    this.data.valuesArray.splice(i, 1);
    this.data.valuesBreaks.splice(i, 1);
  }


  checkRange(index: number, value: any){
    if (
      index !== 0 &&
      (value < this.data.valuesStored[0] ||
        value > this.data.valuesStored[this.data.valuesStored.length - 1] ||
        this.data.valuesBreaks[index].to < this.data.valuesStored[0] ||
        this.data.valuesBreaks[index].to >
          this.data.valuesStored[this.data.valuesStored.length - 1] ||
        this.data.valuesBreaks[index - 1].to < this.data.valuesStored[0] ||
        this.data.valuesBreaks[index - 1].to >
          this.data.valuesStored[this.data.valuesStored.length - 1])
    ) {
      alert('Invalid value. Value must be within the range.');
      value = '';
    } else if (
      index == 0 &&
      (this.data.valuesBreaks[index].to < this.data.valuesStored[0] ||
        this.data.valuesBreaks[index].to >
          this.data.valuesStored[this.data.valuesStored.length - 1])
    ) {
      alert('Invalid value. Value must be within the range.');
    } else if (
      index !== 0 &&
      (this.data.valuesBreaks[index].to < value ||
        value < this.data.valuesBreaks[index - 1].from)
    ) {
      alert('Invalid value. Value must be greater than the previous value.');
    }
  }

  fillNext(index: any, value: any) {
    this.data.valuesBreaks[index + 1].from = this.data.valuesBreaks[index].to;

  }

  legendeBenutzerDef(){
    let combinedValues: any = [];
    this.data.valuesBreaks.forEach((obj) => {
      combinedValues.push(parseFloat(obj.from));
      combinedValues.push(parseFloat(obj.to));
    });
    let uniqueValues = Array.from(new Set(combinedValues));
    uniqueValues.sort((a: any, b: any) => a - b);
    return uniqueValues;
  }

  manageSetBreaks(chosenCase:number){
    if(chosenCase==0)this.setBreaks='Gleichmäßig';
    else if(chosenCase==1){
      this.setBreaks='Benutzerdefiniert';
      let customisedBreaks=this.legendeBenutzerDef().length-1;
      this.data.breaksAmount=customisedBreaks;
    }
    else if(chosenCase==2)this.setBreaks='jenks'
    else if(chosenCase==3)this.setBreaks='quantile';
    let result={
      setBreaks:this.setBreaks,
      breaksAmount:this.data.breaksAmount,
      valuesArray:this.data.valuesArray,
      valuesStored:this.data.valuesStored,
      valuesBreaks:this.data.valuesBreaks
    }
    return result
  }

  setMax(){
    return Math.min(this.data.valuesStored.length - 1, 8);
  }

  closeDialog(){
    this.dialogRef.close(this.manageSetBreaks(this.data.chosenBreaks));
  }

  closeAll(){
    this.dialogRef.close();
  }
}
