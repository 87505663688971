<div  *ngIf="regioMetaData.length != 0" style="display: flex; justify-content: center; align-items: center;gap:50px">
    
    <div style="height: 500px; border:3px solid #ccc; overflow-y: scroll; width: 800px; font-weight: bold;"> 
      <ng-container *ngFor="let topic of filteredTopics">
        <div class="searchedThema" style="padding: 20px; border-bottom: 1px solid #ccc;">
          
          <div   class="green-hover" (click)="toggleIndicators(topic)" style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
            <mat-checkbox  [(ngModel)]="topic.checked"  [checked]="topic.checked"
            [value]="topic.Bereich" 
            
              color="#10684E" disabled >
              <h3 style="color: black;"> {{ topic.Bereich }} </h3>
             </mat-checkbox>
             <!-- <mat-icon aria-hidden="false" (click)="toggleIndicators(topic)" style="background-color: #10684E; color:white ; border-radius: 50%;" >arrow_drop_down</mat-icon> -->
          </div>
         

          <div *ngIf="topic.showIndicators">
            <div style="display: flex;
              justify-content: flex-start;
              padding-bottom: 20px;
              flex-wrap: wrap;
              gap: 10px;
              padding-top: 20px;
              
              flex-direction: column;
              font-weight: 400;">
              <span style="font-weight: 700;">Indikatoren:</span>
              <ng-container *ngFor="let indi of topic.data">
                <mat-checkbox *ngIf="indi.visible" class="searchedIndi" style="font-weight: 400;"  [(ngModel)]="indi.checked" 
                [checked]="indi.checked" color="#10684E"
                (change)="checkIndicators(topic); onIndicatorChange(indi, $event.checked)" 
                [disabled]="disableUncheckedCheckboxes && !selectedIndicators.includes(indi)">
                  {{ indi.Name }}
                </mat-checkbox>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>



    <div style="display: flex; justify-content: center;align-items: center; flex-direction: column; position: relative;">

      <mat-form-field style="width: 250px;">
        <mat-label>Filter nach Thema</mat-label>
        <input matInput [(ngModel)]="topicFilter" (keyup)="filterTopics()" (focus)="showDropdown = true" (blur)="doNotShowDropdown()">  
      </mat-form-field>

      <div class="autocomplete-dropdown" *ngIf="showDropdown && filteredTopics.length > 0">
        <div class="dropdown-item" *ngFor="let searchedTopic of filteredTopics" (click)="topicFilter= searchedTopic.Bereich;showDropdown = false;filterTopics()">
          {{ searchedTopic.Bereich }}
        </div>
      </div>

      <mat-form-field style="width: 250px;">
        <mat-label>Filter nach Indikator</mat-label>
        <input matInput  [(ngModel)]="indiFilter"  (keyup)="filterIndi()" (focus)="showDropdown2 = true" (blur)="doNotShowDropdown2()">
      </mat-form-field>

      <div class="autocomplete-dropdown2" *ngIf="showDropdown2 && filteredIndi.length > 0">
            <div *ngFor="let searchedTopic of filteredTopics">
              <div *ngFor="let searchedIndi of searchedTopic.data">
                  <div *ngIf="searchedIndi.visible" class="dropdown-item" (click)="indiFilter= searchedIndi.Name ;showDropdown2 = false;filterIndi()">
                    {{ searchedIndi.Name }}
                  </div>
              </div>
            </div>
      </div>


      <ng-container *ngIf="selectedIndicators">
        <h3 class="indicator-title">Indikatoren:</h3>
        <div class="indicator-list">
            <p *ngFor="let indi of selectedIndicators; let i = index" class="indicator-item">
              <mat-icon color="#00695C;"> done</mat-icon> {{ indi.Name }}
            </p>
        </div>
      </ng-container>
      
      


    </div>
</div>