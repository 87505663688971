import { Component } from '@angular/core';

@Component({
  selector: 'app-krankheiten',
  templateUrl: './krankheiten.component.html',
  styleUrls: ['./krankheiten.component.css']
})
export class KrankheitenComponent {

}
