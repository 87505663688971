import { latLng} from 'leaflet';
import { Component, OnInit} from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-draw';
import 'leaflet-editable';
import { RegioService } from 'src/app/service/regioservice/regioservice.component';
import { Storageservice } from 'src/app/service/storageservice-component/storageservice-component.component';
import { MatDialog } from '@angular/material/dialog';
import { TransitDialogComponent } from './transit-dialog/transit-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import 'leaflet-draw';
import { Chart, registerables } from 'chart.js';
import { FahrplanDialogComponent } from './fahrplan-dialog/fahrplan-dialog.component';
import { CompMobilityDialogComponent } from 'src/app/components/schul-entiwicklung-planung/comp-mobility-dialog/comp-mobility-dialog.component';
import { UmbauMobilityDialogComponent } from './umbau-mobility-dialog/umbau-mobility-dialog.component';

Chart.register(...registerables);

@Component({
  selector: 'app-schul-entiwicklung-planung',
  templateUrl: './schul-entiwicklung-planung.component.html',
  styleUrls: ['./schul-entiwicklung-planung.component.css']
})
export class SchulEntiwicklungPlanungComponent  implements OnInit{
  map:any;
  mode:string="";
  selectedMarkers: any[] = []; 
  geoJsonLayer: any; 
  markers = L.markerClusterGroup();
  markersNetwork = L.markerClusterGroup();
  needDialog=false;
  needDialogAnpassen=false
  duration:any
  distance:any
  formattedDuration:any
  routeTime: string = ''; 
  selectedLayers: any = [];
  schedule: any = [];
  scheduleUmbau:any=[];
  initialTime: Date = new Date('2024-10-28T08:00:00');
  Uhrzeit = 'delete';
  chart: any;
  orifinalRoute:any;
  isNotclicked=true;
  mobilityRaster:any;
  variante1=false;
  variante2=false;

  constructor(
    public storageService: Storageservice,
    public dialog: MatDialog,
    private regioService: RegioService,
    private snackBar: MatSnackBar
  ) {}

  isLoading=false;

  startLoading(){
    this.isLoading=true;
    setTimeout(() => {
      this.isLoading=false;
      this.calculStops();
      this.schowAntwort();
      this.variante2=true;
    }, 2000);
  }

  antwort=false;
  schowAntwort(){
    this.antwort=true;
  }

  async onModeChange(selectedMode: string): Promise<void> {
  if (selectedMode === 'FahrplanAnsehen') {
    console.log('d');
    this.openDialogPlan()
    
    this.routeTime='';
    this.selectedMarkers= []; 
    if(this.chart)this.chart.destroy();
    this.Uhrzeit = 'delete';
    this.markers.clearLayers();
    if (this.geoJsonLayer)  await this.geoJsonLayer.remove();
    if(this.orifinalRoute)  await this.orifinalRoute.remove();
    this.isNotclicked=true;

    if (this.drawControl) { this.map.removeControl(this.drawControl);}
    this.drawnItems.clearLayers();
  
    setTimeout(async () => {
      const transit = await this.regioService.getTransitPlan();
      const transitWithPointsSameStop = await this.processPoints(transit);
      const transitWithPointsSameStopAndfixedRouteTime= await this.filterTransitLines(transitWithPointsSameStop)

      await this.plotTransitData(transitWithPointsSameStopAndfixedRouteTime);
      this.addMultiLineStrings();
    }, 100);

  } else if (selectedMode === 'NeueRouteErstellen') {
    console.log('Log2: Neue Route erstellen selected');
  }    
  else if(this.mode=='Variantenvergleich'){
    this.openCompDialog();
  }
}


openDialogPlan(){

  this.dialog.open(FahrplanDialogComponent, {
    width: '1400px',
    height: '800px',
  }).afterClosed().subscribe((result: any) => {
    if (result) {
      console.log(result);  
    }
  }); 
}

  async ngOnInit() {
    this.initializeMap();
    const transit = await this.regioService.getTransitPlan();
    const transitWithPointsSameStop = await this.processPoints(transit);
    const transitWithPointsSameStopAndfixedRouteTime= await this.filterTransitLines(transitWithPointsSameStop)
    this.createMarkerClusterForNetwork(transitWithPointsSameStopAndfixedRouteTime)
    await this.plotTransitData(transitWithPointsSameStopAndfixedRouteTime);
    this.addMultiLineStrings();
    const mobility=await this.regioService.getmobilityRaster();
    console.log(mobility);
    
  }

  private initializeMap(): void {
    const osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    const osmAttrib =
      '&copy; <a href="http://openstreetmap.org/copyright">OpenStreetMap</a> contributors';
    const osm = L.tileLayer(osmUrl, { maxZoom: 18, attribution: osmAttrib });

    this.map = L.map('map', {
      center: latLng(51.165195078, 11.97547),
      zoom: 13,
      layers: [osm],
    });

  }

  private async plotTransitData(transit: any) {

    this.geoJsonLayer=L.geoJSON(transit, {
      filter: (feature) => {
        return (feature.properties.transportMode === 'bus' || feature.geometry.type === 'Point');
      },
      pointToLayer: (feature, latlng) => {
  
        if (feature.geometry.type === 'Point') {
          return L.marker(latlng, {
            icon: L.divIcon({
              className: 'bus-marker',
              html: '<span class="material-icons" style="font-size:24px; color:black;">directions_bus</span>'
            })
          });
        }
  
        return L.circleMarker(latlng, {
          radius: 8,
          fillColor: '#3388ff',
          color: '#3388ff',
          weight: 1,
          opacity: 1,
          fillOpacity: 0.8
        });
      },
      onEachFeature: (feature:any, layer:any) => {
        if (feature.properties) {
          //layer.bindPopup(feature.properties.modes);
        }
  
        layer.on('click',  () => {
            this.handleClick(feature,transit);
        });
  
        // layer.on('mouseover', () => {
        //   layer.setStyle({ color: 'red' });
        //   layer.bringToFront();
        // });
  
        // layer.on('mouseout', () => {
        //   layer.setStyle({ color: '#3388ff' });
        //   layer.bringToBack();
        // });
      },
      style: {
        color: '#3388ff',
        weight: 4,
      }
    }).eachLayer((layer) => {
      this.markers.addLayer(layer);
    });
    this.map.addLayer(this.markers);
  }

  handleClick(feature:any,transit:any){
    const coordinates = feature.geometry.coordinates;
    console.log(feature);
    if (this.mode == 'FahrplanAnsehen'){
      this.variante1=true
      const stopFacilityTransitRoutes = feature.properties.stopFacilityTransitRoutes.split(',');
      const stop=feature.properties
      this.dialog.open(TransitDialogComponent, {
        data: { stopFacilityTransitRoutes ,transit:transit ,stop:stop }, 
        width: '1200px',
        height: '800px',
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          console.log(result);  
        }
      });
    }
    else if (this.mode === 'NeueRouteErstellen') {
      const isMarkerAlreadySelected = this.selectedMarkers.some(marker => marker.properties.stopFacilityId === feature.properties.stopFacilityId);
      if (isMarkerAlreadySelected) {
          this.snackBar.open("Station bereits hinzugefügt.", "OK", { duration: 2000 });
      } else {
        this.selectedMarkers.push(feature);
        console.log("Selected markers:", this.selectedMarkers);
      }
    }

    }


    openCompDialog(){
      this.dialog.open(CompMobilityDialogComponent, {
        width: '1800px',
        height: '800px',
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          console.log(result);  
        }
      });
    }

////////////////////////////////////////////originall route ///////////////////////////////////////////////////////
private coordinates = [
  [51.14520150620289, 12.003442066070924],
  [51.14507861177967, 12.004909191469995],
  [51.144479823665016, 12.00623460594516],
  [51.1431959330183, 12.008714548039052],
  [51.14664796290954, 12.016508802926674],
  [51.147908495065515, 12.016052357686615],
  [51.14967432522501, 12.019761802441908],
  [51.15502446565215, 12.02836815661722],
  [51.15585775135048, 12.029381019115426],
  [51.155845509617336, 12.029391276824736],
  [51.155664992804674, 12.029699646071668],
  [51.15432398865783, 12.029658530172078],
  [51.15338268362144, 12.030542522013285],
  [51.15352452533513, 12.031734883101427],
  [51.1528539970292, 12.032618874942635],
  [51.1528539970292, 12.032618874942635],
  [51.15352452533513, 12.031734883101427],
  [51.15338268362144, 12.030542522013285],
  [51.15432398865783, 12.029658530172078],
  [51.155664992804674, 12.029699646071668],
  [51.155845509617336, 12.029391276824736],
  [51.15518790925722, 12.028240031636185],
  [51.17896758110206, 12.00102471216234],
  [51.18231279754989, 11.999764727774236],
  [51.18317229313213, 12.00000560714255],
  [51.183532347329184, 12.001506470898967],
  [51.18273093253767, 12.00093206625145],
  [51.182440561420805, 11.997578284277232],
  [51.18847778609944, 11.989567406995759],
  [51.20081845947058, 11.980196010306981],
  [51.19907364234217, 11.97407549520246],
  [51.20012745068968, 11.972393732043109],
  [51.20135398414636, 11.975095581053212],
  [51.20294324657373, 11.972586721103172],
  [51.20275806083433, 11.971784509680548],
// Weißenfels HBF
  [51.20275806083433, 11.971784509680548],
  [51.20278829626533, 11.970990226778628], 
  [51.20224645713728, 11.967010132277782], 
  [51.20228011192715, 11.966682486171775],
  [51.20107702610205, 11.966617955908475],
  [51.1988851504401, 11.966517125970219],
  [51.19844773480094, 11.962918272702026],
  [51.194312043118316, 11.953379713494288],
  [51.19424741999249, 11.951884480072499],
  [51.19327806222862, 11.951162643248189],
  [51.18824462310045, 11.933722040575763],
  [51.18688327555737, 11.929874018548245],
  [51.185539769489374, 11.929752597200325],
  [51.184448408291324, 11.92734572509463],
  //Ließling
  [51.184448408291324, 11.92734572509463],
  [51.18075684806123, 11.928369925893364],
  [51.17542763972227, 11.926731204580415],
  [51.17327651937485, 11.925911843864435],
  [51.161299046412125, 11.929906227064752],
  [51.15519217357582, 11.93021088160359],
  [51.152561793481674, 11.929012699150682],
  [51.15008784680745, 11.929661714646008],
  //fuß
  [51.15008784680745, 11.929661714646008],
  [51.15015048000517, 11.92671618278261],
  [51.14917965588815, 11.926566409975997],
  [51.150860374326925, 11.917513397008065],
  [51.15216817615761, 11.917210103975295]

];
private addMultiLineStrings(): void {

  const segments = [
    this.coordinates.slice(0, 35),   
    this.coordinates.slice(34, 57),  
    this.coordinates.slice(56)   
  ];

  const colors = ['orange' ,'orangered', '#cc0000', '#990000', '#660000'];

  segments.forEach((segment, index) => {
    const lineStringGeoJSON: any = {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: segment.map(([lat, lng]) => [lng, lat])
      }
    };

    this.orifinalRoute=L.geoJSON(lineStringGeoJSON, {
      style: {
        color: colors[index],
        weight: 8,
    }
  })
  .on('mouseover', (e) => {
    e.target.setStyle({ weight: 12});    
  })
  .on('mouseout', (e) => {
    e.target.setStyle({ weight: 8, color: colors[index], opacity: 1 });
  })
  .addTo(this.map);

  const [startLat, startLng] = segment[0];
  const [endLat, endLng] = segment[segment.length - 1];

  L.circleMarker([startLat, startLng], {
    color: 'DarkOrchid', 
    fillColor: 'White',    
    radius: 6,
    fillOpacity: 1,
    weight: 2
  }).addTo(this.map);

  L.circleMarker([endLat, endLng], {
    color: 'DarkOrchid',
    fillColor: 'white',  
    radius: 6,
    fillOpacity: 1,
    weight: 2
  }).addTo(this.map);
});
}

//////////////////////////////////////////see transit plan//////////////////////////////////////////////

//see Dialog

//////////////////////////////////new Route//////////////////////////

async removeTransitAddNetwork(){
  this.isNotclicked=false;
  const networkBlk = await this.regioService.getBlkNetwork();
  console.log(networkBlk);
  const filteredFeatures = this.filterNetworkFeatures(networkBlk.features);
  console.log(filteredFeatures);
  console.log(this.markers);
  
  if (this.geoJsonLayer) {
    this.geoJsonLayer.remove(); 
  }
 this.markers.clearLayers();

 this.map.addLayer(this.markersNetwork);

  this.geoJsonLayer = L.geoJSON(filteredFeatures, {
    // filter: (feature) => {
    //   return (feature.properties.freespeed === 25);
    // },
    filter: (feature) => {
      return (feature.geometry.type === 'MultiPolygon');
    },
    style: {
      color: 'red', 
      weight: 4, 
    },
    onEachFeature: (feature,layer:any) => {
      layer.bindPopup(feature.properties.Street);

      layer.on('click',  () => {
        console.log(feature);

        this.handleClickNetwork(layer, feature);
      });
      const originalStyle = {
        color: 'blue',
        weight: 4,
      };

      // layer.on('mouseover', () => {
      //   if (!this.isLayerSelected(layer)) {
      //     layer.setStyle({
      //       color: 'transparent', 
      //       weight: 3,
      //     });
      //   }
      // });

      // layer.on('mouseout', () => {
      //   if (!this.isLayerSelected(layer)) {
      //     layer.setStyle(originalStyle);
      //   } else {
      //     layer.setStyle({
      //       color: 'transparent',
      //       weight: 5,
      //     });
      //   }
      // });
    }
    
  });

this.geoJsonLayer.addTo(this.map);

// @ts-ignore
this.drawControl = new L.Control.Draw({
  draw: {
    polyline: true, 
    polygon: false,
    circle: false,
    rectangle: false,
    marker: false,
    circlemarker: false
  }
});
this.map.addControl(this.drawControl);


// @ts-ignore
this.map.on(L.Draw.Event.CREATED, (event: any) => {
  const layer = event.layer;
  const lineCoordinates = layer.getLatLngs().map((latLng: L.LatLng) => [latLng.lng, latLng.lat]);
   //this.storeLineStringCoordinates(lineCoordinates);
  this.drawnItems.addLayer(layer); // Add the new layer to drawnItems for easy removal
  this.openUmbauDialog()
});

}

openUmbauDialog(){

  this.dialog.open(UmbauMobilityDialogComponent, {
    width: '800px',
    height: '650px',
  }).afterClosed().subscribe((result: any) => {
      this.startLoading();
  }); 
}

//@ts-ignore
private drawControl: L.Control.Draw;

private drawnItems: L.LayerGroup = L.layerGroup()


async calculStops() {
  let currentTime = new Date(this.initialTime);

  const coordinatesPairs1 = this.selectedMarkers.map(marker => marker.geometry.coordinates.map((coord:any) => coord));
  const coordinatesPairs =coordinatesPairs1.map((coord:any)=> coord.reverse())


  for (let i = 0; i < coordinatesPairs.length - 1; i++) {
    const fromPlaceCoords = coordinatesPairs[i];
    const toPlaceCoords = coordinatesPairs[i + 1];
    const fromPlace = fromPlaceCoords.join('%2C');
    const toPlace = toPlaceCoords.join('%2C');

    const url = `https://otp15.bunsencloud.de/otp/routers/default/plan?fromPlace=${fromPlace}&toPlace=${toPlace}&date=10-28-2024&mode=CAR&arriveBy=true&wheelchair=false&locale=en`;
    console.log(url);
    
    try {
      const response = await fetch(url, { method: 'GET' });

      if (response.ok) {
        let data = await response.json();
        //console.log("Route data:", data);

        let route = data;
        console.log(route);
        let duration = route.plan.itineraries[0].duration;
        let formattedDuration = this.formatDuration(duration);
        const timeString = currentTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });

        this.schedule.push({
          station: this.selectedMarkers[i].properties.stopFacilityName,
          time: timeString,
          travelDuration: formattedDuration
        });

        currentTime = new Date(currentTime.getTime() + duration * 1000);
        console.log(currentTime);
        
      } else {
        console.error("Failed to retrieve route data", response.status);
      }
    } catch (error) {
      console.error("Error sending route request:", error);
    }
  }


  const finalTimeString = currentTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
  this.schedule.push({
    station: this.selectedMarkers[this.selectedMarkers.length - 1].properties.stopFacilityName,
    time: finalTimeString
  });

  console.log("Transit Schedule:", this.schedule);

  const adjsutedSchedule = this.adjustSchedule(this.schedule)
  console.log(adjsutedSchedule);

  this.scheduleUmbau = this.createNewSchedule(adjsutedSchedule);
  console.log(this.scheduleUmbau );

  this.Uhrzeit='Umbau';  

  setTimeout(() => {
    this.calculateTimeDifferences(this.schedule);
    this.calculateTimeDifferences(this.scheduleUmbau);
    this.plotCombinedChart();
  }, 300);
}


adjustSchedule(schedule:any) {
  // Parse the input time for the last station
  const lastStationIndex = schedule.length - 1;
  const lastStationArrivalTime = new Date();
  
  // Set the arrival time for the last station
  const [hours, minutes] = this.routeTime.split(':').map(Number);
  const isPM = this.routeTime.includes('PM');
  console.log(this.routeTime);
  
  // Adjust hours for 24-hour format
  lastStationArrivalTime.setHours(isPM ? hours + 12 : hours, minutes, 0, 0);
  console.log(lastStationArrivalTime);

  schedule[lastStationIndex].time= this.formatTime(new Date(lastStationArrivalTime.getTime()))
  // Adjust time for the preceding stations
  for (let i = lastStationIndex; i >= 0; i--) {
    const currentEntry = schedule[i];
    
    // If there's a travel duration, calculate the new time
    if (currentEntry.travelDuration) {
      const [travelHours, travelMinutes, travelSeconds] = currentEntry.travelDuration.split(':').map(Number);
      const travelDurationInMs = (travelHours * 3600 + travelMinutes * 60 + travelSeconds) * 1000;

      // Calculate the new time by subtracting travel duration from the arrival time of the next station
      const newTime = new Date(lastStationArrivalTime.getTime() - travelDurationInMs);
      
      // Update the schedule entry time in "hh:mm AM/PM" format
      currentEntry.time = this.formatTime(newTime);
      
      // Update the last station arrival time for the next iteration
      lastStationArrivalTime.setTime(newTime.getTime());
    }
  }

  return schedule;
}

// Function to create the new schedule
createNewSchedule(schedule: any) {
  const newSchedule = [];
  
  // Start with the arrival time being the time of the last station
  const arrivalTime = this.timeStringToDate(schedule[schedule.length - 1].time);

  // Iterate through the schedule in reverse order to calculate new departure times
  for (let i = schedule.length - 1; i >= 0; i--) {
    const item = schedule[i];

    if (item.travelDuration) {
      // Convert the travel duration to seconds and multiply by 0.68
      const travelDurationInSeconds = this.travelDurationToSeconds(item.travelDuration);
      const newTravelDurationInSeconds = Math.round(travelDurationInSeconds * 0.68);

      // Calculate new departure time by subtracting new travel duration from the arrival time
      const newDepartureTime = new Date(arrivalTime);
      newDepartureTime.setSeconds(arrivalTime.getSeconds() - newTravelDurationInSeconds);

      // Format new travel duration string back to HH:MM:SS
      const newTravelDurationHours = Math.floor(newTravelDurationInSeconds / 3600);
      const newTravelDurationMinutes = Math.floor((newTravelDurationInSeconds % 3600) / 60);
      const newTravelDurationString = `${String(newTravelDurationHours).padStart(2, '0')}:${String(newTravelDurationMinutes).padStart(2, '0')}:00`;

      // Create the new schedule item
      newSchedule.push({
        station: item.station,
        time: this.dateToTimeString(newDepartureTime),
        travelDuration: item.travelDuration,
        newTravelDuration: newTravelDurationString,
      });

      // Update arrivalTime for the next iteration (previous station)
      arrivalTime.setSeconds(arrivalTime.getSeconds() - newTravelDurationInSeconds);
    } else {
      // Last station with no travel duration (if it reaches here, it's the first station in this loop)
      newSchedule.push({
        station: item.station,
        time: this.dateToTimeString(arrivalTime), // Use current arrival time for the station
        newTravelDuration: '00:00:00', // No travel duration for arrival
      });
    }
  }

  return newSchedule.reverse(); // Reverse to maintain original order
}

async sendRouteRequest(coordinates: any[]) {
  const reversedCoordinates = coordinates.map(coord => coord.reverse());
  const fromPlace = reversedCoordinates[0].join('%2C');
  const toPlace = reversedCoordinates[reversedCoordinates.length - 1].join('%2C');
  const intermediatePlaces = reversedCoordinates.slice(1, -1).map(coord => coord.join('%2C')).join('|');
  const [hours, minutes] = this.routeTime.split(':').map(Number);
  const period = hours >= 12 ? 'PM' : 'AM';
  const formattedTime = `${(hours % 12 || 12).toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
  formattedTime.replace(':', '%3A');
  const url=`https://otp15.bunsencloud.de/otp/routers/default/plan?fromPlace=${fromPlace}&toPlace=${toPlace}&time=${formattedTime}&date=10-28-2024&mode=CAR&arriveBy=true&wheelchair=false&locale=en&intermediatePlaces=${intermediatePlaces}`;
  try {
    const response = await fetch(url, {
      method: 'GET'
    });
    
    if (response.ok) {
      const data = await response.json();
      const route = data;
      this.distance = route.plan.itineraries[0].legs.reduce((total:any, leg:any) => total + leg.distance, 0);
      this.duration = route.plan.itineraries[0].duration
      this.formattedDuration = this.formatDuration(this.duration);
    } else {
      console.error("Failed to retrieve route data", response.status);
    }
  } catch (error) {
    console.error("Error sending route request:", error);
  }
}

clearTable() {
  this.selectedMarkers.forEach((_, index) => {
    const row = document.querySelectorAll('.table-row-enter')[index];
    if (row) row.classList.add('table-row-leave');
  });
  setTimeout(() => {
    this.selectedMarkers = [];
  }, 300); 
}

removeMarker(index: number) {
  this.selectedMarkers.splice(index, 1);
  console.log("Selected markers:", this.selectedMarkers);
}

private plotCombinedChart(): void {
  const stations = this.schedule.slice(0).map((item:any) => item.station);
  const timeDifferences1 = this.schedule.slice(0).map((item:any) => item.timeDifference);
  const timeDifferences2 = this.scheduleUmbau.slice(0).map((item:any) => item.timeDifference);

  this.chart = new Chart('myChart', {
    type: 'bar',
    data: {
      labels: stations,
      datasets: [
        {
          label: 'Plan vor Umbau',
          data: timeDifferences1,
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1
        },
        {
          label: 'Plan nach Umbau',
          data: timeDifferences2,
          backgroundColor: 'rgba(153, 102, 255, 0.6)',
          borderColor: 'rgba(153, 102, 255, 1)',
          borderWidth: 1
        }
      ]
    },
    options: {
      scales: {
        x: { title: { display: true, text: 'Stations' } },
        y: { 
          beginAtZero: true,
          title: { display: true, text: 'Minuten bis zum Ziel' }
        }
      }
    }
  });
}

////////////////////////////////////////////////preprocessing//////////////////////////////////////////////
createMarkerClusterForNetwork(transitGeoJSON:any) {
  const pointsGeoJSON = {
    type: 'FeatureCollection',
    features: transitGeoJSON.features.filter((feature:any) => feature.geometry.type === 'Point')
  };

  pointsGeoJSON.features.forEach((point:any) => {
    const busIcon = L.divIcon({
      className: 'bus-station-icon', 
      html: '<span class="material-icons" style="font-size:24px; color:black;">directions_bus</span>',
    });

    const marker = L.marker(
      L.latLng(point.geometry.coordinates[1], point.geometry.coordinates[0]),
      { icon: busIcon }
    ).bindPopup(point.properties.stopFacilityName || 'Transit Stop');

    marker.on('click', () => {
      console.log('Coordinates:', point.geometry.coordinates);
    });

    this.markersNetwork.addLayer(marker);
  });

  console.log(pointsGeoJSON);
}

async processPoints(transit:any) {

  const points = transit.features.filter((feature: any) => feature.geometry.type === 'Point');
  
  const groupedPoints = new Map<string, any[]>();

  points.forEach((point: any) => {
    const stopFacilityId = point.properties.stopFacilityId;
    const stopIdNumber = stopFacilityId.split('.link')[0];

    if (groupedPoints.has(stopIdNumber)) {
      groupedPoints.get(stopIdNumber)!.push(point);
    } else {
      groupedPoints.set(stopIdNumber, [point]);
    }
  });

  const processedPoints:any = [];

  groupedPoints.forEach((pointGroup, stopIdNumber) => {
    if (pointGroup.length > 1) {
      const combinedRoutes = new Set<string>();

      pointGroup.forEach((point: any) => {
        const routes = point.properties.stopFacilityTransitRoutes.split(',');
        routes.forEach((route: string) => combinedRoutes.add(route));
      });

      const newPoint = {
        ...pointGroup[0], 
        properties: {
          ...pointGroup[0].properties,
          stopFacilityTransitRoutes: Array.from(combinedRoutes).join(','), 
        }
      };
      processedPoints.push(newPoint);
    } else {
      processedPoints.push(pointGroup[0]);
    }
  });

  const nonPoints = transit.features.filter(
    (feature: any) => feature.geometry.type !== 'Point' && feature.properties.transportMode === 'bus');
  transit.features = [...nonPoints, ...processedPoints];

  console.log(transit);
  return transit;
}

async filterTransitLines(transit:any) {

  const validRoutes = new Set();

  const filteredTransitFeatures = transit.features
    .map((feature:any) => {
      if (feature.geometry.type === 'LineString') {
        const departures = feature.properties.departures.split(',');

        const filteredDepartures = departures.filter((time:any) => {
          const [hours, minutes, seconds] = time.split(':').map(Number);
          const totalMinutes = hours * 60 + minutes;
          return totalMinutes >= 4* 60 +30 && totalMinutes <=10 * 60 + 30;
        });

        if (filteredDepartures.length > 0) {
          feature.properties.departures = filteredDepartures.join(',');
          validRoutes.add(feature.properties.transitRouteId);
          return feature; 
        }
        return null;
      }

      return feature;
    })
    .filter((feature:any) => feature !== null);

  const finalTransitFeatures = filteredTransitFeatures
    .map((feature:any) => {
      if (feature.geometry.type === 'Point') {
        const routeIds = feature.properties.stopFacilityTransitRoutes.split(',');
        const filteredRoutes = routeIds.filter((routeId:any) => validRoutes.has(routeId));

        if (filteredRoutes.length > 0) {
          feature.properties.stopFacilityTransitRoutes = filteredRoutes.join(',');
          return feature;
        }
        return null;
      }

      return feature;
    })
    .filter((feature:any) => feature !== null); 

  const finalResult = {
    ...transit,
    features: finalTransitFeatures,
  };

  console.log(finalResult); 

  return finalResult;
}

private filterNetworkFeatures(features: any): GeoJSON.Feature[] {
  const seenPairs = new Set<string>();
  const filteredFeatures: GeoJSON.Feature[] = [];

  for (const feature of features) {
      const fromToPair = `${feature.properties!.from}-${feature.properties!.to}`;
      const toFromPair = `${feature.properties!.to}-${feature.properties!.from}`;

      if (!seenPairs.has(fromToPair)) {
          filteredFeatures.push(feature);
          seenPairs.add(fromToPair);
          seenPairs.add(toFromPair);
      }
  }

  return filteredFeatures;
}

////////////////////////////////////////////////////////////time formatting//////////////////////////////
private calculateTimeDifferences(schedule: any[]): void {
  for (let i = 0; i < schedule.length; i++) {
    const currentMinutes = this.convertTimeToMinutes(schedule[i].time);
    const previousMinutes = this.convertTimeToMinutes(schedule[schedule.length-1].time);
    const differenceInMinutes = previousMinutes - currentMinutes ;
    schedule[i]['timeDifference'] = differenceInMinutes;
  }
}

private convertTimeToMinutes(time: string): number {
  const [timePart, period] = time.split(' ');
  const [hours, minutes] = timePart.split(':').map(Number);
  const totalMinutes = (hours % 12) * 60 + minutes + (period === 'PM' ? 12 * 60 : 0);
  return totalMinutes;
}

formatDuration(durationInSeconds: number): string {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = Math.floor(durationInSeconds % 60);
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

// Function to convert time string to Date object
timeStringToDate(timeString: string): Date {
  const [time, modifier] = timeString.split(' ');
  let [hours, minutes] = time.split(':').map(Number);

  if (modifier === 'PM' && hours < 12) {
    hours += 12;
  }
  if (modifier === 'AM' && hours === 12) {
    hours = 0;
  }

  const date = new Date();
  date.setHours(hours, minutes, 0);
  return date;
}

// Function to convert Date object back to time string
dateToTimeString(date: Date): string {
  let hours: number = date.getHours();
  const minutes: number = date.getMinutes();
  const modifier: string = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12 || 12; // Convert to 12-hour format
  const formattedMinutes: string = minutes < 10 ? '0' + minutes : minutes.toString();
  
  return `${hours}:${formattedMinutes} ${modifier}`;
}

// Function to convert HH:MM:SS to seconds
travelDurationToSeconds(duration: string): number {
  const [hours, minutes, seconds] = duration.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}



// Helper function to format Date to "hh:mm AM/PM"
formatTime(date: Date): string {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const strMinutes = minutes < 10 ? '0' + minutes : minutes;

  return `${hours}:${strMinutes} ${ampm}`;
}

///////////////////////////////////////////////////////could be useful ///////////////////////////////////////////////////////////
storeLineStringCoordinates(coordinates: any[]) {
  console.log("Stored LineString coordinates:", coordinates);
}

isLayerSelected(layer: any): boolean {
  return this.selectedLayers.some((selected: any) => selected.layer === layer);
}

handleClickNetwork(layer: any, feature: any) {
  //layer.setStyle({ color: 'red', weight: 5 });
  this.selectedLayers.push({ feature, layer });
  console.log("Selected layers:", this.selectedLayers);
}



//the other way
// private coordinates = [
//   [51.1528539970292, 12.032618874942635],
//   [51.15352452533513, 12.031734883101427],
//   [51.15338268362144, 12.030542522013285],
//   [51.15432398865783, 12.029658530172078],
//   [51.155664992804674, 12.029699646071668],
//   [51.155845509617336, 12.029391276824736],
//   [51.15518790925722, 12.028240031636185],
//   [51.17896758110206, 12.00102471216234],
//   [51.18231279754989, 11.999764727774236],
//   [51.18317229313213, 12.00000560714255],
//   [51.183532347329184, 12.001506470898967],
//   [51.18273093253767, 12.00093206625145],
//   [51.182440561420805, 11.997578284277232],
//   [51.18847778609944, 11.989567406995759],
//   [51.20081845947058, 11.980196010306981],
//   [51.19907364234217, 11.97407549520246],
//   [51.20012745068968, 11.972393732043109],
//   [51.20135398414636, 11.975095581053212],
//   [51.20294324657373, 11.972586721103172],
//   [51.20228681875762, 11.967017603755812],
//   [51.20213134764155, 11.966576485550084],
//   [51.19141341687773, 11.969235539645403],
//   [51.18306812052078, 11.96344880500905],
//   [51.183196521140715, 11.962270974065365],
//   [51.17873438967772, 11.960222572120589],
//   [51.18033952261303, 11.95499914793556],
//   [51.18492989436455, 11.938048623847136],
//   [51.18618173458695, 11.936358692493156],
//   [51.18704837328514, 11.929906227323414],
//   [51.185539769489374, 11.929752597200325],
//   [51.184448408291324, 11.92734572509463],
//   [51.18075684806123, 11.928369925893364],
//   [51.17542763972227, 11.926731204580415],
//   [51.17327651937485, 11.925911843864435],
//   [51.161299046412125, 11.929906227064752],
//   [51.155284315428695, 11.93019652624692],
//   [51.1526202490841, 11.929080433801111],
//   [51.15016995009382, 11.929576474888137],

//   [51.15016230038648, 11.92712271959392],
//   [51.14912469048684, 11.926610175621608],
//   [51.15086376989453, 11.917780441600263]

// ];
}
