import { Component } from '@angular/core';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.css']
})
export class BoardComponent {
  columns = [
    { name: 'To Do', issues: [] },
    { name: 'In Progress', issues: [] },
    { name: 'Done', issues: [] },
  ];



  addIssue(columnName: string) {
    const issueTitle = prompt('Enter issue title');
    if (issueTitle) {
      const column = this.columns.find((col) => col.name === columnName);
      //@ts-ignore
      column?.issues.push({ title: issueTitle });
    }
  
}


}
