<div class="popup-overlay" id="popup" style="display: flex; align-items: center; flex-direction: column; padding:30px">
    <div class="popup-content">
        <h2 class="popup-header"> {{ data?.schoolToBeDeleted }} schließen</h2>

    </div>
</div>


<div style="padding: 10px;" *ngIf="!nextStep ">
    <p>Zeitpunkt </p>
    <mat-radio-group [(ngModel)]="time" class="flexColum" id="schoolList">
        <mat-radio-button>2024/25 </mat-radio-button>
    
        <mat-radio-button>2025/26
    
        </mat-radio-button> 
        <mat-radio-button>
            2026/27     
        </mat-radio-button>

        <mat-radio-button>
            2026/27     
        </mat-radio-button>

        <mat-radio-button>
            2027/28   
        </mat-radio-button>

        <mat-radio-button>
            2028/29   
        </mat-radio-button>
        <mat-radio-button>
            2029/30    
        </mat-radio-button>
        <mat-radio-button>
            2030/31    
        </mat-radio-button>
        <mat-radio-button>
            2031/32    
        </mat-radio-button>



    </mat-radio-group> 

<p>Optionen </p>
<mat-radio-group [(ngModel)]="options" class="flexColum" id="schoolList">
    <mat-radio-button>
        Aufwachsende Sekundarstufe II + Sekundarstufe I verteilen sich auf ausgewählte Schulen (keine neuen 5.
        Klassen, 11. Klassen wechseln direkt)

    </mat-radio-button>
    <br>

    <mat-radio-button>
        Aufwachsende + Sekundarstufe I verteilen sich auf ausgewählte Schulen (keine neuen 5. Klassen, restliche
        Klassen machen Abschluss)

    </mat-radio-button>
    <br>
    <mat-radio-button>
        Sofortschließung
    </mat-radio-button>
</mat-radio-group>   <br><br><br>

<button (click)="nextStepF()"  class="share-btn">Weiter</button> <br><br><br>


    
</div>


<div style="display: flex; " *ngIf="nextStep">
<div class="grfikChart2" >
    <p>Anzahl SuS die neu verteilt werden müssen: </p>

    <canvas #chartCanvas></canvas>

    <p>Freie Kapazitäten: </p>
    <canvas #chartCanvas2></canvas>


    </div>

    <div>
        <h3> Kapazitäten verteilen </h3>
        <span *ngFor="let sch of filteredSchools"> 
            <input id="age-input"  value="25" min="0" max="100" [ngModel]="sch.properties.anteil"> %

            <mat-icon *ngIf="sch.properties.anteil < 100" (click) = "sch.properties.anteil = sch.properties.anteil + 25" style="width: 20px;height: 20px; " >add</mat-icon> 
            <mat-icon *ngIf="sch.properties.anteil > 0" (click) = "sch.properties.anteil  = sch.properties.anteil - 25" style="width: 20px;height: 20px; " >remove</mat-icon> 
     
            {{ sch.properties.Schulname  }} <br>
         </span> 

         



<br>

<br>

<br>

     <button (click)="save()"  class="share-btn">Anwenden</button> <br><br><br>


    </div>

</div>




 