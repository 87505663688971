import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component,Inject, OnInit, AfterViewInit } from '@angular/core';
import { Chart,ChartData, ChartOptions } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(annotationPlugin);

@Component({
  selector: 'app-comp-mobility-dialog',
  templateUrl: './comp-mobility-dialog.component.html',
  styleUrls: ['./comp-mobility-dialog.component.css']
})
export class CompMobilityDialogComponent implements OnInit{

  step=1

  constructor(
    public dialogRef: MatDialogRef<CompMobilityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
) {}

original_plan=[
  {"station": "Obernessa", "time_diff": 53},
  {"station": "Nessa, Ort", "time_diff": 51},
  {"station": "Dippelsdorf", "time_diff": 50},
  {"station": "Wernsdorf (Nessa) Ort", "time_diff": 47},
  {"station": "Prittitz, Abzweig Bahnhof", "time_diff": 15},
  {"station": "Plotha, Ort (Prittitz)", "time_diff": 14},
  {"station": "Plotha, Schule (Prittitz)", "time_diff": 0}
]


adjusted_plan=[
  {"station": "Obernessa", "time_diff": 40},
  {"station": "Nessa, Ort", "time_diff": 38},
  {"station": "Dippelsdorf", "time_diff": 37},
  {"station": "Wernsdorf (Nessa) Ort", "time_diff": 34},
  {"station": "Prittitz, Abzweig Bahnhof", "time_diff": 5},
  {"station": "Plennschütz", "time_diff": 3},
  {"station": "Plotha, Ort (Prittitz)", "time_diff": 2},
  {"station": "Plotha, Schule (Prittitz)", "time_diff": 0}
]

newRoute_plan=[
  {"station": "Wernsdorf (Nessa), Ort", "time_diff": 17},
  {"station": "Dippelsdorf", "time_diff": 16},
  {"station": "Nessa, Ort", "time_diff": 15},
  {"station": "Obernessa", "time_diff": 19},
  {"station": "Prittitz, Ort", "time_diff": 4},
  {"station": "Prittitz, Abzweig Bahnhof", "time_diff": 3},
  {"station": "Plennschütz", "time_diff": 2},
  {"station": "Plotha, Ort (Prittitz)", "time_diff": 1},
  {"station": "Plotha, Schule (Prittitz)", "time_diff": 0}
]


public barChartOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false, // Allow resizing
  plugins: {
    legend: {
      position: 'top',
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          return context.raw !== null ? `${context.raw} mins` : 'No data';
        }
      }
    },
    annotation: {
      annotations: {
        line1: {
          type: 'line',
          yMin: 30,
          yMax: 30,
          borderColor: 'red',
          borderWidth: 2,
          label: {
            display: true,
            content: '30 min threshold',
            position: 'end',
            color: 'red',
          }
        }
      }
    }
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Stations'
      }
    },
    y: {
      title: {
        display: true,
        text: 'Time Difference (minutes)'
      },
      beginAtZero: true
    }
  }
};

public barChartData: ChartData<'bar'> = {
  labels: [], // populated dynamically
  datasets: []
};

ngOnInit(): void {
  this.prepareChartData();
}

private prepareChartData() {




  const newRoutePlan = [
    { station: 'Wernsdorf (Nessa) Ort', time_diff: 17 },
    { station: 'Dippelsdorf', time_diff: 16 },
    { station: 'Nessa, Ort', time_diff: 15 },
    { station: 'Obernessa', time_diff: 19 },
    { station: 'Prittitz, Ort', time_diff: 4 },
    { station: 'Prittitz, Abzweig Bahnhof', time_diff: 3 },
    { station: 'Plennschütz', time_diff: 2 },
    { station: 'Plotha, Ort (Prittitz)', time_diff: 1 },
    { station: 'Plotha, Schule (Prittitz)', time_diff: 0 }
  ];
  const adjustedPlan = [
    { station: 'Obernessa', time_diff: 40 },
    { station: 'Nessa, Ort', time_diff: 38 },
    { station: 'Dippelsdorf', time_diff: 37 },
    { station: 'Wernsdorf (Nessa) Ort', time_diff: 34 },
    { station: 'Prittitz, Abzweig Bahnhof', time_diff: 5 },
    { station: 'Plennschütz', time_diff: 3 },
    { station: 'Plotha, Ort (Prittitz)', time_diff: 2 },
    { station: 'Plotha, Schule (Prittitz)', time_diff: 0 }
  ];
  const originalPlan = [
    { station: 'Obernessa', time_diff: 53 },
    { station: 'Nessa, Ort', time_diff: 51 },
    { station: 'Dippelsdorf', time_diff: 50 },
    { station: 'Wernsdorf (Nessa) Ort', time_diff: 47 },
    { station: 'Prittitz, Abzweig Bahnhof', time_diff: 15 },
    { station: 'Plotha, Ort (Prittitz)', time_diff: 14 },
    { station: 'Plotha, Schule (Prittitz)', time_diff: 0 }
  ];

  const allStations = Array.from(new Set([
  

    ...newRoutePlan.map(item => item.station),
    ...adjustedPlan.map(item => item.station),
    ...originalPlan.map(item => item.station)
  ]));

  this.barChartData.labels = allStations;

  const datasets = [
    { label: 'Ursprünglicher Plan', data: this.mapTimeDiffsToStations(allStations, originalPlan), backgroundColor: '#ff6384' },
    { label: 'Angepasster Plan', data: this.mapTimeDiffsToStations(allStations, adjustedPlan), backgroundColor: '#36a2eb' },
    { label: 'Neuer Routenplan', data: this.mapTimeDiffsToStations(allStations, newRoutePlan), backgroundColor: '#cc65fe' }
  ];

  this.barChartData.datasets = datasets;
}

private mapTimeDiffsToStations(allStations: string[], plan: { station: string, time_diff: number }[]) {
  const stationMap = new Map(plan.map(item => [item.station, item.time_diff]));
  return allStations.map(station => stationMap.get(station) ?? null);
}

}
