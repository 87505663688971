<div class="container">
  <div style="display: flex; flex-direction: column; align-items: center;">

    <div style="display: flex; "> 

    <button class="btn" (click)="setInitState()" *ngIf="hasBeenModified && !generateBool"> Ausgangszustand </button>
    <button class="btn" (click)="setModifyMode()" *ngIf="!modifiyMode && !generateBool"> Standorte modifizieren </button>
    <button class="btn" (click)="endModifyMode()" *ngIf="modifiyMode && !generateBool"> Modifikation setzen </button>
    <button class="btn" (click)="sendData()" *ngIf="hasBeenModified"> Für {{ year }} übernehmen </button>

  

    <button class="btn" *ngIf="!modifiyMode && !generateBool"  style="color: grey;"> Vorschlagsgenerierung </button>


    <button class="btn" *ngIf="!modifiyMode && generateBool" [ngStyle]="{'background-color': optimizationIndex == 0 ? 'lightgray' : '#10684E'}"   (click)="drawMarkersOpti(0)"> Optimierung 1  </button>
    <button class="btn" *ngIf="!modifiyMode && generateBool" [ngStyle]="{'background-color': optimizationIndex == 1 ? 'lightgray' : '#10684E'}"(click)="drawMarkersOpti(1)"> Optimierung 2 </button>
    <button class="btn" *ngIf="!modifiyMode && generateBool" [ngStyle]="{'background-color': optimizationIndex == 2 ? 'lightgray' : '#10684E'}"(click)="drawMarkersOpti(2)"> Optimierung 3 </button>
    <button class="btn" *ngIf="!modifiyMode && generateBool" [ngStyle]="{'background-color': optimizationIndex == 3 ? 'lightgray' : '#10684E'}"(click)="drawMarkersOpti(3)"> Status Quo </button>
</div>
    
    <button class="btn" *ngIf="generateBool" (click)="endOpti()"> Beenden </button>
    <button class="btn" (click)="modifiyMode = false; generateBool = false; hasBeenModified = false" *ngIf="modifiyMode && !generateBool"> Beenden </button>




</div>


    <div class="row">

            <div class="map" leaflet [leafletOptions]="options" [leafletLayers]="layers"
                (leafletMapReady)="onMapReady($event)">
            </div>

        <div class="chart">
            <h1>Notfallversorgung Burgenlandkreis {{ year }} </h1>
            <canvas id="canvas0">{{chart}}</canvas>
        </div>




    </div>






</div>


<dialog id="dialog">
    <div style="width: 100vw; display: flex; align-items: center; flex-direction: column; ">
      <h1> Hinweis </h1>
      <h3> Um eine neue Einrichtung hinzuzufügen, klicken Sie auf den gewünschten Ort in der Karte </h3>
      <h3> Eine bestehende Einrichtung kann mittels Klick auf der gewünschten Einrichtung verschoben oder gelöscht werden </h3>

      <button mat-raised-button  (click)="closeDialog()"> OK!</button>
    </div>
  </dialog>




  <dialog id="dialog3">
    <div style="width: 20vw; display: flex; align-items: center; flex-direction: column; position: right;
       height: 50vh;
    overflow-y: scroll; ">

     <div *ngIf="selectedMarkerForOpti == ''" style="display: flex; align-items: center; flex-direction: column;"> 
      <h3> Klicken Sie die Einrichtung an, für die es Optimierungsvorschläge geben soll</h3>
     

      <button mat-raised-button color="primary" (click)="setMarker()"> Einrichtung auswählen </button>

    </div>


    <div *ngIf="selectedMarkerForOpti != ''" style="display: flex; align-items: center; flex-direction: column;">
      <h2> Ausgewählte Einrichtung</h2>
      <h3> {{ selectedMarkerForOpti }} </h3>
     

      <button mat-raised-button color="primary" (click)="generate()"> Optimierung starten </button>

    </div>
    </div>
  </dialog>




<dialog id="dialog2">
    <div style="width: 100px; display: flex; align-items: center; ">
      <mat-spinner *ngIf="spinner"></mat-spinner>
    </div>
  </dialog>



