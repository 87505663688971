import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatahubService } from 'src/app/service/datahubservice/datahubservice.component';
import { KeycloakOperationService } from 'src/app/service/keycloakService/keycloak-operation.service';
import { SimulationService } from 'src/app/service/simulationservice/simulation.service';
import { Storageservice } from 'src/app/service/storageservice-component/storageservice-component.component';

import { DataDocLeafletComponent } from '../data-doc-leaflet/data-doc-leaflet.component';

@Component({
  selector: 'doc-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent {

isRente = false;
  rentenAlter: number = 67 ;
  userList: any;
startNewScenario() {
  var basisScen = this.existingScenarios.filter(scen => scen.name == "basis_test")

  console.warn("BASIS",  basisScen[0])
 


  console.warn("BASIS",  basisScen[0])
  if(this.isRente) {
  this.dataDoc.setRentenEintrittsAlter(this.rentenAlter)
  }

  this.setExistingScenario(basisScen[0])
}

formatLabel(value: number): string {

  if (value >= 1000) {
    return Math.round(value / 1000) + 'k';
  }

  return `${value}`;
}
  @Input()
  docPopID: string | undefined;

  @Input()
  docPopReference: any;

searchExtended: any;
searchBox: any  = '';
existingScenarios: any [] = [];
  userID: any;
newScen: any;
newExtended: any;
newName: any;

checkDate(date: any): any {
  var dateParsed = new Date(date);
  var dateformatted = dateParsed.toLocaleString('en-GB', { timeZone: 'UTC' });
  var tempSearchBox = this.searchBox.replaceAll(".", "/");
  tempSearchBox = tempSearchBox.replaceAll("-", "/");
  return dateformatted.includes(tempSearchBox)
}


setExistingScenario(scen: any) {
  this.storageService.docScenForNachfrage = scen; 

  var rente = this.isRente ? this.rentenAlter : undefined; 
this.dataDoc.setScenario(scen, rente, this.newName); 
}

translateUserName(id: any) {
var t = this.userList.filter((user: { id: any; }) => user.id == id) 
  

if(t.length > 0) {
  return t[0].username;

}

return 'unbekannt'
}
async ngOnInit(): Promise<void> {
  this.keyCloakService.getUsersList().subscribe((users: any) => {
    console.log(users);
    this.userList=users;
    console.warn("HH", this.userList)
  });

    
  this.keyCloakService.getUserProfile().then(async (data: any) => {
    this.userID = data.id;
  

//@ts-ignore
this.existingScenarios = await this.datahubService.getDocData(this.userID);




});

}


constructor( private keyCloakService: KeycloakOperationService,
  private datahubService: DatahubService, public storageService: Storageservice, private dataDoc: DataDocLeafletComponent) { 



  }
  
}
