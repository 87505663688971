

<div >
    <div>
    </div>

    <div style="display: flex; flex-direction: column; justify-content: center;">

        <div class="info">
            <span class="span0"> Digitaler Planungszwilling. </span> <span class="span1"> Wähle deinen Landkreis. </span>
            <br>
            <br>
        </div>


        <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; width: 95vw;">
            <div class="map-cont">
                <div class="map" leaflet [leafletOptions]="options" [leafletLayers]="layers"
                    (leafletMapReady)="onMapReady($event)"></div>
                <div style="display: flex; align-items: center; justify-content:space-evenly;">
                    <button (click)="removeLK()" *ngIf="isSecondLevel" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
                        <mat-icon>arrow_back</mat-icon>
                    </button>
                    <h3 style="margin-left: 48%"> {{region}} </h3>
                </div>
            </div>


            <div  *ngIf="selectedObject" id="infoLK" style="width: 30%">

   

                <div *ngIf="selectedObject"  style=" height: 80%; width: 100%;">

                    <div id="logoLK" style="display: flex; align-items: center; margin-bottom: 8%;" > 

                        
                <img *ngIf="selectedObject.Logo_URL" src="{{ selectedObject.Logo_URL }}" width="50px"  style="margin-left: 15px;"/>

               
                <div style="margin-left: 5%;"> 
                    <span class="span0" style="color: white;"> {{ selectedObject.Object_Name}}</span> <br>
                </div>
                    

                </div>

                    <div id="infoOneLK">



                           

                            <span class="span2" style="color: black;">  Bevölkerung: {{ selectedObject["Bevoelkerung gesamt"]}} Einwohner </span> <br>
                                <span class="span2" style="color: black;"> Bodenfläche: {{ selectedObject["Bodenflaeche gesamt qkm"]}} km²</span> <br>
                                    <span class="span2" style="color: black;"> Durchschnittsalter: {{ selectedObject["Durchschnittsalter der Bevoelkerung"]}} Jahre</span> <br>



                    </div>

                </div>



                <br>
                <br>


                <button mat-raised-button style="background-color: #10684E; color: white" (click)="test()"
                    *ngIf="selectedObject"> Landkreis festlegen </button>

            </div>

        </div>

    </div>
</div>


<br>

<br>
