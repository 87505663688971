

<button class="btn" (click)="shoPlotly = !shoPlotly">  Change </button>

<div *ngIf="shoPlotly" style="display: flex; justify-content: space-around;">


  <textarea id="w3review" name="w3review" rows="100" cols="80" [(ngModel)] = 'graphString'>
  </textarea>

  <button class="btn" (click)="updateChartPlot()"> Update Chart </button>

<plotly-plot  *ngIf = "shoPlotly" [data]="graph.data" [layout]="graph.layout" [config]="config"></plotly-plot>


</div>




<div *ngIf="!shoPlotly" style="display: flex; justify-content: space-around;">

<textarea id="w3review" name="w3review" rows="100" cols="80" [(ngModel)] = 'chartString'>
</textarea>
<div>


<button class="btn" (click)="updateChart2()"> Update Chart </button>

</div>

<div class="chart">
  
    <canvas baseChart #chartCanvasResult [data]="chartResultConfig.data" [options]="chartResultConfig.options"
      [type]="chartResultConfig.type">
    </canvas>
  
  </div>


      
</div>