import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GibietParamsDialogComponent } from 'src/app/components/datahub2/gebiete/gibiet-params-dialog/gibiet-params-dialog.component';

@Component({
  selector: 'app-mobi-analyse',
  templateUrl: './mobi-analyse.component.html',
  styleUrls: ['./mobi-analyse.component.css']
})
export class MobiAnalyseComponent {
hh: any =  6;
mm: any = 0;
bestBoys: any = ['06:00', '07:00', '08:00', '09:00'];
selTime: any;

  constructor(   @Inject(MAT_DIALOG_DATA) public data: any ,     public dialogRef: MatDialogRef<GibietParamsDialogComponent>,) {
    
  }


  endDialog() {
    this.dialogRef.close({success: true})

  }

}
