import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ChatService } from 'src/app/service/chatService/chat.service';
import { DatahubService } from 'src/app/service/datahubservice/datahubservice.component';
//import { DashboardService } from 'src/app/service/dashboardService/dashboard.service';
import { KeycloakOperationService } from 'src/app/service/keycloakService/keycloak-operation.service';
import { RegioService } from 'src/app/service/regioservice/regioservice.component';


@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.css']
})
export class ShareDialogComponent {

  userList:any;
  checkedUsers: Set<number> = new Set();
  currentUser:any
  dashboardId:string =''
  getVersion:number=0
  messageContent: string = '';
  formattedMessage: SafeHtml = ''; 
  additionalText: string = ''; 

  constructor(
    public dialogRef: MatDialogRef<ShareDialogComponent>,
    private keyCloakService: KeycloakOperationService,
    private chatService: ChatService,
    private datahubService: DatahubService,
    private route:ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.keyCloakService.getUsersList().subscribe((users: any) => {
      this.userList=users
    });

    this.keyCloakService.getUserProfile().then((data: any) => {
      this.currentUser= data;
      console.warn("DATA", data)
    });
    
    //get route if you are not in the component that is rendered using router-outlet
    this.route.firstChild!.params.subscribe(
      (params: any) => {
          if (params.hasOwnProperty('id') != '') {
              this.dashboardId = params['id']
              this.getVersion = params['version'];

              this.prepareMessage();

          } 
      });
  if(!data.additionalText) {
    this.additionalText = "Schaue dir folgende Analyse an: " + 'http://localhost:4200/analysen/reachability/' + data.id

  } else {
    this.additionalText = data.additionalText
  }


  }

  prepareMessage() {
    const url = window.location.href;
    /*
  
    this.dashboardService.getDashboard(this.dashboardId, this.getVersion).subscribe((response: any) => {
      console.log(response);
      const dashboardName = response.data.savedName;
      let allIndicators:any = [];
  
      const collectIndicators = (topics: any[], label: string) => {
        topics.forEach((topic: any) => {
          if (topic.checked) {
            topic.data.forEach((indicator: any) => {
              if(indicator.checked) allIndicators.push(` <li>${indicator.Name} (${label})</li> `);
            });
          }
        });
      };
      collectIndicators(response.data.topicsLandkreis, 'Landkreis');
      collectIndicators(response.data.topicsVerbandsgemeinde, 'Verbandsgemeinde');
      
      const indicatorList = allIndicators.join('');
      const messageTemplate = `Ich habe das Dashboard <a href="${url}" target="_blank" style="text-decoration: none; color: cornflowerblue; font-weight: bold;">${dashboardName}</a> mit dir geteilt. Das Dashboard hat folgende Indikatoren: <ul>${indicatorList}</ul>`;
      this.messageContent = messageTemplate;
      this.formatMessage();
    }); */
  }
  

  formatMessage() {
    const formattedContent = this.sanitizer.bypassSecurityTrustHtml(this.messageContent);
    this.formattedMessage = formattedContent;
  }

  async apply(){

    var finalMessage = this.messageContent + ' ' + this.additionalText.trim();
 /*   this.dashboardService.updateCanSeeDashboard(this.checkedUsers,this.dashboardId).subscribe((response)=>{
        console.log(response);
      })
*/
const stringArray = [...this.checkedUsers].map(num => num.toString());

if(this.data.skipCheck) {
  this.dialogRef.close();
  return
}


var res : any= await this.datahubService.updateCanRead( this.data.id, stringArray , this.currentUser.id)

if(res.error) {
  alert(res.error.message);
  this.dialogRef.close();
  return;

}

console.warn("RES", res)

    this.checkedUsers.forEach(userId => {
      this.chatService.joinRoom(this.currentUser.id, userId);
      const message = {
        from: this.currentUser.id, 
        to: userId,
        content: finalMessage,
        timestamp: new Date() 
      };
      this.chatService.saveMessage(message).subscribe((result:any) => {
        console.log(`Message sent to user ${userId}:`, result);
      });
    });

    this.dialogRef.close();
  }

  toggleUser(userId: number, isChecked: boolean) {
    if (isChecked) {
      this.checkedUsers.add(userId);
    } else {
      this.checkedUsers.delete(userId);
    }
  }

  closeAll(){
    this.dialogRef.close();
  }

}
