<div style="padding-top:40px">
  <div class="folder-sticker">
    <span class="mainDatahub"> Analysen. Notfallinfrastruktur.</span>
  </div>

  <div style="display: flex; flex-direction: column; justify-content: space-between; ;">

    <div class="auswahl">

      <div id="second-page" *ngIf="selectedItem">

        <app-notfallinfrastruktur-item *ngIf="start && selectedItem  && !edit" [item]="selectedItem">
        </app-notfallinfrastruktur-item>

      </div>

      <div *ngIf="!selectedItem" [style.height]="selectedItem  ? '0vh' : '100vh'"
        style=" display: flex; flex-direction: row; " id="first-page">

        <div
          style="display: flex; justify-content:center; align-items:center;flex-direction:column;gap:10px; border-right:1px solid #ccc; padding-right:10px;">

          <div>
            <input id="adress2" [(ngModel)]="searchBox" />
          </div>

          <button mat-fab extended color="primary" (click)="selectedItem = undefined; startAnalyse()">
            <mat-icon>add</mat-icon> Analyse hinzufügen </button>
        </div>

        <div id="left-container">

          <div style="padding: 1%; display: flex;     overflow-y: scroll;">

            <div *ngFor="let item of storedObject" (click)="clickItem(item)" id="tile-background"
              [style.background-color]="selectedItem  == item ? 'lightgray' : 'white'">
              <div class="pad2" *ngIf="filterItem(item)"
                [style.background-color]="selectedItem  == item ? '#D4E5C6' : 'white'">

                <div style="border-bottom: 3px solid gray;">
                  <mat-icon>analytics</mat-icon>
                  <span class="span3">{{item.name }} </span>

               
              

                </div>

                <div >
                  <span class="span3" style="margin-left: 2%;">  Zeitraum: {{item.startYear }} - {{item.endYear }} </span>

                  <span class="span3"  style="margin-left: 2%;"> Infrastruktur:  {{item.notfallPop }} </span>

                  <span class="span3"  style="margin-left: 2%;">  Population:  {{item.pop }} </span>
                </div>


              </div>

            </div>

          </div>

        </div>

      </div>










    </div>







  </div>


  <dialog id="delete-dialog">
    <h1> Nachfragemodell {{ this.selectedItem?.name }} löschen? </h1>


    <div style="display: flex; justify-content: space-around;">

      <button mat-fab extended color="primary" (click)="deleteSelectedItem()"> Ja </button>
      <button mat-fab extended color="secondary" (click)="closeDeleteDialog()"> Nein </button>
    </div>




  </dialog>





</div>