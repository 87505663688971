import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Chart, ChartConfiguration, Plugin } from 'chart.js';
import { DatahubService } from 'src/app/service/datahubservice/datahubservice.component';
import { LlamaComponent } from 'src/app/service/llama/llama.component';
interface YearlyTypeStats {
  [year: number]: {
    [typ: string]: { count: number; totalCapacity: number };
  };
}

@Component({
  selector: 'app-nachfrageberechnung-item',
  templateUrl: './nachfrageberechnung-item.component.html',
  styleUrls: ['./nachfrageberechnung-item.component.css']
})




export class NachfrageberechnungItemComponent implements OnInit, OnChanges{
typeMessage: any;

fullText: string = "Hallo! Das ist ein Beispiel für Text, der langsam angezeigt wird.";
displayedText: string = ''; // Der Text, der in innerHTML angezeigt wird
speed: number = Math.floor(Math.random() * 21) + 10;
; // Geschwindigkeit in Millisekunden pro Buchstabe
currentIndex: number = 0;
checkIfDraw(): any {

  return this.messages.length > 2 && this.fullText == this.displayedText && ((this.messages[this.messages.length-1].content.includes("66,05") || this.messages[this.messages.length-1].content.includes("66.05") ) )
}
wait: any = false;
  async sendAnswer() {

      this.wait = true;
 
     
        this.messages.push({
          content: this.textinput,
          role: 'user'
        })
        this.textinput = ''
      
        var answer =  await this.llamaService.addMessages(this.messages)
        //@ts-ignore
        answer = answer.message.content 
 //@ts-ignore
        this.fullText = answer; 
        this.typeText()

      
      this.wait = false;
      if(answer) {
      this.messages.push({
                //@ts-ignore
        content: answer,
        role: 'assistant'
      })
    }

    

}
  delay: number = 0;
  finalStep = false; 
textinput: any = "Wie sind die prognostizierten Werte der Arztdichte für Allgemeinmediziner für die nächsten Jahre zu interpretieren? Wie steht der Burgenlandkreis im Vergleich zu anderen Landkreisen dar?";
userMessage: any = '';
printMessage2: any = '';
mockMessage1 = "Hallo, ich kann dich als AI-Bot unterstützen deine Daten zu analysieren. Mit meiner Intelligenz analysiere ich den Datensatz und vergleiche diesen mit verschiedenen Datenquellen. Wie kann ich behilflich sein?"


messages = [{
  content: this.mockMessage1,
  role: 'assistant'
}];
  async nextStepAI() {



  var string1 = "Ich habe folgende JSON Datei: " + JSON.stringify(this.item["Arztdichte"].chartData) +".Wir sehen hier die Anzahl der Ärzte in der Allgemeinmedizin je 100 000 Einwohner. Die Werte sind für " + this.item["Arztdichte"].chartData.labels.length + " Jahre."
  var string2 =  "Und zwar folgende Jahre: " + this.item["Arztdichte"].chartData.labels + ". Wir betrachten im Parameter datasets 3 Datenreihen. Die stehen für Modelle die in den einzelnen datasets im Label stehen. Kannst du die Daten vergleichend interpretieren?"
  var addon = "Arbeite am liebsten mit Absätzen in deiner Antwort. Der Burgenlandkreis gehört zum Bundesland Sachsen-Anhalt. Der Durchschnitt der Allgemeinmediziner-Dichte pro 100.000 Einwohner für alle Landkreise in Sachsen-Anhalt betrug im Jahr 2023 66.05. du hast diesen Wert aus der Quelle: Kassenärztliche Bundesvereinigung,  https://gesundheitsdaten.kbv.de/cms/html/16402.php) erhalten." 
 var addon1 = "Leite deine Antwort immer mit diesem Satz ein: Die durchschnittliche Ärztedichte für gesamt Sachsen-Anhalt betrug laut Kassenärztliche Bundesvereinigung im Jahr 2023 66,05"
  var t =  await this.llamaService.addChat(string1 + string2 + addon ) ; //this.textinput;
  //@ts-ignore
  this.printMessage2 = t.message.content; 
  //@ts-ignore
  //this.userMessage = t.message.content
  console.warn(this.userMessage)
  //@ts-ignore
  //this.printMessage2 =  t.message.content


  this.messages.push({
    content: string1 + string2 + addon + this.textinput,
    role: 'user'
  })


  return   this.printMessage2 ;




}

typeText(): void {




  if (this.currentIndex < this.fullText.length) {
    this.displayedText += this.fullText.charAt(this.currentIndex);
    this.currentIndex++; 
    
    setTimeout(() => this.typeText(), this.speed);
  }
}






transform(value: string): string {
  var temp =  value
    // Replace double asterisks with <h2>
    .replace(/\*\*(.*?)\*\*/g, '<h2>$1</h2>')
    // Replace single asterisks with <strong>, including text that has spaces inside the asterisks
    .replace(/\*(.*?)\*/g, '<strong>$1</strong>');


    temp = temp.replaceAll('*', '');

    return temp; 
}

finalStepAI() {
  this.wait = true; 



  console.warn("chart",  this.chartObject?.data.datasets)

  let maxLength = 0;


  this.chartObject?.data.datasets.forEach((dataset: { data: string | any[]; }) => {
    if (dataset.data.length > maxLength) {
      maxLength = dataset.data.length;
    }
  });



  var dataArray = Array(maxLength).fill(66.05);
  var line =  { label: 'Dichte_2023', data:  dataArray , backgroundColor: 'red', borderColor: 'red', borderWidth: 2,  type: 'line' }
//@ts-ignore
  this.chartObject?.data.datasets.push(line);
  this.chartObject?.update(); 

  //

  setTimeout(() => {
    this.wait = false
    this.closeDialog();
  }, 2000)


}

 printMessage = ""
 mockMessage2 = "Wie ich sehe analysierst du die Arztdichte im Burgendlandkreis. Das Burgenlandkreis gehört zum Bundesland Sachsen-Anhalt. Der Durchschnitt der Allgemeinmediziner-Dichte pro 100.000 Einwohner für alle Landkreise in Sachsen-Anhalt betrug im Jahr 2023 66.05 (Quelle: Kassenärztliche Bundesvereinigung,  https://gesundheitsdaten.kbv.de/cms/html/16402.php). Soll dieser Wert als horizontale Linie in die entsprechende Grafik eingezeichnet werden, damit du es selbst vergleichen kannst?"
  intervalId: any;
closeDialog() {

  this.finalStep = false; 
  this.printMessage = ""; 

    //@ts-ignore
    document.getElementById('ai-dialog').close();
  
  
}

openDialog() {

  if(this.chartType  != 'Arztdichte') {
    return;
  }

    //@ts-ignore
    document.getElementById('ai-dialog').showModal();

  // while(this.printMessage.length !=  this.mockMessage1.length) {
    // Simuliere verzögerte Antwort nach 1-3 Sekunden
    this.delay = Math.random() * 200 + 500; // Zufällige Verzögerung zwischen 1 und 3 Sekunden
    this.intervalId  = setInterval(() => {
      this.delay = Math.random() * 200 + 1000;
      this.printMessage =   this.printMessage + this.mockMessage1.substring(this.printMessage.length, this.printMessage.length + Math.random() * 70);
      if(this.printMessage.length == this.mockMessage1.length) {
        clearInterval(  this.intervalId)
      }
    }, this.delay);
  //}
  
  

}
  
    chartIndex: any = 0;
createChartForPop(popInfo: any) {

this.selectedPop =  popInfo;

}
chart: any;
popChart: any;

@Input() item: any;
  chartObject:  any;
  chartType: string = 'Absolute Nachfrage';
popType: any = 'DOC';
  chartObjectSecond: any;
    selectedDocPop: string | undefined;
chartPopAvailable: any;
selectedPop: any;

constructor(private datahubService: DatahubService, private llamaService: LlamaComponent){
 // this.messages = []


this.messages = [{
  content: this.mockMessage1,
  role: 'assistant'
}];

this.wait = false; 

this.textinput = "Wie sind die prognostizierten Werte der Arztdichte für Allgemeinmediziner für die nächsten Jahre zu interpretieren? Wie steht der Burgenlandkreis im Vergleich zu anderen Landkreisen dar?";
  setTimeout(() => {
    this.createBarChart('Absolute Nachfrage');
    this.createCategoryBarChart(this.item.selectedScenarios[0].name); 
    if(this.messages.length == 1) {
    this.createEmbedding()
    }
  }, 500) 


}
  ngOnChanges(changes: SimpleChanges): void {

    this.wait = false; 
    this.messages = [{
      content: this.mockMessage1,
      role: 'assistant'
    }];
    this.textinput = "Wie sind die prognostizierten Werte der Arztdichte für Allgemeinmediziner für die nächsten Jahre zu interpretieren? Wie steht der Burgenlandkreis im Vergleich zu anderen Landkreisen dar?";



  //  this.messages = []
    setTimeout(() => {
      this.createBarChart('Absolute Nachfrage');
      this.createCategoryBarChart(this.item.selectedScenarios[0].name); 
      if(this.messages.length == 1) {
     this.createEmbedding(); }
      //alert("bonsoir")
    }, 200) 
  }
  createEmbedding() {
    var string1 = "Ich habe folgende JSON Datei: " + JSON.stringify(this.item["Arztdichte"].chartData) +".Wir sehen hier die Anzahl der Ärzte in der Allgemeinmedizin je 100 000 Einwohner. Die Werte sind für " + this.item["Arztdichte"].chartData.labels.length + " Jahre."
    var string2 =  "Und zwar folgende Jahre: " + this.item["Arztdichte"].chartData.labels + ". Wir betrachten im Parameter datasets 3 Datenreihen. Die stehen für Modelle die in den einzelnen datasets im Label stehen. Kannst du die Daten vergleichend interpretieren?"
    var addon = "Der Burgenlandkreis gehört zum Bundesland Sachsen-Anhalt. Der Durchschnitt der Allgemeinmediziner-Dichte pro 100.000 Einwohner für alle Landkreise in Sachsen-Anhalt betrug im Jahr 2023 66.05. du hast diesen Wert aus der Quelle: Kassenärztliche Bundesvereinigung,  https://gesundheitsdaten.kbv.de/cms/html/16402.php) erhalten." 
    var addon1 = "Kannst du zum Schluss anbieten, den Durchschnittswert von 66.05 als horizontale Linie auf einer Chart einzuzeichnen?"
    this.messages.push({
        content: string1 + string2 + addon + addon1,
        role: "user"

    })
  }
    async ngOnInit(): Promise<void> {
     
       var result =  await this.datahubService.postGetPopInfos(this.item.selectedPop)
     
       //@ts-ignore
       this.chartPopAvailable = result

       this.selectedDocPop =  "basis_test"//this.chartPopAvailable.charts_available[0]



    }


createCategoryBarChart(selectedDocPop: string) {

  const statsPerYear: YearlyTypeStats = {};



    this.selectedDocPop = selectedDocPop;

    if(this.chartObjectSecond) {
        this.chartObjectSecond.destroy(); 
      } 
  // 1. Zählen der Einrichtungen pro Kategorie
  const categoryCounts: any = {};
  console.log("Scen", this.item.selectedScenarios)

  var selDocPop = this.item.selectedScenarios.filter((el: { name: string; }) => el.name == selectedDocPop);
  if(selDocPop.length == 0 ) {
    return;
  }
 var filteredPop =   selDocPop[0].pop.filter((el: { Typ: string; }) => el.Typ == "Allgemeinmedizin");


 filteredPop .forEach((obj: { active: number[]; Typ: string | number; capacity: any[]; }) => {
    obj.active.forEach((jahr: number, index: number) => {
      if (!statsPerYear[jahr]) {
        statsPerYear[jahr] = {};
      }
      if (!statsPerYear[jahr][obj.Typ]) {
        statsPerYear[jahr][obj.Typ] = { count: 0, totalCapacity: 0 };
      }
      statsPerYear[jahr][obj.Typ].count++;
      statsPerYear[jahr][obj.Typ].totalCapacity += obj.capacity[index] || 0;
    });
  });

  // Schritt 2: Daten für Chart.js formatieren
const years = Object.keys(statsPerYear).map(Number);
const types = Array.from(new Set(filteredPop.map((obj: { Typ: any; }) => obj.Typ)));

// Datensätze für Anzahl der Objekte und Kapazität erstellen
const datasets = types.flatMap((typ: any) => {
  // Datensatz für Anzahl der Objekte
  const countDataset = {
    label: `${typ} - Anzahl`,
    data: years.map((year) => statsPerYear[year][typ]?.count || 0),
    backgroundColor: this.getRandomColor(),
    stack: 'Anzahl',  // Gruppiert die "Anzahl"-Balken
  };

  return [countDataset];
});
  

this.popChart = document.getElementById("PopChart") as HTMLCanvasElement;

console.warn("DD", datasets[0])

this.chartObjectSecond = new Chart(this.popChart, {
  type: 'bar',
  data: {
    labels: years,
    //@ts-ignore
    datasets: datasets,
  },
  options: {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Jahr',


        },
        stacked: true,
      },
      y: {
        title: {
          display: true,
          text: 'Anzahl / Kapazität',
        },
        beginAtZero: true,
        stacked: true,
      },
    },
  },
});

  return;
}

getRandomColor(): string {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

  // Funktion zum Erstellen des Diagramms
   createPopChart(index: any): void {

    console.warn("pop ava", this.chartPopAvailable)
    if(! this.chartPopAvailable) return
  
    this.chartIndex = index; 
    const currentChart = this.chartPopAvailable.chartJS_data_collection[index].chart
    if(this.chartObjectSecond) {
        this.chartObjectSecond.destroy(); 
      } 
    
    const chartConfig: ChartConfiguration = {
      type: 'line',
      data: {
        labels: currentChart.chartData.labels,
        datasets: currentChart.chartData.datasets.map((dataset: { label: any; data: any; backgroundColor: any; borderColor: any; }) => ({
          label: dataset.label,
          data: dataset.data,
          backgroundColor: dataset.backgroundColor,
          borderColor: dataset.borderColor,
          fill: false
        }))
      },
      options: {
        scales: {
          x: {
            title: {
              display: true,
              text: currentChart.chartMeta["x-axis"]
            }
          },
          y: {
            title: {
              display: true,
              text: currentChart.chartMeta["y-axis"]
            }
          }
        },
        plugins: {
          title: {
            display: true,
            text: currentChart.chartMeta.title
          }
        }
      }
    };

    //const ctx = document.getElementById('popChart') as HTMLCanvasElement;

    this.chartObjectSecond = new Chart(    this.popChart, chartConfig);
  }




 createBarChart(type: any): void {

  this.chartType = type;

 this.chart = document.getElementById("IndiChart") as HTMLCanvasElement;

 if(this.chartObject) {
  this.chartObject.destroy(); 
} 

  if (!this.chart) {
      console.error('Canvas element not found!');
      return;
  }

  var chartInfo : any = {};
  chartInfo.chartData = this.item[type].chartData;
  chartInfo.chartMeta = this.item[type].chartMeta;
  const chartData = {
      labels: chartInfo.chartData.labels,
      datasets: chartInfo.chartData.datasets.map((dataset: { label: any; data: any; backgroundColor: any; borderColor: any; }) => ({
          label: dataset.label,
          data: dataset.data,
          backgroundColor: dataset.backgroundColor,
          borderColor: dataset.borderColor,
          borderWidth: 1,
      }))
  };

  this.chartObject = new Chart(this.chart, {
      type: 'bar',
      data: chartData,
      options: {
          responsive: true,
          plugins: {
              title: {
                  display: true,
                  text: chartInfo.chartMeta.title,
              },
              tooltip: {
                  callbacks: {
                      label: function (context) {
                          return `${context.dataset.label}: ${context.raw} ${chartInfo.chartData.unit}`;
                      }
                  }
              },
              
          },
          scales: {
              y: {
                  beginAtZero: true,
                  min: 0,
                  max: chartInfo.chartData.y_max_value + 5,
                  title: {
                      display: true,
                      text: chartInfo.chartData.unit,
                  }
              },
              x: {
                  title: {
                      display: true,
                      text: chartInfo.chartData.label_category,
                  }
              }
          },
       
      }
  });
}

}
