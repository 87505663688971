import { Component } from '@angular/core';

@Component({
  selector: 'app-krankheiten',
  templateUrl: './krankheiten.component.html',
  styleUrls: ['./krankheiten.component.css']
})
export class KrankheitenComponent {
allIndikatoren: any;
setAll(arg0: any) {
throw new Error('Method not implemented.');
}
updateAll(_t9: any) {
throw new Error('Method not implemented.');
}
hasIndikator: any;
confirmIndikatoken() {
throw new Error('Method not implemented.');
}

}
