

<div class="main">

    <p  id="isConfirmed" *ngIf="isConfirmed">Indikator '{{selectedIndi}}' wurde erweitert!</p>
        
    <mat-horizontal-stepper #stepper *ngIf="!isConfirmed">

        <mat-step label="Indikator wählen">
            <p>Welchen Indikator wollen Sie erweitern?</p>
    
                <mat-radio-group [(ngModel)]="selectedIndi">
                    <mat-radio-button *ngFor="let indicator of indicators" [value]="indicator">{{ indicator }}</mat-radio-button>
                </mat-radio-group>

            <div>
              <button mat-button matStepperNext [disabled]="!selectedIndi">Weiter</button>
            </div>
          </mat-step>
      
      <mat-step label="Bevölkerung wählen">
        <p>Welche Bevölkerung wollen Sie hinzufügen?</p>
        <mat-radio-group [(ngModel)]="selectedPopulation" style="height: 170px;overflow-y: scroll;">
          <mat-radio-button *ngFor="let pop of populations" [value]="pop">{{ pop }}</mat-radio-button>
        </mat-radio-group>
        <div>
          <button mat-button  [disabled]="!selectedPopulation" (click)="dataforSlider(stepper)">Weiter</button>
        </div>
      </mat-step>
  
      <mat-step label="Jahresbereich wählen">
        <p>Was ist der Jahresbereich?</p>
        <mat-slider min="{{years[0]}}" max="{{years[years.length - 1]}}" step="1" showTickMarks discrete>
          <input [(ngModel)]="selectedYearRange1" value="{{years[0]}}" matSliderStartThumb>
          <input [(ngModel)]="selectedYearRange2" value="{{years[years.length - 1]}}" matSliderEndThumb>
        </mat-slider>
  
        <div>Ausgewählter Jahresbereich: {{selectedYearRange1}} - {{selectedYearRange2}}</div>
        <div>
          <button mat-button matStepperPrevious>Zurück</button>
          <button mat-button matStepperNext [disabled]="!selectedYearRange1 && !selectedYearRange2">Weiter</button>
        </div>
      </mat-step>
  
      <mat-step label="Bestätigung">
        <p>Indikator '{{selectedIndi}}' erweitern?</p>
        <div>
          <button mat-button matStepperPrevious>Zurück</button>
          <button mat-button (click)="confirmAddition()">Ja</button>
        </div>
      </mat-step>
  
    </mat-horizontal-stepper>
  
    <mat-progress-bar *ngIf="popSpinner" mode="indeterminate" style="position: absolute;bottom: 0;"></mat-progress-bar>
  </div>
  