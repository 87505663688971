import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Chart, ChartConfiguration } from 'chart.js';

@Component({
  selector: 'app-delete-gym',
  templateUrl: './delete-gym.component.html',
  styleUrls: ['./delete-gym.component.css']
})
export class DeleteGymComponent {
  nextStep = false;
time: any;
uniqueSchools: any[] = [];
deleteSchools: any;
@ViewChild('chartCanvas') chartCanvas!: ElementRef;
chartInstance!: Chart;
@ViewChild('chartCanvas2') chartCanvas2!: ElementRef;
chartInstance2!: Chart;


options: any;
affectedBezirke: any;
filteredSchools: any;

  nextStepF() {
    this.nextStep = true;
    setTimeout(() => {
      this.createChart()
      this.createFreeChart()

    }, 1000)

    
  }
save() {

  this.dialogRef.close({endFlg: true})}


constructor(    @Inject(MAT_DIALOG_DATA) public data: any,  public dialogRef: MatDialogRef<DeleteGymComponent> ) {
  console.warn("data", data)

  this.filteredSchools = this.data.schools.features.filter((sch: { properties: { Schulname: any; Variante: string; }; }) => sch.properties.Schulname != data.schoolToBeDeleted && sch.properties.Variante == "SEP 2022 Fortschreibung 2024")
  this.filteredSchools.forEach((element: { properties: { anteil: number; }; }) => {
    element.properties.anteil = 25;
    
  });
 this.deleteSchools = this.data.schools.features.find((sch: { properties: { Schulname: any; Variante: string; }; }) => sch.properties.Schulname == data.schoolToBeDeleted && sch.properties.Variante == "SEP 2022 Fortschreibung 2024")




}

ngAfterViewInit(): void {
  this.createChart();
  this.createFreeChart(); 
}


createChart(): void {
  const ctx = this.chartCanvas?.nativeElement.getContext('2d');
  const config: ChartConfiguration = {
    type: 'bar',
    data: {
      labels: Object.keys(this.deleteSchools.properties['Abgehende SuS']),
      datasets: [
        {
          label: 'Anzahl SuS Neuverteilung',
          data: Object.values(this.deleteSchools.properties['Abgehende SuS']),
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
        },
      ],
    },
   
  };

  this.chartInstance = new Chart(ctx, config);
}







stringToColor(str: string) {
  let hash = 0;
  if (!str) return 'black'

  // Berechne einen Hash-Wert basierend auf dem String
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  // Erstelle eine Farbe aus dem Hash
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF;
    color += ('00' + value.toString(16)).slice(-2);
  }

  return color;
}


  createFreeChart(): void {
    const years = ["2024/25", "2025/26", "2026/27", "2027/28", "2028/29", "2029/30", "2030/31", "2031/32"];

    // Datasets für jede Schule vorbereiten
    const datasets = this.filteredSchools.map((school: { properties: { [x: string]: { [x: string]: any; }; Schulname: any; }; }) => {
      return {
        label: school.properties.Schulname,
        data: years.map(year => school.properties["Freie Kapazitäten"][year]),
        backgroundColor: this.stringToColor(school.properties.Schulname),
        borderColor: this.stringToColor(school.properties.Schulname),
        borderWidth: 1,
      };
    });


    console.warn("DD", datasets);

    const config = {
      type: 'bar',
      data: {
        labels: years,
        datasets: datasets,
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Freie Kapazitäten'
            }
          }
        }
      } 
    };
   if(!this.chartCanvas2) return
    const ctx = this.chartCanvas2.nativeElement.getContext('2d');
    this.chartInstance2 = new Chart(ctx, config);
  }
}

