import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component,Inject, OnInit, AfterViewInit } from '@angular/core';
import * as L from 'leaflet';  // Import Leaflet library
import { KeycloakOperationService } from 'src/app/service/keycloakService/keycloak-operation.service';

@Component({
    selector: 'app-fahrplan-dialog',
    templateUrl: './fahrplan-dialog.component.html',
    styleUrls: ['./fahrplan-dialog.component.css']
})
export class FahrplanDialogComponent implements OnInit,AfterViewInit {
    step=1;
    plan = 1;
    selectedStop: string = '';
    availableStops: string[] = ['Plennschütz', 'Plotha, Ort (Prittitz)', 'Plotha, Schule (Prittitz)'];
    //suggestedStops: string[] = [];
    departureTime: string = '05:35';
    stopsToRemove: number = 1; 
    userList:any;
    currentUser:any
    selectedUserId: string=''

    suggestedStops: string[] = [];
    
    stopData = [
      {
        type: 'Feature',
        properties: {
          stopFacilityName: 'Plennschütz',
        },
        geometry: { type: 'Point', coordinates: [51.154692078,11.919305] }
      },
      {
        type: 'Feature',
        properties: {
          stopFacilityName: 'Plotha, Ort (Prittitz)',
        },
        geometry: { type: 'Point', coordinates: [ 51.153566078, 11.916875] }
      },
      {
        type: 'Feature',
        properties: {
          stopFacilityName: 'Plotha, Schule (Prittitz)',
        },
        geometry: { type: 'Point', coordinates: [ 51.151843078,11.917109] }
      },
      {
        type: 'Feature',
        properties: {
          stopFacilityName: 'Prittitz, Ort',
        },
        geometry: { type: 'Point', coordinates: [ 51.150090078,11.929665] }
      }
    ];

    stopDataRed:any=
      {
        type: 'Feature',
        properties: {
          stopFacilityName: 'Prittitz, Abzweig Bahnhof',
        },
        geometry: { type: 'Point', coordinates: [ 51.154567078,11.930063] }
      }


    isLoading=false;
    private map:any;

    constructor(
        public dialogRef: MatDialogRef<FahrplanDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private keyCloakService: KeycloakOperationService,
    ) {}


    ngOnInit(){
      this.keyCloakService.getUsersList().subscribe((users: any) => {
        console.log(users);
        this.userList=users
      });
  
      this.keyCloakService.getUserProfile().then((data: any) => {
        this.currentUser= data;
        console.warn("DATA", data)
      });
    }

    ngAfterViewInit(): void {
      this.initMap();
    }

    initMap(): void {
      this.map = L.map('map4').setView([51.15288653825398, 11.922965998712606], 15); 
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);
  
      this.stopData.forEach(stop => {
        const marker = L.marker(
          [stop.geometry.coordinates[0], stop.geometry.coordinates[1]],
          {icon: L.divIcon({
            className: 'bus-marker',
            html: '<span class="material-icons" style="font-size:24px; color:black;">directions_bus</span>'
          })
        })
        .addTo(this.map)
        .bindPopup(`<b>${stop.properties.stopFacilityName}</b>`)
        .on('click', () => this.suggestedStops.push(stop.properties.stopFacilityName))
      });

      L.marker(this.stopDataRed.geometry.coordinates,
        {icon: L.divIcon({
          className: 'bus-marker',
          html: '<span class="material-icons" style="font-size:24px; color:red;">directions_bus</span>'
        })
        }
      ).addTo(this.map)

      L.marker([51.15216817615761, 11.917210103975295],
        {icon: L.divIcon({
          className: 'bus-marker',
          html: '<span class="material-icons" style="font-size:24px; color:green;">school</span>'
        })
      }
      ).addTo(this.map)
    }
  
    confirmConversion(stepper: any) {
        this.plan = 2;
        stepper.next();
    }

    removeStopsAndFootpath(stepper: any) {
        this.plan=3;
        stepper.next();
    }

    updateStopList() {
        if (this.suggestedStops.length < 3) {
            this.suggestedStops.push(this.selectedStop);
        }
    }

    test(stepper:any){
      this.plan=4;
      stepper.next();
    }

    finalizePlan(stepper:any) {
      this.plan=5;
    }

    comp(){
      this.step=2;
    }

    pvgAntwort(){
      this.isLoading=true;
      setTimeout(() => {
        this.isLoading=false;
        this.step=3;
      }, 2000);
    }
    
}
