import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TransitService {
  private apiUrl 

  constructor(private http:HttpClient,) {
    const useLocal = true;
    this.apiUrl = useLocal ? 'http://localhost:3000/transit' : 'https://dashboardservice.cephlabs.de/transit';
   }

   getTimeTable(route: string, departure: string){
    const body = { route, departure };
    return firstValueFrom(this.http.post<any>(`${this.apiUrl}/getTimeTable`, body));
  }
}