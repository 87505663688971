import { Component } from '@angular/core';

@Component({
  selector: 'app-schulplanung-overview',
  templateUrl: './schulplanung-overview.component.html',
  styleUrls: ['./schulplanung-overview.component.css']
})
export class SchulplanungOverviewComponent {

}
