<app-loading-overlay *ngIf="isLoading"></app-loading-overlay>
<div class="map-container" style="height: 100%">
    <div class="map" id="map1"></div>
</div>

<div class="tabCharts" id="tabCharts" #tabCharts>
    <div class="header" (click)="toggleCollapse()">
        <span class="header-text">{{ isCollapsed ? 'vergrössern' : 'verkleinern' }}</span>
    </div>
    <div class="chart-container">
        <mat-tab-group (selectedTabChange)="onTabChange($event)">
            <mat-tab label="Ortsteil">
                <canvas id="ortsteilChart" style="height: 450px;width:900px;"></canvas>
            </mat-tab>
            <mat-tab label="Schulbezirk">
                <canvas id="schulbezirkChart" style="height: 450px;width:900px;"></canvas>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

<div class="legend">
    <h4>Durchschnittliche Reisezeit (min)</h4>
    <div class="legend-box">
        <div class="legend-item">
            <span class="color-box" style="background-color: #00FF00;"></span>
            <span>0-10</span>
        </div>
        <div class="legend-item">
            <span class="color-box" style="background-color: #ADFF2F;"></span>
            <span>10-20</span>
        </div>
        <div class="legend-item">
            <span class="color-box" style="background-color: #FFD700;"></span>
            <span>20-30</span>
        </div>
        <div class="legend-item">
            <span class="color-box" style="background-color: #FFA500;"></span>
            <span>30-40</span>
        </div>
        <div class="legend-item">
            <span class="color-box" style="background-color: #FF6347;"></span>
            <span>40-50</span>
        </div>
        <div class="legend-item">
            <span class="color-box" style="background-color: #FF4500;"></span>
            <span>50-60</span>
        </div>

        <div class="legend-item">
            <span class="color-box" style="background-color: #FF0000;"></span>
            <span>60+</span>
        </div>
    </div>
</div>


<div class="otherScenario">

    <p>
        basierend auf Ihre Veränderung ! 
    </p>

    <ul>
        <li>neu errichtung grundschule plotha</li>
        <li>neu zuweisung Schulbezirke</li>
    </ul>

    <button *ngIf="newScenario=='initial'"  mat-raised-button color="primary" (click)="changeSzenario()"> Zum modellierten Szenario <mat-icon color="white">edit_note</mat-icon></button>
    <button *ngIf="newScenario=='new'"  mat-raised-button color="primary" (click)="Mob()"> Zum Mobilität <mat-icon color="white">bus_alert</mat-icon></button>
</div>

  