import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component,Inject, OnInit, AfterViewInit } from '@angular/core';
import { KeycloakOperationService } from 'src/app/service/keycloakService/keycloak-operation.service';

@Component({
  selector: 'app-umbau-mobility-dialog',
  templateUrl: './umbau-mobility-dialog.component.html',
  styleUrls: ['./umbau-mobility-dialog.component.css']
})
export class UmbauMobilityDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<UmbauMobilityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private keyCloakService: KeycloakOperationService,
) {}

userList:any;
currentUser:any
selectedUserId: string=''

ngOnInit(){
  this.keyCloakService.getUsersList().subscribe((users: any) => {
    console.log(users);
    this.userList=users
  });

  this.keyCloakService.getUserProfile().then((data: any) => {
    this.currentUser= data;
    console.warn("DATA", data)
  });
}

close(){
  this.dialogRef.close();
}

}
