<div class="main">
    <h2>Ertüchtigung eines Straßenabschnitts Voraussichtlich erforderlich</h2>

    <mat-form-field appearance="fill">
        <mat-label>Benutzer auswählen</mat-label>
        <mat-select [(ngModel)]="selectedUserId">
          <mat-option *ngFor="let user of userList" [value]="user.id">
            {{ user.username }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="selectedUserId">
        <p>
        Die von Ihnen definierte Route führt über einen landwirtschaftlichen Weg, welcher keine klassifizierte Straße ist. Die Länge des Streckenabschnitts beträgt 6 km.
        </p>

        <p>
        Die Nutzung des Streckenabschnitts für die Schülerbeförderung setzt eine Ertüchtigung im Sinne einer Straßenbreitenvergrößerung sowie die Aufstufung zur Gemeindestraße voraus.
        </p>    
      </div>


      <div>
        <button mat-raised-button color="primary" (click)="close()">OK</button>
      </div>
</div>