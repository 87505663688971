<div style="display: flex; justify-content: center; flex-direction: column; padding: 10px;">

  <div class="chart-container">


    <div>
      <mat-tab-group>


        <mat-tab label="Einstellungen">
          <div *ngIf="chart" style="display: flex; justify-content: center; align-items: center; height: 7vh;">


            <mat-button-toggle-group name="fontStyle" aria-label="Font Style" value="bar">
              <mat-button-toggle value = "pie"(click)="changeType('pie')">Pie</mat-button-toggle>
              <mat-button-toggle value="bar" (click)="changeType('bar')">Bar</mat-button-toggle>
              
            </mat-button-toggle-group>
          </div>
        </mat-tab>

        <mat-tab label="Optionen">
          <div  style="display: flex; justify-content: center;height: 80px; padding: 10px;width: 700px;gap:50px">
            <button (click)="openDialogShare()" class="mapIcons" style="background-color: #126F62;color: white" mat-mini-fab (mouseenter)="showShareChart=true" (mouseleave)="showShareChart=false" >
              <mat-icon>share</mat-icon>
              <span *ngIf="showShareChart" style="position: absolute;  bottom:-30px ; left: -85px; padding: 4px; color: white; background-color:#585757cc ; border-radius: 8px; box-shadow: 2px 2px 2px black;font-size: 14px; width: 200px;">
                Dashboard teilen
              </span>
            </button>
            
            <button class="mapIcons" style="background-color: #126F62;color: white" mat-mini-fab (mouseenter)="showDownloadChart=true" (mouseleave)="showDownloadChart=false" (click)="showReport()" >
              <mat-icon>save_alt</mat-icon>
              <span *ngIf="showDownloadChart" style="position: absolute;  bottom:-30px ; left: -85px; padding: 4px; color: white; background-color:#585757cc ; border-radius: 8px; box-shadow: 2px 2px 2px black;font-size: 14px; width: 200px;">
                als PDF herunterladen
              </span>
            </button>
            <button class="mapIcons" style="background-color: #126F62;color: white" mat-mini-fab (click)="openDialogData()" (mouseenter)="showTable=true" (mouseleave)="showTable=false" >
              <mat-icon>dataset</mat-icon>
              <span *ngIf="showTable" style="position: absolute;  bottom:-30px ; left: -85px; padding: 4px; color: white; background-color:#585757cc ; border-radius: 8px; box-shadow: 2px 2px 2px black;font-size: 14px; width: 200px;">
                Daten als Tabelle anzeigen
              </span>
            </button>

       
          </div>
        </mat-tab>
      </mat-tab-group>

      <div class="flexBox">

        <div id="chart-container">

          <div style=" width: 35vw; height: 25vh; display: flex; align-items: center;" > 
          <div [hidden]="chartType != 'bar'" style="width: 100%; height: 100%" >
          <canvas id="MyChart">
            {{ chart }}
          </canvas>
          </div>
    
     <div *ngIf="chartType != 'bar'"  style="width: 35vw; height: 25vh;display: flex; align-items: center; justify-content: center;"> 

          <div [hidden]="chartType == 'bar'" style="width: 100%; height: 130%; display: flex; align-items: center; justify-content: center;" >
            <canvas   width="1500" height="300" id="myPieChart"></canvas>
          </div>

        </div>



        </div>


          <div *ngIf="chartType == 'bar'" class="flexBox" style="flex-direction: column; gap: 10px;">

            <div id="chartLegend" class="selectable-legend">
              <div style="display: flex;" *ngFor="let l of chartLegend">

                <div style="width:10px; height: 20px " (click)="l.disabled = !l.disabled; filterAndUpdateChart()">
                  <s *ngIf="l.disabled"> {{ l.label }} </s>
                  <span *ngIf="!l.disabled"> {{ l.label }} </span>

                </div>

              </div>
            </div>
            <div>
              <button button mat-raised-button color="primary" style="margin: 5px;"(click)="deselectAll(false); filterAndUpdateChart()" *ngIf="selectMode"> Alle
                auswählen </button>
              <button button mat-raised-button color="primary"  style="margin: 5px;"(click)="deselectAll(true); filterAndUpdateChart()" *ngIf="!selectMode"> Alle
                abwählen </button>
                <br>

                <button button mat-raised-button color="primary"  style="margin: 5px;" (click)="openDialogBreaks()" *ngIf="!reachComponent.allMode">Breaks</button>

            </div>
          </div>

          <div *ngIf="chartType != 'bar'">
 

            <div id="chartLegend" class="selectable-legend">
              <div style="display: flex;" *ngFor="let l of chartLegend">

                <div style="width:10px; height: 20px " (click)="updatePieChart(); selectedLabel = l.label">
                  <b *ngIf="selectedLabel == l.label"> {{ l.label }} </b>
                  <span *ngIf="selectedLabel != l.label"> {{ l.label }} </span>

                </div>

              </div>
            </div>
            <div>
    
                <button button mat-raised-button color="primary"  style="margin: 5px;" (click)="openDialogBreaks()">Breaks</button>

            </div>
          </div>
        </div>


      </div>


    </div>



  </div>




  <div class="bottom-container">

    <div class="left">


      <div class="list-item" >
        <div style=" display: flex; justify-content: start; align-items: center; width: 60%;">
          Name:
        </div>
        <div class="pad2" (click)=showName(false) style="color:white;font-weight:600; background-color:#10684E; border: none" >
          <div *ngIf="selectedName">
            {{selectedName}}
          </div>
        </div>
      </div>

      <div class="list-item">
        <div style=" display: flex; justify-content: start; align-items: center; width: 60%;">
          Umkreissuche:
        </div>
        
        <div class="pad2" style="color:white;font-weight:600; background-color:#10684E;border: none" >
     
      
          <div *ngIf="selectedRadius"  (click)=showUmkreis() style="margin-right: 2px;">
            {{ selectedRadius}} {{ selectedUnit }}
          </div>


          <div *ngIf="selectedUnit != 'm'" style="border-left: 3px solid white; margin-right: 2px; padding: 2px" (click)="openTransport()"> 
            <mat-icon *ngIf="selectedTransport == 'CAR'"> directions_car</mat-icon> 
            <mat-icon *ngIf="selectedTransport == 'TRANSIT'"> train</mat-icon> 
            <mat-icon *ngIf="selectedTransport == 'BICYCLE'"> directions_bike</mat-icon> 
            <mat-icon *ngIf="selectedTransport ==  'WALK'"> directions_walk</mat-icon> 
     
          
          </div>
        </div>
      </div>

      <div class="list-item">
        <div style=" display: flex; justify-content: start; align-items: center; width: 60%;">
          Einrichtungen:
        </div>
        <div class="pad2" (click)="showEinrichtung()" style="color:white;font-weight:600; background-color:#10684E;border: none" >

         
          <div *ngIf="selectedEinrichtungen">
            {{ selectedEinrichtungen.toString().substring(0,15)  + '..'}}
          </div>
        </div>
      </div>

      <div class="list-item">
        <div style=" display: flex; justify-content: start; align-items: center; width: 60%;">
          Startpunkt(e):
        </div>
        <div class="pad2" style=" height: 80px;color:white;font-weight:600; background-color:#10684E;border: none"(click)=showStandort()>

  
          <div  >
            <b>{{ result?.Starting_Point[0].Name }}|{{ result?.Starting_Point[0].Street_Name }}</b> 
            <span *ngIf="(result?.Starting_Point.length + reachComponent.markersObjRefMap.size )>1">  und {{ result?.Starting_Point.length + reachComponent.markersObjRefMap.size -1 }}  weitere.. </span>

          </div>

     
          <div >   
  


         


            <br>
          </div>

        </div>
      </div>



      <div style="display: flex; justify-content: space-between; margin-top:2px">


        <button *ngIf="checkForModification() && !this.orgObj?.isTemp" mat-button style="background-color: #10684E; color:white" (click)="startAnalyse()">
          <mat-icon>start</mat-icon>
          Analyse starten
        </button>

       <!-- <button *ngIf="this.orgObj?.isTemp && selectedName && checkForModification() &&  this.orgObj?.root && this.orgObj?.root?.Meta[0]?.Name"mat-button style="background-color: #10684E; color:white" (click)="storeItem()">
          <mat-icon>save</mat-icon>
         {{ this.orgObj?.root?.Meta[0]?.Name }} überschreiben
        </button> -->

        <button *ngIf="this.orgObj?.isTemp"mat-button style="background-color: #10684E; color:white" (click)="this.addMode = true; showName(true);saveItem() ">
          <mat-icon>save</mat-icon>
         Neue Datei 
        </button>
     
      </div>





    </div>
    <div class="right">

      <div class="list-item" style="color:white;font-weight:600; background-color:#10684E" >
     <div> 
      <h3> Einrichtungen ({{ getNumberOfFiltered() }}) </h3>

  
    <ng-container *ngIf="result?.Starting_Point.length == 1">
         <ng-container *ngFor="let start of result?.Starting_Point"> {{ start.Street_Name  }} 
       </ng-container>
      </ng-container>


    </div>
    <div style="display: flex; flex-direction: row;" class="scrollable-y"> 

      <div class="pad" 
      [ngStyle]="{'background-color':  ( result.Starting_Point[0].Name == start.Name && result.Starting_Point.length == 1 )? 'green' : '#D4E5C6'}"
      
      style="margin: 10px" *ngFor="let start of orgObj?.Starting_Point; let i = index" (click)="clickResult(orgObj.Results[i], orgObj, result.Starting_Point); "> 
      {{ start.Name  }}
      </div>


      


      
    </div>
    </div>


    <div class="scrollable">
    <div *ngFor="let einrichtung of result?.Einrichtungen_Naehe; let i = index">
      <div class="list-item"
      [ngStyle]="{'background-color':  (selectedEinrichtung == einrichtung || clickedName == einrichtung.Name )? 'green' : 'white'}"
      (click)="clickEinrichtung(einrichtung)"
      *ngIf="checkEinrichtungFilter(einrichtung)">
  
  

      <div style=" display: flex; justify-content: start; align-items: center; width: 60%;"
      [id]="einrichtung.Name">
      {{ einrichtung.Name }} <br>
      {{ einrichtung.Typ }} | {{ einrichtung.Kategorie }}
    </div>
    <div class="pad" *ngIf="einrichtung.Distanz" style="height: 50px">
      {{ (einrichtung.Distanz)?.toFixed(2) }} {{ orgObj?.Meta[0].Unit }}
    </div>
    </div>
    </div>

  </div>


    </div>

  </div>





</div>

<dialog id="einrichtung">
  <div>


    <b> Einrichtungstypen </b>
    <br>
    <br>
    <b> Wählen Sie beliebig viele Einrichtungstypen aus:</b>





    <mat-selection-list [(ngModel)]="selectedEinrichtungen" [multiple]="true">
      <div *ngFor="let metaobj of meta">
        <mat-list-option [value]="item.Typ" *ngFor="let item of metaobj.Typen" style="border-bottom: 2px solid grey;">
          <img src='{{ "../../../../assets/icon/" + item.Icon  }}' width="20px">
          <b> {{item.Typ}} </b>
        </mat-list-option>
      </div>
    </mat-selection-list>



    <div style="display: flex; justify-content: space-between;">

      <span style="margin-right: 0; " class="span2" (click)="saveEinrichtung();closeEinrichtung()"> Übernehmen
        <button mat-mini-fab style="background-color: #10684E; color: white">
          <mat-icon>save </mat-icon>
        </button>

      </span>
    </div>




  </div>

</dialog>


<dialog id="umkreis">

  <div>
    <br>
    <br>
    <br>
    <span class="span0"> Umkreis</span> <br>
    <span class="span0"> Wählen Sie ihren Radius aus: </span>

    <div style="display: flex; justify-content: center; flex-direction: column;">
      <button class="btn2" (click)="umkreisMode = 0; selectedRadius = orgObj?.Meta[0].Distanz "> Via Radius </button>
      <br>
      <div *ngIf="umkreisMode == 0">

        <div style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
          <div> <span class="span0"> {{ selectedRadius }} Meter </span> </div>
          <mat-slider style="width: 90%;" min="0" max="10000" step="1" [(ngModel)]="selectedRadius " discrete>
            <input matSliderThumb (change)="setValue($event, 'radius')">
          </mat-slider>
        </div>


      </div>

      <br>
      <button class="btn2" (click)="umkreisMode = 1; selectedRadius= orgObj?.Meta[0].Distanz ; selectedTransport = 'CAR'"> Via Zeit </button>


      <div *ngIf="umkreisMode == 1">

        <div style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
          <div> <span class="span0"> {{ selectedRadius }} Minuten </span> </div>
          <mat-slider style="width: 90%;" min="0" max="60" step="1" [(ngModel)]="selectedRadius " discrete>
            <input matSliderThumb (change)="setValue($event, 'time')">
          </mat-slider>
        </div>


      </div>



    </div>



    <div style="display: flex; justify-content: space-between;">

      <span style="margin-right: 0; " class="span2" (click)="saveUmkreis(); closeUmkreis()"> Speichern
        <button mat-mini-fab style="background-color: #10684E; color: white">
          <mat-icon>save </mat-icon>
        </button>

      </span>
    </div>

  </div>

</dialog>


<dialog id="transportMode">



  <div  style="display: flex; justify-content: space-around; flex-direction: column; ;">
    <button class="btn2" (click)="selectedTransport =  'CAR'"> Auto </button>
    <button class="btn2" (click)="selectedTransport =  'TRANSIT'"> Öffentliche Verkehrsmittel </button>
    <button class="btn2" (click)="selectedTransport =  'BICYCLE'"> Fahrrad </button>
    <button class="btn2" (click)="selectedTransport =  'WALK'"> Zu Fuß </button>
  </div>

  <div style="display: flex; justify-content: space-between;">

    <span style="margin-right: 0; " class="span2" (click)="closeTransport()"> Übernehmen
      <button mat-mini-fab style="background-color: #10684E; color: white">
        <mat-icon>save </mat-icon>
      </button>

    </span>
  </div>


</dialog>

<dialog id="name">

  <div style="padding: 40px;">
    <br>

    <span class="span0"> Wählen Sie einen Namen aus: </span>
    <br>
    <br>

    <b> Sichtbarkeit </b>

    <mat-chip-listbox [(ngModel)]="selectedSichtbarkeit">

        <div *ngFor="let cat of ['public', 'protected', 'privat']">
            <mat-chip-option> {{ cat }} </mat-chip-option>
        </div>
    </mat-chip-listbox>


    <div>

      <input name="name" placeholder="Name..." style="width: 100%; " [(ngModel)]="selectedName " />

    </div>



    <div style="display: flex; justify-content: space-between;">

      <span style="margin-right: 0; " class="span2" (click)="closeName()"> Speichern
        <button mat-mini-fab style="background-color: #10684E; color: white">
          <mat-icon>save </mat-icon>
        </button>

      </span>
    </div>

  </div>

</dialog>


<dialog id="dialog_mini">
  <div>

    <br>
    <br>
    <br>
    <b> Standort </b>
    <br>
    <br>

    <location-app> </location-app>

    <div style="display: flex; justify-content: space-between;">

      <span style="margin-right: 0; " class="span2" (click)="saveStandort(); closeStandort()"> Speichern
        <button mat-mini-fab style="background-color: #10684E; color: white">
          <mat-icon>save </mat-icon>
        </button>

      </span>
    </div>


  </div>

</dialog>


<dialog id="overwrite">

  <div style="padding: 40px;">
    <br>
    <span class="span0"> Achtung! </span> <br>


    <!--<b *ngIf="this.orgObj && this.orgObj?.root && this.orgObj?.root?.Meta[0]
    && this.orgObj?.root?.Meta[0].Name
    
    "> {{ this.orgObj?.root?.Meta[0].Name }} wird überschreiben. </b>-->

    <b> Objekt wird überschreiben. </b>

    <div style="display: flex; justify-content: space-between;">

      <span style="margin-right: 0; " class="span2" (click)="overwrite()"> Okay
        <button mat-mini-fab style="background-color: #10684E; color: white">
          <mat-icon>save </mat-icon>
        </button>
      </span>


 
    </div>

  </div>

</dialog>

<dialog id="buffer">
  <h3> Berechnung... </h3>

  <div style="padding: 40px;">
    <mat-spinner></mat-spinner>


  </div>

</dialog>