import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nvigation-box',
  templateUrl: './nvigation-box.component.html',
  styleUrls: ['./nvigation-box.component.css']
})
export class NvigationBoxComponent {
  @Input() title?: string;
  @Input() panelTitle?: string;
  @Input() panelText?: string; 
  @Input() boxTexts: string[] = [];
  @Input() boxRoutes: string[] = [];
  
  constructor(private router: Router) {}

  // Method to navigate based on the box index
  navigate(index: number): void {
    const route = this.boxRoutes[index];
    if (route) {
      this.router.navigate([route]);
    }
  }
}
