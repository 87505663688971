import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import L from 'leaflet';
import { HospitalService } from 'src/app/service/hospitalService/hospital.service';
import { RegioService } from 'src/app/service/regioservice/regioservice.component';
import { RoutingserviceComponent } from 'src/app/service/routingservice/routingservice.component';
import { Storageservice } from 'src/app/service/storageservice-component/storageservice-component.component';


interface Hospital {
  Object_key: string;
  name: string;
  only_day_clinic: boolean;
  anzahl_betten_total: number;
  ambulant_fallzahlen_total: number;
  stationaer_fallzahlen_total: number;
  anzahl_fachabteilungen: number;
  geometry: {
    type: string;
    coordinates: number[];
  };
}

interface Disease {
  Unterkategorie: string;
  Hauptkategorie: string;

}

@Component({
  selector: 'app-krankenhaus-dialog',
  templateUrl: './krankenhaus-dialog.component.html',
  styleUrls: ['./krankenhaus-dialog.component.css']
})
export class KrankenhausDialogComponent {

modus: any = "Distance";
umkreisLayer: any;
layerSelection: boolean = false;
endRadius() {
this.radius = 500;
this.radiusSelection = false; 
if(this.locationMarker) {
  this.map.removeLayer(this.locationMarker);
  this.locationMarker = undefined;

}

if(this.umkreisLayer) {
  this.map.removeLayer(this.umkreisLayer);
  this.umkreisLayer = undefined;

}
}

abortRadius() {
this.endRadius() 

this.updatedCache.forEach(el => {
  el.checked = false;
  el.leafletMarkerTemp.setIcon(this.customIcon);
})



}

allKH = false;
krankenhaus: any;

customIconRed = L.icon({
  iconUrl: '../assets/icon/hospital-solid-red.svg', 
  iconSize: [32, 32], 
  iconAnchor: [16, 32], 
  popupAnchor: [0, -32] ,

});
customIcon = L.icon({
  iconUrl: '../assets/icon/hospital-solid.svg', 
  iconSize: [32, 32], 
  iconAnchor: [16, 32], 
  popupAnchor: [0, -32] ,

});

locationIcon = L.icon({
  iconUrl: '../assets/icon/standort.png', 
  iconSize: [32, 32], 
  iconAnchor: [16, 32], 
  popupAnchor: [0, -32] ,

});

map:any;
isLoading:boolean=false;
mapSelection:boolean=false;
MarkerArray:any[]=[];
updatedCache:any[]=[];
diseases: Disease[] = [];
radiusSelection: boolean = false;
locationMarker: L.Marker<any> | undefined = undefined;
radius: number = 500;
umrisse: any[] = []
selectedUmrisse: any[] = []
selectedUmriss: any;




constructor(
@Inject(MAT_DIALOG_DATA) private data: any,
public dialogRef: MatDialogRef<KrankenhausDialogComponent>,
private hospitalService:HospitalService,
private routingService: RoutingserviceComponent,
private storageService: Storageservice,
private regioService: RegioService

) {
  this.krankenhaus = data.krankenhaus
  
}

selectHospitalViaUmriss() {
  console.log(this.selectedUmriss.Object_Key)

  console.log(this.krankenhaus)
  this.krankenhaus.forEach((kh: any) => {
    console.log(kh.raw.Object_Key)
    this.selectedUmrisse.forEach(umriss => {
      if( kh.raw.Object_Key.startsWith(umriss.Object_Key)) {
        kh.checked = true;
        kh.leafletMarkerTemp.setIcon(this.customIconRed);
    
       }
      
    });
 
    
  });

}

endHospitalViaUmriss() {
  this.layerSelection = false
  this.selectedUmrisse = []
  this.umrisse.forEach(l => {
    this.map.removeLayer(l)
  })

  this.umrisse = []

}

async startRadius() {

  var body = {
    "Gesuchter_Typ": [
      "Krankenhaus"  ],
    "Mode": "CAR",
    "Position": [
      {
        "X": this.locationMarker?.getLatLng().lng, //12.132333,
        "Y": this.locationMarker?.getLatLng().lat, //51.048965,
        "Name": "Marker_1",
        }
  ],
    "Radius": this.radius,
    "Type": "Umkreissuche",
    "Filter":this.modus
  }

  if(this.umkreisLayer) this.map.removeLayer(this.umkreisLayer)
  if(this.locationMarker) this.map.removeLayer(this.locationMarker)

try{
var res = await this.routingService.getUmkreisAnalyse(body, "", "", true);
//@ts-ignore
if(res?.Results.length > 0) {
    
  console.log(res)
  //@ts-ignore
  var res2 =  res.Results[0]
  //alle einrichtungen checken
  res2.Einrichtungen_Naehe.forEach((kh: any) => {
    
    var updateKH = this.krankenhaus.find((el : any)=> el.raw.name.toLowerCase().trim() == kh.Name.toLowerCase().trim())
    
    if(updateKH) {
      ///store the ones, the one vvhich has been unchecked in order to reset
      if(!updateKH.checked) {
        this.updatedCache.push(updateKH)

      }
      updateKH.checked = true;
      updateKH.leafletMarkerTemp.setIcon(this.customIconRed);


    } else {
      //console.log("no match found for " , kh.Name  )
    }
  });

 

  res2["Isochron"].forEach((raster: any) => {
    var l = L.geoJSON(raster.geometry,
      {
        style: {
          fillColor: 'green',
          fillOpacity: 0.2,

          color: "black",
          weight: 0.3
        }
      })
    var umkreisString;
    if (raster.Umkreis) {
      umkreisString = raster.Umkreis + " Meter"
    } else {
      umkreisString = (raster.time / 60) + " Minuten "
    }
   
    l.bindPopup("<b> Umkreis: " + umkreisString + "</b>")
   
   
  this.umkreisLayer = l
  this.map.addLayer(l)

    


    this.map.fitBounds(l.getBounds());

 
  });


  this.map.removeLayer(this.locationMarker)
  this.locationMarker = undefined

  this.locationMarker = L.marker( [res2.Starting_Point[0].geometry.coordinates[1], res2.Starting_Point[0].geometry.coordinates[0]  ]   , { draggable: true, icon: this.locationIcon }).addTo(this.map)
  this.locationMarker.on('dragend', () => {//update

    this.startRadius()
  





  })



} 

} catch(er) {
  alert("Anfrage gescheitert")
}




}

closeAndMapSelect(){
  //this.mapSelection = true;
  //this.dialogRef.close({mapSelection: true});

}

closeAndRadiusSelect(){
  this.radiusSelection = true
  this.layerSelection = false
  this.locationMarker = undefined
 // this.dialogRef.close({radiusSelection: true});

}

setAll(arg0: boolean) {
this.krankenhaus.forEach((k: any ) => {k.checked = arg0;
  if(arg0) {
  k.leafletMarkerTemp.setIcon(this.customIconRed);
  } else {
    k.leafletMarkerTemp.setIcon(this.customIcon);
  }
});
}

updateAll(hospital: any) {
  if(hospital.checked) {
    this.map.flyTo(hospital.leafletMarkerTemp.getLatLng())
    hospital.leafletMarkerTemp.setIcon(this.customIconRed);


  } else {
    hospital.leafletMarkerTemp.setIcon(this.customIcon);

  }

}



async setLayer(level: number) {
  var key = this.storageService.getObject().Object_Key
  var l =  await this.regioService.getShapefileByIdAndLevel('15',level);
  console.log(l[0])
  this.umrisse.forEach(umriss => {
    this.map.removeLayer(umriss)
    
  });
  //@ts-ignore
  l[0].forEach((layer : any)=> {

    var lay = L.geoJSON(layer.geometry,
      {
        style: {
          fillColor: 'green',
          fillOpacity: 0.2,

          color: "black",
          weight: 0.3
        }
      })
  
   
    lay.bindPopup("<b> " + layer.Object_Nam + "</b>")
    lay.on('click', () => {
      this.selectedUmriss = layer
      const layerIndex = this.selectedUmrisse.indexOf(layer)

      if( layerIndex == -1) {

      lay.setStyle({
        fillColor: 'red',
        fillOpacity: 0.5,
        color: "black",
        weight: 1
      });
      this.selectedUmrisse.push(layer)


    } else {
      lay.setStyle({
        fillColor: 'green',
        fillOpacity: 0.2,

        color: "black",
        weight: 0.3
      });

      this.selectedUmrisse.splice(layerIndex, 1);


    }
    })
    this.umrisse.push(lay)
   
   
  this.map.addLayer(lay)
    
  });


  
}



async ngOnInit(): Promise<void> {
  this.initMap();

}




public initMap(): void {
  this.map = L.map('map2', {
    center: [51.943889, 11.433056],
    zoom: 7,
  });

  const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 18,
    minZoom: 3,
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
  });

  tiles.addTo(this.map);

  this.map.on('click', (e: any) => {
    if(this.locationMarker ) return

    if(!this.radiusSelection ) return
    
          const lat = e.latlng.lat;
          const lng = e.latlng.lng;
         this.locationMarker = L.marker([lat, lng], { draggable: true, icon: this.locationIcon }).addTo(this.map)



  })



  this.krankenhaus.forEach((hospital: any) => {
    if (hospital.raw.geometry.type === 'Point') {
      const [lng, lat] = hospital.raw.geometry.coordinates;
      
      let marker = L.marker([lat, lng], { icon: this.customIcon })
      .addTo(this.map)
      .bindPopup(`
        <b>${hospital.raw.name}</b>
        <br><b>Betten:</b> ${hospital.raw.anzahl_betten_total}
      `);
      var checked = false;
      if( hospital.checked) {checked = true}
 

    

      marker.on("click", () => {if(true) {
        if(hospital.checked) {
          hospital.checked = false
          marker.setIcon(this.customIcon);
        } else {
        hospital.checked = true
        marker.setIcon(this.customIconRed);
        }
      }})

      hospital.leafletMarkerTemp = marker;



    }
  });



 
}




} 

