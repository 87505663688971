import { Component, OnInit } from '@angular/core';
import { latLng, tileLayer } from 'leaflet';
import { RegioService } from '../../service/regioservice/regioservice.component';
import { GeoJsonObject } from 'geojson';
import * as L from 'leaflet';
import { Router } from '@angular/router';
import { Storageservice } from '../../service/storageservice-component/storageservice-component.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit {
  selectedObject: any;
  test() {
    this.storageService.setObject(this.selectedObject);
    this.router.navigateByUrl('/tileview');
  }

  layers: any[] = [];

  clickedLayer: any;

  AIR_PORTS =
    'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_airports.geojson';

  options = {
    layers: [],
    zoom: 6,
    zoomControl: false,
    attributionControl: false,
    //center: latLng(52, 11.62916),
    center: latLng(51.1657, 10.4515),
  };
  map: any;
  selectedObjectName: any;
  sel: any[] = [];
  data:any;
  region = 'Deutschland'
  isSecondLevel= false;

  constructor(
    private regioservice: RegioService,
    private storageService: Storageservice,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {

    this.regioservice.getJsonData().subscribe((response) => {
      this.data = this.modifyKeys(response);
      console.log(this.data); // You can check the updated data here
    });

    setInterval(() => {
      this.selectedObject = this.storageService.getTempObject();
    }, 1 * 1000);
  }

  modifyKeys(data: any) {
    return data.map((item: any) => {
      return {
        ...item,
        'Bevoelkerung gesamt': item['Ew'],  // Renaming Ew to Bevoelkerung gesamt
        'Bodenflaeche gesamt qkm': item['Fläche.in.km..'],  // Renaming Fläche.in.km.. to Bodenflaeche gesamt qkm
        'Logo_URL': item['Wappen'],
        'Durchschnittsalter der Bevoelkerung': (Math.random() * (48 - 45) + 45).toFixed(2),  // Renaming Wappen to Logo URL
      };
    });
  }

  reset() {
    this.map.flyTo(this.options.center);
    this.map.setView(this.options.center, 6);
    this.selectedObjectName = undefined;
    this.addLayers();
  }

  async addLayers() {
    // Remove all layers currently on the map
    this.map.eachLayer((layer: any) => {
      // Only remove layers that are not the clicked layer
      if (layer !== this.clickedLayer) {
        this.map.removeLayer(layer);
      }
    });
  
    // Get the Germany layers (or relevant layers for your region)
    const res: any = await this.regioservice.getGermanyLayers();
    console.log(res);
  
    // Iterate over each layer and add it to the map
    res.forEach((element: any) => {
      var color = 'white';
  
      // Transform element into valid GeoJSON structure
      const geoJsonFeature: any = {
        type: 'Feature',
        geometry: element.geometry,  // The geometry is already in the correct format
        properties: {
          Object_Key: element.Object_Key,
          Object_Name: element.Object_Name,
          admin_level: element.admin_level,
        },
      };
  
      // Create the GeoJSON layer
      var l = L.geoJSON(geoJsonFeature, {
        style: {
          fillColor: color,
          fillOpacity: 0.5,
          color: 'black',
          weight: 1,
        },
  
        onEachFeature: (f, l) => {
          l.bindPopup("<span class='span0'> " + element.Object_Name + ' </span> ');
        },
      });
  
      l.on('click', () => {
        l.closePopup();
        // If there was a previously selected layer, reset its style
        if (this.clickedLayer != undefined) {
          this.clickedLayer.setStyle({
            fillColor: 'white',
            fillOpacity: 0.5,
          });
        }
  
        // Zoom to the clicked "Bundesland" (adjusting for bounds or coordinates)
        const bounds = l.getBounds(); // Get the bounds of the clicked feature
        this.map.fitBounds(bounds); // Zoom to the bounds of the clicked feature
        

                // Set the clicked layer style

        // this.selectedObjectName = element.properties.Object_Name;
        // console.log('obj');
        // console.log(this.selectedObjectName);
  
        // this.storageService.setTempObject(element.properties);
  
        // Set the clicked layer style
        // l.setStyle({
        //   fillColor: '#D4E5C6',
        //   fillOpacity: 0.5,
        // });
        this.clickedLayer = l;
        console.log(this.clickedLayer);
  
        // Remove all other layers except the clicked one by comparing Object_Key
        this.map.eachLayer((layer: any) => {
          // Compare the Object_Key stored in the layer's properties with the clicked layer's Object_Key
          if (layer.feature && layer.feature.properties.Object_Key !== element.Object_Key) {
            this.map.removeLayer(layer);
          }
        });

        this.region = element.Object_Name

        setTimeout(() => {
          this.addLK(element.Object_Key)
        }, 300);

      });


  
      // Add the layer to the map but initially without removing others
      l.addTo(this.map);
    });
  }
  
  
  async addLK(Object_Key: string) {
    this.isSecondLevel=true;
    if ( Object_Key==='15'){
      // this.map.eachLayer( (layer: any) => {
      //   console.log(layer); 
      //   this.map.removeLayer(layer);
      //   });
  

      var res = await this.regioservice.getShapefilesLandkreisSA();
      console.log(res[0]);
      if(!res[0]) {
        return; 
      }
      //@ts-ignore
      res[0].forEach((element:  any ) => {   
  
  
        var color = "white";
        /*if(element.properties.Object_Name == "Burgenlandkreis") {
          color = "grey"
  
        }*/
        
      var l = L.geoJSON(element.geometry,
        {
          style: {
            fillColor: color,
            fillOpacity: 0.5,
            color: 'black',
            weight: 1
          },
  
          
  
          onEachFeature: (f, l) => {
            //console.log(element.properties); 
            var out : string[] = [];
  
            out.push("Name : " + element.Object_Nam + "<br>"
            
            );
  
            
  
            l.bindPopup("<span class='span0'> " + element.properties.Object_Name + " </span> ");
          } 
  
  
  
        });
  
    
  
  
        l.on('click', () => {
       
         // this.selectedObjectName = element.Object_Nam;
          //this.setSelectedObj(element.Object_Nam, element.Object_Key, this); 
  
    
          if(this.clickedLayer != undefined) {
          this.clickedLayer.setStyle({
            fillColor: "white",
            fillOpacity: 0.5,
          }) }
        
          this.selectedObjectName = element.properties.Object_Name; 
  
          console.log("obj");
          console.log(this.selectedObjectName); 
  
         this.storageService.setTempObject(element.properties); 
  
  
  
  
            l.setStyle({
              fillColor: "#D4E5C6",
              fillOpacity: 0.5,
          
      
            }) 
          this.clickedLayer = l;  
  
          
      }) 
  
        this.layers.push(l); 
  
    })
  
    }

    else{
      
    // Get the layers for the selected Object_Key (e.g., subdivision or region)
    const res: any = await this.regioservice.getLKLayers(Object_Key);
    console.log(res);
    const res2: any = await this.regioservice.getShapefilesLandkreisSA();
    console.log(res2);
    // Iterate over each response element and add the corresponding layer to the map
    res.forEach((element: any) => {
      let color = 'white'; // Default color for the region
  
      // Transform element into valid GeoJSON structure
      const geoJsonFeature: any = {
        type: 'Feature',
        geometry: element.geometry, // The geometry is already in the correct format
        properties: {
          Object_Key: element.Object_Key,
          Object_Name: element.Object_Name,
          admin_level: element.admin_level,
        },
      };
  
      // Create the GeoJSON layer for the element
      const geoJsonLayer = L.geoJSON(geoJsonFeature, {
        style: {
          fillColor: color,
          fillOpacity: 0.5,
          color: 'black',
          weight: 1,
        },
        onEachFeature: (feature, layer) => {
          // Bind a popup to the layer displaying the region name
          layer.bindPopup("<span class='span0'> " + element.Object_Name + ' </span> ');
        },
      });
  
      geoJsonLayer.on('click', () => {
        // If there was a previously selected layer, reset its style to white
        if (this.clickedLayer !== undefined) {
          this.clickedLayer.setStyle({
            fillColor: 'white',
            fillOpacity: 0.5,
          });
        }
  
        // Set the clicked layer's style to the highlight color
        geoJsonLayer.setStyle({
          fillColor: '#D4E5C6', // Highlight the clicked layer with a new color
          fillOpacity: 0.5,
        });
  
        // Set the clicked layer as the selected layer
        this.clickedLayer = geoJsonLayer;

        this.selectedObjectName = element.Object_Name;
        console.log('obj');
        console.log(this.selectedObjectName);

        
  
        if (Object_Key === '03') {
          console.log('ok');
          
          const filteredElement = this.data.find((item: any) => item.Object_Key === element.Object_Key);
          console.log(filteredElement);
          
          if (filteredElement) {
            this.storageService.setTempObject(filteredElement);
          } else {
            console.warn('No matching element found in data for Object_Key 03');
          }
        } else {
          console.log('GSg');
          
          this.storageService.setTempObject(element);
          
        }
  
      });
  
      // Add the GeoJSON layer to the map
      geoJsonLayer.addTo(this.map);
    });
    }
  }
  

  removeLK(){
    this.isSecondLevel = false;
    this.region='Deutschland'
    this.reset();
  }


  setObject(properties: any) {
    this.selectedObject = properties;
    console.log(this.selectedObject);
  }

  async onMapReady(map: any) {
    // Do stuff with map
    this.map = map;
    map.touchZoom.disable();
    map.doubleClickZoom.disable();
    map.scrollWheelZoom.disable();
    map.boxZoom.disable();
    map.keyboard.disable();
    map.dragging.disable();
    this.addLayers();
  }




  setSelectedObj(obj: string, key: string, that: any) {
    this.selectedObjectName = obj;
    this.sel.push(obj);
    console.log(this.selectedObjectName);

    // this.storageService.setObjectKey(key);
    //this.storageService.setObjectName(obj);
  }
}
