<mat-dialog-content>
    <h2 mat-dialog-title>Gespeicherte Berichte</h2>
  
    <mat-list role="list" class="report-list">
      <mat-list-item 
        *ngFor="let report of reports" 
        role="listitem"
        (click)="fetchReportDetails(report._id)"
        class="report-item">
        <div style="display: flex;justify-content:flex-start; align-items:center; gap:10px">
            <mat-icon>description</mat-icon>
            <span>{{ report.titel }}</span>
        </div>
      </mat-list-item>
    </mat-list>
  
    <mat-card *ngIf="selectedReport" class="report-details">
      <mat-card-header>
        <mat-card-title>{{ selectedReport.titel }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>{{ selectedReport.content }}</p>
      </mat-card-content>
    </mat-card>
  </mat-dialog-content>
  
  <mat-dialog-actions align="end">
    <button  (click)="close()" mat-button mat-dialog-close color="primary">auswählen</button>
    
  </mat-dialog-actions>
  