<p>ai-chart works!</p>
<button (click)="updateChart()">
clearButton
</button>

<button (click)="createChart('')">
    testbutton1
    </button>

<div class="chart">
    <h1> title </h1>
    <canvas id="canvas0">{{chart}}</canvas>
</div>