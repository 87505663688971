<h1> Krankenhäuser auswählen </h1>

<div style="display: flex; justify-content: space-evenly; ">

  <button mat-mini-fab style="background-color: #10684e; color: white" (click)="closeAndMapSelect2()">
    <mat-icon>map</mat-icon>
  </button>


  <button *ngIf="!radiusSelection" mat-mini-fab style="background-color: #10684e; color: white"
    (click)="closeAndRadiusSelect()">
    <mat-icon>
      track_changes</mat-icon>
  </button>

  <button *ngIf="getKrankenhausChecked().length > 0 "mat-mini-fab style="background-color: #10684e; color: white" (click)="sendReq()">
    <mat-icon>arrow_forward</mat-icon>
  </button>


  <div *ngIf=" radiusSelection && !layerSelection" class="btn" style="width: 500px; right: 5px">
    <span *ngIf="radiusSelection && !locationMarker"> Markieren Sie einen Startpunkt </span>
    <span *ngIf="radiusSelection && locationMarker">
      Startpunkt markiert - zum ändern Marker verschieben </span> <br>
    <div *ngIf="radiusSelection && locationMarker">
      <span>
        Alle KHs im Umkreis von </span>
      <input placeholder="500" [(ngModel)]="radius">
      <select matNativeControl required [(ngModel)]="modus">
        <option value="Distance">Meter</option>
        <option value="Time">Minuten (Fahrzeit) </option>

      </select>
      <span> auswählen. </span>

      <u *ngIf="radius && !umkreisLayer" (click)="startRadius()"> anwenden </u>
      <u *ngIf="umkreisLayer && (radius != oldReq.Radius || modus != oldReq.Filter)" (click)="startRadius()"> updaten
      </u>

      <u *ngIf="!umkreisLayer" (click)="endRadius()"> abbrechen </u>
      <u *ngIf="umkreisLayer" (click)="abortRadius()"> widerrufen </u>

    </div>
  </div>
  <div *ngIf="layerSelection && !radiusSelection">

    <div *ngIf="layerSelection && umrisse.length == 0">
      <button class="btn" style="margin-left: 10px" (click)="setLayer(1)"> Bundesland einzeichnen </button>
      <button class="btn" style="margin-left: 10px" (click)="setLayer(3)"> Landkreise einzeichnen </button>
      <button class="btn" style="margin-left: 10px" (click)="setLayer(4)"> Verbandsgemeinde einzeichnen </button>
      <button class="btn" style="margin-left: 10px" (click)="setLayer(5)"> Gemeinde einzeichnen </button>
    </div>



    <div *ngIf=" umrisse.length > 0" class="btn" style="width: 45%; right: 5px">
      <span *ngIf="!selectedUmriss"> Umriss auswählen </span>
      <span *ngIf="selectedUmrisse.length>0">
        Alle KHs in

        <span *ngFor="let umriss of selectedUmrisse">
          {{ umriss.Object_Nam }}
        </span>



        selektieren? </span> <br>

      <U *ngIf="selectedUmrisse.length>0 && !hasBeenSelectedUmriss" (click)="selectHospitalViaUmriss()"> ja </U>
      <U style="margin-left: 10px;"*ngIf="selectedUmrisse.length>0 && hasBeenSelectedUmriss" (click)="abertHospitalViaUmriss()"> zurücksetzen </U>

      <U style="margin-left: 10px;"*ngIf="selectedUmrisse.length>0" (click)="endHospitalViaUmriss()"> beenden </U>


    </div>
  </div>

</div>



<pre *ngIf="shoreq">{{ sample | json }}</pre>



    <mat-form-field appearance="outline" style="width: 300px; margin-left: 10px; margin-top: 20px">
      <input matInput placeholder="Geben Sie einen Namen ein..." [(ngModel)]="search" name="name">            
  </mat-form-field>


<div style="display: flex;" [hidden]="shoreq">
  <div class="selection-container"  [hidden]="shoreq">
    <span>
      <mat-checkbox [checked]="allKH" (change)="setAll($event.checked)">
        Alle
      </mat-checkbox> <br>

      <span *ngFor="let k of krankenhaus">
        <div *ngIf="checkSearch(k.name) && k.checked"> 
        <span *ngIf="checkSearch(k.name)">
          <mat-checkbox [(ngModel)]="k.checked" (ngModelChange)="updateAll(k)">
            {{k.name}}
          </mat-checkbox>
        </span> <br >
      </div>
      </span>
      <span *ngFor="let k of krankenhaus">
        <div *ngIf="checkSearch(k.name) && !k.checked"> 
        <span *ngIf="checkSearch(k.name)">
          <mat-checkbox [(ngModel)]="k.checked" (ngModelChange)="updateAll(k)">
            {{k.name}}
          </mat-checkbox>
        </span> <br >
      </div>
      </span>

    </span>

  </div>


  <div>
    <div  [hidden]="shoreq" class="map-container" style="height: 50%; background-color: red; ">
      <div class="map2" id="map2"></div>
    </div>



  </div>
</div>

