import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from 'src/app/service/dashboardService/dashboard.service';
import { KeycloakOperationService } from 'src/app/service/keycloakService/keycloak-operation.service';


@Component({
  selector: 'app-dialog-save',
  templateUrl: './dialog-save.component.html',
  styleUrls: ['./dialog-save.component.css']
})
export class DialogSaveComponent {
  
  choice:string='';
  savedName:string='';
  userProfileId:string='';
  load=false;
  getVersion!:number;
  dashboardId:string='';
  newVersion=false;

  createNewDashboard=false;
  // createNewVersionVar=false;


  constructor(
    private dashboardService:DashboardService,
    private keyCloakService: KeycloakOperationService,
    private route:ActivatedRoute,
    public dialogRef: MatDialogRef<DialogSaveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.keyCloakService.getUserProfile().then((data: any) => {
      this.userProfileId = data.id;
    });
    this.route.firstChild!.params.subscribe(
      (params: any) => {
          if (params.hasOwnProperty('version') != '') {
              this.getVersion = params['version'];
              this.dashboardId= params['id'];
              this.newVersion = true;
              this.createNewDashboard=false;        
          } else{
            this.createNewDashboard=true;
          }
      });

    
  }


  preparationForNesestVersion(){
    this.dashboardService.getDashboard(this.dashboardId,this.getVersion).subscribe((dashboard:any)=>{
      this.data.id = dashboard.data.id;
      this.data.canRead = dashboard.data.canRead;
      this.data.savedName = dashboard.data.savedName;
      this.data.version = dashboard.data.version + 1 ;
    })
  }

  CreatFirstVersion(){

    //check if you are saving the newest version if so than south if not than code the logic 

      //saving the newest version creates a new version:done
      //saving a version that is not the newest one creates a whole new dashboards with its dashboardsID

    this.data.choice = this.choice;
    this.data.savedName = this.savedName;
    this.data.userId = this.userProfileId;
    this.data.canRead = [];
    this.load=true;
    this.dashboardService.postDashboard(this.data).subscribe((res)=>{
        setTimeout(() => {
          this.load=false;
          this.dialogRef.close(this.data);
        }, 1000);
    })
  }

  createNewVersion(){
    this.load=true;
    this.dashboardService.getDashboard(this.dashboardId,this.getVersion).subscribe((dashboard:any)=>{
      this.data.id = dashboard.data.id;
      this.data.canRead = dashboard.data.canRead;
      this.data.savedName = dashboard.data.savedName;
      this.data.version = dashboard.latestVersion + 1 ;
      

      this.data.modified_By=this.userProfileId
      this.dashboardService.createNewDashboardVersion(this.data,this.dashboardId).subscribe((res)=>{
          setTimeout(() => {
            this.load=false;
            this.dialogRef.close(this.data);
          }, 1000);
      })
    })
  }

  closeAll(){
    this.dialogRef.close();
  }
}
