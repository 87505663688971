<div>
  <b>{{ json.name }}</b>
<br><b>Betten:</b> {{ json.anzahl_betten_total }}
<br><b>Ambulante Fallzahlen:</b> {{ json.ambulant_fallzahlen_total }}
<br><b>Stationäre Fallzahlen:</b> {{ json.stationaer_fallzahlen_total }}
<br><b>Fachabteilungen:</b> {{ json.anzahl_fachabteilungen }}

<!-- Optional angezeigte Felder -->
<div *ngIf="json.Total_Cases_Hospital">
  <br><b>Gesamtfälle Krankenhaus:</b> {{ json.Total_Cases_Hospital }}
</div>
<div *ngIf="json.Total_Relevant_Cases_Hospital">
  <br><b>Relevante Fälle Krankenhaus:</b> {{ json.Total_Relevant_Cases_Hospital }}
</div>
<div *ngIf="json.Intra_Hospital_Specialization">
  <br><b>Intra-Hospital-Spezialisierung:</b> {{ json.Intra_Hospital_Specialization }}
</div>
<div *ngIf="json.Inter_Hospital_Specialization">
  <br><b>Inter-Hospital-Spezialisierung:</b> {{ json.Inter_Hospital_Specialization }}
</div>
<div *ngIf="json.Global_Specialization">
  <br><b>Globale Spezialisierung:</b> {{ json.Global_Specialization }}
</div>
<div *ngIf="json.Leistung">
  <br><b>Leistung:</b> {{ json.Leistung }}
</div>
<div *ngIf="json.Fallzahl">
  <br><b>Fallzahl:</b> {{ json.Fallzahl }}
</div>
<br><b>Trägerart:</b> {{ json.traegerart }}

  
      <div>
        <button class="btn" id="btn1" >Umkreisanalyse</button>

      </div>
    </div>