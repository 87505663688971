import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
declare var require: any
const pdfMake = require('pdfmake/build/pdfmake.js');
import pdfFonts from 'pdfmake/build/vfs_fonts';
import html2canvas from 'html2canvas';
import { LlamaComponent } from 'src/app/service/llama/llama.component';
import { RegioService } from 'src/app/service/regioservice/regioservice.component';
import { KeycloakOperationService } from 'src/app/service/keycloakService/keycloak-operation.service';

@Component({
  selector: 'app-report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.css']
})
export class ReportDialogComponent implements OnInit {
  minMaxStr: string = "";
  indakatoren: any[] = [];
  generatedDescriptions: { [key: number]: string } = {}; 
  showActionButton = -1;
  selectedText = '';
  displayedDescriptions: string[] = [];
  storeReportV:any
  titelReport:any=''
  userName:any
  creator:any

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<ReportDialogComponent>,
    private llamaService: LlamaComponent ,
    private regioService: RegioService,
     private keyCloakService: KeycloakOperationService,
  ) {
      this.keyCloakService.getUserProfile().then((data: any) => {
        const firstName = this.capitalizeFirstLetter(data.firstName);
        
        if(data.lastName){
          const lastName = this.capitalizeFirstLetter(data.lastName);
          this.userName = `${firstName} ${lastName}`;}
        else{
          this.userName = `${firstName}`;
        }   
      });    
  }

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  splitAtNearestPeriod(text:any) {
    const middleIndex = Math.floor(text.length / 2);

    // Search forward for a period ('.')
    let splitIndex = middleIndex;
    while (splitIndex < text.length && text[splitIndex] !== '.') {
        splitIndex++;
    }

    // If no period is found ahead, search backward
    if (splitIndex === text.length) {
        splitIndex = middleIndex;
        while (splitIndex >= 0 && text[splitIndex] !== '.') {
            splitIndex--;
        }
    }

    // If still no period is found, split at the middle index
    if (splitIndex < 0) {
        splitIndex = middleIndex;
    }

    return [text.slice(0, splitIndex + 1), text.slice(splitIndex + 1).trim()];
}



  async ngOnInit(): Promise<void> {

    if (this.data.data) {
      console.log(this.data);
      let updatedData:any = [];

        // Iterate through each element in data array
        this.data.data.forEach((element:any) => {
          if (element.krankImg !== undefined) {
            // Split KIText into two parts
            const [firstPart, secondPart] = this.splitAtNearestPeriod(element.KIText);
    
            // Create two copies with different KIText values
            let firstCopy = { ...element, KIText: firstPart };
            let secondCopy = { ...element, KIText: secondPart };
    
            // Add both modified copies instead of the original
            updatedData.push(firstCopy, secondCopy);
        } else {
            updatedData.push({ ...element }); // Add original if no krankImg
        }
        });
        this.data.data=updatedData
        console.log(this.data);
        console.log(this.data);

      this.titelReport=this.data.titel
      this.storeReportV=this.data.data
      this.creator=this.data.author
      let stepIndex = 0; 

      this.storeReportV.forEach((element: any, index: any) => {
          console.log(stepIndex);
          console.log('index '+index);
          
          this.displayedDescriptions[stepIndex] = element.KIText;
          if (index==2) {
            this.displayedDescriptions[3] = element.KIText;
          }
          stepIndex += 2; 
      });

      console.log(this.displayedDescriptions);
      this.data.data.forEach((d: any) => {
        if(d.chartImg) this.indakatoren.push({...d, pageType: 'chart'});
        if(d.mapImg) this.indakatoren.push({...d, pageType: 'map'});
        if(d.krankImg) this.indakatoren.push({...d, pageType: 'krank'});
      });
    }
    else{
      console.log(this.data);
      this.creator=this.userName
      this.storeReportV=this.data
      let stepIndex = 0; 

      this.storeReportV.forEach((element: any, index: any) => {
          console.log(stepIndex);
          this.displayedDescriptions[stepIndex] = element.KIText; 
          stepIndex += 2; 
      });
      this.data.forEach((d: any) => {
        if(d.chartImg) this.indakatoren.push({...d, pageType: 'chart'});
        if(d.mapImg) this.indakatoren.push({...d, pageType: 'map'});
        if(d.krankImg) this.indakatoren.push({...d, pageType: 'krank'});
      });
    }
    
  }

  public async storeRepot() {
    let stepIndex = 0; 

    this.storeReportV.forEach((element: any, index: any) => {
        console.log(stepIndex);
        element.KIText = this.displayedDescriptions[stepIndex];
        stepIndex += 2; 
    });

    console.log(this.displayedDescriptions);
    console.log(this.storeReportV);

    // Create an object that wraps the array and title properly
    const payload = {
        data: this.storeReportV, 
        titel: this.titelReport,
        author: this.creator
    };

    console.log(payload);

    try {
      await this.regioService.storeRepotData(payload);
      console.log('Data successfully sent!');
  } catch (error) {
      console.error('Error sending data:', error);
  } finally {
      this.dialogRef.close(); // Ensures closure no matter what
  }
  
}


  public async generateDescription(index: number, imageUrl: string) {   
      const indikator = this.indakatoren[index];
      const { apiChartData, name, type, unit } = indikator;
      const description = await this.llamaService.describeChart(apiChartData.chart.chartData,name,type,unit);
      this.generatedDescriptions[index] = description;
      this.animateTextDisplay(description, index);
  }

  private animateTextDisplay(text: string, index: number) {
    const words = text.split(' '); 
    let currentIndex = 0;
    this.displayedDescriptions[index] = '';
  
    const interval = setInterval(() => {
      if (currentIndex < words.length) {
        this.displayedDescriptions[index] += (currentIndex > 0 ? ' ' : '') + words[currentIndex];

        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 50); 
  }

  public async generatePDF() {
    const pages = Array.from(document.getElementsByClassName('reporting-page'));
    const buttons = Array.from(document.querySelectorAll('button[mat-raised-button]'));

    buttons.forEach((button: any) => {
      button.style.display = 'none';
    });

    if (pages) {
      const pagesContent: any[] = new Array(pages.length);

      const canvasPromises = pages.map((page: any, index: number) =>
        html2canvas(page, {
          height: 1200,
          width: 1200,
          scale: 2,
          backgroundColor: null,
          logging: false
        }).then((canvas: any) => {

          pagesContent[index] = {
            image: canvas.toDataURL(),
            width: 810,
            height: 940
          };
        })
      );
  
      await Promise.all(canvasPromises);
  
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
  
      const docDefinition = {
        content: pagesContent,
        pageOrientation: 'landscape',
        pageSize: 'A4'
      };
  
      pdfMake.createPdf(docDefinition).open();
    }
  }
  
  

  public getMinMax(indikator: any): string {
    let minMaxStr = indikator.minYear;
    if(indikator.minYear !== indikator.maxYear){
      minMaxStr += ` - ${indikator.maxYear}`
    }
    return minMaxStr;
  }

  public getDateTime(): string {
    return dayjs().locale('de').format('dddd, D. MMMM YYYY, HH:mm');
  }

  public handleTextSelection(index: any, event: MouseEvent) {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return;
  
    const range = selection.getRangeAt(0);
    const node = range.startContainer;
  
    if (!node || !node.textContent) return;
  
    let text = node.textContent;
    let offset = range.startOffset;
  
    // Find the start of the sentence (previous period)
    let startIndex = text.lastIndexOf('.', offset - 1) + 1;
    
    // Find the end of the sentence (next period)
    let endIndex = text.indexOf('.', offset);
    if (endIndex === -1) {
      endIndex = text.length;
    }
  
    const sentence = text.substring(startIndex, endIndex).trim();
  
    if (sentence) {
      this.selectedText = sentence;
      this.showActionButton = index;
  
      // Update the selection to highlight the full sentence
      range.setStart(node, startIndex);
      range.setEnd(node, endIndex);
      selection.removeAllRanges();
      selection.addRange(range);
    } else {
      this.showActionButton = -1;
    }
  }
  
  

async handleSentenceChange(index: any) {
    const newSentence = await this.llamaService.reformSentence(this.selectedText);
    const originalDescription = this.generatedDescriptions[index] || '';
    const updatedDescription = originalDescription.replace(
      this.selectedText,
      newSentence
    );
 
    this.displayedDescriptions[index] = updatedDescription;

    //
    this.selectedText = '';
    this.showActionButton = -1;
}


}
