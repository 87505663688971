import { Component } from '@angular/core';

@Component({
  selector: 'app-datahub2',
  templateUrl: './datahub2.component.html',
  styleUrls: ['./datahub2.component.css']
})
export class Datahub2Component {

  constructor(){}

}
