import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatSort } from '@angular/material/sort';

import { MatTableDataSource } from '@angular/material/table';
import { HospitalService } from 'src/app/service/hospitalService/hospital.service';


export interface Hospital {
  selected: boolean;
  id: string;
  name: string;
  Total_Cases_Hospital: number;
  Total_Relevant_Cases_Hospital: number;
  Intra_Hospital_Specialization: number;
  Inter_Hospital_Specialization: number;
  Global_Specialization: number;
  anzahl_betten_total: number;
  anzahl_fachabteilungen: number;
  traeger: string;
  art_des_traegers: string;
  data_given: boolean;
  geometry: {
    type: string;
    coordinates: [number, number];
  };
}


@Component({
  selector: 'app-spezini',
  templateUrl: './spezini.component.html',
  styleUrls: ['./spezini.component.css']
})
export class SpeziniComponent implements AfterViewInit{
startYear: any = 0;
endYear: any = 99;
  diseases: any[] = [

    {name: 'Herzkrankheit', checked: false},
    
     {name:'Hautkrankheit', checked: false},
    
     {name: 'Leberkrankheit', checked: false},
    
     {name: 'Ernährungsbezogene Erkrankungen', checked: false}];
selectAll(event: any) {
  const checked = event.checked;  // Get the checked state of the "Select All" checkbox
  this.data.result.Specialization.forEach((element: { selected: any; }) => {
  element.selected = checked;
});


}


sortColumn: keyof Hospital | null = null;
sortAscending = true;

  dataSource!: MatTableDataSource<any>;
  displayedColumns: string[] = [
    'select', 
    'name', 
    'Total_Cases_Hospital', 
    'Total_Relevant_Cases_Hospital', 
    'Intra_Hospital_Specialization', 
    'Inter_Hospital_Specialization', 
    'Global_Specialization', 
    'anzahl_betten_total', 
    'anzahl_fachabteilungen', 
    'traeger', 
  ];
  step = 0;
  @ViewChild(MatSort) sort!: MatSort;

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.data.result.Specialization);

    this.dataSource.sort = this.sort;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SpeziniComponent>,
    public hospitalService: HospitalService

    
    ) {
      console.log('D', data)
     // this.diseases = data.result.Specialization.split(',')
     // this.diseases = data.result.diseases.split(',').map((item: string) => item.trim());

      this.data = data;
    //  this.dataSource = new MatTableDataSource(data.result.Specialization);

    

    }




    async startApiCall() {

      let selectedHospitals = this.dataSource.filteredData.filter(hospital => hospital.selected).map(hospital => hospital.id); // Ext
      let allHospitals = this.dataSource.filteredData.map(hospital => hospital.id); // Ext

      let requestbody = {
        "Type": "Rasteranalyse_Diff",
        "Pop": "Halle", //obsolete, to be implemented
        "Year": "2024", //obsolete, to be implemented
        "Age_From": this.startYear, //obsolete, to be implemented
        "Age_To": this.endYear, //obsolete, to be implemented
        "Mode": "TRANSIT", //osbsolete, to be implemented
        "Gesuchter_Typ": [ // obsololete, to be implemented
            "Krankenhaus"
        ],
        "kh_filter_before": // hosptial ids of the current selection
        allHospitals,
         "kh_filter_after": // hosptial ids that are remaining after some hospitals have been de-selected
         selectedHospitals,
        
        "Only_burgenlandkreis":false
    }

  
  this.dialogRef.close({request: requestbody,
    diseases: this.diseases.filter(el => el.checked ==true)
   })

    }


    nextStep() {
      this.step++;

     if(this.step == 2) {
      this.startApiCall();
     }
    }


}

