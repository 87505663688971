

<h1> Krankenhäuser auswählen </h1>


<div style="display: flex; justify-content: space-evenly;">


<button mat-mini-fab style="background-color: #10684e; color: white" (click)="layerSelection =!layerSelection; radiusSelection = false">
<mat-icon>map</mat-icon>
</button>
  



    <button *ngIf="!radiusSelection" mat-mini-fab style="background-color: #10684e; color: white" (click)="closeAndRadiusSelect()">
        <mat-icon>
            track_changes</mat-icon>
        </button>

        <div *ngIf=" radiusSelection" class="btn" style="width: 45%; right: 5px"> 
            <span *ngIf="radiusSelection && !locationMarker" >  Markieren Sie einen Startpunkt </span>  
            <span *ngIf="radiusSelection && locationMarker"> 
           Startpunkt markiert - zum Ändern Marker verschieben </span> <br>
           <div *ngIf="radiusSelection && locationMarker"> 
           <span > 
             Alle KHs im Umkreis von    </span> 
                <input  placeholder="500" [(ngModel)]="radius">            
             <select matNativeControl required [(ngModel)]="modus">
                <option value="Distance">Meter</option>
                <option value="Time">Minuten (Fahrzeit) </option>
               
              </select>
             
             
             <span> auswählen. </span>

             <u *ngIf="radius && !umkreisLayer" (click)="startRadius()"> anwenden </u>
             <u *ngIf="umkreisLayer" (click)="startRadius()"> updaten </u>

             <u  *ngIf="!umkreisLayer"  (click)="endRadius()"> abbrechen </u>
             <u  *ngIf="umkreisLayer"  (click)="abortRadius()"> widerufen </u>



             
           </div>
           </div>

           <div *ngIf="layerSelection">
            <button class="btn" style="margin-left: 10px"(click)="setLayer(1)"> Bundesland einzeichnen </button>
            <button class="btn" style="margin-left: 10px" (click)="setLayer(3)"> Landkreise einzeichnen </button>
            <button class="btn" style="margin-left: 10px" (click)="setLayer(4)"> Gemeinde einzeichnen </button>
            <button class="btn" style="margin-left: 10px" (click)="setLayer(5)"> Gemeinde einzeichnen </button>



           </div>
           

           
        <div *ngIf=" umrisse.length > 0" class="btn" style="width: 45%; right: 5px"> 
          <span *ngIf="!selectedUmriss"> Umriss auswählen </span>  
          <span *ngIf="selectedUmrisse.length>0"> 
         Alle KHs in 

         <span *ngFor="let umriss of selectedUmrisse">
          {{ umriss.Object_Nam }}
         </span>
         
         
         
          selektieren? </span> <br>

         <U *ngIf="selectedUmrisse.length>0" (click)="selectHospitalViaUmriss()"> ja </U>
         <U *ngIf="selectedUmrisse.length>0" (click)="endHospitalViaUmriss()"> beenden </U>
       
         </div>

    </div>


<div style="display: flex;">
    <div class="selection-container">
    <span>
      <mat-checkbox [checked]="allKH" (change)="setAll($event.checked)">
        Alle
      </mat-checkbox> <br>
      <span *ngFor="let k of krankenhaus">
        <span>
          <mat-checkbox [(ngModel)]="k.checked" (ngModelChange)="updateAll(k)">
            {{k.raw.name}}
          </mat-checkbox>
        </span> <br>
      </span>

    </span>

</div>


 <div> 
    <div class="map-container" style="height: 50%; background-color: red; ">
        <div class="map2" id="map2"></div>
    </div> 



 </div>
</div>

<mat-dialog-actions align="end">
    <button mat-raised-button (click)="initMap()">Abbrechen</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="true" >Bestätigen</button>
  </mat-dialog-actions>
