import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import { HospitalService } from '../service/hospitalService/hospital.service';
import { MatDialog } from '@angular/material/dialog';
import { KrankenhausDialogComponent } from './dialog/krankenhaus-dialog/krankenhaus-dialog.component';
import { KrankheitenDialogComponent } from './dialog/krankheiten-dialog/krankheiten-dialog.component';
import { PopDialogComponent } from './dialog/pop-dialog/pop-dialog.component';
import { RegioService } from '../service/regioservice/regioservice.component';
import { RoutingserviceComponent } from '../service/routingservice/routingservice.component';
import { UmkreisDialogComponent } from './dialog/umkreis-dialog/umkreis-dialog.component';
import { ReMapComponent } from '../re-map/re-map.component';
import { PopupComponent } from '../re-map/popup/popup.component';
import { HospitalPopupComponent } from '../re-map/hospital-popup/hospital-popup.component';
import { ReChartComponent } from '../re-chart/re-chart.component';
import { AiChartComponent } from '../components/ai-chart/ai-chart.component';
import { AiServiceComponent } from '../re-chart/ai-service/ai-service.component';
import { HospitalUmkreisComponent } from '../re-map/hospital-umkreis/hospital-umkreis.component';
import { mock } from 'node:test';
import { Chart } from 'chart.js';
import { SpeziniComponent } from './dialog/spezini/spezini.component';



interface Hospital {
    Object_key: string;
    name: string;
    only_day_clinic: boolean;
    anzahl_betten_total: number;
    ambulant_fallzahlen_total: number;
    stationaer_fallzahlen_total: number;
    anzahl_fachabteilungen: number;
    geometry: {
        type: string;
        coordinates: number[];
    };
}

interface Disease {
    Unterkategorie: string;
    Hauptkategorie: string;



}

@Component({
    selector: 'app-krankenhausreform',
    templateUrl: './krankenhausreform.component.html',
    styleUrls: ['./krankenhausreform.component.css']
})
export class KrankenhausreformComponent implements OnInit {
    spezIniResult: any;
    selRaster = 0;
    clusterMeta: any; 
    raster: any[] = [];
    selectedChartIndex: any = 0;
    umkreisResultBody: any;
    legend: any // = L.control({ position: 'bottomright' }) as L.Control;
    clusterMetatTitle: string = 'Differenz Fahrzeit in Minuten zum nächsten Spezialisierungs-Krankenhaus zwischen Initial und Modifikationsszenario';
    spezResultDiseasesBuffer: any;
    updateResultChart(event: number) {
    this.hideResultChart = false;
    let chartConfig;
switch(event) {
    case 0:
         chartConfig = JSON.parse(this.spezResult.chartJS_Object_Fallzahlen_nach_Krankenhaus_Krankheit)
    break;
    case 1:
        chartConfig = JSON.parse(this.spezResult.chartJS_Object_Spezialisierung['Intra-Krankenhaus-Spezialisierung'])
        break;
        case 2: 
        chartConfig = JSON.parse(this.spezResult.chartJS_Object_Spezialisierung['Inter-Krankenhaus-Spezialisierung'])

        break;
        case 3:
            chartConfig = JSON.parse(this.spezResult.chartJS_Object_Spezialisierung['Inter-Krankenhaus-Spezialisierung (bundesweit)'])

            break; 


}

if(!chartConfig.data  ||  !chartConfig.options  ||  !chartConfig.type ) {
    alert('API Chart Config incorrect')
}
this.chartResultConfig =  chartConfig



}
    hideResultChart = true; 
    chartResultConfig: any = {

    };
unitchanged() {

    if(this.unit == 'm') {
        this.radius = 3000
    } else {
        this.radius = 10
    }

 }
mapMoved() {
//alert('movement!')
//@ts-ignore
//let chartConfig = this.createChartConfig(this.hospitals.data, this.tabNames['anzahl_betten_total']);
//this.chartComponent.updateChart(chartConfig)



}
markerClicked($event: any) {
    
if(this.selectedHospital) {
    //deselect 
    this.remap.updateMarker(this.selectedHospital, undefined, undefined, undefined,  this.remap.customIcon, HospitalPopupComponent, false )

} 
this.selectedHospital = $event.id 
 let m = this.remap.updateMarker($event.id, undefined, undefined, undefined,  this.remap.customIconRed, HospitalPopupComponent, false )
//this.remap.focusMapOnId($event.id);
console.log('hh', m)
m.leaflet.openPopup(); 
this.updateChart('anzahl_betten_total')

 }
hideChart: any = false;
 tabNames = [
    { label: 'Anzahl Betten',
      key:   'anzahl_betten_total' },
    { label:  'Ambulante Fallzahlen',
        key: 'ambulant_fallzahlen_total'
    },
    { label:  'Fachabteilungen',
        key: 'anzahl_fachabteilungen'
    },
    { label:  'Stationäre Fallzahlen',
        key: 'stationaer_fallzahlen_total'
    }];
updateChart(arg0: any) {
   
   this.selectedChartIndex = arg0
        let chartConfig  =  this.createChartConfig(this.hospitals.data, this.tabNames[arg0])
        this.chartComponent.updateChart(chartConfig)

}
    radiusValue: any;
    unit: any;
    umkreisAnsicht = false;


    @ViewChild(ReMapComponent)
    remap!: ReMapComponent;  // Zugriff auf die Kindkomponente
    umkreisResult: any;
    umkreisResults: any[] = [];

    shoResult: any;
    startUmkreis: any;

    @ViewChild(ReChartComponent)
    chartComponent!: ReChartComponent;


    currentYear: number = 2025;
    isLoading: boolean = false;
    mapSelection: boolean = false;
    MarkerArray: any[] = [];
    diseases: Disease[] = [];
    radiusSelection: boolean = false;
    locationMarker: L.Marker<any> | undefined;
    selectedPop: any =
        {
            pop_name: ''
        };
    selectedHospital: Hospital | undefined;
    modus: any;
    radius: any;
    hospitals: any;
    editUmkreis = false;
    spezResult: any;
    chartConfig: {
        type: string; // Balkendiagramm
        data: {
            labels: any[]; // Krankenhausnamen als Labels
            datasets: {
                label: string; data: any[]; // Anzahl Betten als Werte
                backgroundColor: string; borderColor: string; borderWidth: number;
            }[];
        }; options: { responsive: boolean; plugins: { legend: { display: boolean; position: string; }; }; scales: { y: { beginAtZero: boolean; }; }; };
    } | undefined;

    openDialog() {

    }
    constructor(
        private hospitalService: HospitalService,
        private routingservice: RoutingserviceComponent,
        public dialog: MatDialog,
    ) {
        
        this.openPop() 
    }



    async mapInit() {
        //map init
        if(!this.hideChart) {
        this.remap.center[1] = this.remap.center[1] 
        }
        this.remap.initMap();

        if(this.raster) {
            this.raster.forEach((r: any) => this.remap.getMap().removeLayer(r))
        }
        this.remap.focusMapOnLatLng(this.remap.center[0], this.remap.center[1] + 0.2, 9)
        //  this.remap.
        this.remap.getAndAddLayerByKeyAndLevel('15', 3, this.remap.layerEmptyStyle )
        this.hospitals.data.forEach((hospital: any) => { this.remap.addMarker(hospital.id, hospital.geometry.coordinates[1], hospital.geometry.coordinates[0], hospital, this.remap.customIcon, HospitalPopupComponent); })

    }
    async ngOnInit(): Promise<void> {
  
        

     


    }
    createChartConfig(hospitals: any[], key: any) {

        let bounds = this.remap.getMap()?.getBounds();
        let filteredHospitals = [];
    if(bounds){

    
hospitals.forEach((hospital: any) => {
    if(bounds && bounds?.contains([hospital.geometry.coordinates[1], hospital.geometry.coordinates[0]])) {
        filteredHospitals.push(hospital); }
    })
    } else {
        filteredHospitals  = hospitals
    }
    
 

        if(!key) {
            key = { label:  'Fachabteilungen',
                key: 'anzahl_fachabteilungen'
            }

        }
       return  {
          type: 'bar', // Balkendiagramm
          data: {
            labels: filteredHospitals.map((h: { name: any; }) => h.name.substring(0,30)), // Krankenhausnamen als Labels
            datasets: [{
              label: key.label,
              data: filteredHospitals.map((h:  any ) => h[key.key]), // Anzahl Betten als Werte
              backgroundColor: filteredHospitals.map((h: any) =>
               this.selectedHospital === h.id ? 'rgba(255, 99, 132, 0.6)' : 'rgba(54, 162, 235, 0.6)'
            ),
            borderColor: filteredHospitals.map((h: any) =>
                this.selectedHospital === h.id ? 'rgba(255, 99, 132, 1)' : 'rgba(54, 162, 235, 1)'
            ),
              borderWidth: 1
            }]
          },
          options: {
            responsive: true,
            plugins: {
              legend: {
                display: true,
                position: 'top'
              }
            },
            scales: {
              y: {
                beginAtZero: true
              },
            // x: {
            //     "beginAtZero": true,
            //     "ticks":{
            //       autoSkip:false,
            //       maxRotation:90,
            //       minRotation:90
            //     }
      
                
            //   },
            }
          }
       }
      }
    
    updateUmkreisanalyse() {


       // var latestEntry = this.umkreisResults[this.umkreisResults.length - 1];
       // if (!latestEntry) { alert('fatal error'); return };
       // latestEntry.req.body.unit = this.unit;
        //latestEntry.req.body.radius = this.radius;
        this.editUmkreis = false;
        this.hideChart = true;

        console.log('hh', this.umkreisResultBody)

       // let hos = this.hospitals.data.find((el: { id: any; }) => el.id == latestEntry.req.body.id)
var mockBody = this.umkreisResultBody;
mockBody.radius = this.radius;
mockBody.unit =    this.unit;

        this.startUmkreisanalyse('', mockBody)

    }

    oldMArkers:any=[]
    allRaster:any=[]

    plotRasterLayer(mockRaster: any, title: any) {
        if(this.raster) this.raster.forEach((r: any) => this.remap.getMap().removeLayer(r))

        this.remap.removeAllLayers();
        this.remap.removeAllMarkers();

        console.log('mockRaster', mockRaster)
        if(mockRaster.Point_Params.length > 0) {
            //let hospitalName = mockRaster.Point_Params[0].name
            let hospital = mockRaster.Point_Params
 

            if (this.oldMArkers) {
                this.oldMArkers.forEach((marker:any) => marker.remove()); // Remove each marker from the map
                this.oldMArkers=[]
            }

            if (this.allRaster) {
                this.allRaster.forEach((l:any) => l.remove()); // Remove each marker from the map
                this.allRaster=[]
            }
                        
            
            mockRaster.Point_Params.forEach((element: any) => {
                // Add each hospital point as a marker
                let marker = L.marker([element.geometry.coordinates[1], element.geometry.coordinates[0]], {
                    icon: this.remap.customIcon
                });
            
                // Bind the popup to each hospital marker with the required details
                marker.bindPopup("<b>Name: " + element.name + "</b><br>"
                );
                this.oldMArkers.push(marker)
                // Add the marker to the map
                this.remap.getMap().addLayer(marker);
            

            });
            
            
            //this.remap.focusMapOnLatLng(hospital.geometry.coordinates[1], hospital.geometry.coordinates[0], 7)
        } 

        
        mockRaster.Raster_Params.forEach((raster: any) => {


            var l = L.geoJSON(raster.geometry,
              {
                style: {
                  fillColor: raster.color_map,
                  fillOpacity: 0.2,      
                  color: raster.color_map,
                  weight: 0.2
                }
              })

     l.bindPopup("<b> Raster ID: " + raster.raster_id + "</b> <br> Zeit zur nächsten Einrichtung: " + raster.shortest_travel_time
        + "<br> Anteil Bevölkerung: " + raster.share_of_population_living_in_raster)
     
              this.remap.getMap().addLayer(l)
              this.raster.push(l)
              this.allRaster.push(l)
            })
      
   this.remap.removeAllLayers()

   this.chartResultConfig  = JSON.parse(mockRaster.ChartJS_plot);

   console.log('config', mockRaster, this.chartResultConfig)
   
   this.hideResultChart = false; 
   this.hideChart = true; 
    this.clusterMetatTitle = title
    //"Fahrzeit in Minuten zum nächsten Spezialisierungs-Krankenhaus"
//"Differenz Fahrzeit in Minuten zum nächsten Spezialisierungs-Krankenhaus zwischen Initial und Modifikationsszenario"
   this.clusterMeta = mockRaster.Raster_Colors_Legend[0]
 //  this.addLegend(mockRaster.Raster_Colors_Legend, 'Zeiten')

      
    }


    async startUmkreisanalyse(id: any, body: any) {
        let hos = this.hospitals.data.find((el: { id: any; }) => el.id == id)
        let mockBody: any;
        if (hos) {
            mockBody =  {
                    "radius": 3000,
                    "unit": "m",
                    "id": hos.id,
                    "object_types": [
                        "Wohnhaus"
                    ],
                    "hospitals": [hos]
                }
            
        }
        if (body) { mockBody = body }



        this.unit = mockBody.unit;
        this.radius = mockBody.radius;
        this.isLoading = true;
        try{
            console.log('body', mockBody)

        let res: any = await this.hospitalService.getUmkreissuche(mockBody);

        this.umkreisResultBody = mockBody;
      
        console.log('result', res)
        this.hideChart = true;
        this.umkreisAnsicht = true; 
        if (res.Starting_Point) {

            this.remap.removeAllMarkers();
            this.remap.addMarker('h', res.Starting_Point[0].geometry.coordinates[1], res.Starting_Point[0].geometry.coordinates[0], res.Starting_Point[0], this.remap.customIcon, undefined);
        }
        if (res.Isochron) {
            this.remap.removeAllLayers();

            console.log('res', res)
            let layer = res;
            layer.geometry = res.Isochron[0].geometry;
            this.umkreisResult = layer;
            this.umkreisResult.hospital = hos;
       

            this.umkreisResult.unit = this.unit;
            this.umkreisResult.radius = this.radius;
            console.log('umkreos', this.umkreisResult)
            this.remap.addLayer('res1', layer, undefined, this.remap.layerHighlightStyle)
            this.remap.focusMapOnId('res1', true)
        }

        if(res.chartJS_Object) {
          //  this.chartResultConfig.type = 'bar';
          let chartConfig = JSON.parse(res.chartJS_Object)
           this.chartResultConfig =  chartConfig
    
  

           this.hideResultChart = false; 
           this.hideChart = true; 



        }




     
        this.umkreisResults.push({
            req: mockBody,
            res: res
        })
        this.hideChart = true; 

        this.isLoading = false;
    } catch(error) {
        alert('API-Fehler')
        this.isLoading = false;

        return;
    }





    }
    async ngAfterViewInit() {
        //chart init 
           //data
           this.hospitals = await this.hospitalService.getHospitals();


           console.log('h', this.hospitals.data[0])
           this.diseases = this.hospitalService.getDiseases();
           //@ts-ignore
           this.chartConfig = this.createChartConfig(this.hospitals.data, this.tabNames['anzahl_betten_total']);
   
           this.mapInit();
    

        this.chartComponent.updateChart(this.chartConfig)


        return;


    }

    updateDataset(existingDataset: any[], apiResponse: any[]) {
        return existingDataset.map(existingItem => {
            // Finde das passende Element aus der API-Response anhand der "id" oder einem anderen Schlüssel
            const apiItem = apiResponse.find(api => api.id === existingItem.id);

            if (apiItem) {
                let icon = this.remap.customIcon
                if (apiItem.selected) icon = this.remap.customIconRed

                this.remap.updateMarker(apiItem.id, undefined, undefined, apiItem, icon, undefined, false);

                // Aktualisiere das bestehende Item mit Werten aus dem API-Item
                return {
                    ...existingItem,
                    ...apiItem, // Werte aus API überschreiben bestehende Werte
                    geometry: {
                        ...existingItem.geometry,
                        ...apiItem.geometry // Falls `geometry` existiert, aktualisiere nur deren Felder
                    }
                };

            }

            // Wenn kein passender Eintrag gefunden wurde, bleibt das Item unverändert
            return existingItem;
        });
    }

    openKrankenhaus(krankheiten: any[]) {
        this.mapSelection = false;

        const dialogRef = this.dialog.open(KrankenhausDialogComponent, {
            data: { krankenhaus: this.hospitals.data, krankheiten: krankheiten },
            width: '80vw',
            height: '70vh',
        });
        dialogRef.afterClosed().subscribe(async (result: any) => {
            if (result?.config) {
                console.log('result config', result.config)
                this.isLoading = true;
                let resp: any;
                let url: any;
                try{
                 let apiCall = await this.hospitalService.getSpezAnalyse(result.config.hospitals, result.config.diseases);
                resp = apiCall.result;
                url = apiCall.url;

                } catch(error) {
                    this.isLoading = false;
                    alert('API Error: ' + url)

                }
                if(!resp) {
                    this.isLoading = false;
                    alert('API Error' + url)

                    return
                }
                console.log('response', resp)
                
                this.hideChart = true;
                this.remap.removeAllLayers();
                this.remap.removeAllMarkers();

                this.remap.getAndAddLayerByKeyAndLevel('15', 3, this.remap.layerDefaultStyle)

                this.isLoading = false;
                let hospitals: string[] = [];
                let boundingBounds: L.LatLngBounds | null = null; // Initialize as null to check later

                resp.Specialization.forEach((h: any) => { 
                    hospitals.push(h.name);

                    let j = this.hospitals.data.find((el: { id: any; }) => el.id == h.id)
                    j.Total_Cases_Hospital = h.Total_Cases_Hospital
                    j.Total_Relevant_Cases_Hospital = h.Total_Relevant_Cases_Hospital
                    j.Intra_Hospital_Specialization = h.Intra_Hospital_Specialization
                    j.Inter_Hospital_Specialization = h.Inter_Hospital_Specialization
                    j.Global_Specialization = h.Global_Specialization
                    j.Fallzahl = h.Fallzahl
                    j.traegerart = h.traegerart
                    this.remap.addMarker(j.id, j.geometry.coordinates[1], j.geometry.coordinates[0], j, this.remap.customIcon, HospitalPopupComponent);
                  ///  this.remap.focusMapOnLatLng(j.geometry.coordinates[1], j.geometry.coordinates[0] - 16, 5)
                  let latLng = L.latLng(j.geometry.coordinates[1], j.geometry.coordinates[0]);
                  if (boundingBounds === null) {
                      boundingBounds = L.latLngBounds(latLng, latLng); // Initialize with the first location
                  } else {
                      boundingBounds.extend(latLng); // Extend bounds for subsequent locations
                  }
                




                })

               this.remap.getMap().fitBounds(boundingBounds)
                this.spezResult = resp;
                this.spezResult.diseases  = result.config.diseases?.map((d: { Unterkategorie: any; }) => d.Unterkategorie).join(', ') || '';
                this.spezResultDiseasesBuffer = this.spezResult.diseases;
                this.spezResult.hospitals = hospitals 
             //   this.remap.getAndAddLayerByKeyAndLevel();
                if(resp.chartJS_Object_Fallzahlen_nach_Krankenhaus_Krankheit) {
                  

                 this.updateResultChart(0);
                  

                }

                
                this.hideResultChart = false;
                return; 
           

                resp.forEach((h: any) => {
                    if (h.selected) {
                        let j = this.hospitals.data.find((el: { id: any; }) => el.id == h.id)
                        j.Total_Cases_Hospital = h.Total_Cases_Hospital
                        j.Total_Relevant_Cases_Hospital = h.Total_Relevant_Cases_Hospital
                        j.Intra_Hospital_Specialization = h.Intra_Hospital_Specialization
                        j.Inter_Hospital_Specialization = h.Inter_Hospital_Specialization
                        j.Global_Specialization = h.Global_Specialization
                        j.Fallzahl = h.Fallzahl
                        j.traegerart = h.traegerart
                        this.remap.addMarker(j.id, j.geometry.coordinates[1], j.geometry.coordinates[0], j, this.remap.customIcon, HospitalPopupComponent);
                        this.remap.focusMapOnLatLng(j.geometry.coordinates[1], j.geometry.coordinates[0] - 16, 5)
                    }

                })
                this.isLoading = false;
                this.spezResult = resp;
                this.spezResult.diseases = result.config.diseases?.map((d: { Unterkategorie: any; }) => d.Unterkategorie).join(', ') || '';
               // this.spezResult.hospitals = result.config.hospitals?.map((d: { name: any; }) => d.name).join(', ') || '';

                const nameList = resp.map((hospital: any) => hospital.name);
                const colorList = this.getRandomColors(nameList.length);
                this.spezResult.hospitals = nameList;
                this.spezResult.colors = colorList;
                const fallzahlen = resp.map((hospital: any) => hospital.Fallzahl);
                const intraSpecializationList = resp.map((hospital: { Intra_Hospital_Specialization: any; }) => hospital.Intra_Hospital_Specialization);
                const interSpecializationList = resp.map((hospital: { Inter_Hospital_Specialization: any; }) => hospital.Inter_Hospital_Specialization);
                const globalSpecializationList = resp.map((hospital: { Global_Specialization: any; }) => hospital.Global_Specialization);

                const Total_Relevant_Cases_Hospital = resp.map((hospital: { Total_Relevant_Cases_Hospital: any; }) => hospital.Total_Relevant_Cases_Hospital);

                this.createPieChart(`intraChart`,nameList, colorList, intraSpecializationList);
                this.createPieChart(`interChart`,nameList, colorList, interSpecializationList);
                this.createPieChart(`globalChart`,nameList, colorList, globalSpecializationList);
                this.createPieChart(`fallzahlChart`,nameList, colorList, fallzahlen);
                this.createPieChart(`totalChart`,nameList, colorList, Total_Relevant_Cases_Hospital);
            }




        });
    }

    createPieChart(chartId: string, label: string[], colors: string[], value: number[]) {
        new Chart(chartId, {
            type: 'pie',
            data: {
                labels: label,
                datasets: [{
                    data: value,
                    backgroundColor: colors
                }]
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false // Disables the legend
                    }
                }
            }
        });
    }
    getRandomColors(n: number): string[] {
        return Array.from({ length: n }, () => {
            return '#' + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');
        });
    }

    openKrankheiten() {

        const dialogRef = this.dialog.open(KrankheitenDialogComponent, {
            data: { krankheiten: this.diseases },
            width: '1200px',
            height: '800px',
        });
        dialogRef.afterClosed().subscribe(async (result: any) => {
            if (result) { this.openKrankenhaus(result); }
        });
    }

    openInitiative() {

        const dialogRef = this.dialog.open(SpeziniComponent, {
            data: { result: this.spezResult , pop: this.selectedPop.pop_name},
            width: '1600px',
            height: '800px',
        });
        dialogRef.afterClosed().subscribe(async (result2: any) => {
            if (result2) { 
                this.spezResult = undefined
                this.hideChart = true; 
                this.hideResultChart = false;


                this.isLoading = true;
                let body;

                let req;
                /* = {
                    "Type": "Rasteranalyse_Diff",
                    "Pop": "Halle", //obsolete, to be implemented
                    "Year": "2024", //obsolete, to be implemented
                    "Age_From": 0, //obsolete, to be implemented
                    "Age_To": 100, //obsolete, to be implemented
                    "Mode": "TRANSIT", //osbsolete, to be implemented
                    "Gesuchter_Typ": [ // obsololete, to be implemented
                        "Krankenhaus"
                    ],
                    "kh_filter_before": // hosptial ids of the current selection
                    [
                      "3dda0b152f1cd34a","9df99701a2f3bf80"
                    ],
                     "kh_filter_after": // hosptial ids that are remaining after some hospitals have been de-selected
                    [
                      "3dda0b152f1cd34a"  ],
                    
                    "Only_burgenlandkreis":false
                }*/

                console.log('body for request raster', result2.request)
                req =  result2.request

                req.Operations = this.spezResultDiseasesBuffer.split(',').map((item: string) => item.trim());

                console.warn('neu body', req)
  
                 try{
                 body = await this.hospitalService.getSpezIni(req)
                 } catch(error) {
                    alert('API fail');
                    this.isLoading = false;

                 }

                this.isLoading = false;
      

                //@ts-ignore
            this.spezIniResult = body;
            this.spezIniResult.diseases = result2.diseases.map((item: { name: any; }) => item.name);


            this.spezIniResult.hospitals = this.getEinrichtungenAfter(body);



   this.selRaster = 1
            this.plotRasterLayer(this.spezIniResult?.Results.before, "Fahrzeit in Minuten zum nächsten Spezialisierungs-Krankenhaus")


        
        }
        });
    }

    openUmkreis(marker: any, hospital: any) {
        marker.setIcon(this.remap.customIconRed);

        this.selectedHospital = hospital
    }

    addLegend2(clusterMeta: any, title: any) {
        let div = L.DomUtil.create('div', 'info legend custom-legend');

// Erstellt das Hauptcontainer-Div mit Styling
let container = document.createElement('div');
container.style.backgroundColor = 'rgba(0, 0, 0, 0.1)';
container.style.padding = '10px';
container.style.borderRadius = '20px';
container.style.color = 'green';
container.style.fontFamily = 'Arial, sans-serif';
container.style.fontSize = '14px';
container.style.boxShadow = '0 0 15px rgba(0, 0, 0, 0.2)';
container.style.borderBottom = '1px solid green';
container.style.borderTop = '1px solid green';
container.style.borderRight = '7px solid green';
container.style.borderLeft = '7px solid green';
container.style.display = 'flex';
container.style.justifyContent = 'center';
container.style.alignItems = 'center';
container.style.flexDirection = 'column'; // Falls mehrere Zeilen

// Titel hinzufügen
let titleDiv = document.createElement('h3');
titleDiv.innerHTML = title;
container.appendChild(titleDiv);

// Labels generieren und in container einfügen
for (let i = 0; i < clusterMeta.length; i++) {
  let row = document.createElement('div');
  row.style.display = 'flex';
  row.style.flexDirection = 'row';
  row.style.alignItems = 'center'; // Zentriert vertikal

  // Farbkreis erstellen
  let circle = document.createElement('div');
  circle.className = 'circle';
  circle.style.backgroundColor = clusterMeta[i].color_map;
  circle.style.width = '40px';
  circle.style.height = '20px';

  // Text hinzufügen
  let text = document.createElement('div');
  text.style.marginLeft = '5px';
  text.innerHTML = `<b>${clusterMeta[i].class_map}</b>`;

  // Alles zusammensetzen
  row.appendChild(circle);
  row.appendChild(text);
  container.appendChild(row);
}

// Das Hauptcontainer-Div in das Legend-Div einfügen
div.appendChild(container);

    }

    addLegend(clusterMeta: any, title: any) { 
      //  if (this.legend instanceof L.Control) { this.remap.getMap().removeControl(this.legend); }
    
    
        //@ts-ignore
      //  this.legend = this.legend = L.control({ position: 'bottomleft' }) as L.Control;

        //@ts-ignore
      //  this.legend.onAdd = () => {
    
            let div = L.DomUtil.create('div', 'info legend custom-legend');
            var labels = [' <h3> ' + title +' </h3>  '];
        
          for (var i = 0; i < clusterMeta.length; i++) {
            div.innerHTML +=
              labels.push(
                '<div style="display: flex; flex-direction: row" > <div class="circle" style="background-color:' + clusterMeta[i].color_map  + '; width:40px; height: 20px"></div> ' +
                '<div style="margin-left: 5px;" > <b> ' + clusterMeta[i].class_map + ' </b> </div> </div>');}
    
           
    
          var l = labels.join('<br>');
          div.innerHTML = '<div style="background-color: rgba(0, 0, 0, 0.1);padding: 10px;border-radius: 20px;color: green;font-family: Arial, sans-serif;font-size: 14px;box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); border-bottom:1px solid green;border-top:1px solid green;border-right:7px solid green;border-left:7px solid green; display:flex;jutify-content:center; align-items:center; height:30px;"> ' + l + '</div>'
        //  return div;
       // };

       // this.remap.getMap().add(this.legend)
     // this.legend.addTo(this.remap.getMap());
    
      }

    getEinrichtungenAfter(spezIniResult: any) {

        let id = [];
        let hospitalNames: any[] = [];
        if(spezIniResult && spezIniResult.Meta[0]) {
           if(spezIniResult.Meta[0].Einrichtungen_after) {
     if(spezIniResult.Meta[0].Einrichtungen_after instanceof Array) {
        id = spezIniResult.Meta[0].Einrichtungen_after
     } else {
        id.push(spezIniResult.Meta[0].Einrichtungen_after)
     }


     id.forEach((element: any) => {
      let hospital =  this.hospitals.data.find((el: { id: any; }) => el.id == element)
      hospitalNames.push(hospital.name)
        
     });


           }
        }
        console.log('hospitalNames', hospitalNames)

        return hospitalNames
    }
    updateNext(event: Event) {
        const target = event.target as HTMLInputElement;
        this.currentYear = parseInt(target.value);
    }

    layerClicked(event: any) {
        if (event.id == 'res1') {
            this.umkreisResult = !this.umkreisResult

        }


    }

    getColorForTicks(year: number): any {
        if (year == this.currentYear) return 'red'
        return 'black'
    }
    async openPop() {

        var pop = await this.routingservice.getPopulation();
        const dialogRef = this.dialog.open(PopDialogComponent, {
            data: { krankheiten: this.diseases, pop: pop },
            width: '1200px',
            height: '800px',
        });
        dialogRef.afterClosed().subscribe(async (result: any) => {
            if (result.pop) {
                //alert(result.pop)
                this.selectedPop = result.pop
            }
        });
    }

}
