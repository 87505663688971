<div style="display: flex; justify-content: center; flex-direction: column; padding: 10px;height: calc(100vh - 245px);">

    <div class="chart-container">
  
  
      <div>
        <mat-tab-group>
          <mat-tab label="Grafik">


            <div *ngIf="chart"
              style="display: flex; justify-content: space-between; align-items: center; width: 650px; height: 7vh;">
            </div>
  
  
          </mat-tab>
  
          <mat-tab label="Einstellungen">
            <div *ngIf="chart" style="display: flex; justify-content: center; align-items: center; height: 7vh;">
  
  
              <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
                <mat-button-toggle (click)="changeType('pie')">Pie</mat-button-toggle>
                <mat-button-toggle (click)="changeType('bar')">Bar</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </mat-tab>
  
          <mat-tab label="Optionen">
            <div style="display: flex; align-items: center; justify-content: center;"> 
            <div  style="display: flex; justify-content: center; align-items: center; height: 80px; padding: 10px;width: 700px;gap:50px">
              <button (click)="openDialogShare()" class="mapIcons" style="background-color: #126F62;color: white" mat-mini-fab (mouseenter)="showShareChart=true" (mouseleave)="showShareChart=false" >
                <mat-icon>share</mat-icon>
                <span *ngIf="showShareChart" style="position: absolute;  bottom:-30px ; left: -85px; padding: 4px; color: white; background-color:#585757cc ; border-radius: 8px; box-shadow: 2px 2px 2px black;font-size: 14px; width: 200px;">
                  Dashboard teilen
                </span>
              </button>
              
              <button class="mapIcons" style="background-color: #126F62;color: white" mat-mini-fab (mouseenter)="showDownloadChart=true" (mouseleave)="showDownloadChart=false" (click)="showReport()" >
                <mat-icon>save_alt</mat-icon>
                <span *ngIf="showDownloadChart" style="position: absolute;  bottom:-30px ; left: -85px; padding: 4px; color: white; background-color:#585757cc ; border-radius: 8px; box-shadow: 2px 2px 2px black;font-size: 14px; width: 200px;">
                  als PDF herunterladen
                </span>
              </button>
              <button class="mapIcons" style="background-color: #126F62;color: white" mat-mini-fab (click)="openDialogData()" (mouseenter)="showTable=true" (mouseleave)="showTable=false" >
                <mat-icon>dataset</mat-icon>
                <span *ngIf="showTable" style="position: absolute;  bottom:-30px ; left: -85px; padding: 4px; color: white; background-color:#585757cc ; border-radius: 8px; box-shadow: 2px 2px 2px black;font-size: 14px; width: 200px;">
                  Daten als Tabelle anzeigen
                </span>
              </button>
  
              <button class="mapIcons" style="background-color: #126F62;color: white" mat-mini-fab (click)="openDialogSave()" (mouseenter)="showSave=true" (mouseleave)="showSave=false" >
                <mat-icon>save</mat-icon>
                <span *ngIf="showSave" style="position: absolute;  bottom:-30px ; left: -85px; padding: 4px; color: white; background-color:#585757cc ; border-radius: 8px; box-shadow: 2px 2px 2px black;font-size: 14px; width: 200px;">
                  Dashboard speichern
                </span>
              </button>
            </div>

        </div>
          </mat-tab>
        </mat-tab-group>
  
        <div class="flexBox">
  
          <div id="chart-container">
            <div [hidden]="chartType != 'bar'" style="width: 400px; height: 400px;" >
            <canvas id="MyChart">
              {{ chart }}
            </canvas>
            </div>
      
  
  
            <div [hidden]="chartType == 'bar'" style="width: 400px; height: 300px;" >
              <canvas id="myPieChart"></canvas>
            </div>
  
  
           <!-- <div *ngIf="chartType == 'bar'" class="flexBox" style="flex-direction: column; gap: 10px;">
  
              <mat-form-field class="example-full-width">
                <input type="text" aria-label="Assignee" matInput [formControl]="myControl" [matAutocomplete]="auto"
                  placeholder="suche...">
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="scrollToElement($event)">
                  <mat-option *ngFor="let l of filteredOptions | async" [value]="l.label">
                    {{l.label}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
  
              <div id="chartLegend" class="selectable-legend">
                <div style="display: flex;" *ngFor="let l of chartLegend">
  
                  <div style="width:10px; height: 20px " (click)="l.disabled = !l.disabled">
                    <s *ngIf="l.disabled"> {{ l.label }} </s>
                    <span *ngIf="!l.disabled"> {{ l.label }} </span>
  
                  </div>
  
                </div>
              </div>
              <div>
                <button button mat-raised-button color="primary" (click)="deselectAll(false)" *ngIf="selectMode"> Alle
                  auswählen </button>
                <button button mat-raised-button color="primary" (click)="deselectAll(true)" *ngIf="!selectMode"> Alle
                  abwählen </button>
              </div>
            </div>-->
  
            <!--<div *ngIf="chartType != 'bar'">
              <div id="chartLegend" class="selectable-legend">
                <div style="display: flex; justify-content: space-evenly;" *ngFor="let l of chartLegend">
  
                  <div class="pad2" style="margin: 5px;" [ngStyle]="{'background-color':  (selectedLabel == l.label) ? '#a5ba93' : 'white'}" (click)="updatePieChart(); selectedLabel = l.label">
                    {{ l.label }}
                  </div>
                
                </div>
              </div>
            </div>-->
          </div>
  
  
        </div>
  
  
      </div>
  
  
  
    </div>
  
  
  
  
    <div class="bottom-container">
  
      <div class="left" style="overflow-y: scroll;">
  
  
        <div class="list-item">
          <div style=" display: flex; justify-content: start; align-items: center; width: 60%;">
            Name:
          </div>
          <div class="pad2" (click)=showName(false)>
            Rasteranalyse
            <!--<div *ngIf="selectedName">
              {{selectedName}}
            </div>-->
          </div>
        </div>

        <div class="list-item">
            <div style=" display: flex; justify-content: start; align-items: center; width: 60%;">
              Fortbewegung:
            </div>
            <div class="pad2" (click)=showUmkreis()>
          
                {{ orgObj?.Meta[0].Transport_Mode }} 
  
     
            </div>
          </div>
  
        <div class="list-item">
          <div style=" display: flex; justify-content: start; align-items: center; width: 60%;">
            Population:
          </div>
          <div class="pad2" (click)=showUmkreis()>
        
              {{ orgObj?.Meta[0].Pop }} | {{  orgObj?.Meta[0].Year }}

   
          </div>
        </div>

        <div class="list-item">
            <div style=" display: flex; justify-content: start; align-items: center; width: 60%;">
              Alter:
            </div>
            <div class="pad2" (click)=showUmkreis()>
          
                {{ orgObj?.Meta[0].Age_From }} - {{  orgObj?.Meta[0].Age_To }}
  
     
            </div>
          </div>
  
        <div class="list-item">
          <div style=" display: flex; justify-content: start; align-items: center; width: 60%;">
            Einrichtung:
          </div>
          <div class="pad2" (click)="showEinrichtung()">
            {{ result?.Einrichtung }}
  
          
          </div>
        </div>

  
  
  
  
  
  
  
  
  
  
        <br>
        <br>
  
        <div style="display: flex; justify-content: space-between;">
  
  
          <button *ngIf="checkForModification() && !this.orgObj?.isTemp" mat-button style="background-color: #10684E; color:white" (click)="startAnalyse()">
            <mat-icon>start</mat-icon>
            Analyse starten
          </button>
  
          <button *ngIf="this.orgObj?.isTemp && selectedName && checkForModification() && this.orgObj?.root?.Meta[0].Name"mat-button style="background-color: #10684E; color:white" (click)="storeItem()">
            <mat-icon>save</mat-icon>
           {{ this.orgObj?.root?.Meta[0].Name }} überschreiben
          </button>
  
  
          <button *ngIf="this.orgObj?.isTemp"mat-button style="background-color: #10684E; color:white" (click)="this.addMode = true; showName(true); ">
            <mat-icon>save</mat-icon>
           Neue Datei 
          </button>
       
        </div>
  
  
  
  
  
      </div>
      <div class="right">

        <div class="list-item" style="color:white;font-weight:400; background-color:#10684E" >
          <div> 
           <h3 style="font-weight:300;"> Rasteranalyse </h3>
           {{ result?.Einrichtung }} <ng-container *ngIf="result?.Point_Params"> ({{result?.Point_Params.length }}) </ng-container>
         </div>
        </div>
     
  
        <div class="scrollable">
          <div *ngFor="let einrichtung of result?.Point_Params; let i = index">
            <div class="list-item"
              [ngStyle]="{'background-color':  (selectedEinrichtung == einrichtung || clickedName == einrichtung.Name )? 'green' : 'white'}"
              (click)="clickEinrichtung(einrichtung)"
              *ngIf="checkEinrichtungFilter(einrichtung)">
              <div style=" display: flex; justify-content: start; align-items: center; width: 60%;"
                [id]="einrichtung.Name">
                {{ einrichtung.Name }} <br>
                {{ einrichtung.Typ }} | {{ einrichtung.Kategorie }}
              </div>
              <div class="pad">
                {{ einrichtung.share_population }} %
              </div>
            </div>
  
          </div>
  
        </div>
  
  
  
      </div>
  
    </div>
  
  
  
  
  
  </div>

  <!--
  
  <dialog id="einrichtung">
    <div>
  
  
      <b> Einrichtungstypen </b>
      <br>
      <br>
      <b> Wählen Sie beliebig viele Einrichtungstypen aus:</b>
  
  
  
  
  
      <mat-selection-list [(ngModel)]="selectedEinrichtungen" [multiple]="true">
        <div *ngFor="let metaobj of meta">
          <mat-list-option [value]="item.Typ" *ngFor="let item of metaobj.Typen" style="border-bottom: 2px solid grey;">
            <img src='{{ "../../../../assets/icon/" + item.Icon  }}' width="20px">
            <b> {{item.Typ}} </b>
          </mat-list-option>
        </div>
      </mat-selection-list>
  
  
  
      <div style="display: flex; justify-content: space-between;">
  
        <span style="margin-right: 0; " class="span2" (click)="saveEinrichtung();closeEinrichtung()"> Speichern
          <button mat-mini-fab style="background-color: #10684E; color: white">
            <mat-icon>save </mat-icon>
          </button>
  
        </span>
      </div>
  
  
  
  
    </div>
  
  </dialog>
  
  
  <dialog id="umkreis">
  
    <div>
      <br>
      <br>
      <br>
      <span class="span0"> Umkreis</span> <br>
      <span class="span0"> Wählen Sie ihren Radius aus: </span>
  
      <div style="display: flex; justify-content: center; flex-direction: column;">
        <button class="btn2" (click)="umkreisMode = 0; selectedRadius = orgObj?.Meta[0].Distanz "> Via Radius </button>
        <br>
        <div *ngIf="umkreisMode == 0">
  
          <div style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
            <div> <span class="span0"> {{ selectedRadius }} Meter </span> </div>
            <mat-slider style="width: 90%;" min="0" max="10000" step="1" [(ngModel)]="selectedRadius " discrete>
              <input matSliderThumb (change)="setValue($event, 'radius')">
            </mat-slider>
          </div>
  
  
        </div>
  
        <br>
        <button class="btn2" (click)="umkreisMode = 1; selectedRadius= orgObj?.Meta[0].Distanz "> Via Zeit </button>
  
  
        <div *ngIf="umkreisMode == 1">
  
          <div style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
            <div> <span class="span0"> {{ selectedRadius }} Minuten </span> </div>
            <mat-slider style="width: 90%;" min="0" max="60" step="1" [(ngModel)]="selectedRadius " discrete>
              <input matSliderThumb (change)="setValue($event, 'time')">
            </mat-slider>
          </div>
  
  
        </div>
  
  
  
      </div>
  
  
  
      <div style="display: flex; justify-content: space-between;">
  
        <span style="margin-right: 0; " class="span2" (click)="saveUmkreis(); closeUmkreis()"> Speichern
          <button mat-mini-fab style="background-color: #10684E; color: white">
            <mat-icon>save </mat-icon>
          </button>
  
        </span>
      </div>
  
    </div>
  
  </dialog>
  
  
  <dialog id="name">
  
    <div style="padding: 40px;">
      <br>
  
      <span class="span0"> Wählen Sie einen Namen aus: </span>
      <br>
      <br>
  
      <div>
  
        <input name="name" placeholder="Name..." style="width: 100%; " [(ngModel)]="selectedName " />
  
      </div>
  
  
  
      <div style="display: flex; justify-content: space-between;">
  
        <span style="margin-right: 0; " class="span2" (click)="closeName()"> Speichern
          <button mat-mini-fab style="background-color: #10684E; color: white">
            <mat-icon>save </mat-icon>
          </button>
  
        </span>
      </div>
  
    </div>
  
  </dialog>
  
  
  <dialog id="dialog_mini">
    <div>
  
      <br>
      <br>
      <br>
      <b> Standort </b>
      <br>
      <br>
  
      <location-app> </location-app>
  
      <div style="display: flex; justify-content: space-between;">
  
        <span style="margin-right: 0; " class="span2" (click)="saveStandort(); closeStandort()"> Speichern
          <button mat-mini-fab style="background-color: #10684E; color: white">
            <mat-icon>save </mat-icon>
          </button>
  
        </span>
      </div>
  
  
    </div>
  
  </dialog>
  
  
  <dialog id="overwrite">
  
    <div style="padding: 40px;">
      <br>
      <span class="span0"> Achtung! </span> <br>
      <b> {{ this.orgObj?.root?.Meta[0].Name }} wird überschreiben. </b>
  
      <div style="display: flex; justify-content: space-between;">
  
        <span style="margin-right: 0; " class="span2" (click)="overwrite()"> Okay
          <button mat-mini-fab style="background-color: #10684E; color: white">
            <mat-icon>save </mat-icon>
          </button>
        </span>
  
  
   
      </div>
  
    </div>
  
  </dialog>

  -->