import { Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Storageservice } from '../../service/storageservice-component/storageservice-component.component';
import { DatahubService } from '../../service/datahubservice/datahubservice.component';
import { KeycloakOperationService } from 'src/app/service/keycloakService/keycloak-operation.service';
import { ChatService } from 'src/app/service/chatService/chat.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Data } from 'plotly.js-dist-min';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent {

  wait: boolean = false;
  feedbackMsg: string = '';
category: any;


  async sendFeedback() {
  this.wait = true;

  var message = window.location.href + " " + this.userName + " " + this.textinput //todo: do something

 var feedback = {
  category: this.category,
  user: this.userName,
  url: window.location.href, 
  message: this.textinput

  }

  await this.datahubService.addFeedback(feedback)

  this.textinput = ''

  setTimeout(() => {
    this.wait = false

    this.textinput = "Vielen Dank für ihr Feedback!"
    setTimeout(()=>{
      this.textinput = ''
      this.closeDialog(); 
    }, 2000)
  }, 2000
  )

}
textinput: any = 'Hier Feedback eintippen.. ';
closeDialog() {
       //@ts-ignore
       document.getElementById('feedback-dialog').close();
}
  routeCheck: any;
  auth: boolean = false;
  iconVisible: any = false;
  userName: any;
  viewMode = '';
  selectedObject: any;
  notificationsCount: number =0;
  @Input() drawer!: any;

  constructor(
    public router: Router,
    private storageService: Storageservice,
    private keyCloakService: KeycloakOperationService,
    private chatService: ChatService,
    private datahubService: DatahubService
  ) {
    setInterval(() => {
      this.selectedObject = this.storageService.getObject();
      //console.log("Route" + this.router.url)// === '/login'
      this.auth = this.storageService.getAuth();
      //console.log(this.auth)
      this.routeCheck = this.router.url === '/start' || this.router.url === '/';
    }, 1 * 1000);
  }

  ngOnInit() {
    this.getUserName();
    this.chatService.getNotificationCountObservable().subscribe((count:any)=>{
      this.notificationsCount= count
    })
  }

  changeLandkreis() {
    this.router.navigateByUrl('/start');
  }

  backLogin() {
    this.drawer.close();
    this.router.navigateByUrl('');
  }

  openFeedback() {
       //@ts-ignore
       document.getElementById('feedback-dialog').showModal();


  }

  mouseLeave() {
    this.iconVisible = false;
  }

  mouseEnter() {
    this.iconVisible = true;
  }

  click(viewMode: string) {
    this.viewMode = viewMode;
    this.router.navigateByUrl(viewMode);
  }

  logout() {
    this.keyCloakService.logout();
  }

  getUserName() {
    this.keyCloakService.getUserProfile().then((data: any) => {
      const firstName = this.capitalizeFirstLetter(data.firstName);
      
      if(data.lastName){
        const lastName = this.capitalizeFirstLetter(data.lastName);
        this.userName = `${firstName} ${lastName}`;}
      else{
        this.userName = `${firstName}`;
      }
      //console.warn("currentUser", data)
     // console.log(this.userName);
      
    });
  }

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  toggleDrawer() {
    this.drawer.toggle();
  }

  

}
