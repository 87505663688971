import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HospitalService {

  private apiUrl

  constructor(private http:HttpClient,) {
    const useLocal = false;
    this.apiUrl = useLocal ? 'http://localhost:3000/hospital' : 'https://dashboardservice.cephlabs.de/hospital';
   }

   async getHospitals() {
    const url = `${this.apiUrl}/getHospital`;
    const result = await firstValueFrom(this.http.get<any>(url));
    return result;
  }

  getDiseases(){
    return [
      {"Unterkategorie": "Achillessehne", "Hauptkategorie": "Fuß"},
      {"Unterkategorie": "Akne", "Hauptkategorie": "Haut"},
      {"Unterkategorie": "Alkoholische Leberkrankheiten", "Hauptkategorie": "Leber"},
      {"Unterkategorie": "Alzheimer/Demenz", "Hauptkategorie": "Gehirn/Nervensystem"},
      {"Unterkategorie": "Andere OPs am Herzen", "Hauptkategorie": "Herz/Kreislauf"},
      {"Unterkategorie": "Angststörungen", "Hauptkategorie": "Psychatrie/Psychosomatik"},
      {"Unterkategorie": "Arthrose am Hüftgelenk", "Hauptkategorie": "Knochen/Gelenke"},
      {"Unterkategorie": "Arthrose am Kniegelenk", "Hauptkategorie": "Knochen/Gelenke"},
      {"Unterkategorie": "Asthma", "Hauptkategorie": "Lunge"},
      {"Unterkategorie": "Augenlid", "Hauptkategorie": "Auge"},
      {"Unterkategorie": "Augenverletzungen", "Hauptkategorie": "Auge"},
      {"Unterkategorie": "Ausstülpungen am Dickdarm", "Hauptkategorie": "Verdauung"},
      {"Unterkategorie": "Autoimmunerkrankungen/Entzündungen", "Hauptkategorie": "Knochen/Gelenke"},
      {"Unterkategorie": "Autoimmunerkrankungen/Neurodermitis", "Hauptkategorie": "Haut"},
      {"Unterkategorie": "Ballenzeh", "Hauptkategorie": "Fuß"},
      {"Unterkategorie": "Bandscheibenvorwölbung/-vorfall", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Bindehaut", "Hauptkategorie": "Auge"},
      {"Unterkategorie": "Blinddarm", "Hauptkategorie": "Verdauung"},
      {"Unterkategorie": "Bluthochdruck", "Hauptkategorie": "Herz/Kreislauf"},
      {"Unterkategorie": "Bronchitis", "Hauptkategorie": "Lunge"},
      {"Unterkategorie": "Brustfell", "Hauptkategorie": "Lunge"},
      {"Unterkategorie": "Brustkrebs", "Hauptkategorie": "Brust"},
      {"Unterkategorie": "Brustwarzenausfluss (Galaktorrhoe)", "Hauptkategorie": "Brust"},
      {"Unterkategorie": "Brüche (Hernien)", "Hauptkategorie": "Verdauung"},
      {"Unterkategorie": "Bypass", "Hauptkategorie": "Herz/Kreislauf"},
      {"Unterkategorie": "Bösartige Hauterkrankungen und Melanom", "Hauptkategorie": "Haut"},
      {"Unterkategorie": "COPD", "Hauptkategorie": "Lunge"},
      {"Unterkategorie": "Carcinom in situ in der Brustdrüse", "Hauptkategorie": "Brust"},
      {"Unterkategorie": "Dammriss", "Hauptkategorie": "Geburt"},
      {"Unterkategorie": "Darmverschluss", "Hauptkategorie": "Verdauung"},
      {"Unterkategorie": "Dekubitalgeschwür/Druckulkus", "Hauptkategorie": "Haut"},
      {"Unterkategorie": "Depressionen", "Hauptkategorie": "Psychatrie/Psychosomatik"},
      {"Unterkategorie": "Dickdarmkrebs", "Hauptkategorie": "Verdauung"},
      {"Unterkategorie": "Eierstöcke und Eileiter", "Hauptkategorie": "Weibliche Geschlechtsorgane"},
      {"Unterkategorie": "Einengung des Rückenmarkkanals", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Einsatz/Wechsel künstliches Kniegelenk", "Hauptkategorie": "Knie"},
      {"Unterkategorie": "Enddarm und After", "Hauptkategorie": "Verdauung"},
      {"Unterkategorie": "Enddarmkrebs", "Hauptkategorie": "Verdauung"},
      {"Unterkategorie": "Entzündliche Krankheiten in der Brustdrüse", "Hauptkategorie": "Brust"},
      {"Unterkategorie": "Entzündung der Hahnblase", "Hauptkategorie": "Männliche Geschlechtsorgane"},
      {"Unterkategorie": "Entzündung der Magen- und Darmschleimhaut", "Hauptkategorie": "Verdauung"},
      {"Unterkategorie": "Entzündungen der Haut (akut/chronisch)", "Hauptkategorie": "Haut"},
      {"Unterkategorie": "Entzündungen der NNH", "Hauptkategorie": "Lunge"},
      {"Unterkategorie": "Epilepsie", "Hauptkategorie": "Gehirn/Nervensystem"},
      {"Unterkategorie": "Essstörungen", "Hauptkategorie": "Psychatrie/Psychosomatik"},
      {"Unterkategorie": "Fehllage des Kindes", "Hauptkategorie": "Geburt"},
      {"Unterkategorie": "Fibrose und Zirrhose der Leber", "Hauptkategorie": "Leber"},
      {"Unterkategorie": "Finger und Zehen", "Hauptkategorie": "Knochen/Gelenke"},
      {"Unterkategorie": "Frakturen und Blutungen", "Hauptkategorie": "HNO"},
      {"Unterkategorie": "Gallensteine/Gallenblase", "Hauptkategorie": "Verdauung"},
      {"Unterkategorie": "Gaumen", "Hauptkategorie": "Zähne/Gebiß/Zunge"},
      {"Unterkategorie": "Gaumen und Rachenmandeln", "Hauptkategorie": "HNO"},
      {"Unterkategorie": "Gaumen- und Rachenmandeln (chronisch)", "Hauptkategorie": "Lunge"},
      {"Unterkategorie": "Geburt", "Hauptkategorie": "Geburt"},
      {"Unterkategorie": "Geburt mit Zangen oder Saugglocke", "Hauptkategorie": "Geburt"},
      {"Unterkategorie": "Gebärmutter", "Hauptkategorie": "Weibliche Geschlechtsorgane"},
      {"Unterkategorie": "Gebärmutterhals", "Hauptkategorie": "Weibliche Geschlechtsorgane"},
      {"Unterkategorie": "Gehirnerschütterung", "Hauptkategorie": "Gehirn/Nervensystem"},
      {"Unterkategorie": "Gelenk-Operationen", "Hauptkategorie": "Fuß"},
      {"Unterkategorie": "Glaskörper", "Hauptkategorie": "Auge"},
      {"Unterkategorie": "Gleichgewichtsstörungen", "Hauptkategorie": "HNO"},
      {"Unterkategorie": "Grippe", "Hauptkategorie": "Lunge"},
      {"Unterkategorie": "Gutartige Erkrankungen in der Brustdrüse (Mammadysplasie)", "Hauptkategorie": "Brust"},
      {"Unterkategorie": "Harnblase", "Hauptkategorie": "Männliche Geschlechtsorgane"},
      {"Unterkategorie": "Harnblase", "Hauptkategorie": "Nieren"},
      {"Unterkategorie": "Harnröhre", "Hauptkategorie": "Nieren"},
      {"Unterkategorie": "Harnwege (Verschluss am Rückstau)", "Hauptkategorie": "Nieren"},
      {"Unterkategorie": "Hautveränderungen durch Sonne", "Hauptkategorie": "Haut"},
      {"Unterkategorie": "Hepatitis (chronisch)", "Hauptkategorie": "Leber"},
      {"Unterkategorie": "Herzinfarkt/Herzmuskel/Durchblutung", "Hauptkategorie": "Herz/Kreislauf"},
      {"Unterkategorie": "Herzkatheteruntersuchung", "Hauptkategorie": "Herz/Kreislauf"},
      {"Unterkategorie": "Herzklappen", "Hauptkategorie": "Herz/Kreislauf"},
      {"Unterkategorie": "Herzschrittmacher/Lungengefäße", "Hauptkategorie": "Herz/Kreislauf"},
      {"Unterkategorie": "Hexenschuss", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Hirnfunktionsstörungen", "Hauptkategorie": "Psychatrie/Psychosomatik"},
      {"Unterkategorie": "Hirnhaut-/Gehirnentzündung", "Hauptkategorie": "Gehirn/Nervensystem"},
      {"Unterkategorie": "Hoden und Samenleiter", "Hauptkategorie": "Männliche Geschlechtsorgane"},
      {"Unterkategorie": "Hornhaut", "Hauptkategorie": "Auge"},
      {"Unterkategorie": "Hornhaut-Schwielen/Hühneraugen", "Hauptkategorie": "Haut"},
      {"Unterkategorie": "Hörverlust", "Hauptkategorie": "HNO"},
      {"Unterkategorie": "Hühneraugen und Hornhaut", "Hauptkategorie": "Fuß"},
      {"Unterkategorie": "Iliosakralgelenk-Syndrom", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Infektion", "Hauptkategorie": "Auge"},
      {"Unterkategorie": "Infektionen", "Hauptkategorie": "Haut"},
      {"Unterkategorie": "Infektionen", "Hauptkategorie": "Knochen/Gelenke"},
      {"Unterkategorie": "Infektionen", "Hauptkategorie": "Lunge"},
      {"Unterkategorie": "Ischias", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Kaiserschnitt", "Hauptkategorie": "Geburt"},
      {"Unterkategorie": "Knoten in der Brust", "Hauptkategorie": "Brust"},
      {"Unterkategorie": "Krebs", "Hauptkategorie": "Männliche Geschlechtsorgane"},
      {"Unterkategorie": "Kreuzbandriss/Meniskusriss", "Hauptkategorie": "Knochen/Gelenke"},
      {"Unterkategorie": "Lungenblutung", "Hauptkategorie": "Lunge"},
      {"Unterkategorie": "Lungenembolie/Lungengefäße", "Hauptkategorie": "Herz/Kreislauf"},
      {"Unterkategorie": "Lungengewebe", "Hauptkategorie": "Lunge"},
      {"Unterkategorie": "Lymphschwellungen", "Hauptkategorie": "Haut"},
      {"Unterkategorie": "Magengeschwürr/Zwölffingerdarm", "Hauptkategorie": "Verdauung"},
      {"Unterkategorie": "Mehrlingsschwangerschaft", "Hauptkategorie": "Geburt"},
      {"Unterkategorie": "Meniskusriss", "Hauptkategorie": "Knochen/Gelenke"},
      {"Unterkategorie": "Migräne/Kopfschmerzen", "Hauptkategorie": "Gehirn/Nervensystem"},
      {"Unterkategorie": "Morbus Bechterew", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Multiple Sklerose", "Hauptkategorie": "Gehirn/Nervensystem"},
      {"Unterkategorie": "Nesselsucht und Juckreiz", "Hauptkategorie": "Haut"},
      {"Unterkategorie": "Nierensteine/Harnleitersteine/Koliken", "Hauptkategorie": "Nieren"},
      {"Unterkategorie": "Osteoporose", "Hauptkategorie": "Knochen/Gelenke"},
      {"Unterkategorie": "Plastische Operationen", "Hauptkategorie": "Brust"},
      {"Unterkategorie": "Posttraumatische Belastungsstörungen", "Hauptkategorie": "Psychatrie/Psychosomatik"},
      {"Unterkategorie": "Prostata", "Hauptkategorie": "Männliche Geschlechtsorgane"},
      {"Unterkategorie": "Querschnittslähmung", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Reizdarmsyndrom (RDS)", "Hauptkategorie": "Verdauung"},
      {"Unterkategorie": "Rückenschmerz", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Schädel-Hirn-Trauma", "Hauptkategorie": "Gehirn/Nervensystem"},
      {"Unterkategorie": "Schielen/Sehstörung", "Hauptkategorie": "Auge"},
      {"Unterkategorie": "Schmerzen in der Brust (Masstodynie)", "Hauptkategorie": "Brust"},
      {"Unterkategorie": "Schlaganfall/Hirnblutung", "Hauptkategorie": "Gehirn/Nervensystem"},
      {"Unterkategorie": "Schlafstörung", "Hauptkategorie": "Gehirn/Nervensystem"},
      {"Unterkategorie": "Schlafstörungen", "Hauptkategorie": "HNO"},
      {"Unterkategorie": "Schwangerschaftsdiabetes", "Hauptkategorie": "Geburt"},
      {"Unterkategorie": "Scheiden-/Gebärmuttervorfall (Genitalprolaps)", "Hauptkategorie": "Weibliche Geschlechtsorgane"},
      {"Unterkategorie": "Sehnerven", "Hauptkategorie": "Auge"},
      {"Unterkategorie": "Sterilität", "Hauptkategorie": "Männliche Geschlechtsorgane"},
      {"Unterkategorie": "Stens", "Hauptkategorie": "Herz/Kreislauf"},
      {"Unterkategorie": "Toxische Leberkrankheiten", "Hauptkategorie": "Leber"},
      {"Unterkategorie": "Tränenapparat", "Hauptkategorie": "Auge"},
      {"Unterkategorie": "Tumorerkrankung", "Hauptkategorie": "Auge"},
      {"Unterkategorie": "Unterschenkelgeschwür", "Hauptkategorie": "Haut"},
      {"Unterkategorie": "Verhaltens-/Persönlichkeitsstörungen", "Hauptkategorie": "Psychatrie/Psychosomatik"},
      {"Unterkategorie": "Vorzeitige Entbindung", "Hauptkategorie": "Geburt"},
      {"Unterkategorie": "Vorzeitiger Blasensprung", "Hauptkategorie": "Geburt"},
      {"Unterkategorie": "Wachstumsschäden/Deformitäten/Fehlstellungen", "Hauptkategorie": "Knochen/Gelenke"},
      {"Unterkategorie": "Wirbel/Bandscheiben", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Wirbelgelenk-Arthrose", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Wirbelgleiten", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Wirbelsäulenbruch", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Wirbelsäulenentzündung", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Wirbelsäulenverkrümmung", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "X-/O-Beine", "Hauptkategorie": "Knochen/Gelenke"},
      {"Unterkategorie": "Zehengelenk", "Hauptkategorie": "Fuß"},
      {"Unterkategorie": "Zähne/Gebiß/Zunge", "Hauptkategorie": "Zähne/Gebiß/Zunge"}
    ]
    
  }

}
