
<div style="padding: 50px"> 
  <div style="display: flex; align-items: center; justify-content: space-between;">
 


<h1> Neue Prognose anlegen</h1>
<button  mat-fab  color="primary" (click)="closeAll()"> <mat-icon>cancel</mat-icon>  </button>

</div>



<div id="page1"> 
  <mat-spinner *ngIf="buffer"> </mat-spinner>

    <div> <input id="adress" name="adress" (click)="name = ''" [(ngModel)]="name" /> </div>


<div> 


<mat-form-field>
    <mat-label>Notfallinfrastruktur wählen</mat-label>
    <mat-select disableRipple>
      <mat-option value="1" selected>Notfallinfrastruktur 1</mat-option>

    </mat-select>
  </mat-form-field>


  <mat-form-field>
    <mat-label>Population wählen</mat-label>
    <mat-select disableRipple >


      <mat-option *ngFor="let p of pop" value="{{ p.name }}"> {{ p.name }} </mat-option>
   
    </mat-select>
  </mat-form-field>


</div>
<div>


<mat-form-field>
    <mat-label>von</mat-label>
    <mat-select disableRipple [(ngModel)] = "startYear">
      <mat-option value="2024">2024</mat-option>
      <mat-option value="2025">2025</mat-option>
      <mat-option value="2026">2026</mat-option>
      <mat-option value="2027">2027</mat-option>
      <mat-option value="2028">2028</mat-option>
      <mat-option value="2029">2029</mat-option>
      <mat-option value="2030">2030</mat-option>
      <mat-option value="2031">2031</mat-option>
      <mat-option value="2032">2032</mat-option>
      <mat-option value="2033">2033</mat-option>
      <mat-option value="2034">2034</mat-option>

    </mat-select>
  </mat-form-field>



<mat-form-field>
    <mat-label>bis</mat-label>
    <mat-select disableRipple [(ngModel)] = "endYear">

      <mat-option value="2025">2025</mat-option>
      <mat-option value="2026">2026</mat-option>
      <mat-option value="2027">2027</mat-option>
      <mat-option value="2028">2028</mat-option>
      <mat-option value="2029">2029</mat-option>
      <mat-option value="2030">2030</mat-option>
      <mat-option value="2031">2031</mat-option>
      <mat-option value="2032">2032</mat-option>
      <mat-option value="2033">2033</mat-option>
      <mat-option value="2034">2034</mat-option>
      <mat-option value="2035">2035</mat-option>
    </mat-select>
  </mat-form-field>


</div>


<button  mat-fab extended color="primary" (click)="startAnalyse()"> <mat-icon>start</mat-icon> Start </button>



</div>

</div>


