import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-color',
  templateUrl: './dialog-color.component.html',
  styleUrls: ['./dialog-color.component.css']
})
export class DialogColorComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogColorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {viridisCustom:string[],color1:string,color2:string ,colorScheme:string[],breaksAmount:number},
  ) {}

  generateColorScheme() {
    this.data.colorScheme = [];
    for (let i = 0; i < this.data.breaksAmount; i++) {
      const ratio = i / (this.data.breaksAmount-1);
      const color = this.interpolateColor(this.data.color1, this.data.color2, ratio);
      this.data.colorScheme.push(color);
    }
    let result={
      setBreaks:true,
      colorScheme:this.data.colorScheme,
      color1:this.data.color1,
      color2:this.data.color2,
    }
    return result
  }

  interpolateColor(color1: string, color2: string, ratio: number): string {
    const hex = (color: string) => parseInt(color.slice(1), 16);
    const r = Math.ceil(((1 - ratio) * (hex(color1) >> 16)) + (ratio * (hex(color2) >> 16)));
    const g = Math.ceil(((1 - ratio) * ((hex(color1) >> 8) & 0x00FF)) + (ratio * ((hex(color2) >> 8) & 0x00FF)));
    const b = Math.ceil(((1 - ratio) * (hex(color1) & 0x0000FF)) + (ratio * (hex(color2) & 0x0000FF)));
    return `#${(0x1000000 + (r * 0x10000) + (g * 0x100) + b).toString(16).slice(1)}`;
  }

  drawAfternewColors(){
    this.dialogRef.close(this.generateColorScheme());
  }


  doNotUseCustomColor(){
    let result={setBreaks:false}
    return result
   }

  closePassDefault(): void {
    this.dialogRef.close(this.doNotUseCustomColor());
  }

  closeAll(): void {
    this.dialogRef.close();
  }
}
