import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GibietParamsDialogComponent } from 'src/app/components/datahub2/gebiete/gibiet-params-dialog/gibiet-params-dialog.component';
import { DatahubService } from 'src/app/service/datahubservice/datahubservice.component';
import { RegioService } from 'src/app/service/regioservice/regioservice.component';
import { Chart } from 'chart.js/auto';

@Component({
  selector: 'app-gefahranalyse',
  templateUrl: './gefahranalyse.component.html',
  styleUrls: ['./gefahranalyse.component.css'],
})
export class GefahranalyseComponent {
  sizeClassOutside: number = 20;
  sizeClassCenter: number = 30;
  countYears: number = 1;
  countSchool: number = 0;
  gefahrAnalyse: any[] = [];
  step: number = 0;
  resultGefahr = [];
  schoolSearch: any = '';

  extractRawData(data: any[]) {
    return data.map((item) => item.raw);
  }
  endAnalyse() {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    this.dialogRef.close({
      schools: this.data.schools,
      sizeClassOutside: this.sizeClassOutside,
      sizeClassCenter: this.sizeClassCenter,
      countYears: this.countYears,
      name: 'G_Analyse_' + hours + ':' + minutes + ':' + seconds,
    });
  }

  endUmverteilungsAnalyse() {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    //API CALLL
    var result = ''; //TODO: API ERGEBNIS
    this.dialogRef.close({
      schools: result,
      name: 'U_Analyse_' + hours + ':' + minutes + ':' + seconds,
    });
  }

  startAnalyse() {
    this.data.schools.forEach((school: any) => {
      if (school.raw.checked) {
        var count = 0;
        if (school.raw.properties.Mittelzentrum) {
          var refValue = this.sizeClassCenter;
        } else {
          refValue = this.sizeClassOutside;
        }

        console.warn(school.raw.properties.Schulname, refValue);

        for (const year in school.raw.properties.Klassengroessen) {
          if (school.raw.properties.Klassengroessen[year] < refValue) {
            count++;
          }
        }

        if (count >= this.countYears) {
          school.raw.inDanger = true;
          this.countSchool++;
        }
      }
    });

    this.step++;
  }

  downloadJson(type: any) {
    console.warn('DDD', this.data);
    var t = '';
    var d: any;
    if (type == 'school') {
      d = this.extractRawData(this.data.schools);
      t = 'school.json';
    }

    if (type == 'bezirke') {
      d = this.extractRawData(this.data.bezirke);

      t = 'bezirke.json';
    }

    const jsonString = JSON.stringify(d, null, 2); // JSON in lesbaren Text umwandeln
    const blob = new Blob([jsonString], { type: 'application/json' }); // Blob erstellen
    const url = window.URL.createObjectURL(blob); // Objekt-URL erstellen

    // Download-Link erstellen und klicken
    const a = document.createElement('a');
    a.href = url;
    a.download = t;
    a.click();

    // URL wieder freigeben
    window.URL.revokeObjectURL(url);
  }

  constructor(
    public dialogRef: MatDialogRef<GibietParamsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private regioservice: RegioService
  ) {
    this.gefahrAnalyse = data.gefahrAnalysen;

    console.warn('existing ', data);
  }

  selectSchool() {
    this.data.schools.forEach((element: any) => {
      element.raw.checked = true;
    });
  }
  deselectSchool() {
    this.data.schools.forEach((element: any) => {
      element.raw.checked = false;
    });
  }

  goToMobility() {
    this.router.navigateByUrl('prognose/schoolMobility');
    this.dialogRef.close();
  }

  async getWithoutSch() {
if(!this.data.stepIso)
{

    const without = await this.regioservice.getWithoutSch();
    console.log(without);
    const parsedWithout = JSON.parse(without[0].data_data);
    console.log(parsedWithout);

    parsedWithout.forEach((item: any) => {
      item.Einschulung = JSON.parse(item.Einschulung);
    });

    console.log(parsedWithout);

    // Extract years and data for each school
    const schoolNames = parsedWithout.map((item: any) => item.Schulname);
    const years = Object.keys(parsedWithout[0].Einschulung);
    
    const datasets = parsedWithout.map((school: any) => {
      return {
        label: school.Schulname,
        data: years.map((year: string) => school.Einschulung[year]),
        backgroundColor: this.getRandomColor(), // Function to set unique colors for each school
      };
    });

    const ctx = document.getElementById('myChart') as HTMLCanvasElement;
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: years,
        datasets: datasets,
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Enrollment Numbers by School Year',
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
            title: {
              display: true,
              text: 'School Year',
            },
          },
          y: {
            stacked: true,
            title: {
              display: true,
              text: 'Number of Enrollments',
            },
          },
        },
      },
    });

  }
  else if(this.data.stepIso){
    const without = await this.regioservice.getWithtSch();
    console.log(without);
    const parsedWithout = JSON.parse(without[0].data_data);
    console.log(parsedWithout);

    parsedWithout.forEach((item: any) => {
      item.Einschulung = JSON.parse(item.Einschulung);
    });

    console.log(parsedWithout);

    // Extract years and data for each school
    const schoolNames = parsedWithout.map((item: any) => item.Schulname);
    const years = Object.keys(parsedWithout[0].Einschulung);
    
    const datasets = parsedWithout.map((school: any) => {
      return {
        label: school.Schulname,
        data: years.map((year: string) => school.Einschulung[year]),
        backgroundColor: this.getRandomColor(), // Function to set unique colors for each school
      };
    });

    const ctx = document.getElementById('myChart2') as HTMLCanvasElement;
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: years,
        datasets: datasets,
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Enrollment Numbers by School Year',
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
            title: {
              display: true,
              text: 'School Year',
            },
          },
          y: {
            stacked: true,
            title: {
              display: true,
              text: 'Number of Enrollments',
            },
          },
        },
      },
    });
  }
  }

  getRandomColor() {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return `rgba(${r}, ${g}, ${b}, 0.7)`;
}

close(){
  this.dialogRef.close({resetPoint:true});
}


getNewSchoolPoints(){
  this.dialogRef.close({newPoints:true});
}
}
