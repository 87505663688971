import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Observer, Subject } from 'rxjs';
import { Socket } from 'ngx-socket-io';
import { KeycloakOperationService } from '../keycloakService/keycloak-operation.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService implements OnInit {
  private apiUrl = 'https://chatservice.cephlabs.de';
  private notificationObservable!: Observable<any>;
  notificationsCount: number = 0;
  notificationCountSubject: Subject<number> = new Subject<number>();
  users!: any;
  userWithUpdatedNotification: any;

  constructor(
    private http: HttpClient,
    private socket: Socket,
    private keyCloakService: KeycloakOperationService
  ) {}

  ngOnInit() {
    this.keyCloakService.getUsersList().subscribe((users: any) => {
      this.users = users;
    });
  }

  /////////////load ansd send messages between 2 useres ////////////////
  loadMessages(userId1: any, userId2: any) {
    const queryParams = `?userId1=${userId1}&userId2=${userId2}&_=${new Date().getTime()}`;
    return this.http.get(`${this.apiUrl}/messages${queryParams}`);
  }

  saveMessage(message: {
    from: any;
    to: any;
    content: string;
  }): Observable<any> {
    const timestamp = new Date().getTime();
    return this.http.post(
      `${this.apiUrl}/messages?timestamp=${timestamp}`,
      message
    );
  }

  getMessageUpdates(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.socket.on('newMessage', (message: any) => {
        observer.next(message);
        console.log(message);
      });
    });
  }

  joinRoom(userId1: any, userId2: any): void {
    const room = [userId1, userId2].sort().join('-');
    this.socket.emit('joinRoom', room);
  }

  ///////////////get the online/offline satuts of useres/////////////////
  connectUser(userId: string) {
    this.socket.emit('userConnected', userId);
  }

  getUserStatusUpdates(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('userStatusChanged', (statusUpdate: any) => {
        observer.next(statusUpdate);
      });
    });
  }

  getOnlineUsers(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('onlineUsersList', (onlineUsers: any) => {
        console.log('Received online users list:', onlineUsers);
        observer.next(onlineUsers);
      });
    });
  }

  ////////////////send and load messages in groups/////////////////////////

  loadGroupMessages(userId1: any, groupId: any) {
    const queryParams = `?userId1=${userId1}&groupId=${groupId}&_=${new Date().getTime()}`;
    return this.http.get(`${this.apiUrl}/groupMessages${queryParams}`);
  }

  createGroup(group: any) {
    return this.http.post(`${this.apiUrl}/groups`, group);
  }

  getGroups(): Observable<any[]> {
    const queryParams = `?_=${new Date().getTime()}`;
    return this.http.get<any[]>(`${this.apiUrl}/groups${queryParams}`);
  }

  saveMessageGroupe(message: {
    from: any;
    to: any;
    content: string;
  }): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/groupMessages`, message);
  }

  getMessageUpdatesGroup(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.socket.on('newGroupMessage', (message: any) => {
        observer.next(message);
        console.log(message);
      });
    });
  }

  getGroupeUpdates(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.socket.on('createdNewGroupe', (message: any) => {
        observer.next(message);
        console.log(message);
      });
    });
  }

  joinGroup(userId: any, groupId: any): void {
    const groupRoom = `group-${groupId}`;
    this.socket.emit('joinGroup', { userId, groupRoom });
  }

  /////////////////////notify useres and groups/////////////////////
  notifyUser(id: any) {
    const userId = `notify-${id}`;
    this.socket.emit('createNotificationArea', { userId });
  }

  getNotificationUpdates(): Observable<any> {
    if (!this.notificationObservable) {
      this.notificationObservable = new Observable((observer) => {
        this.socket.on('notification', (notification: any) => {
          observer.next(notification);
          this.notificationsCount++;
          this.notificationCountSubject.next(this.notificationsCount);
          console.log('notificationsCount: ', this.notificationsCount);
          console.log(notification);
        });
      });
    }
    return this.notificationObservable;
  }

  getNotificationUpdatesGroupe(userIds: any) {
    this.socket.emit('sendNotifGroups', { userIds });
  }

  getNotificationCountObservable(): Observable<number> {
    return this.notificationCountSubject.asObservable();
  }

  private userNotificationsSubject = new BehaviorSubject(this.users);
  userNotifications$ = this.userNotificationsSubject.asObservable();

  updateNotificationBadge(userId: string, count: number, users: any) {
    const user = users.find((u: any) => u.id === userId);
    console.log(users);
    if (user) {
      console.log(user);
      this.userWithUpdatedNotification = user;
      user.notificationsCount = count;
      this.userNotificationsSubject.next(users); // Emit the updated users list
    }
    console.log(users);
  }

  getUserWithUpdatedNotification() {
    return this.userWithUpdatedNotification;
  }

  setUniqueUSerNotification(): Observable<number> {
    return this.userNotificationsSubject.asObservable();
  }

  clearNotif(receiver: any, sender: any) {
    const body = { receiver, sender };
    console.log('clear');
    return this.http.post(`${this.apiUrl}/clearNotification`, body);
  }

  loadNotifications(): Observable<any> {
    const timestamp = `?_=${new Date().getTime()}`;
    return this.http.get<any>(`${this.apiUrl}/loadNotification${timestamp}`);
  }

  areUsersInSameRoom(userId1: string, userId2: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const room = [userId1, userId2].sort().join('-');
      this.socket.emit('checkRoom', room, (result: boolean) => {
        observer.next(result);
        observer.complete();
      });
    });
  }
  ////////////////disconnect user//////////////////
  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
  numberNot:any

  setnotificationcount(x:any){
    this.numberNot=x
  }

  getnotificationcount(){
    return this.numberNot
  }
}
