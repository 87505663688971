import { Icon, latLng, tileLayer } from 'leaflet';
import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-draw';
import 'leaflet-editable';
import { RegioService } from 'src/app/service/regioservice/regioservice.component';


import { MatDialog } from '@angular/material/dialog';
import { v4 as uuidv4 } from 'uuid'; // Import UUID generator

import { Chart, Plugin } from 'chart.js';
import { StartDialogComponent } from './schulplanung-dialog/start-dialog/start-dialog.component';
import { DatahubService } from 'src/app/service/datahubservice/datahubservice.component';
import { GefahranalyseComponent } from './schulplanung-dialog/gefahranalyse/gefahranalyse.component';
import { Storageservice } from 'src/app/service/storageservice-component/storageservice-component.component';
import { SaveDialogComponent } from './schulplanung-dialog/save-dialog/save-dialog.component';
import { KeycloakOperationService } from 'src/app/service/keycloakService/keycloak-operation.service';

// Interface to store polygon information
interface PolygonInfo {
  id: string;
  geoJSON: any;
  count: number;
}

@Component({
  selector: 'app-schulplanung',
  templateUrl: './schulplanung.component.html',
  styleUrls: ['./schulplanung.component.css']
})
export class SchulplanungComponent {
  userList: any;
  userID: any;

  extractRawData(data: any[]) {
    return data.map(item => item.raw);
  };
  async savePop() {
    console.warn(this.schools, this.bezirke, this.gefahrAnalyseResults)
    var obj = {
      schools: this.extractRawData(this.schools),
      bezirke: this.extractRawData(this.bezirke),
      analyse: this.gefahrAnalyseResults
    }
    await this.datahubService.addSchulprognose(obj)

  }
  showLine = true;
  tableData = [
    { schuljahr: '2024/25', klassengroesse: 50, regelzuegigkeit: 3 },
    { schuljahr: '2025/26', klassengroesse: 45, regelzuegigkeit: 3 },
    { schuljahr: '2026/27', klassengroesse: 53, regelzuegigkeit: 3 },
    { schuljahr: '2027/28', klassengroesse: 46, regelzuegigkeit: 3 },
    { schuljahr: '2028/29', klassengroesse: 45, regelzuegigkeit: 3 },
    { schuljahr: '2029/30', klassengroesse: 48, regelzuegigkeit: 3 },
  ];
  newSchoolName: any = "Neue Schule";
  drawAffectedSchools() {

    this.deselectBezirke();

    console.warn("BZ", this.bezirke)
    this.bezirke.forEach((element: any) => {
      if (element.raw.properties.glm_id == this.selectedSchoolTemp['properties'].gml_id) {
        element.raw.checked = true;
        this.map.addLayer(element.layer)

      } else {

        element.raw.checked = false;

      }
    })



    this.schools.forEach((element: any) => {
      element.raw.checked = false;
      if (this.affectedSchools.includes(element)) {
        element.raw.inDanger = true
        element.raw.checked = true;
      }



    });

    this.showGrafik(false);



  }
  setMittelschule(event: any, school: any) {
    school.raw.properties.Mittelzentrum = event.target.checked
  }

  selectedSchoolTemp: any = { properties: {} };

  highlightedLayerMulti: any = [];
  gefahrAnalyseResults: any = [];


  addBezirkForNewSchool() {


    //this.drawEditAbleShapesNew([])
    this.selectBezirke();

    this.selectedSchoolTemp.properties.Schulname = "Neue Schule";
    // this.selectedSchoolTemp.properties.gml_id = "NewSchool_" +  Date.now(); 
    this.map.setZoom(11)
    this.changeBezirkOfSchool = true;
    this.addSchoolMode = true;
    this.close('.newSchool')
  }

  affectedSchools: any[] = []


  methChgBezirkOfSchool(abort: boolean) {


    var gmlId = this.selectedSchoolTemp?.properties?.gml_id




    this.highlightedLayerMulti.forEach((element: any) => {
      if (!abort) {
        if (!this.affectedSchools.includes(this.getSchoolForGML(element.raw.properties.glm_id)))
          this.affectedSchools.push(this.getSchoolForGML(element.raw.properties.glm_id))
        element.raw.properties.glm_id = gmlId

      }
      this.map.removeLayer(element.layer)
      element.layer.setStyle({

        color: this.stringToColor(element.raw.properties.gemarkung),

        fillColor: this.stringToColor(element.raw.properties.gemarkung),
        weight: 4,
        fillOpacity: 0.2
      });

      this.map.addLayer(element.layer)
    });
    if (this.addSchoolMode) {
      this.open('.newSchool')
    }

    this.highlightedLayerMulti = [];
    this.changeBezirkOfSchool = false;
    this.addSchoolMode = false

    //this.selectedSchoolTemp = {}


  }

  currentYear: number = 2024;
  changeSchoolOfBezirk: any = false;
  changeBezirkOfSchool: boolean = false;
  getLabelForSchool(school: any) {
    if (school?.raw?.properties.Mittelzentrum) return '(M)'
    if (school?.raw?.properties.Mittelzentrum_Gemeinde) return '(MG)'
    return ''
  }

  setNewSchoolForBezirk(bezirk: any) {

    this.changeSchoolOfBezirk = true;


  }
  clickSchool(school: any) {
    this.selectedSchool = school
    this.map.flyTo(school.layer.getLatLng())
    school.layer.openPopup();
  }
  schoolSearch: any = '';
  async showSchool(response: any) {
    if (!response)
      //response = await this.datahubService.getSchoolPoints();
    response = await this.datahubService.getSchools();
    console.log(response);
    
    const schoolPoints= response[0].data
    const parsedSchoolPoints = JSON.parse(schoolPoints);
    parsedSchoolPoints.features.forEach((feature:any) => {
      const properties = feature.properties;

      if (properties.Einschulung) {
        properties.Klassengroessen = properties.Einschulung[0];
        delete properties.Einschulung;
      }
      
      // if (properties.Total) {
      //   properties.Total = JSON.parse(properties.Total);
      // }
      // if (properties.Schulbezirk) {
      //   properties.Schulbezirk = JSON.parse(properties.Schulbezirk);
      // }
    });
    
    console.log(parsedSchoolPoints);

    this.plotPointsOnMap(parsedSchoolPoints.features, []);


    if (this.checkIfOpen('.schule-display')) {
      this.close('.schule-display')
      return;
    }
    this.open('.schule-display')

  }
  getBezirke(gmlId: any) {




    var t = this.bezirke.filter((el: { raw: { properties: { glm_id: any; }; }; }) => el.raw.properties.glm_id == gmlId)



    if (t.length == 0) {
      return undefined
    }
    return t
  }
  selectedSchool: any //=  { properties: {} };


  getSchoolForGML(gmlId: any) {
    var t = this.schools?.filter((el: any) => el?.raw?.properties?.gml_id == gmlId)

    if (t && t.length > 0) {

      return t[0]

    } else {

      //console.warn("No School found for " + gmlId  )
      return undefined
    }


  }
  clickBezirk(bezirk: any) {


    if (!bezirk.raw.checked) {
      this.map.removeLayer(bezirk.layer)

    } else {
      this.map.addLayer(bezirk.layer)
    }

  }

  toggleSchool(school: any) {

    if (!school.raw.checked) {
      this.map.removeLayer(school.layer)

    } else {
      this.map.addLayer(school.layer)
    }

    this.updateChart()

  }
  bezirkeSearch: any = '';
  selectBezirke() {
    this.bezirke.forEach((element: any) => {
      element.raw.checked = true;
      this.clickBezirk(element)

    });
  }
  deselectBezirke() {

    this.bezirke.forEach((element: any) => {
      element.raw.checked = false;
      this.clickBezirk(element)

    });

  }


  selectSchool() {
    this.schools.forEach((element: any) => {
      element.raw.checked = true;
      this.map.addLayer(element.layer)
      //this.clickSchool(element)

    });
    this.updateChart()

  }
  deselectSchool() {
    this.schools.forEach((element: any) => {
      element.raw.checked = false;
      this.map.removeLayer(element.layer)
      //this.clickSchool(element)

    });
    this.updateChart()

  }
  closeBezirke() {
    throw new Error('Method not implemented.');
  }
  bezirke: any = [];
  selectedBezirk: any;
  public highlightedLayer: L.Layer | null = null;


  async getAdressForCoordinates() {

    if (!this.newSchoolMarker?.getLatLng().lat || !this.newSchoolMarker?.getLatLng().lng)
      return 'Adresse nicht gefunden'

    var res = await this.datahubService.getAdressForCoordinates(this.newSchoolMarker.getLatLng().lat, this.newSchoolMarker.getLatLng().lng)


    //@ts-ignore
    if (res?.features[0]?.properties?.label)
      //@ts-ignore
      return res.features[0].properties.label

    return 'Adresse nicht gefunden'
  }

  async saveNewSchool() {


    var hh = await this.datahubService.getCoordinatesForAdress(this.selectedSchoolTemp.properties.Adresse)
    const klassengroessen: { [key: string]: number } = {};

    // Iteriere durch die tableData und berechne die Klassengröße / Regelzügigkeit
    this.tableData.forEach(row => {
      klassengroessen[row.schuljahr] = Math.round(row.klassengroesse / row.regelzuegigkeit);
    });
    var obj = {
      raw: {
        "type": "Feature",
        "properties": {
          "gml_id": this.selectedSchoolTemp?.properties.gml_id,
          // "postCode": "06632",
          // "electronicMailAddress": "kontakt@gs-jahn-freyburg.bildung-lsa.de",
          //"telephoneVoice": "+493446427290",
          "website": "www.beispiel.de",
          "Object_Key": "",
          "Adresse": this.selectedSchoolTemp.properties.Adresse,
          "Schulname": this.newSchoolName,
          "Klassengroessen": klassengroessen,
          "Mittelzentrum": false,
          //  "Ortschaft": "Freyburg",
          // "Gemeinde": "Freyburg (Unstrut), Stadt",
          // "Mittelzentrum_Gemeinde": false
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            //@ts-ignore
            hh.features[0].geometry.coordinates[0],

            //@ts-ignore
            hh.features[0].geometry.coordinates[1]

          ]
        },
        "checked": true
      }
    }


    console.warn("newuw schule", obj)
    this.schools.push(obj)
    this.updateChart();

    this.clickSchool(obj)

    //@ts-ignore
    //document.getElementById('afterNewSchool').showModal(); TODO:

    this.newSchoolName = ''

    this.closeNewSchoolOptions()

  }
  newSchoolOpen: boolean = false;
  newSchoolMarker: any;
  endSchoolMode() {
    this.addSchoolMode = false;
    this.closeNewSchoolOptions()

    this.toggleOption()

  }
  setAddSchoolMode() {
    this.addSchoolMode = true;
    // this.toggleOption()
    this.open('schule-display');

  }
  showChart: boolean = false;
  addSchoolMode: boolean = false;

  updateNextDay(event: Event) {
    const target = event.target as HTMLInputElement;
    this.currentYear = parseInt(target.value);
    this.updateChart()


  }


  layerControl: any;
  layers: any = [];
  map: any;
  selectedObject: any;
  anzahl: any = 0;
  schools: any = [];
  polygons: PolygonInfo[] = [];
  totalPoints: number = 0;
  chartInstance!: Chart;
  chartInstance2!: Chart;
  schoolsPop: any;
  sizeClassCenter = 0;
  sizeClassOutside = 0;


  constructor(
    private keyCloakService: KeycloakOperationService,
    public storageService: Storageservice,
    public dialog: MatDialog,
    private regioService: RegioService,
    private datahubService: DatahubService
  ) {
    this.selectedObject = this.storageService.getObject();
  }

  private drawnItems!: L.FeatureGroup;




  async setLayer() {


    if (!this.selectedObject) {
      alert("Bitte Landkreis auswählen!")
      return;
    }


    var resA = await this.regioService.getShapefileByIdAndLevel(this.selectedObject.Object_Key, 5);
    console.warn(resA)
    //@ts-ignore




    //@ts-ignore
    resA[0].forEach((element: any) => {
      var l = L.geoJSON(element.geometry,
        {
          style: {
            fillColor: 'none',
            weight: 3,
            opacity: 0.3,


            color: 'black'
          },
          onEachFeature: (f, l) => {
            var out: string[] = [];

            out.push("Name : " + element.Object_Nam + "<br>"

            );

            l.bindPopup("<span class='span0'> " + element.Object_Nam + " </span> ");
          }
        });

      this.map.addLayer(l)



      this.layers.push(l);

    })




  }
  async ngOnInit() {
    await this.openStartDialog();

    this.keyCloakService.getUsersList().subscribe((users: any) => {
      this.userList = users
      console.warn("USERLIST", this.userList)
    });




    this.keyCloakService.getUserProfile().then(async (data: any) => {
      this.userID = data.id;




    })






    this.initializeMap();
    this.setLayer();
    this.close('.schule-display');
    //var response = await this.datahubService.getSchoolPoints();
    //this.showSchool(response);

    //var response = await this.datahubService.getSchoolPoints();
    const response = await this.datahubService.getSchools();
    console.log(response);
    
    const schoolPoints= response[0].data
    const parsedSchoolPoints = JSON.parse(schoolPoints);
    parsedSchoolPoints.features.forEach((feature:any) => {
      const properties = feature.properties;

      if (properties.Einschulung) {
        properties.Klassengroessen = properties.Einschulung[0];
        delete properties.Einschulung;
      }
      
      // if (properties.Total) {
      //   properties.Total = JSON.parse(properties.Total);
      // }
      // if (properties.Schulbezirk) {
      //   properties.Schulbezirk = JSON.parse(properties.Schulbezirk);
      // }
    });
    
    console.log(parsedSchoolPoints);
    this.plotPointsOnMap(parsedSchoolPoints.features, []);
    this.open('.schule-display')
    //var bezirke = await this.datahubService.getSchulbezirke()
    const bezirke = await this.datahubService.getBezirkeDB()
   
    const parsedBezirke = JSON.parse(bezirke.data);
    parsedBezirke.features.forEach((feature:any) => {
      const properties = feature.properties;
      
      if (properties.Orte) {
        properties.Orte = JSON.parse(properties.Orte);
      }
      if (properties.Einschulungsjahrgänge) {
        properties.Einschulungsjahrgänge = JSON.parse(properties.Einschulungsjahrgänge);
      }
    });

    console.log(parsedBezirke);

    this.drawEditAbleShapesNew(parsedBezirke);
    setTimeout(() => {
      this.deselectBezirke()
      this.close('.bezirke-display');

    }, 10)






  }


  openSaveDialog() {
    const dialogRef = this.dialog.open(SaveDialogComponent, {
      data: {
        schools: this.schools,
        gefahrAnalysen: this.gefahrAnalyseResults,
        bezirke: this.bezirke,
        userID: this.userID,
        userList: this.userList
      },
      width: '1200px',
      height: '800px',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {

      }
    });
  }

  openDialogAnalyse() {
    const dialogRef = this.dialog.open(GefahranalyseComponent, {
      data: {
        schools: this.schools,
        bezirke: this.bezirke,
        gefahrAnalysen: this.gefahrAnalyseResults
      },
      width: '1200px',
      height: '800px',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.schools) {

        this.gefahrAnalyseResults.push(result)
        this.schools = result.schools;
        this.sizeClassCenter = result.sizeClassCenter
        this.sizeClassOutside = result.sizeClassOutside
        this.schools.forEach((element: any) => {
          element.raw.checked = false;

          if (element.raw.inDanger) {
            element.raw.checked = true;
          }


        });

        this.showGrafik(false);
      }
    });
  }


  async openStartDialog() {

    var t = await this.datahubService.getSchulprognose()

    const dialogRef = this.dialog.open(StartDialogComponent, {
      data: { formerSelection: t },
      width: '1200px',
      height: '800px',
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {

        if (result.schools) { //overwrite schools with existing scenario
          console.warn(result.schools)
          this.plotPointsOnMap(result.schools, []);
          this.open('.schule-display')
        } else {
         // var response = await this.datahubService.getSchoolPoints();
         const response = await this.datahubService.getSchools();
    console.log(response);
    
    const schoolPoints= response[0].data
    const parsedSchoolPoints = JSON.parse(schoolPoints);
    parsedSchoolPoints.features.forEach((feature:any) => {
      const properties = feature.properties;

      if (properties.Einschulung) {
        properties.Klassengroessen = properties.Einschulung[0];
        delete properties.Einschulung;
      }
      
      // if (properties.Total) {
      //   properties.Total = JSON.parse(properties.Total);
      // }
      // if (properties.Schulbezirk) {
      //   properties.Schulbezirk = JSON.parse(properties.Schulbezirk);
      // }
    });
    
    console.log(parsedSchoolPoints);
          this.plotPointsOnMap(parsedSchoolPoints.features, []);
        }

        if (result.bezirke) { //overwrite schools with existing scenario

          //var bezirke = await this.datahubService.getSchulbezirke()
          const bezirke = await this.datahubService.getBezirkeDB()
   
    const parsedBezirke = JSON.parse(bezirke.data);
    parsedBezirke.features.forEach((feature:any) => {
      const properties = feature.properties;
      
      if (properties.Orte) {
        properties.Orte = JSON.parse(properties.Orte);
      }
      if (properties.Einschulungsjahrgänge) {
        properties.Einschulungsjahrgänge = JSON.parse(properties.Einschulungsjahrgänge);
      }
    });

    console.log(parsedBezirke);
          //@ts-ignore
          parsedBezirke.features = result.bezirke
          this.drawEditAbleShapesNew(parsedBezirke);

        } else {

          //var bezirke = await this.datahubService.getSchulbezirke()
          const bezirke = await this.datahubService.getBezirkeDB()
   
    const parsedBezirke = JSON.parse(bezirke.data);
    parsedBezirke.features.forEach((feature:any) => {
      const properties = feature.properties;
      
      if (properties.Orte) {
        properties.Orte = JSON.parse(properties.Orte);
      }
      if (properties.Einschulungsjahrgänge) {
        properties.Einschulungsjahrgänge = JSON.parse(properties.Einschulungsjahrgänge);
      }
    });

    console.log(parsedBezirke);
          this.drawEditAbleShapesNew(parsedBezirke);



        }

        this.close('.bezirke-display');
        this.deselectBezirke();






      }
    });
  }

  async drawEditAbleShapesNew(res: any) {

    if (this.checkIfOpen('.bezirke-display')) {

      this.close('.bezirke-display');
      return;
    }

    this.open('.bezirke-display');
    console.warn("res", res)

    //if (this.bezirke.length > 0) return
    this.bezirke = []
    if (!res) {
      //res = await this.datahubService.getSchulbezirke()
      const bezirke = await this.datahubService.getBezirkeDB()
   
    const parsedBezirke = JSON.parse(bezirke.data);
    parsedBezirke.features.forEach((feature:any) => {
      const properties = feature.properties;
      
      if (properties.Orte) {
        properties.Orte = JSON.parse(properties.Orte);
      }
      if (properties.Einschulungsjahrgänge) {
        properties.Einschulungsjahrgänge = JSON.parse(properties.Einschulungsjahrgänge);
      }
    });

    console.log(parsedBezirke);
    res= parsedBezirke
    }


    if (!res) return

    res.features.forEach(async (shape: any) => {
      shape.checked = true;



      var l = L.geoJSON(shape.geometry,
        {
          style: {
            fillColor: this.stringToColor(shape.properties.gemarkung),
            color: this.stringToColor(shape.properties.gemarkung),
            weight: 4,
            fillOpacity: 0.2
          }

        });


      l.on('click', () => { this.openBezirk(l, shape) })

      this.bezirke.push(
        {
          raw: shape,
          layer: l

        })

      l.addTo(this.map);

    });
  }




  plotPointsOnMap(points: any, schoolsAll: any) {
    if (this.schools) {

      this.schools.forEach((marker: any) => { if (marker.layer) { this.map.removeLayer(marker.layer) } });
    }

    this.schools = schoolsAll;

    console.warn("L", points.length, points)





    points.forEach((point: any) => {
      var color = 'black'

      if (point.inDanger) {
        color = 'red'
      }

      var icon = L.divIcon({
        className: 'custom-div-icon',
        html: "<div class='marker-pin'></div><i  style='color:" + color + ";'  class='material-icons'>   fiber_manual_record </i>",
        iconSize: [24, 36],
        iconAnchor: [10, 10],

      });

      const coordinates = point.geometry.coordinates;

      if (coordinates && coordinates.length === 2) {
        const lat = coordinates[1]; // Latitude
        const lng = coordinates[0]; // Longitude

        if (!isNaN(lat) && !isNaN(lng)) {
          const marker = L.marker([lat, lng], { icon: icon }).addTo(this.map);

          const popupContent = `
          <style>
            .popup-button {
              padding: 8px 16px;
              border: none;
              border-radius: 4px;
              font-size: 14px;
              cursor: pointer;
              margin-right: 8px;
            }
            .primary-button {
              background-color: #126F62;
              color: white;
              margin: 3px;
            }
            .warn-button {
              background-color: #f44336;
              color: white;
              margin: 3px;
            }
            .popup-container {
              display: flex;
              flex-direction: column;
              align-items: start;
            }
          </style>
          <div class="popup-container">
                        <button id="noButton" class="popup-button warn-button">x</button>



            <strong>${point.properties.Schulname} </strong> <br>
                        ${point.properties.Adresse} <br>
                        ${point.properties.website} <br>
                    
                        

            <div>
              <button id="noButton2" class="popup-button warn-button">Schule deaktivieren</button>
              <button id="yesButton2" class="popup-button primary-button">Neuem Bezirk zuordnen</button>
              <button id="yesButton" class="popup-button primary-button">Schulbezirk anzeigen</button>
            </div>
          </div>
        `;

          marker.bindPopup(popupContent);
          point.checked = true;
          var schoolAlreadyExist = this.schools.find((el: { raw: any; }) => el.raw == point)
          if (schoolAlreadyExist) { //update layer of existign school
            schoolAlreadyExist.layer = marker
            schoolAlreadyExist.raw = point

          } else { //add new entry
            this.schools.push({ raw: point, layer: marker });

          }

          marker.on('click', () => {


            //this.clickSchool({ raw: point, layer:marker})
          }
          )
          marker.on('popupclose', () => {
            this.highlightSchool('')
            this.highlightSchoolLine('')
          })
          marker.on('popupopen', () => {
            const popupElement = document.querySelector('.leaflet-popup-content');

            if (this.selectedSchool)
              this.selectedSchool.raw = point;

            this.highlightSchool(point.properties.Schulname)
            this.highlightSchoolLine(point.properties.Schulname)



            popupElement?.querySelector('#yesButton')?.addEventListener('click', () => {

              this.deselectBezirke();
              this.bezirke.forEach((el: any) => {

                if (el.raw.properties.glm_id == point.properties.gml_id) {
                  el.raw.checked = true;

                  this.clickBezirk(el)
                } else {
                  // el.raw.checked = false;
                }
              });



              marker.closePopup();
            });
            popupElement?.querySelector('#yesButton2')?.addEventListener('click', () => {
              if (this.changeSchoolOfBezirk && !this.changeBezirkOfSchool) {
                point.properties.gml_id = this.selectedBezirk?.properties.glm_id
                this.changeSchoolOfBezirk = false;
              } else {



                this.selectedSchoolTemp = point;


                var oldBez = this.getBezirke(point.properties.gml_id);
                oldBez?.forEach((oldBezirk: any) => {
                  this.map.removeLayer(oldBezirk.layer)
                  this.styleLayerHighlight(oldBezirk.layer)
                  this.map.addLayer(oldBezirk.layer)

                });

                this.changeBezirkOfSchool = true;

              }
              marker.closePopup();


            });


            popupElement?.querySelector('#noButton')?.addEventListener('click', () => {
              marker.closePopup();
            });
            popupElement?.querySelector('#noButton2')?.addEventListener('click', () => {

              var schoolToBeDeleted = (this.schools.find((el: { raw: any; }) => el.raw.properties.Schulname == point.properties.Schulname))

              if (schoolToBeDeleted) {
                const selectedYear = `${this.currentYear}/${(this.currentYear + 1).toString().slice(-2)}`;
                var resetFlag = false;
                Object.keys(schoolToBeDeleted.raw.properties.Klassengroessen).forEach(key => {
                  if (key == selectedYear) {
                    resetFlag = true;

                  }

                  if (resetFlag) {
                    schoolToBeDeleted.raw.properties.Klassengroessen[key] = 0;  // Setze den Wert auf 0, wenn shouldReset true ist

                  }

                })




              }

              console.warn("after", schoolToBeDeleted, this.schools)
              marker.closePopup();
              this.map.removeLayer(marker);
            });
          });
        } else {
          console.error('Invalid LatLng:', lat, lng);
        }
      } else {
        console.error('Invalid coordinates for point:', point);
      }
    });
  }

  getKlassengroesse(school: any, year: any) {
    const selectedYear = `${year}/${(year + 1).toString().slice(-2)}`;


    var value = 0;

    Object.keys(school.raw.properties.Klassengroessen).forEach(key => {

      if (key == selectedYear) {

        value = school.raw.properties.Klassengroessen[key]

      }
    })

    return value
  }

  private initializeMap(): void {
    if (this.map) return
    const osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    const osmAttrib =
      '&copy; <a href="http://openstreetmap.org/copyright">OpenStreetMap</a> contributors';
    const osm = L.tileLayer(osmUrl, { maxZoom: 18, attribution: osmAttrib });

    this.map = L.map('map', {
      center: latLng(51.04962, 12.1369),
      zoom: 14,
      layers: [osm],
    });

    this.drawnItems = new L.FeatureGroup();
    this.map.addLayer(this.drawnItems);

    this.map.editTools = new L.Editable(this.map);

    this.map.on('click', (e: { latlng: L.LatLngExpression; }) => {

      if (this.addSchoolMode && !this.newSchoolMarker) {
        var icon = L.divIcon({
          className: 'custom-div-icon',
          html: "<div class='marker-pin'></div><i  style='color:green;'  class='material-icons'>   fiber_manual_record </i>",
          iconSize: [24, 36],
          iconAnchor: [12, 36],

        });

        this.newSchoolMarker = L.marker(e.latlng, { icon: icon }).addTo(this.map);
        // alert(e.latlng)
        this.openNewSchoolOptions()

      }


    })

  }

  stringToColor(str: string) {
    let hash = 0;
    if (!str) return 'black'

    // Berechne einen Hash-Wert basierend auf dem String
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    // Erstelle eine Farbe aus dem Hash
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xFF;
      color += ('00' + value.toString(16)).slice(-2);
    }

    return color;
  }



  async openNewSchoolOptions() {

    this.newSchoolOpen = true;
    this.addSchoolMode = false;
    this.affectedSchools = []
    const grafikChart = document.querySelector('.newSchool') as HTMLElement;
    this.selectedSchoolTemp['properties'].Adresse = await this.getAdressForCoordinates()
    this.selectedSchoolTemp.properties.gml_id = "NewSchool_" + Date.now();

    grafikChart.style.display = 'block';
  }


  openBezirk(l: any, bezirk: any) {



    if (this.changeBezirkOfSchool) {
      this.map.removeLayer(l)




      var items = this.highlightedLayerMulti.filter((itm: { raw: any; }) => itm.raw == bezirk);

      if (items.length < 1) {
        this.highlightedLayerMulti.push({ layer: l, raw: bezirk })


        //@ts-ignore
        l.setStyle({
          weight: 10,
          // fillColor: '#FFCCCB',
          fillOpacity: 100,

          color: this.stringToColor(bezirk.properties.gemarkung),

          fillColor: this.stringToColor(bezirk.properties.gemarkung),

        });
        this.map.addLayer(l)

      } else {

        this.highlightedLayerMulti = this.highlightedLayerMulti.filter((itm: { raw: any; }) => itm.raw != bezirk)
        this.map.removeLayer(items[0].layer)
        items[0].layer.setStyle({

          color: this.stringToColor(bezirk.properties.gemarkung),

          fillColor: this.stringToColor(bezirk.properties.gemarkung),
          weight: 4,
          fillOpacity: 0.2
        });

        this.map.addLayer(l)


      }

      // this.clickBezirk(l)



    } else {
      this.map.removeLayer(l)

      if (this.highlightedLayer) {


        //@ts-ignore
        this.highlightedLayer.setStyle({

          color: this.stringToColor(bezirk.properties.gemarkung),

          fillColor: this.stringToColor(bezirk.properties.gemarkung),
          weight: 4,
          fillOpacity: 0.2
        });



      }


      // Setze den Stil des aktuellen Layers
      l.setStyle({
        // color: '#FF5733',
        weight: 10,
        // fillColor: '#FFCCCB',
        fillOpacity: 100
      });
      // Speichere den hervorgehobenen Layer
      this.highlightedLayer = l;

      this.map.addLayer(l)





      this.selectedBezirk = bezirk;
      var school = this.getSchoolForGML(this.selectedBezirk?.properties.glm_id);
      console.warn("sch", school?.layer)
      this.open('.bezirk')
      if (school) {
        var markerBounds = L.latLngBounds(school.layer.getLatLng(), school.layer.getLatLng());
        var layerBounds = l.getBounds();

        var combinedBounds = layerBounds.extend(markerBounds);

        this.map.fitBounds(combinedBounds, {
          //  padding: [0, 250],  // Adds padding around the bounds
          paddingTopLeft: [300, 300],  // Mehr Padding oben und links
          paddingBottomRight: [400, 400],
          animate: true,      // Enable animation
          duration: 4,        // Animation duration in seconds (default: 0.25)
          easeLinearity: 1.2  // Adjusts the smoothness of the zoom/center transition (default: 0.25)
        })

      }

    }






    this.map.fitBounds(l.getBounds(), {
      //  padding: [0, 250],  // Adds padding around the bounds
      paddingTopLeft: [300, 300],  // Mehr Padding oben und links
      paddingBottomRight: [300, 300],
      animate: true,      // Enable animation
      duration: 4,        // Animation duration in seconds (default: 0.25)
      easeLinearity: 1.2  // Adjusts the smoothness of the zoom/center transition (default: 0.25)
    })




  }

  styleLayerNormal(layer: any, name: string) {
    layer.setStyle({

      color: this.stringToColor(name),

      fillColor: this.stringToColor(name),
      weight: 4,
      fillOpacity: 0.2
    });


  }
  styleLayerHighlight(l: any) {


    l.setStyle({
      color: 'red',

      fillColor: 'red',
      weight: 10,

      fillOpacity: 100
    });

  }
  checkIfOpen(id: string) {
    const element = document.querySelector(id) as HTMLElement;
    return !(element.style.display == 'none');

  }

  open(id: string) {
    const element = document.querySelector(id) as HTMLElement;
    if (!element) return
    element.style.display = 'block';

  }
  close(id: string) {
    const element = document.querySelector(id) as HTMLElement;
    if (!element) return
    element.style.display = 'none';

  }


  closeNewSchoolOptions() {
    this.map.removeLayer(this.newSchoolMarker)
    this.newSchoolMarker = undefined;
    const grafikChart = document.querySelector('.newSchool') as HTMLElement;
    grafikChart.style.display = 'none';
  }


  toggleOption() {
    const grafikChart = document.querySelector('.options') as HTMLElement;
    if (!grafikChart) return
    if (grafikChart?.style?.display == 'none') {
      grafikChart.style.display = 'block'
    } else {
      grafikChart.style.display = 'none'
    }

  }


  showGrafik(toggleMode: boolean) {




    //const grafikChart = document.querySelector('.grfikChart') as HTMLElement;
    const grafikChart2 = document.querySelector('.grfikChart2') as HTMLElement;

    if (grafikChart2.style.display != 'block' || !toggleMode) {

      // grafikChart.style.display = 'block';
      grafikChart2.style.display = 'block';
      this.showChart = true;

      this.updateChart();
    } else {
      // grafikChart.style.display = 'none';
      grafikChart2.style.display = 'none';
    }
  }





  private updateChart() {
    //console.warn("schhols", this.schoolsPop)



    var schoolsRaw: any[] = []



    this.schools.forEach((school: any) => {


      if (school.raw.checked && (this.getKlassengroesse(school, this.currentYear) > 0))
        schoolsRaw.push(school.raw)

    });
    this.plotPointsOnMap(schoolsRaw, this.schools);
    console.warn("sch2", schoolsRaw, schoolsRaw.length)
    console.warn("sch2", this.schools, this.schools.length)


    this.createChart(schoolsRaw);
    this.createLineChart(schoolsRaw)

  }

  randomizeTotalPoints(points: number): number {
    const variation = points * 0.1; // 10% of total points
    return Math.floor(points + (Math.random() * 2 - 1) * variation); // Randomize +/- 10%
  }


  createLineChart(schoolPop: any): void {
    const ctx: any = (document.getElementById('chartCanvas2') as HTMLCanvasElement).getContext('2d');
    if (this.chartInstance2) {
      this.chartInstance2.destroy()
    }

    console.warn(schoolPop[0])
    if (!schoolPop[0]) return
    // Schuljahre (X-Achse)
    const years = Object.keys(schoolPop[0]?.properties.Klassengroessen);

    // Plugin zur horizontalen Linie
    const horizontalLinePlugin: Plugin = {
      id: 'horizontalLinePlugin',
      afterDraw: (chart) => {
        const ctx = chart.ctx;
        const yScale = chart.scales['y'];  // y-Achse des Diagramms


        const yValue = yScale.getPixelForValue(this.sizeClassOutside);

        ctx.save();
        ctx.beginPath();
        ctx.moveTo(chart.scales['x'].left, yValue);  // Start der Linie (linke Seite des Diagramms)
        ctx.lineTo(chart.scales['x'].right, yValue); // Ende der Linie (rechte Seite des Diagramms)
        ctx.strokeStyle = 'red';  // Farbe der Linie
        ctx.lineWidth = 2;        // Breite der Linie
        ctx.stroke();
        ctx.restore();
      }
    };
    // Plugin zur horizontalen Linie
    const horizontalLinePlugin2: Plugin = {
      id: 'horizontalLinePlugin2',
      afterDraw: (chart) => {
        const ctx = chart.ctx;
        const yScale = chart.scales['y'];  // y-Achse des Diagramms

        const yValue = yScale.getPixelForValue(this.sizeClassCenter);

        ctx.save();
        ctx.beginPath();
        ctx.moveTo(chart.scales['x'].left, yValue);  // Start der Linie (linke Seite des Diagramms)
        ctx.lineTo(chart.scales['x'].right, yValue); // Ende der Linie (rechte Seite des Diagramms)
        ctx.strokeStyle = 'red';  // Farbe der Linie
        ctx.lineWidth = 2;        // Breite der Linie
        ctx.stroke();
        ctx.restore();
      }
    };


    // Daten für jede Schule sammeln
    const datasets = schoolPop.map((school: { properties: { Schulname: any; Klassengroessen: { [x: string]: any; }; }; }) => {
      return {
        label: school.properties.Schulname,
        data: years.map(year => school.properties.Klassengroessen[year] || 0), // Schülerzahlen für jedes Jahr
        fill: false,
        tension: 0.1,
        backgroundColor: this.stringToColor(school.properties.Schulname), // Füllt die Linie (falls gefüllt) oder die Punkte
        borderColor: this.stringToColor(school.properties.Schulname),
        pointBackgroundColor: this.stringToColor(school.properties.Schulname)

      };
    });
    var myPlugins = []
    if (this.sizeClassCenter != 0) {
      myPlugins.push(horizontalLinePlugin)
    }

    if (this.sizeClassOutside != 0) {
      myPlugins.push(horizontalLinePlugin2)
    }
    // Erstellen des Line-Charts
    this.chartInstance2 = new Chart(ctx, {
      type: 'line',
      data: {
        labels: years, // Schuljahre als Labels (X-Achse)
        datasets: datasets // Die einzelnen Linien pro Schule
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Anzahl der Schüler'
            }
          },
          x: {
            title: {
              display: true,
              text: 'Schuljahre'
            }
          }
        },
        plugins: {
          legend: {
            display: false  // Versteckt die Legende
          }
        }
      },

      plugins: myPlugins,  // Hier fügen wir das Plugin hinzu

    });
  }



  createChart(schoolPop: any): void {

    console.warn("sch", schoolPop, schoolPop.length)


    const ctx: any = (document.getElementById('chartCanvas') as HTMLCanvasElement).getContext('2d');
    if (this.chartInstance) {
      this.chartInstance.destroy()
    }
    const selectedYear = `${this.currentYear}/${(this.currentYear + 1).toString().slice(-2)}`;

    // Extrahiere und sortiere die Daten nach Schülerzahlen für das ausgewählte Jahr
    const sortedSchools = schoolPop
      .map((school: { properties: { Schulname: any; Klassengroessen: { [x: string]: any; }; }; }) => ({
        name: school.properties.Schulname,
        students: school.properties.Klassengroessen[selectedYear] || 0
      }))
      .sort((a: { students: number; }, b: { students: number; }) => b.students - a.students);
    // Daten für das Chart vorbereiten
    const labels = sortedSchools.map((s: any) => s.name + this.getLabelForSchool(s));
    const data = sortedSchools.map((s: { students: any; }) => s.students);



    // Erstellen des Bar-Charts
    this.chartInstance = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels, // Schullisten
        datasets: [{
          label: `Schülerzahlen für ${selectedYear}`,
          data: data, // Schülerzahlen
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1
        }]
      },
      options: {
        responsive: true,

        scales: {
          x: {
            ticks: {
              font: {
                size: 10  // Kleinere Schriftgröße für X-Achsen-Labels
              }
            }
          },
          y: {
            beginAtZero: true
          }
        }
      }
    });
  }

  highlightSchool(schoolName: string) {

    schoolName = this.selectedSchool?.raw.properties.Schulname

    if (!this.chartInstance) return;
    //@ts-ignore
    const selectedSchoolIndex = this.chartInstance?.data?.labels.findIndex(
      //@ts-ignore

      (label: string) => label.startsWith(schoolName) // oder label.includes(schoolName) je nach Labelaufbau
    );

    if (selectedSchoolIndex === -1) {
      console.warn('Schule nicht gefunden');
      return;
    }

    // Setze alle Balken auf Standardfarbe zurück
    //@ts-ignore

    this.chartInstance.data.datasets[0].backgroundColor = this.chartInstance.data.labels.map(
      (_, index) => index === selectedSchoolIndex ? 'rgba(255, 99, 132, 0.2)' : 'rgba(54, 162, 235, 0.2)'
    );
    //@ts-ignore

    this.chartInstance.data.datasets[0].borderColor = this.chartInstance.data.labels.map(
      (_, index) => index === selectedSchoolIndex ? 'rgba(255, 99, 132, 1)' : 'rgba(54, 162, 235, 1)'
    );

    // Chart aktualisieren, um die Änderungen anzuzeigen
    this.chartInstance.update();
  }

  highlightSchoolLine(schoolName: string) {
    schoolName = this.selectedSchool?.raw.properties.Schulname


    if (!this.chartInstance2) return;

    // Finde den Index des Datensets der ausgewählten Schule
    const selectedDatasetIndex = this.chartInstance2.data.datasets.findIndex(
      //@ts-ignore
      (dataset: { label: string; }) => dataset.label === schoolName
    );

    if (selectedDatasetIndex === -1) {
      console.warn('Schule nicht gefunden');
      return;
    }

    // Setze alle Datensets auf die Standardfarben und -stile zurück
    this.chartInstance2.data.datasets.forEach((dataset: any, index: number) => {
      dataset.borderWidth = index === selectedDatasetIndex ? 3 : 1;  // Hervorhebung durch dickere Linie
      dataset.pointRadius = index === selectedDatasetIndex ? 6 : 3;  // Größere Punkte für ausgewählte Schule
      if (index == selectedDatasetIndex) {
        dataset.borderColor = 'red'
        dataset.backgroundColor = 'red'
        dataset.pointBackgroundColor = 'red'
      } else {
        dataset.borderColor = this.stringToColor(dataset.label);
        dataset.backgroundColor = this.stringToColor(dataset.label);
        dataset.pointBackgroundColor = this.stringToColor(dataset.label);
      }
    });

    // Chart aktualisieren, um die Änderungen anzuzeigen
    this.chartInstance2.update();
  }



}


