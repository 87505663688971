<div *ngIf="step == 0"  style="padding: 1px">
    <h1> Krankenhäuser für Spezialisierungsinitiative auswählen </h1>

    <button mat-mini-fab style="background-color: #10684e; color: white; margin: 5px; position:absolute; right:10px;top:10px" (click)="nextStep()">
      <mat-icon>arrow_forward</mat-icon>
    </button>
    
    <table mat-table [dataSource]="dataSource" matSort class="sticky-table">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sticky>
          <mat-checkbox (change)="selectAll($event)"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let hospital">
          <mat-checkbox [(ngModel)]="hospital.selected"></mat-checkbox>
        </td>
      </ng-container>
    
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sticky> Name </th>
        <td mat-cell *matCellDef="let hospital">{{ hospital.name }}</td>
      </ng-container>
    
      <!-- Total Cases Column -->
      <ng-container matColumnDef="Total_Cases_Hospital">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sticky> Total Cases </th>
        <td mat-cell *matCellDef="let hospital">{{ hospital.Total_Cases_Hospital }}</td>
      </ng-container>
    
      <!-- Relevant Cases Column -->
      <ng-container matColumnDef="Total_Relevant_Cases_Hospital">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sticky> Relevant Cases </th>
        <td mat-cell *matCellDef="let hospital">{{ hospital.Total_Relevant_Cases_Hospital }}</td>
      </ng-container>
    
      <!-- Other Columns -->
      <ng-container matColumnDef="Intra_Hospital_Specialization">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sticky> Intra Specialization </th>
        <td mat-cell *matCellDef="let hospital">{{ hospital.Intra_Hospital_Specialization }}</td>
      </ng-container>
    
      <ng-container matColumnDef="Inter_Hospital_Specialization">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sticky> Inter Specialization </th>
        <td mat-cell *matCellDef="let hospital">{{ hospital.Inter_Hospital_Specialization }}</td>
      </ng-container>
    
      <ng-container matColumnDef="Global_Specialization">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sticky> Global Specialization </th>
        <td mat-cell *matCellDef="let hospital">{{ hospital.Global_Specialization }}</td>
      </ng-container>
    
      <ng-container matColumnDef="anzahl_betten_total">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sticky> Total Beds </th>
        <td mat-cell *matCellDef="let hospital">{{ hospital.anzahl_betten_total }}</td>
      </ng-container>
    
      <ng-container matColumnDef="anzahl_fachabteilungen">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sticky> Departments </th>
        <td mat-cell *matCellDef="let hospital">{{ hospital.anzahl_fachabteilungen }}</td>
      </ng-container>
    
      <ng-container matColumnDef="traeger">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sticky> Owner </th>
        <td mat-cell *matCellDef="let hospital">{{ hospital.traeger }}</td>
      </ng-container>
    
      <ng-container matColumnDef="art_des_traegers">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sticky> Owner Type </th>
        <td mat-cell *matCellDef="let hospital">{{ hospital.art_des_traegers }}</td>
      </ng-container>
    
      <!-- Table Rows -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    


</div>

<div  *ngIf="step == 1" style="padding: 20px; display: flex; flex-direction: column;">
    <h1> Bevölkerungscharakteristiken </h1>

    <div>
    <h3> Population: </h3> {{ data.pop}}
  </div>
    <h3> Alter</h3>
    <br>
    <div> 
    <mat-slider min="0" max="100">
        <input value="0" matSliderStartThumb [(ngModel)] = 'startYear'>
        <input value="99" matSliderEndThumb  [(ngModel)] = 'endYear'>
      </mat-slider>

      {{ startYear }} bis {{ endYear}} Jahre 
      <br>
      <br>
    </div>
    <div>
      <h3> Vorerkankung Bevölkerung </h3>
<div style="display: flex; align-items: flex-start;">
      <div *ngFor="let krankheit of diseases"> 
        <mat-checkbox [(ngModel)]="krankheit.checked">        {{ krankheit.name}}
         </mat-checkbox>
        </div>
      </div>
      </div>

      <div style="display: flex; justify-content: space-between;">
        <button *ngIf='step > 0'mat-mini-fab style="background-color: #10684e; color: white; margin: 5px" (click)="step = step - 1">
            <mat-icon>arrow_back</mat-icon>
          </button>
        <button mat-mini-fab style="background-color: #10684e; color: white; margin: 5px" (click)="nextStep()">
            <mat-icon>arrow_forward</mat-icon>
          </button>
    </div>

</div>

