<app-loading-overlay *ngIf="isLoading"></app-loading-overlay>

 



<div class="map-container" style="height: 100%">
    <div class="map" id="map"></div>
</div> 

<button class="btn" style="top: 80px;" (click)="openKrankheiten()"> Spezialisirungsanalyse starten </button> 
<button class="btn" style="top: 140px;" (click)="openKrankenhaus()"> Krankenhäuser selektieren </button> 

<div *ngIf="mapSelection || radiusSelection" class="btn" style="width: 50vw; left: 25vw"> 
 <span *ngIf="mapSelection" >  Klicken Sie auf die Krankenhäuser  </span>  <br>
 <div>
 <span *ngIf="radiusSelection && !locationMarker" >  Markieren Sie einen Startpunkt </span>  <br>
 <span *ngIf="radiusSelection && locationMarker"> 
Startpunkt markiert  </span> <br>
<span *ngIf="radiusSelection && locationMarker"> 
  Alle KHs im Umkreis von 450 Metern auswählen.
</span>
</div>

    <u (click)="openKrankenhaus()"> abbrechen </u>
    <u (click)="openKrankenhaus()"> anwenden </u>
</div>

 