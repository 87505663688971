import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HospitalService {


    async getSpezAnalyse(hospitals: any[], diseases: any[]) {

        // Extrahiere Krankenhaus-IDs
  const hospitalIds = hospitals.map((hospital: any) => hospital.id).join(',');

  // Extrahiere Erkrankungsnamen (falls sie einen Schlüssel "Unterkategorie" haben)
  const diseaseNames = diseases.map((disease: any) => encodeURIComponent(disease.Unterkategorie)).join(',');

  // Parameter zusammensetzen
  const queryParams = new URLSearchParams({
    kh_filter: hospitalIds,
    disease_names_vector: diseaseNames,
    return_all_hospitals: 'false'
  });

      let url = 'https://api9.moraizon.de/krankenhausatlas/get_get_hospital_specialization_scores?' + queryParams.toString(); // +'&disease_names_vector=Alzheimer%2FDemenz%2CGlaskörper&return_all_hospitals=true';
      console.log('before URL', url)

      let fixedUrl = decodeURIComponent(url);


     let result = await firstValueFrom(this.http.get<any>(fixedUrl));

 /*    result = {
      "Relevant_Cases_Detailed": [
          {
              "id": "9df99701a2f3bf80",
              "name": "Universitätsklinikum Halle (Saale)",
              "Krankheit_Behandlung": "Alzheimer/Demenz",
              "Total_Relevant_Cases": 24
          },
          {
              "id": "9df99701a2f3bf80",
              "name": "Universitätsklinikum Halle (Saale)",
              "Krankheit_Behandlung": "Glaskörper",
              "Total_Relevant_Cases": 62
          }
      ],
      "chartJS_Object_Fallzahlen_nach_Krankenhaus_Krankheit": [
          "{\n  \"type\": \"bar\",\n  \"data\": {\n    \"labels\": [\n      \"Universitätsklinikum Halle (Saale)\"\n    ],\n    \"datasets\": [\n      {\n        \"label\": \"Alzheimer/Demenz\",\n        \"data\": [\n          24\n        ],\n        \"borderWidth\": 1,\n        \"stack\": \"Stack 0\"\n      },\n      {\n        \"label\": \"Glaskörper\",\n        \"data\": [\n          62\n        ],\n        \"borderWidth\": 1,\n        \"stack\": \"Stack 0\"\n      }\n    ]\n  },\n  \"options\": {\n    \"plugins\": {\n      \"title\": {\n        \"display\": true,\n        \"text\": \"Fallzahlen nach Krankheit/Behandlung je Krankenhaus\"\n      }\n    },\n    \"responsive\": true,\n    \"interaction\": {\n      \"intersect\": false\n    },\n    \"scales\": {\n      \"x\": {\n        \"stacked\": true\n      },\n      \"y\": {\n        \"stacked\": true\n      }\n    }\n  }\n}"
      ],
      "Specialization": [
          {
              "id": "004f598f664899de",
              "Total_Cases_Hospital": 61857,
              "Total_Relevant_Cases_Hospital": 0,
              "Intra_Hospital_Specialization": 0,
              "Inter_Hospital_Specialization": 0,
              "Global_Specialization": 0,
              "Fallzahl": 9,
              "Object_Key": "1508502352",
              "name": "Harzklinikum Dorothea Christiane Erxleben, Standort Quedlinburg",
              "anzahl_betten_total": 355,
              "anzahl_fachabteilungen": 12,
              "traeger": "Landkreis Harz",
              "art_des_traegers": "öffentlich",
              "data_given": true,
              "geometry": {
                  "type": "Point",
                  "coordinates": [
                      11.1635,
                      51.7949
                  ]
              },
              "selected": true
          },
          {
              "id": "9df99701a2f3bf80",
              "Total_Cases_Hospital": 254342,
              "Total_Relevant_Cases_Hospital": 86,
              "Intra_Hospital_Specialization": 0.0003,
              "Inter_Hospital_Specialization": 1,
              "Global_Specialization": 0.0019,
              "Fallzahl": 4,
              "Object_Key": "1500200000",
              "name": "Universitätsklinikum Halle (Saale)",
              "anzahl_betten_total": 966,
              "anzahl_fachabteilungen": 27,
              "traeger": "Land Sachsen-Anhalt, vertreten durch das Ministerium für Wissenschaft, Energie, Klimaschutz und Umwelt, Vorsitzender des Aufsichtsrates: Minister Prof. Dr. Armin Willingmann",
              "art_des_traegers": "öffentlich",
              "data_given": true,
              "geometry": {
                  "type": "Point",
                  "coordinates": [
                      11.9367,
                      51.502
                  ]
              },
              "selected": true
          }
      ],
      "chartJS_Object_Spezialisierung": {
          "Intra-Krankenhaus-Spezialisierung": [
              "{\n  \"type\": [\"bar\"],\n  \"data\": {\n    \"labels\": [\"004f598f664899de\", \"9df99701a2f3bf80\"],\n    \"datasets\": [\n      {\n        \"label\": [\"Intra-Krankenhaus-Spezialisierung\"],\n        \"data\": [0, 0.0003],\n        \"stack\": [\"Stack 0\"]\n      }\n    ]\n  },\n  \"options\": {\n    \"plugins\": {\n      \"title\": {\n        \"display\": [true],\n        \"text\": [\"Intra-Krankenhaus-Spezialisierung\"]\n      }\n    },\n    \"responsive\": [true],\n    \"interaction\": {\n      \"intersect\": [false]\n    },\n    \"scales\": {\n      \"x\": {\n        \"stacked\": [false]\n      },\n      \"y\": {\n        \"beginAtZero\": [false],\n        \"min\": [0],\n        \"max\": [0.0004]\n      }\n    }\n  }\n}"
          ],
          "Inter-Krankenhaus-Spezialisierung": [
              "{\n  \"type\": [\"bar\"],\n  \"data\": {\n    \"labels\": [\"004f598f664899de\", \"9df99701a2f3bf80\"],\n    \"datasets\": [\n      {\n        \"label\": [\"Inter-Krankenhaus-Spezialisierung (ausgewählte Krankenhäuser)\"],\n        \"data\": [0, 1],\n        \"stack\": [\"Stack 0\"]\n      }\n    ]\n  },\n  \"options\": {\n    \"plugins\": {\n      \"title\": {\n        \"display\": [true],\n        \"text\": [\"Inter-Krankenhaus-Spezialisierung (ausgewählte Krankenhäuser)\"]\n      }\n    },\n    \"responsive\": [true],\n    \"interaction\": {\n      \"intersect\": [false]\n    },\n    \"scales\": {\n      \"x\": {\n        \"stacked\": [false]\n      },\n      \"y\": {\n        \"beginAtZero\": [false],\n        \"min\": [0],\n        \"max\": [1.05]\n      }\n    }\n  }\n}"
          ],
          "Inter-Krankenhaus-Spezialisierung (bundesweit)": [
              "{\n  \"type\": [\"bar\"],\n  \"data\": {\n    \"labels\": [\"004f598f664899de\", \"9df99701a2f3bf80\"],\n    \"datasets\": [\n      {\n        \"label\": [\"Inter-Krankenhaus-Spezialisierung (bundesweit)\"],\n        \"data\": [0, 0.0019],\n        \"stack\": [\"Stack 0\"]\n      }\n    ]\n  },\n  \"options\": {\n    \"plugins\": {\n      \"title\": {\n        \"display\": [true],\n        \"text\": [\"Inter-Krankenhaus-Spezialisierung (bundesweit)\"]\n      }\n    },\n    \"responsive\": [true],\n    \"interaction\": {\n      \"intersect\": [false]\n    },\n    \"scales\": {\n      \"x\": {\n        \"stacked\": [false]\n      },\n      \"y\": {\n        \"beginAtZero\": [false],\n        \"min\": [0],\n        \"max\": [0.002]\n      }\n    }\n  }\n}"
          ]
      }
  }*/
      return { result: result, url: url};  
        }

  private apiUrl

  constructor(private http:HttpClient,) {
    const useLocal = false;
    this.apiUrl = useLocal ? 'http://localhost:3000/hospital' : 'https://dashboardservice.cephlabs.de/hospital';
   }

   async getHospitals() {
    const url = `${this.apiUrl}/getHospital`;
    const result = await firstValueFrom(this.http.get<any>(url));
    return result;
  }

  async getUmkreissuche(body: any) {
    const url = `https://api9.moraizon.de/krankenhausatlas/post_get_hospital_environment_near_point`;
    const result = await firstValueFrom(this.http.post(url, body)); 
    return result;
  }

  
  async getSpezIni(body: any) {
    const url = `https://api9.moraizon.de/krankenhausatlas/raster_analyse_pop_hospital_diff`;
    const result = await firstValueFrom(this.http.post(url, body)); 
    return result;
  }




  getDiseases(){
    return [
      {"Unterkategorie": "Achillessehne", "Hauptkategorie": "Fuß"},
      {"Unterkategorie": "Akne", "Hauptkategorie": "Haut"},
      {"Unterkategorie": "Alkoholische Leberkrankheiten", "Hauptkategorie": "Leber"},
      {"Unterkategorie": "Alzheimer/Demenz", "Hauptkategorie": "Gehirn/Nervensystem"},
      {"Unterkategorie": "Andere OPs am Herzen", "Hauptkategorie": "Herz/Kreislauf"},
      {"Unterkategorie": "Angststörungen", "Hauptkategorie": "Psychatrie/Psychosomatik"},
      {"Unterkategorie": "Arthrose am Hüftgelenk", "Hauptkategorie": "Knochen/Gelenke"},
      {"Unterkategorie": "Arthrose am Kniegelenk", "Hauptkategorie": "Knochen/Gelenke"},
      {"Unterkategorie": "Asthma", "Hauptkategorie": "Lunge"},
      {"Unterkategorie": "Augenlid", "Hauptkategorie": "Auge"},
      {"Unterkategorie": "Augenverletzungen", "Hauptkategorie": "Auge"},
      {"Unterkategorie": "Ausstülpungen am Dickdarm", "Hauptkategorie": "Verdauung"},
      {"Unterkategorie": "Autoimmunerkrankungen/Entzündungen", "Hauptkategorie": "Knochen/Gelenke"},
      {"Unterkategorie": "Autoimmunerkrankungen/Neurodermitis", "Hauptkategorie": "Haut"},
      {"Unterkategorie": "Ballenzeh", "Hauptkategorie": "Fuß"},
      {"Unterkategorie": "Bandscheibenvorwölbung/-vorfall", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Bindehaut", "Hauptkategorie": "Auge"},
      {"Unterkategorie": "Blinddarm", "Hauptkategorie": "Verdauung"},
      {"Unterkategorie": "Bluthochdruck", "Hauptkategorie": "Herz/Kreislauf"},
      {"Unterkategorie": "Bronchitis", "Hauptkategorie": "Lunge"},
      {"Unterkategorie": "Brustfell", "Hauptkategorie": "Lunge"},
      {"Unterkategorie": "Brustkrebs", "Hauptkategorie": "Brust"},
      {"Unterkategorie": "Brustwarzenausfluss (Galaktorrhoe)", "Hauptkategorie": "Brust"},
      {"Unterkategorie": "Brüche (Hernien)", "Hauptkategorie": "Verdauung"},
      {"Unterkategorie": "Bypass", "Hauptkategorie": "Herz/Kreislauf"},
      {"Unterkategorie": "Bösartige Hauterkrankungen und Melanom", "Hauptkategorie": "Haut"},
      {"Unterkategorie": "COPD", "Hauptkategorie": "Lunge"},
      {"Unterkategorie": "Carcinom in situ in der Brustdrüse", "Hauptkategorie": "Brust"},
      {"Unterkategorie": "Dammriss", "Hauptkategorie": "Geburt"},
      {"Unterkategorie": "Darmverschluss", "Hauptkategorie": "Verdauung"},
      {"Unterkategorie": "Dekubitalgeschwür/Druckulkus", "Hauptkategorie": "Haut"},
      {"Unterkategorie": "Depressionen", "Hauptkategorie": "Psychatrie/Psychosomatik"},
      {"Unterkategorie": "Dickdarmkrebs", "Hauptkategorie": "Verdauung"},
      {"Unterkategorie": "Eierstöcke und Eileiter", "Hauptkategorie": "Weibliche Geschlechtsorgane"},
      {"Unterkategorie": "Einengung des Rückenmarkkanals", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Einsatz/Wechsel künstliches Kniegelenk", "Hauptkategorie": "Knie"},
      {"Unterkategorie": "Enddarm und After", "Hauptkategorie": "Verdauung"},
      {"Unterkategorie": "Enddarmkrebs", "Hauptkategorie": "Verdauung"},
      {"Unterkategorie": "Entzündliche Krankheiten in der Brustdrüse", "Hauptkategorie": "Brust"},
      {"Unterkategorie": "Entzündung der Hahnblase", "Hauptkategorie": "Männliche Geschlechtsorgane"},
      {"Unterkategorie": "Entzündung der Magen- und Darmschleimhaut", "Hauptkategorie": "Verdauung"},
      {"Unterkategorie": "Entzündungen der Haut (akut/chronisch)", "Hauptkategorie": "Haut"},
      {"Unterkategorie": "Entzündungen der NNH", "Hauptkategorie": "Lunge"},
      {"Unterkategorie": "Epilepsie", "Hauptkategorie": "Gehirn/Nervensystem"},
      {"Unterkategorie": "Essstörungen", "Hauptkategorie": "Psychatrie/Psychosomatik"},
      {"Unterkategorie": "Fehllage des Kindes", "Hauptkategorie": "Geburt"},
      {"Unterkategorie": "Fibrose und Zirrhose der Leber", "Hauptkategorie": "Leber"},
      {"Unterkategorie": "Finger und Zehen", "Hauptkategorie": "Knochen/Gelenke"},
      {"Unterkategorie": "Frakturen und Blutungen", "Hauptkategorie": "HNO"},
      {"Unterkategorie": "Gallensteine/Gallenblase", "Hauptkategorie": "Verdauung"},
      {"Unterkategorie": "Gaumen", "Hauptkategorie": "Zähne/Gebiß/Zunge"},
      {"Unterkategorie": "Gaumen und Rachenmandeln", "Hauptkategorie": "HNO"},
      {"Unterkategorie": "Gaumen- und Rachenmandeln (chronisch)", "Hauptkategorie": "Lunge"},
      {"Unterkategorie": "Geburt", "Hauptkategorie": "Geburt"},
      {"Unterkategorie": "Geburt mit Zangen oder Saugglocke", "Hauptkategorie": "Geburt"},
      {"Unterkategorie": "Gebärmutter", "Hauptkategorie": "Weibliche Geschlechtsorgane"},
      {"Unterkategorie": "Gebärmutterhals", "Hauptkategorie": "Weibliche Geschlechtsorgane"},
      {"Unterkategorie": "Gehirnerschütterung", "Hauptkategorie": "Gehirn/Nervensystem"},
      {"Unterkategorie": "Gelenk-Operationen", "Hauptkategorie": "Fuß"},
      {"Unterkategorie": "Glaskörper", "Hauptkategorie": "Auge"},
      {"Unterkategorie": "Gleichgewichtsstörungen", "Hauptkategorie": "HNO"},
      {"Unterkategorie": "Grippe", "Hauptkategorie": "Lunge"},
      {"Unterkategorie": "Gutartige Erkrankungen in der Brustdrüse (Mammadysplasie)", "Hauptkategorie": "Brust"},
      {"Unterkategorie": "Harnblase", "Hauptkategorie": "Männliche Geschlechtsorgane"},
      {"Unterkategorie": "Harnblase", "Hauptkategorie": "Nieren"},
      {"Unterkategorie": "Harnröhre", "Hauptkategorie": "Nieren"},
      {"Unterkategorie": "Harnwege (Verschluss am Rückstau)", "Hauptkategorie": "Nieren"},
      {"Unterkategorie": "Hautveränderungen durch Sonne", "Hauptkategorie": "Haut"},
      {"Unterkategorie": "Hepatitis (chronisch)", "Hauptkategorie": "Leber"},
      {"Unterkategorie": "Herzinfarkt/Herzmuskel/Durchblutung", "Hauptkategorie": "Herz/Kreislauf"},
      {"Unterkategorie": "Herzkatheteruntersuchung", "Hauptkategorie": "Herz/Kreislauf"},
      {"Unterkategorie": "Herzklappen", "Hauptkategorie": "Herz/Kreislauf"},
      {"Unterkategorie": "Herzschrittmacher/Lungengefäße", "Hauptkategorie": "Herz/Kreislauf"},
      {"Unterkategorie": "Hexenschuss", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Hirnfunktionsstörungen", "Hauptkategorie": "Psychatrie/Psychosomatik"},
      {"Unterkategorie": "Hirnhaut-/Gehirnentzündung", "Hauptkategorie": "Gehirn/Nervensystem"},
      {"Unterkategorie": "Hoden und Samenleiter", "Hauptkategorie": "Männliche Geschlechtsorgane"},
      {"Unterkategorie": "Hornhaut", "Hauptkategorie": "Auge"},
      {"Unterkategorie": "Hornhaut-Schwielen/Hühneraugen", "Hauptkategorie": "Haut"},
      {"Unterkategorie": "Hörverlust", "Hauptkategorie": "HNO"},
      {"Unterkategorie": "Hühneraugen und Hornhaut", "Hauptkategorie": "Fuß"},
      {"Unterkategorie": "Iliosakralgelenk-Syndrom", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Infektion", "Hauptkategorie": "Auge"},
      {"Unterkategorie": "Infektionen", "Hauptkategorie": "Haut"},
      {"Unterkategorie": "Infektionen", "Hauptkategorie": "Knochen/Gelenke"},
      {"Unterkategorie": "Infektionen", "Hauptkategorie": "Lunge"},
      {"Unterkategorie": "Ischias", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Kaiserschnitt", "Hauptkategorie": "Geburt"},
      {"Unterkategorie": "Knoten in der Brust", "Hauptkategorie": "Brust"},
      {"Unterkategorie": "Krebs", "Hauptkategorie": "Männliche Geschlechtsorgane"},
      {"Unterkategorie": "Kreuzbandriss/Meniskusriss", "Hauptkategorie": "Knochen/Gelenke"},
      {"Unterkategorie": "Lungenblutung", "Hauptkategorie": "Lunge"},
      {"Unterkategorie": "Lungenembolie/Lungengefäße", "Hauptkategorie": "Herz/Kreislauf"},
      {"Unterkategorie": "Lungengewebe", "Hauptkategorie": "Lunge"},
      {"Unterkategorie": "Lymphschwellungen", "Hauptkategorie": "Haut"},
      {"Unterkategorie": "Magengeschwürr/Zwölffingerdarm", "Hauptkategorie": "Verdauung"},
      {"Unterkategorie": "Mehrlingsschwangerschaft", "Hauptkategorie": "Geburt"},
      {"Unterkategorie": "Meniskusriss", "Hauptkategorie": "Knochen/Gelenke"},
      {"Unterkategorie": "Migräne/Kopfschmerzen", "Hauptkategorie": "Gehirn/Nervensystem"},
      {"Unterkategorie": "Morbus Bechterew", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Multiple Sklerose", "Hauptkategorie": "Gehirn/Nervensystem"},
      {"Unterkategorie": "Nesselsucht und Juckreiz", "Hauptkategorie": "Haut"},
      {"Unterkategorie": "Nierensteine/Harnleitersteine/Koliken", "Hauptkategorie": "Nieren"},
      {"Unterkategorie": "Osteoporose", "Hauptkategorie": "Knochen/Gelenke"},
      {"Unterkategorie": "Plastische Operationen", "Hauptkategorie": "Brust"},
      {"Unterkategorie": "Posttraumatische Belastungsstörungen", "Hauptkategorie": "Psychatrie/Psychosomatik"},
      {"Unterkategorie": "Prostata", "Hauptkategorie": "Männliche Geschlechtsorgane"},
      {"Unterkategorie": "Querschnittslähmung", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Reizdarmsyndrom (RDS)", "Hauptkategorie": "Verdauung"},
      {"Unterkategorie": "Rückenschmerz", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Schädel-Hirn-Trauma", "Hauptkategorie": "Gehirn/Nervensystem"},
      {"Unterkategorie": "Schielen/Sehstörung", "Hauptkategorie": "Auge"},
      {"Unterkategorie": "Schmerzen in der Brust (Masstodynie)", "Hauptkategorie": "Brust"},
      {"Unterkategorie": "Schlaganfall/Hirnblutung", "Hauptkategorie": "Gehirn/Nervensystem"},
      {"Unterkategorie": "Schlafstörung", "Hauptkategorie": "Gehirn/Nervensystem"},
      {"Unterkategorie": "Schlafstörungen", "Hauptkategorie": "HNO"},
      {"Unterkategorie": "Schwangerschaftsdiabetes", "Hauptkategorie": "Geburt"},
      {"Unterkategorie": "Scheiden-/Gebärmuttervorfall (Genitalprolaps)", "Hauptkategorie": "Weibliche Geschlechtsorgane"},
      {"Unterkategorie": "Sehnerven", "Hauptkategorie": "Auge"},
      {"Unterkategorie": "Sterilität", "Hauptkategorie": "Männliche Geschlechtsorgane"},
      {"Unterkategorie": "Stens", "Hauptkategorie": "Herz/Kreislauf"},
      {"Unterkategorie": "Toxische Leberkrankheiten", "Hauptkategorie": "Leber"},
      {"Unterkategorie": "Tränenapparat", "Hauptkategorie": "Auge"},
      {"Unterkategorie": "Tumorerkrankung", "Hauptkategorie": "Auge"},
      {"Unterkategorie": "Unterschenkelgeschwür", "Hauptkategorie": "Haut"},
      {"Unterkategorie": "Verhaltens-/Persönlichkeitsstörungen", "Hauptkategorie": "Psychatrie/Psychosomatik"},
      {"Unterkategorie": "Vorzeitige Entbindung", "Hauptkategorie": "Geburt"},
      {"Unterkategorie": "Vorzeitiger Blasensprung", "Hauptkategorie": "Geburt"},
      {"Unterkategorie": "Wachstumsschäden/Deformitäten/Fehlstellungen", "Hauptkategorie": "Knochen/Gelenke"},
      {"Unterkategorie": "Wirbel/Bandscheiben", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Wirbelgelenk-Arthrose", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Wirbelgleiten", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Wirbelsäulenbruch", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Wirbelsäulenentzündung", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "Wirbelsäulenverkrümmung", "Hauptkategorie": "Wirbelsäule"},
      {"Unterkategorie": "X-/O-Beine", "Hauptkategorie": "Knochen/Gelenke"},
      {"Unterkategorie": "Zehengelenk", "Hauptkategorie": "Fuß"},
      {"Unterkategorie": "Zähne/Gebiß/Zunge", "Hauptkategorie": "Zähne/Gebiß/Zunge"}
    ]
    
  }

}
