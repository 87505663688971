<div class="image">
  <div id="intro" (click)="hideDisplay()">
    <div id="DG">Digitaler Planungszwilling</div>
    <img src="https://upload.wikimedia.org/wikipedia/commons/5/53/Wappen_Sachsen-Anhalt.svg"
      width="50px"
      id="SA"
    />
    <img id="mlUni" src="../../../assets/Siegel zentriert schwarz mit Text.jpg"

    />
  </div>

  <div class="container">
    <div style="display: flex; flex-direction: row; gap: 200px">
      <mat-card class="example-card custom-shadow" matRipple (click)="click('datahub2')">
        <mat-card-header>
          <mat-card-title>Datahub</mat-card-title>
        </mat-card-header>
        <img mat-card-image src="../../../assets/ai-generated-8540914_960_720.jpg">
        <mat-card-content>
          <p>
            Der Datahub eröffnet Möglichkeiten zur regionalen Analyse und Prognose, die eine datengesteuerte Entwicklung fördern.
          </p>

          <a  class="more" (click)="click('datahub2')"  style="color: #00695C; text-decoration :none">mehr ... </a>
        </mat-card-content>

      </mat-card>
      
      

      <mat-card class="example-card custom-shadow" matRipple (click)="click('prognosen')" >
        <mat-card-header>
          <mat-card-title>Prognosen</mat-card-title>
        </mat-card-header>
        <img mat-card-image src="../../../assets/technology-6701404_1280.webp">

        <mat-card-content>
          <p>
            Mit dem Tool "Prognosen" können Sie die Bedarfe der lokalen Bevölkerung analysieren und auf die Zukunft prognostizieren.
          </p>
          <a class="more" (click)="click('prognosen')"  style="color: #00695C; text-decoration :none">mehr ... </a>
        </mat-card-content>
      </mat-card>
      
    </div>

    <div style="display: flex; flex-direction: row; gap: 200px">
        <mat-card class="example-card custom-shadow"  matRipple (click)="click('analysen')">
            <mat-card-header>
              <mat-card-title>Analyse</mat-card-title>
            </mat-card-header>
            <img mat-card-image src="../../../assets/business-5475664_1280.jpg">
            <mat-card-content>
              <p>
                „Analysen“ bietet Ihnen einen georeferenzierten Rahmen für die Analyse von Erreichbarkeiten zwischen Bevölkerung und lokaler Infrastruktur.
              </p>
              <a class="more" (click)="click('analysen')"  style="color: #00695C; text-decoration :none">mehr ... </a>
            </mat-card-content>
          </mat-card>

          <mat-card class="example-card custom-shadow" matRipple (click)="click('simulation-section')">
            <mat-card-header>
              <mat-card-title>Simulation</mat-card-title>
            </mat-card-header>
            <img mat-card-image src="../../../assets/earth-3628710_1280.jpg">

            <mat-card-content>
              <p>
                Simulationen erlauben das Durchspielen eines hypothetischen Experiments in einem geschützten, digitalen Raum
              </p>

              <a  class="more" (click)="click('simulation-section')"  style="color: #00695C; text-decoration :none">mehr ... </a>
            </mat-card-content>
          </mat-card>

    </div>
  </div>

  <!-- Notification bubble -->
  <div class="notification-container" (click)="navigateToNotifications()">
    <div class="notification-message" [ngSwitch]="totalNotificationCount">
      <ng-container *ngSwitchCase="0">
        Sie haben keine neuen Benachrichtigungen
      </ng-container>
      <ng-container *ngSwitchCase="1">
        Sie haben 1 neue Benachrichtigung
      </ng-container>
      <ng-container *ngSwitchDefault>
        Sie haben {{ totalNotificationCount }} neue Benachrichtigungen
      </ng-container>
    </div>
  </div>
  
</div>
