
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GibietParamsDialogComponent } from 'src/app/components/datahub2/gebiete/gibiet-params-dialog/gibiet-params-dialog.component';

@Component({
  selector: 'app-analyse-gym',
  templateUrl: './analyse-gym.component.html',
  styleUrls: ['./analyse-gym.component.css']
})
export class AnalyseGymComponent {






  sizeClassOutside: number = 20;
  sizeClassCenter: number = 20;
  countYears: number = 1;
  countSchool: number = 0;
  gefahrAnalyse : any[]= [];
  step: number = 0;
  resultGefahr = []
schoolSearch: any = '';


extractRawData(data: any[]) {
  return data.map(item => item.raw);
};
  endAnalyse() {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');


    this.dialogRef.close({ schools: this.data.schools,
      sizeClassOutside: this.sizeClassOutside,
      sizeClassCenter: this.sizeClassCenter,
      countYears: this.countYears,
      name:  "G_Analyse_" + hours + ":" + minutes + ":" + seconds
     })
  }

  startAnalyse() {
  


    this.data.schools.forEach((school: any ) => {
      if(school.raw.checked) {
      var count = 0;
      if (school.raw.properties.Mittelzentrum || school.raw.properties.Mittelzentrum_Gemeinde) {
        var refValue = this.sizeClassCenter
      } else {
        refValue = this.sizeClassOutside
      }

      for (const year in school.raw.properties.Klassengroessen) {
        if (school.raw.properties.Klassengroessen[year] < refValue) {
          count++;
        }
      }

      if (count >= this.countYears) {
        school.raw.inDanger = true
        this.countSchool++;

      }

    }

    });

    this.step++

  

  }

  downloadJson(type: any) {

    console.warn("DDD", this.data)
    var t = ''
    var d: any; 
    if(type == 'school') {
       d = this.extractRawData(this.data.schools)
      t = 'school.json'

    }

    if(type == 'bezirke') {
      d = this.extractRawData(this.data.bezirke)

t = 'bezirke.json'

    }

    const jsonString = JSON.stringify(d, null, 2); // JSON in lesbaren Text umwandeln
    const blob = new Blob([jsonString], { type: 'application/json' }); // Blob erstellen
    const url = window.URL.createObjectURL(blob); // Objekt-URL erstellen

    // Download-Link erstellen und klicken
    const a = document.createElement('a');
    a.href = url;
    a.download = t;
    a.click();

    // URL wieder freigeben
    window.URL.revokeObjectURL(url);
  }

  constructor(
    public dialogRef: MatDialogRef<GibietParamsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router

  ) {
    this.gefahrAnalyse = data.gefahrAnalysen

    console.warn("existing ",data )
   }



   selectSchool() {
    this.data.schools.forEach((element: any) => {
      element.raw.checked = true;
    });
    

  }
  deselectSchool() {
   this. data.schools.forEach((element: any) => {
      element.raw.checked = false;

    });

  }


  goToMobility(){
    this.router.navigateByUrl('prognose/schoolMobility');
    this.dialogRef.close();
  }

  goToMobility2(){
   // this.router.navigateByUrl('prognose/schoolMobility');
    this.dialogRef.close({mode: true});
  }
}
