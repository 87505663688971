<h2 mat-dialog-title>Report</h2>
<mat-dialog-content>
  <div class="reporting-template-preview-area">


    <ng-container>
      <div class="reporting-page">
        <div class="reporting-header">
          <div class="reporting-header-text">
            <ng-container>

            </ng-container>
          </div>
          <div class="reporting-header-logo">
            <img
              src=".\assets\Wappen_Sachsen-Anhalt.png"
              alt="Wappen Sachsen-Anhalt"
            />
          </div>
        </div>
       

          <div style="margin-top:-150px">
            <ng-container>
              <div class="report-title-container">
                <textarea matInput [(ngModel)]="titelReport" class="report-title" placeholder="Geben Sie hier den Berichtstitel ein..."></textarea>
              </div>
              
              
              <div class="report-title-container">
                <h2 style="margin-top: 20px;">
                  erstellt von {{creator}}
                </h2>
              </div>

            </ng-container>


            

          </div>




         
       
        <div id="reporting-footer" class="reporting-footer">
          <div class="reporting-footer-date">
            <span>erstellt am: {{ getDateTime() }}</span>
          </div>
          <div class="reporting-footer-page-number">
          
          </div>
        </div>
      </div>
    </ng-container>


    <ng-container *ngFor="let indikator of indakatoren; let i = index">
      <div class="reporting-page">
        <div class="reporting-header">
          <div class="reporting-header-text">
            <ng-container *ngIf="indikator.pageType === 'krank'">
              <h2>{{ indikator.filterName }}</h2>
            </ng-container>
            <ng-container *ngIf="indikator.pageType === 'chart'">
              <h2>{{ indikator.name }} - {{ indikator.filterName }}</h2>
              <span>{{ getMinMax(indikator) }}</span>
            </ng-container>
            <ng-container *ngIf="indikator.pageType === 'map'">
              <h2>{{ indikator.name }}</h2>
              <span>{{ indikator.maxYear }}</span>
            </ng-container>
          </div>
          <div class="reporting-header-logo">
            <img
              src=".\assets\Wappen_Sachsen-Anhalt.png"
              alt="Wappen Sachsen-Anhalt"
            />
          </div>
        </div>
        <div class="reporting-content" [ngClass]="{ 'flex-start': indikator.pageType === 'krank' }">
          <div class="chart-legend" *ngIf="indikator.pageType === 'chart'">
            <div class="legend-item" *ngFor="let l of indikator.chartLegend">
              <div
                [style.background-color]="l.backgroundColor"
                class="item-color"
              ></div>
              <span> {{ l.label }} </span>
            </div>
          </div>





          <div style="display: flex;justify-content:space-between; align-items:flex-start">
            <ng-container *ngIf="indikator.pageType === 'chart'">
              <div class="chart-img">
                <img [src]="indikator.chartImg" alt="chart" />
              </div>
            </ng-container>

            <ng-container *ngIf="indikator.pageType === 'krank'">
              <div class="chart-img">
                <img [src]="indikator.krankImg" alt="chart" />
              </div>
            </ng-container>
  
            <div class="map-img" *ngIf="indikator.pageType === 'map'">
              <img [src]="indikator.mapImg" alt="map" />
            </div>
  
              <div style="display:flex;justify-content:center;align-items:center;flex-direction:column; gap:10px">
                <div 
                [ngSwitch]="indikator.pageType" 
                contenteditable="true"
                [innerHTML]="displayedDescriptions[i] || ''"
                (mouseup)="handleTextSelection(i, $event)"
                [ngClass]="{
                  'chart-comment': indikator.pageType === 'chart',
                  'map-comment': indikator.pageType === 'map',
                  'krank-comment': indikator.pageType === 'krank'
                }">
              </div>
              

         
    
              <!-- Button to generate description for chart/map -->
              <button *ngIf="indikator.pageType === 'chart'"
                mat-raised-button
                color="primary"
                (click)="
                  generateDescription(
                    i,
                    indikator.pageType === 'chart'
                      ? indikator.chartImg
                      : indikator.mapImg
                  )
                "
              >
                <mat-icon>diamond</mat-icon> KI Beschreibung ?
              </button>
                <div  [ngSwitch]="indikator.pageType" class="btn-collection" style="display:flex; gap: 10px;">
                  <div *ngSwitchCase="'chart'">
                    <button 
                    mat-raised-button 
                    *ngIf="showActionButton === i" 
                    (click)="handleSentenceChange(i)">
                    Wortwahl ändern
                  </button>
                  </div>

                  <div *ngSwitchCase="'chart'">
                    <mat-form-field *ngIf="showActionButton === i"  >
                      <textarea matInput placeholder="Ergänzung..."></textarea>
                  </mat-form-field>
                  </div>
                  
                </div>



              </div>
          </div>




         
        </div>
        <div id="reporting-footer" class="reporting-footer">
          <div class="reporting-footer-date">
            <span>erstellt am: {{ getDateTime() }}</span>
          </div>
          <div class="reporting-footer-page-number">
            <span>Seite {{ i + 1 }}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Zurück</button>
  <button  (click)="storeRepot()" mat-raised-button  color="warn">Report speichern</button>
  <button
    mat-raised-button
    color="primary"
    [mat-dialog-close]="true"
    (click)="generatePDF()"
  >
  als PDF herunterladen
  </button>
</mat-dialog-actions>
