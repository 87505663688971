import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-add-route-display',
  templateUrl: './add-route-display.component.html',
  styleUrls: ['./add-route-display.component.css']
})
export class AddRouteDisplayComponent {
  @Input() points: any[] = [];

  close() {
    
  }
}
