import { Component, Input, OnInit } from '@angular/core';
import { ReachMatrixComponent } from '../../raum-analysen/reach-matrix/reach-matrix.component'
import { DatahubService } from '../../../service/datahubservice/datahubservice.component';
import { RoutingserviceComponent } from '../../../service/routingservice/routingservice.component';
import { DomSanitizer } from '@angular/platform-browser';
import { Storageservice } from '../../../service/storageservice-component/storageservice-component.component';
import { SimulationService } from 'src/app/service/simulationservice/simulation.service';
import * as XLSX from 'xlsx';
import { HttpClient } from '@angular/common/http';
import { LocationAppComponent } from '../location-app/location-app.component';




@Component({
  selector: 'app-reach-config',
  templateUrl: './reach-config.component.html',
  styleUrls: ['./reach-config.component.css']
})
export class ReachConfigComponent implements OnInit {
  @Input() step: any; 
  @Input() selectedMode: any; 
  @Input() umkreisMode: any; 
  @Input() singleMode: any; 

downloaded: any;
fileName: any;




selectedTextMode: any;
selectedTextBatchMode: any;
markerContainer: any [] = [];
selectedAddressStr: any;
selectedIndex: any;
  uploaded: boolean = false;
  upload: any[] = [];
  convertToJson: string = '';
selectedDocPop: any;


getAddress(marker: any) {
console.log(marker); 
}




rangeMin:number = 2023;
rangeMax:number = 2033; 



onFileChange(event: any) {
  this.uploaded = true;
  const file = event.target.files[0];
  const reader = new FileReader();
  this.fileName = file.name;
  reader.readAsBinaryString(file);
  reader.onload = (e: any) => {
    const data = e.target.result;
    const workbook = XLSX.read(data, { type: 'binary' });
    workbook.SheetNames.forEach((sheet) => {
      this.upload = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
      console.log(this.upload);

  this.upload.forEach((el: any) => {
  
    console.log(el);
    this.importData.push({
      name: el.Name,
      address: el.Adresse,
      latlng: []
    })
  }
    ); 
      this.convertToJson = JSON.stringify(this.upload, undefined, 4);
      console.log("JSON", this.convertToJson);
    });
  };


  console.log(this.upload);

  this.upload.forEach((el: any) => {
  
  console.log(el);
  this.importData.push({
    name: el.Name,
    address: el.Adresse,
    latlng: []
  })
}
  ); 

  console.warn(this.importData); 

 
}



addRow() {
  console.log(this.tableDummy)
  this.tableDummy.push({
    name: '',
    address: '',
    latlng: []
  })
}
  async setPop(pop: string) {
 
  this.step = 6
  this.pop=pop;

 var  years = await this.routingservice.getYearPopArray(pop)
//@ts-ignore
 years.sort((a:any, b:any) => parseInt(a) - parseInt(b));
 //@ts-ignore
  this.rangeMin=years[0];
  //@ts-ignore
  this.rangeMax=years[years.length - 1]; 

}
  src: any = []; 



  minAge = 0;
  maxAge = 100;


  latlng: any
  meta: any;

  selectedEinrichtungen: any;
  within_flg!: boolean;
  transportMode!: string;
  gemeindeebene = false;
  layers: any;
  selectedGemeinde: any;
  popYear: number [] = [];
  radius: number = 0;
  radiusObj = {
    radius: 0,
    isDistance: true
  }
  spin = false;
png: any;

/////////////ahmed Var
adressList:any = [];
adress:any
selectedAddress:any="";
filteredAdressList: any[] = [];
selectedCoordinates: any[] | undefined;
adressListCor:any= [];
showDropdown: boolean = false;
progress:any= 0.5
checkName:boolean = false;
needDialog:boolean=false;
popOptions:any;
pop:any;
importData : any[] = []; 
tableDummy = [{
  name: "Beispiel_1",
  address: "Kalkstraße 1, 06712 Zeitz",
  latlng: [51.0489876115527, 12.13517227105837]
},
{
  name: "",
  address: "",
  latlng: []
},
]

 async completeDropNew(add: string) {
    this.filteredAdressList = [];
    this.showDropdown = true; 
    if(add.length < 4) {
      return; 
    }
    var res = await this.datahub.getAutocompleteList(add);
    console.warn("res", res); 
    //@ts-ignore
    this.filteredAdressList = res.features; 
  }
fillList() {
  this.simulationsService.getAdress().subscribe((options: any) => {
      options.features.forEach((feature: any) => {
        const s = feature.properties?.street_name;
        const c = feature.geometry?.coordinates;
        this.adressListCor!.push({
          adress: s,
          coord: c
        });
        this.adressList.push(s);
      });
      this.adressList = Array.from(new Set(this.adressList));
  });
}

selectedCoordinatesLatLng:any;
getCoordinates() {
  this.checkName=false;
  this.showDropdown = false;
  if (this.selectedAddress) {
    const selectedAddressObject = this.adressListCor.find((item:any) => item.adress === this.selectedAddress);
    if (selectedAddressObject) {
      this.selectedCoordinatesLatLng={
        lat:selectedAddressObject.coord[1],
        lng:selectedAddressObject.coord[0]
      }
    }
  }
  this.storageService.setSelectedMarker(this.selectedCoordinatesLatLng);
}

completeDrop(){

  // Filtern Sie die Adressen, die zur Suchanfrage passen
  this.filteredAdressList = this.adressList.filter((address:any) => address.toLowerCase().includes(this.selectedAddress.toLowerCase()));
  this.showDropdown = true;
}

 


  async chooseLocation() {
    this.checkName=true;
    this.selectedAddress=undefined
    //@ts-ignore
    document.getElementById("dialog").close();
    this.reachComponent.addMarker();

  }

  exportData() {
    var dataEx = [['Name', 'Adresse']];
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataEx);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'eigene_daten.xlsx');
    this.downloaded = true;
  }


  async checkSteetName() {
    console.log('Überprüfen');
    if(this.checkName){
      //@ts-ignore
        await this.storageService.setStreetName(this.reachComponent.newMarker?._latlng);
          console.log('Überprüfen');
          if (this.storageService.imagePath !== '') {
            console.log('Überprüfen 2');
            this.selectedAddress = await this.storageService.getStreetName();
            console.log(this.selectedAddress);
      
            if (this.selectedAddress !== undefined) {
              if(this.selectedAddress ==''){
                this.selectedAddress='Straßennamen nicht gefunden'
              }
              
            }
          }
         
    }
    
  }

 sleep(ms:any) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  setValue($event: Event, arg1: string) {
    //@ts-ignore
    var val = Number($event.target.ariaValueText)

    switch (arg1) {
      case 'max':
        this.maxAge = val;
        break;
      case 'min':
        this.minAge = val;
        break;

      case 'radius':
        this.radius = val;
        this.radiusObj.radius = val;
        this.radiusObj.isDistance = true;
        break;
      case 'time':
        this.radius = val;
        this.radiusObj.radius = val;
        this.radiusObj.isDistance = false;
        break;

    }
  }


  /*displayRaster(res: any) {

    //console.log(res); 
    //this.storageService.setResult(res); 

    this.reachComponent.updateLayerRaster();

  }*/

  closeDialog() {

    this.step = 1;
    this.spin = false;
    //@ts-ignore
    document.getElementById("dialog").close();
  }




  getMarkerFromLocationApp() {

  }

  async umkreissuche(latlng: any) {
    var res: any;

    if (this.selectedMode != 0) { return; } 

    var positionFormat: { X: any; Y: any; Name: any; Street_Name: any; }[] = []
    for (const [key, value] of this.reachComponent.markersObjRefMap) {

      positionFormat.push(
        {"X":value.plain.latlng[1], "Y": value.plain.latlng[0], "Name": value.plain.name,"Street_Name": value.plain.address}
 
      )
    }

    console.warn("S", this.reachComponent.markersObjRefMap,  positionFormat)


    

      var umkreisRequest = {
        type: "Umkreissuche",
        position: positionFormat, 
        gesuchter_typ: this.selectedEinrichtungen,
        only_burgenlandkreis: this.within_flg,
        radius_meter: this.radiusObj.radius * 1000,
        radius_time: this.radiusObj.radius,
        mode: this.transportMode,
        street_name:this.selectedAddress,
      }

      console.log('Umkreisrequest', umkreisRequest)




        try{
          if (this.radiusObj.isDistance) {

            var data = { 
              "Gesuchter_Typ": this.selectedEinrichtungen,
              "Mode": this.transportMode,
              "Position": positionFormat,
              "Radius": this.radiusObj.radius * 1000,
              "Type": "Umkreissuche",
              "Filter" : "Distance"
            }
      
            res = await this.routingservice.getUmkreisAnalyse(data, undefined, "Neu" , true) 
            console.log("RES_DISTANCE", res)
   
          } else {
    
            data = { 
              "Gesuchter_Typ": this.selectedEinrichtungen,
              "Mode":this.transportMode,
              "Position": positionFormat,
              "Radius": this.radiusObj.radius,
              "Type": "Umkreissuche",
              "Filter" : "Time"
            }


            res = await this.routingservice.getUmkreisAnalyse(data, undefined, "Neu", true) 
            console.log("RES_TIME", res)
          }
        } 
        catch(error) {
          console.log(error)
          this.spin = false; 
          this.step = 0;
          res='empty';
          this.needDialog=true;
        
      
        }
         

         

        console.log("RES", res)

      
    return res; 
  }
  async endDialog() {
    var res: any;
    this.spin = true;

    if (this.selectedMode == 0) {
      var results: any[] = []
     
        var res = await this.umkreissuche(''); 
        if(res!='empty'){
          this.spin = false; 
          this.closeDialog();
          this.storageService.addTempResult(res);
          console.warn("Res",res)
          this.reachComponent.clickResult(undefined, res)

          
        } else {
          this.spin = false; 
          this.step = 0;
          res='empty';
          this.needDialog=true;
        }
      res = results[0]; 

    }
    else {
      var rasterResults = []
      
      /*
      var rasterRequest = {
        Type: "Rastersuche",
        Pop: this.pop,
        Year: this.popYear,
        Age_From: this.minAge,
        Age_To: this.maxAge,
        Mode: this.transportMode,
        Gesuchter_Typ: this.selectedEinrichtungen,
        Only_Burgenlandkreis: this.within_flg,
      } */

      var rasterRequest = {
        "Type": "Rasteranalyse",
        "Pop": this.pop,
        "Year": this.popYear,
        "Age_From": this.minAge,
        "Age_To": this.maxAge,
        "Mode": this.transportMode,
        "Gesuchter_Typ": this.selectedEinrichtungen,
        "Only_Burgenlandkreis":false
    }

    console.warn("RASTERREQ", rasterRequest)


      try{

      res = await this.routingservice.postRasterAnalyse(rasterRequest);
      console.warn("raster", res)
        
      //this.closeDialog();
    }
      catch(error) {
        alert("Error /POST Rasteranalyse"); 
        console.warn(error)
        this.spin = false; 
        this.step = 0;

        //this.closeDialog();
        res='empty';
        this.needDialog=true;
 
      }

      if(res!='empty' ){
        if(res.Error) {
          alert(res.Error)
          this.closeDialog();

          return; 
        }
       // this.storageService.setResult(res, undefined);
        this.storageService.addTempResult(res);
        //if(i ==this.selectedEinrichtungen.length) {
        //this.closeDialog();
        //} else {
         // alert("next req")
       // }
       //rasterResults.push(res); 
      } else {
        alert("Error /POST Rasteranalyse"); 
      }

   
    

   

    this.reachComponent.clickRasterResult(res.Results[0], res, 0)
    this.closeDialog();


    }

    if(res!='empty'){
     // this.reachComponent.updateResult(0);
    }
  }


  constructor(private reachComponent: ReachMatrixComponent, public storageService: Storageservice, public datahub: DatahubService, public routingservice: RoutingserviceComponent, private _sanitizer: DomSanitizer,private simulationsService:SimulationService, private http:HttpClient, private locationApp:LocationAppComponent) {
    setInterval(async () => {
      this.layers = this.storageService.getCurrentShapes()
    }, 1000);
  }
  async ngOnInit(): Promise<void> {
    this.fillList();
    this.meta = await this.datahub.getEinrichtungenMeta();

    console.warn(this.meta); 
    this.popOptions= await this.routingservice.getPopulation();
    
  }


  auswahlInLandkreis(mode: boolean){
    this.within_flg = mode;
    if(this.selectedMode == 0) {
      this.endDialog();
    } else{
      this.step = 5; 
    }


  }







}
