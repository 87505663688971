

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GibietParamsDialogComponent } from 'src/app/components/datahub2/gebiete/gibiet-params-dialog/gibiet-params-dialog.component';
import { DatahubService } from 'src/app/service/datahubservice/datahubservice.component';
import { SynthService } from 'src/app/service/synthService/synth.service';

@Component({
  selector: 'app-start-dialog',
  templateUrl: './start-dialog.component.html',
  styleUrls: ['./start-dialog.component.css']
})
export class StartDialogComponent {
getSum() {

  const average = this.tableData.filter(item => item.checked)
    .reduce((acc, item, _, arr) => acc + item.Prozent / arr.length, 0);

  return average
}
selectedPrimary: any;
clickSelection(scen: any) {
  this.dialogRef.close(scen);
}

tableData =  [
  { "Schuljahr": "2014/15", "Prozent": 37.3 , "checked" : true},
  { "Schuljahr": "2015/16", "Prozent": 41.6 , "checked" : true},
  { "Schuljahr": "2016/17", "Prozent": 42.2 , "checked" : true},
  { "Schuljahr": "2017/18", "Prozent": 36.5 , "checked" : true},
  { "Schuljahr": "2018/19", "Prozent": 36.0 , "checked" : true},
  { "Schuljahr": "2019/20", "Prozent": 36.0 , "checked" : true},
  { "Schuljahr": "2020/21", "Prozent": 33.4 , "checked" : true},
  { "Schuljahr": "2021/22", "Prozent": 31.9 , "checked" : true},
  { "Schuljahr": "2022/23", "Prozent": 33.9 , "checked" : true},
  { "Schuljahr": "2023/24", "Prozent": 31.1 , "checked" : true},
  { "Schuljahr": "2024/25", "Prozent": 33.0 , "checked" : true}
]

  step = 1;
  populations: any;
  mobis = ["PVG Fahrplan 2024/2025", "PVG Fahrplan 2023/2024", "Neuen Buslinienplan erstellen"]
  schools = ['Grundschule', 'Gymnasien','Sekundarschule', 'Berufsbegleitende Schule (BBS)'];
  formerSelections : any[]= [
  ];
  
  selectedPopulation: string = '';
  selectedSchool: string = '';
 // selectedYear: number = 2024;
selectedMobi: any;
sizeClassCenter: any = 20;
sizeClassOutside: any = 12;
maxTransportTime: any = 25;
selectedEndYear: any = 2029;
selectedStartYear: any = 2024; 

  constructor(
    public dialogRef: MatDialogRef<GibietParamsDialogComponent>,
    private synthService: SynthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.formerSelections = data.formerSelection

  }

  async ngOnInit(): Promise<void> {
    const res: any = await this.synthService.getPopuationNames();
    this.populations = res.popNames;

    this.goBack() 
  }

  goToParams() {
    this.step = 2;
  }

  onYearChange(event: any) {
}

    goBack() {
      this.selectedPopulation = ''
      this.selectedMobi = ''
      this.selectedSchool = ''
      this.selectedStartYear = 2024
      this.selectedEndYear = 2029
      this.maxTransportTime = 25
      this.sizeClassCenter = 20;
      this.sizeClassOutside = 12;
      this.step = 1
    }

  storeSelection() {



    const selectedData = {
      population: this.selectedPopulation,
      mobility: this.selectedMobi, 
      school: this.selectedSchool,
      startYear: this.selectedStartYear, 
      endYear: this.selectedEndYear, 
      maxTransportTime: this.maxTransportTime,
      sizeClassCenter: this.sizeClassCenter,
      sizeClassOutside: this.sizeClassOutside
    };
    console.log('Selected Data:', selectedData);
    this.dialogRef.close({ savedData: 'h'
    
    });
  }
}
