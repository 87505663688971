import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';



@Component({
  selector: 'app-warning-dialog-thema-datahub',
  templateUrl: './warning-dialog-thema-datahub.component.html',
  styleUrls: ['./warning-dialog-thema-datahub.component.css']
})
export class WarningDialogThemaDatahubComponent {
  
  constructor(
    @Inject(MAT_DIALOG_DATA)  public data: { topicName: string } 
  ) {}
}
