import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Chart, ChartConfiguration, Plugin } from 'chart.js';
import { KeycloakService } from 'keycloak-angular';
import { DatahubService } from 'src/app/service/datahubservice/datahubservice.component';
import { KeycloakOperationService } from 'src/app/service/keycloakService/keycloak-operation.service';
import { LlamaComponent } from 'src/app/service/llama/llama.component';
import { NotfallinfrastrukturComponent } from '../notfallinfrastruktur/notfallinfrastruktur.component';
import { ShareDialogComponent } from '../../raum-analysen/share-dialog/share-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notfallinfrastruktur-item',
  templateUrl: './notfallinfrastruktur-item.component.html',
  styleUrls: ['./notfallinfrastruktur-item.component.css']
})


export class NotfallinfrastrukturItemComponent implements OnInit, OnChanges{
closeDeleteDialog() {
       //@ts-ignore
       document.getElementById('delete-dialog').close();


}
async deleteSelectedItem() {

  if(this.item._id) {
    await this.datahubService.deletePrognoseData(this.item)

    } 





  setTimeout(() =>{
    window.location.reload()
    this.close();
  }, 1000)

}
close() {
//  alert("close")

//this.m.start = false
//this.m.edit = false

this.m.selectedItem = undefined// && selectedItem  && !edit

this.router.navigateByUrl('/analysen/notfallinfrastruktur')
}
  wait: boolean = false;
  async saveItem() {
    this.wait = true; 







    const canReadString = [...this.canRead].map(num => num.toString());
    const canWriteString = [...this.canWrite].map(num => num.toString());
  
    this.item.canRead = canReadString;
    this.item.canWrite = canWriteString;
    this.item.visibility = this.selectedSichtbarkeit



    if(this.item._id) 
      {
        //Delete ite,
     
        await this.datahubService.deletePrognoseData(this.item)
     
      } 
 
  await this.datahubService.addPrognoseData(this.item);
  this.wait = false; 

       //@ts-ignore
       document.getElementById('visibility').close();

       

}
selectedSichtbarkeit: any;
  userList: any;
  canRead: Set<number> = new Set();
  canWrite: Set<number> = new Set();
  currentUser: any;
  async save() {  

 
    
    
    //@ts-ignore
    document.getElementById('visibility').showModal();
      
}
getItemForYear(): any {

var  t = this.item?.data.filter((el: { year: number; }) => el.year == this.currentYear)

return t[0]
}
receiveData($event: any) {

for(var i = 0; i < this.item.data.length; i++ ) {
 if($event.year ==  this.item.data[i].year) {

  this.item.data[i].marker =  JSON.parse(JSON.stringify($event.marker))
  this.item.data[i].results =   JSON.parse(JSON.stringify($event.results ))
 }

}
 this.currentYear = this.currentYear + 1; 


}
startAnalyse() {
throw new Error('Method not implemented.');
}
openDeleteDialog() {


  //@ts-ignore
  document.getElementById('delete-dialog').showModal();
 

}

openDialogShare(){

  if( !this.item._id) 
    {
      alert('Bitte Prognose zuerst speichern!')
      return; 
    }
  var additionalText = "Schaue dir folgende Prognose an: " + window.location.href  + "/" + this.item._id 


  const dialogRef =this.dialog.open(ShareDialogComponent,{
    data: { title:  "Prognose "  +this.item?.name + " teilen", additionalText: additionalText , id:  this.item._id,
      skipCheck: true
    },
    width:'600px',
    height:'320px',
  });
  dialogRef.afterClosed().subscribe((result:any) => {
  
  
    if (result) {

    }
  });
}

setYear(year: any) {
  
  this.currentYear = year
}
  @Input() item: any;
  currentYear = 0;
  ngOnInit(): void {
    this.currentYear = parseInt(this.item.startYear)


  }
  ngOnChanges(changes: SimpleChanges): void {
    this.item.startYear = parseInt(this.item.startYear)
    this.item.endYear = parseInt(this.item.endYear)
    this.currentYear = this.item.startYear
    this.selectedSichtbarkeit = this.item.visibility
  

  }

toggleUserRead(userId: number, isChecked: boolean) {
  if (isChecked) {
    this.canRead.add(userId);
  } else {
    this.canRead.delete(userId);
  }
}

toggleUserWrite(userId: number, isChecked: boolean) {
  if (isChecked) {
    this.canWrite.add(userId);
  } else {
    this.canWrite.delete(userId);
  }
}

  constructor(private datahubService: DatahubService, private keyCloakService: KeycloakOperationService,
    private m: NotfallinfrastrukturComponent, public dialog: MatDialog, private router: Router
  ) {

    this.keyCloakService.getUsersList().subscribe((users: any) => {
   
      this.userList=users
    });

    this.keyCloakService.getUserProfile().then((data: any) => {
      this.currentUser= data;
   
    });

  }







}