<div id="container"> 

  <div style="display: flex; align-items: center; justify-content: space-around;"> 

  
 <button style="height: 10vh; width: 60vw; font-size: 3vh;" mat-fab extended color="primary" *ngIf="item"  (click)="startAnalyse()" > <mat-icon>analytics</mat-icon>   Prognose {{ item.name }}  </button>

<div> 

 <button style="margin-left: 20px" mat-fab mini color="primary" *ngIf="item"  (click)="save()" > <mat-icon>save</mat-icon> </button>
 <button style="margin-left: 20px"  mat-fab mini color="primary" *ngIf="item" (click)="openDeleteDialog()"> <mat-icon>delete</mat-icon>   </button>
 <button style="margin-left: 20px"   mat-fab mini color="primary" *ngIf="item" (click)="openDialogShare()"> <mat-icon>share</mat-icon>  </button>
 <button style="margin-left: 20px" mat-fab mini color="primary" *ngIf="item"  (click)="close()" > <mat-icon>cancel </mat-icon> </button>

</div>
</div>


<div class="progress-bar-container" >
  <div class="progress-bar">

    <ng-container *ngFor="let year of [].constructor(item.endYear - item.startYear); let i = index">
     
   
    <div class="progress-pointU" (click)="setYear(item.startYear+i)" [style.backgroundColor]="currentYear == item.startYear + i ? 'green' : 'rgb(58, 102, 223)'"  [style.margin-left.vw]="i * 90/(item.endYear - item.startYear - 1)">  {{ item.startYear + i }}</div>

    </ng-container>
    </div>
  
  </div>
<div style="margin-top: 5vh;">

  <app-daseinsvorsorge [year]="currentYear" [item] = "getItemForYear()" (dataToParent)="receiveData($event)">

  </app-daseinsvorsorge>
</div>
  
  
</div>

<dialog id="visibility">


  <div class="content">
    <div> 

    <div style="padding: 40px;">
      <br>
  
      <span  class="span0"> Prognose {{ item?.name }} speichern </span>
      <br>
      <br>
   



        
      <b> Sichtbarkeit </b>
  
      <mat-chip-listbox [(ngModel)]="selectedSichtbarkeit">
  
          <div *ngFor="let cat of ['public', 'protected', 'private']">
              <mat-chip-option> {{ cat }} </mat-chip-option>
          </div>

      </mat-chip-listbox>

      <div *ngIf="selectedSichtbarkeit == 'protected'" style="display: flex"> 
        <div> 
      <h4> Leserechte </h4>
      <div class="user-list">
        <div class="user-list-inner">
          <ng-container *ngFor="let user of userList" >
            <mat-checkbox *ngIf="currentUser.id !== user.id"  (change)="toggleUserRead(user.id, $event.checked)">
              {{ user.username }}
            </mat-checkbox>
          </ng-container>
        </div>
      </div>
    </div>
    <div>
      <h4> Schreibrechte </h4>
      <div class="user-list">
        <div class="user-list-inner">
          <ng-container *ngFor="let user of userList">
            <mat-checkbox *ngIf="currentUser.id !== user.id"  (change)="toggleUserWrite(user.id, $event.checked)">
              {{ user.username }}
            </mat-checkbox>
          </ng-container>
        </div>
      </div>
    </div>

    <div> 
      <p><mat-slide-toggle labelPosition="before"> Personen benachrichtigen? </mat-slide-toggle></p>



    </div>



    </div>



  
  
  
  <div style="margin-right: 20px;">

    <div class="loader-container" *ngIf="wait">

      <div class="bouncing-dots">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
      </div>
  </div>
  
      <span  style="margin-right: 0; " class="span2" (click)="saveItem()" *ngIf="selectedSichtbarkeit"> Speichern
        <button mat-mini-fab style="background-color: #10684E; color: white">
          <mat-icon>save </mat-icon>
        </button>

      </span>

      <!--

      <span  style="margin-right: 0; " class="span2" (click)="updateItem()" *ngIf="existingItem"> Updaten
        <button mat-mini-fab style="background-color: #10684E; color: white">
          <mat-icon>refresh </mat-icon>
        </button>

      </span> -->


    </div>
    <br>
    <br>

  
    </div>
    


    


    
</div>

    

</div>



</dialog>



<dialog id="delete-dialog">
  <h1> Prognosemodell {{ this.item?.name }} löschen? </h1>
 

  <div style="display: flex; justify-content: space-around;"> 

   <button  mat-fab extended color="primary" (click)="deleteSelectedItem()"> Ja </button>
   <button  mat-fab extended color="secondary" (click)="closeDeleteDialog()"> Nein </button>
 </div>


 
 
   </dialog>




