import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReportDialogComponent } from '../components/datahub2/data-regio/dashboards/report-dialog/report-dialog.component';
import { RegioService } from '../service/regioservice/regioservice.component';
import { Subscription } from 'rxjs';
import * as htmlToImage from 'html-to-image';

@Component({
  selector: 'app-test-report',
  templateUrl: './test-report.component.html',
  styleUrls: ['./test-report.component.css']
})
export class TestReportComponent {


  chartName='chartName'
  chartUnit='%'


  constructor( public dialog: MatDialog,
        private regioService: RegioService,
  )
  {}


  async test(){
    const data:any = await this.regioService.getStoredReports()
    
    console.log(data[0].data);
    
    const ReportDialogRef = this.dialog.open(ReportDialogComponent, {
      width: '1500px',
      height: 'auto',
      data: data[0].data,
      
    });
  }

    public loadingN: boolean = false;
    private subscriptions: Subscription = new Subscription();
    private apiChartData: any;
  
  

  
    public async showPDF(): Promise < void > {
      const data:any = await this.regioService.getStoredReports()
  
      
      this.loadingN = true;
  
        data[0].data.push(await this.getIndikatorReport());
        
          console.log(data[0].data);
  
          const ReportDialogRef = this.dialog.open(ReportDialogComponent, {
            width: '1500px',
            height: 'auto',
            data: data[0].data
          });
  
          this.subscriptions.add(ReportDialogRef.afterOpened().subscribe(() => {
            this.loadingN = false;
          }));
  
          this.subscriptions.add(ReportDialogRef.afterClosed().subscribe(async () => {
            this.loadingN = true;
           
            //await this.showChart();
            this.loadingN = false;
          }));
        
      
    }
  
  
    private async getIndikatorReport(): Promise<any> {
      let mapImg: any = undefined;
      let chartImg: any = undefined;
  

      await new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });
  
      // if(indi.withMap) mapImg = await this.getMapImg();
  
      // if(indi.withChart) chartImg = await this.getChartImg();
      // console.log();
      
      return {
        name: this.chartName,
        filterName: 'this.getFilterName()',
        minYear: 0,
        maxYear: 2025,
        chartLegend: 'this.getActivateLegend()',
        chartImg: 'chartImg',
        mapImg: 'mapImg',
        text: this.getElementContent(),
        apiChartData:this.apiChartData,
        type: 'bar',
        unit:this.chartUnit,
      }
    }
  
    private getChartImg(): Promise < string | undefined > {
      return new Promise((resolve, reject) => {
        const domChart = document.getElementById('chart');
        if (domChart) {
          htmlToImage.toPng(domChart,{skipFonts:true})
            .then(function (dataUrl) {
              resolve(dataUrl);
            })
            .catch(function (error) {
              console.error('Error:', error);
              reject();
            });
        }
      });
    }
  
    private getMapImg(): Promise < string | undefined > {
      return new Promise((resolve, reject) => {
        const domMap = document.getElementById('map2');
        if (domMap) {
          const fabButtons = document.querySelectorAll('.mapIcons');
  
          fabButtons.forEach(button => {
            (button as HTMLElement).style.display = 'none';
          });
  
          htmlToImage.toPng(domMap,{skipFonts:true})
            .then(function (dataUrl) {
              resolve(dataUrl);
            })
            .catch(function (error) {
              console.error('Error:', error);
              reject();
            })
            .finally(() => {
              fabButtons.forEach(button => {
                (button as HTMLElement).style.display = '';
              });
            });
        }
      });
    }
  

    getElementContent(): string | null {
      // Get the HTML element by its ID
      const element = document.getElementById('text');
    
      // Check if the element exists and return its innerText, else return null
      return element ? element.innerText : null;
    }
    
   }
  
