import { Component, Inject,OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { SynthService } from 'src/app/service/synthService/synth.service';

@Component({
  selector: 'app-synth-indi-dialog',
  templateUrl: './synth-indi-dialog.component.html',
  styleUrls: ['./synth-indi-dialog.component.css']
})
export class SynthIndiDialogComponent implements OnInit {

  indicators:any;
  spinner=false;
  successMessage = false;
  populations: string[] = [];
  years: number[] = [];
  selectedPopulation: string = '';
  selectedYearRange1: number = 0;
  selectedYearRange2: number = 0;
  popSpinner: boolean = false;  
  isConfirmed: boolean = false; 
  selectedIndi: string ='' 

  constructor(
    public dialogRef: MatDialogRef<SynthIndiDialogComponent>,
    private synthService: SynthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}


  async ngOnInit(): Promise<void> {
    this.indicators = await this.synthService.getUniqueKeys();
    const res: any = await this.synthService.getPopuationNames();
    this.populations = res.popNames;
  }

  async dataforSlider(stepper: MatStepper) {
    this.popSpinner = true; 
    try {
      const initialData: any = await this.synthService.getInitialChartData(this.selectedIndi, this.populations);
      console.log(initialData);
  
      this.years = initialData.labels.map((year: string) => parseInt(year));
      this.selectedYearRange1 = this.years[0];
      this.selectedYearRange2 = this.years[this.years.length - 1];
    } finally {
      this.popSpinner = false;
      stepper.next(); // Proceed to the next step after the data loading is complete
    }
  }
  


  confirmAddition() {
    this.popSpinner = true; 

    setTimeout(() => {
      console.log(`Selected Population: ${this.selectedPopulation}, Year Range: ${this.selectedYearRange1} - ${this.selectedYearRange2}`);
      this.popSpinner = false;
      this.isConfirmed = true;
    }, 1750); 

    setTimeout(() => {
      this.dialogRef.close();
    },3000);
  }

  onExpand(): void {
    this.spinner = true;

    setTimeout(() => {
      this.spinner = false;
      this.successMessage = true; 
      
      setTimeout(() => {
        this.dialogRef.close();
      }, 2000);
    }, 2000); 
  }
}
