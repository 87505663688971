import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Router } from 'express';
import { ChatService } from 'src/app/service/chatService/chat.service';
import { DatahubService } from 'src/app/service/datahubservice/datahubservice.component';
import { KeycloakOperationService } from 'src/app/service/keycloakService/keycloak-operation.service';
import { VorsorgeserviceComponent } from '../vorsorgeservice/vorsorgeservice.component';

@Component({
  selector: 'app-notfallinfrastruktur-edit',
  templateUrl: './notfallinfrastruktur-edit.component.html',
  styleUrls: ['./notfallinfrastruktur-edit.component.css']
})
export class NotfallinfrastrukturEditComponent {
name: any = "Name eingeben..";
endYear: number = 2024;
startYear: number = 2035;
  pop: any = [];
buffer: any = false;
  async startAnalyse() {
    this.buffer = true;


    if(this.name == '' || this.name == "Name eingeben..") {
      alert("Bitte Name eingeben!")
      return;
    }



  var results = await this.vorsorgeService.getInitialResultBuffer();
  var marker = await this.datahubservice.getNotfalleinrichtungAll( );
  var data: { year: number, marker: any, results: any }[] = [];

  for (let i = this.startYear; i <= this.endYear; i++) {

    
    data.push({ year: i, marker: marker, results: results });
  }




  var newItem = {
    name: this.name,
    notfallPop: "test1",
    pop: "pop1",
    startYear: this.startYear,
    endYear: this.endYear,
    data: data
  }
  this.buffer = false

  
  this.dialogRef.close(newItem);
}
step: any = 0;

constructor(
  private vorsorgeService: VorsorgeserviceComponent, private datahubservice: DatahubService,
public dialogRef: MatDialogRef<NotfallinfrastrukturEditComponent>,

@Inject(MAT_DIALOG_DATA) public data: any){

  //alert(data.pop)

  this.buffer = false

  this.pop = data.pop




}

closeAll(){
  this.dialogRef.close();
}

}
