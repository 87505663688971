import { Component } from '@angular/core';
import { LlamaComponent } from 'src/app/service/llama/llama.component';

@Component({
  selector: 'app-ai-service',
  templateUrl: './ai-service.component.html',
  styleUrls: ['./ai-service.component.css']
})
export class AiServiceComponent {
  messages: any[] = [];



  constructor( private llamaService: LlamaComponent) {


    var stringtest = `Your task is it to convert an json input into a chart js json configuration. Here is a sample of this config data:' 
      {
        "type": "bar",
        "data": {
          "labels": ["January", "February", "March", "April", "May", "June"],
          "datasets": [
            {
              "label": "Sales",
              "data": [65, 59, 80, 81, 56, 55],
              "backgroundColor": "rgba(77, 83, 96, 0.2)",
              "borderColor": "rgba(77, 83, 96, 1)",
              "borderWidth": 1
            }
          ]
        },
        "options": {
          "responsive": true,
          "scales": {
            "x": {
              "beginAtZero": true
            },
            "y": {
              "beginAtZero": true
            }
          }
        }
      }. reply only the the config data json, nothing else. !important make sure the json is valid. it is forbidden to say anything else.
      The top level structure of Chart.js configuration:{
  type: 'line',
  data: {},
  options: {},
  plugins: []
} . reply only using this structure. der body soll fertig sind und keine sonstigen funktionsaufrufe behalten! kannst du  options: {
        plugins: {
            legend: {
                display: false // This removes the legend
            }
        }
    } hinzufügen, damit keine legend eingeblendet ist?

`
    this.messages.push({
      content: stringtest,
      role: 'system'
    })  }

  async sendMessage(msg: string) {

    this.messages.push({
      content: msg ,
      role: 'user'
    })

  var t =  await this.llamaService.sendMessages(this.messages)
    //@ts-ignore

  this.messages.push(t.message)
    //@ts-ignore

  return t.message.content


}

}
