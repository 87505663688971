import { Component, Input, OnInit } from '@angular/core';
import { RequestBuilder } from 'src/app/modals/requestBilder.class';
import { RegioService } from 'src/app/service/regioservice/regioservice.component';
import { Chart, ChartEvent, plugins  } from 'chart.js';
import * as L from 'leaflet';
import { Storageservice } from 'src/app/service/storageservice-component/storageservice-component.component';
import { RadarService } from 'src/app/service/radarService/radar.service';
import { debounceTime, distinctUntilChanged, Subject, Subscription, switchMap } from 'rxjs';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-indicator-radar',
  templateUrl: './indicator-radar.component.html',
  styleUrls: ['./indicator-radar.component.css']
})
export class IndicatorRadarComponent implements OnInit {
  @Input() indiForRadar: any; 
  @Input() dataForRadar: any;
  @Input() regionSelectedInDialog: any;
  @Input() radarYears: any;
  @Input() radarChartIndex:any

  radarChart:any;
  createdDataset:any;
  map:any = undefined;
  initMapOnce=true;
  initShapes=true
  labels :any= [];
  datasetForRadarChart:any=[];
  selectedRegions:any=[];
  arrMittel={ label:'Arithmetisches Mittel',data:[],fill:false};
  selectedYear!:number;
  yearsArray:number[]=[];
  center:any;
  datasets:any;
  shapeArray:any=[];
  selectedIndicator: any = null;
  datasetsWithOriginalAndNormalized:any;
  private mouseOverSubject = new Subject<any>();
  private mouseOutSubject = new Subject<any>();
  private subscriptions: Subscription[] = [];
  selectedOption = 'selected';

  constructor(private regioService:RegioService,
              private storageService:Storageservice,
              private radarService:RadarService) {
              }

  createRequestBody(indi:any){
    let requestBuilder = new RequestBuilder(
      this.dataForRadar.chartLegend,
      this.dataForRadar.selectedMinYear,
      this.dataForRadar.selectedMaxYear,
      this.dataForRadar.selectedMinVal,
      this.dataForRadar.selectedMaxVal,
      this.dataForRadar.selectedShape,
      this.dataForRadar.selectedLevel,
      this.dataForRadar.breaksAmount,
      this.dataForRadar.datahubPath,
      this.dataForRadar.setColor,
      this.dataForRadar.colorScheme,
      this.dataForRadar.storageService,
      indi,
      this.dataForRadar.upload,
    );
    return requestBuilder
  }

  minMaxObject: any;
  ngOnInit() {
    // Mouse over stream
    const mouseOver$ = this.mouseOverSubject.asObservable().pipe(
      debounceTime(500),
      //distinctUntilChanged(),
      switchMap(({ element, e, l }) => this.handleMouseOver(element, e, l))
    );

    // Mouse out stream
    const mouseOut$ = this.mouseOutSubject.asObservable().pipe(
      //debounceTime(10),
      //distinctUntilChanged(),
      switchMap(({ element, e, l }) => this.handleMouseOut(element, e, l))
    );

    this.subscriptions.push(mouseOver$.subscribe());
    this.subscriptions.push(mouseOut$.subscribe());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }


  async displayIndi() {
  
    this.initShapes=true;

    await this.generateRegion()
    this.createdDataset = await Promise.all(await this.generateDataset());
    this.datasets = await this.combineRegionAndDataset();
 
    let  fixLabelError = [];
    if (this.datasets[1])  {
      fixLabelError=this.datasets[1].indicators
      await this.labelKorrektur(fixLabelError)
    }
    this.datasetsWithOriginalAndNormalized = await this.radarService.rangeEachRegion(this.datasets);



    this.radarChart?.destroy();
    var chartDisplay: any = this.chartTemplate(this.labels, this.datasetsWithOriginalAndNormalized);
    setTimeout(() => {
        this.radarChart = new Chart('radarChart', chartDisplay);
    }, 10);
}

  async generateRegion(){
    this.datasetForRadarChart=[]
    this.dataForRadar.chartLegend.forEach((item:any) => {
      this.datasetForRadarChart.push({label:item.label,data:[],fill:false,Object_Key:item.Object_Key,selectedYears:[],indicators:[]})
     });
  }

  async generateDataset(){
    this.labels=[]
    let testData:any=[]
    
    return this.indiForRadar.map(async (indi: any) => {

      if (this.radarChartIndex === 0) {
        testData = await this.regioService.getGeneralChartData(this.createRequestBody(indi).buildRequestObject()) as any;
        this.labels.push([indi.Name, indi.unit ? indi.unit : 'Keine Einheit in der Datenbank registriert',indi.clickedYear])}
      else if (this.radarChartIndex === 1) {
        testData = await this.regioService.getYearlyChangeChartData(this.createRequestBody(indi).buildRequestObject()) as any;
        this.labels.push([indi.Name, '%',indi.clickedYear])
      }
      
      if (this.initMapOnce){
        this.initMap();
        this.initMapOnce=false;
      }

      return  {dataset:testData.chart.chartData.datasets,
                years:testData.chart.chartData.labels,
                indiName:indi.Name,
                indiYear:indi.clickedYear};       
    });
  }

  async combineRegionAndDataset(){

    
    let meanArray:any=[];

      this.datasetForRadarChart.forEach((element:any) => {
        element.data = this.createdDataset.map((item:any) => { 
          const yearIndex = item.years.findIndex((obj:any) => obj === item.indiYear);             
          const regionIndex = item.dataset.findIndex((obj:any) => obj.Object_Key === element.Object_Key);
          
          if (item.dataset[regionIndex]) {
            element.indicators.push(item.indiName);
            element.selectedYears.push(item.indiYear);
            return item.dataset[regionIndex].data[yearIndex];
          }

        });
      });

    let filteredArea = this.regionSelectedInDialog.filter((element:any)=>{return element.disabled===false});
    this.createArrayOfMapKeys(filteredArea);
    
    if (this.selectedOption === 'all') {
      for (let i = 0; i < this.datasetForRadarChart[0].data.length; i++) {
        let sumFirst = this.datasetForRadarChart.map((item: any) => item.data[i])
          .reduce((acc: any, currentValue: any) => acc + currentValue, 0);
  
        let meanFirst = sumFirst / this.datasetForRadarChart.length;
        meanArray.push(meanFirst);
      }
    } else if (this.selectedOption === 'selected') {
      let selectedDataset = this.datasetForRadarChart.filter((item: any) =>
        this.selectedRegions.includes(item.Object_Key)
      );
  
      for (let i = 0; i < selectedDataset[0].data.length; i++) {
        let sumSelected = selectedDataset.map((item: any) => item.data[i])
          .reduce((acc: any, currentValue: any) => acc + currentValue, 0);
  
        let meanSelected = sumSelected / selectedDataset.length;
        meanArray.push(meanSelected);
      }
    }

    this.arrMittel.data=meanArray
    return this.createFinalDataset(filteredArea)
  }

  createArrayOfMapKeys(filteredArea:any){
    this.selectedRegions=[];
    this.selectedRegions = filteredArea.map((item:any) => item.Object_Key);
    console.warn(this.selectedRegions);
    console.warn (this.datasetForRadarChart)

    if (this.initShapes){
      this.drawShapes();
      this.initShapes=false;
    }
  }

  createFinalDataset(filteredArea:any){
    let result= this.datasetForRadarChart.filter((regionItem:any) => {
      return filteredArea.some((filteredItem:any) => {
        return regionItem.Object_Key === filteredItem.Object_Key;
      });
    });
    result.unshift(this.arrMittel);
    return result
  }




onRadioChange(event: MatRadioChange): void {
  this.selectedOption = event.value;
  console.log("Selected option:", this.selectedOption);
  this.displayIndi()
}


  async labelKorrektur(indicators:any){
    this.labels.sort((a:any, b:any) => {
      const indexA = indicators.indexOf(a[0]); 
      const indexB = indicators.indexOf(b[0]); 
      return indexA - indexB; 
    });
  }

  onIndicatorSelected(indicator: any) {
    this.selectedIndicator = indicator;
  }

  async onIndicatorSelectedYear(indicator: any,year:number) {
      let displayCalled = false; 
      for (let i = 0; i < this.indiForRadar.length; i++) {
        const element = this.indiForRadar[i];

        if (element.Name === indicator.Name) {
          this.indiForRadar[i].clickedYear = year;
          
          if (!displayCalled) {
            await this.displayIndi();
            displayCalled = true;
            break;
          }
        }
      }

      if (!displayCalled) {
        console.log("No matching indicator found.");
      }
  }

  private initMap(): void {

    const tiles = L.tileLayer(
      'https://m.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
      {
        maxZoom: 18,
        minZoom: 3,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );
    this.map = L.map('map', {
      center: [51.04962, 12.1369],
      zoom: 7,
    });
    tiles.addTo(this.map);

    
  }
  
  async drawShapes(){
    this.shapeArray.forEach((layer:any) => {
      this.map.removeLayer(layer);
    });
    var bundesland = await this.regioService.getShapefileByIdAndLevel(this.storageService.getObject().Object_Key.substring(0, 2),1) as any;
    var bundeslandLayer = L.geoJSON(bundesland[0][0].geometry, {style: this.styleShape('white')});
    //bundeslandLayer.addTo(this.map);

    var bounds = bundeslandLayer.getBounds();
    var center = bounds.getCenter();
    this.map.setView(center, 7);
    var objKey = this.storageService.getObject().Object_Key;
    if (this.dataForRadar.selectedLevel == 3) objKey = '15';

    var res = await this.regioService.getShapefileByIdAndLevel(objKey,this.dataForRadar.selectedLevel) as any;
    
    res[0].forEach((element: any) => {

      
      var l = L.geoJSON(element.geometry, {style:this.styleShape('cornflowerblue'),
      onEachFeature: (f, l) => {  if (this.selectedRegions.includes(element.Object_Key)) { (l as any).setStyle(this.styleShape('red'));}},});
      l.on('mouseover', (e:any) => {this.mouseOverShape(element,e,l);});
      l.on('mouseout', (e:any) => { this.mouseOutShape(element,e,l);});
     
      l.on('click', (e) => {this.mouseClickShape(element,e,l)})
      l.addTo(this.map);
      this.shapeArray.push(l)
      var bounds = l.getBounds();
      this.center = bounds.getCenter();
      setTimeout(() => {
        if (this.dataForRadar.selectedLevel != 3) this.map.setView(this.center, 9);
      }, 2000);
    });
  }

  chartTemplate(labels: any, datasets: any) {
    

    return {
      type: 'radar',
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        hoverRadius:10,
        elements: {
          line: {
            borderWidth: 3
          }
        },
        scale: {
          r: {
            angleLines: {
              display: false,
            },
            grid: {
              display: false
            },
            ticks: {
              display: false
            },
            pointLabels: {
              color: 'blue',
              font: {
                weight: 'bolder',
                size: 30
              }
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context:any) {
                const originalData = context.dataset.originalDataset;
                const dataIndex = context.dataIndex;
                const relevantLabel = `${context.label[0]} ${context.label[2]}`; 
                return `${relevantLabel} :  ${originalData[dataIndex]}`;
              }
            }
        }
        },
        responsive: false,
        aspectRatio: 1
      },
    }
  }


styleShape(color:string){
  return{
    fillColor: color, 
    fillOpacity: 0.5,
    weight: 2,
    color: 'black',
  }
}

async mouseOverShape(element: any, e: any, l: any) {
  this.mouseOverSubject.next({ element, e, l });
}

async mouseOutShape(element: any, e: any, l: any) {
  this.mouseOutSubject.next({ element, e, l });
}

async handleMouseOver(element: any, e: any, l: any) {

  
  if (!this.selectedRegions.includes(element.Object_Key) ) {
    const index = this.datasetForRadarChart.findIndex((obj: any) => obj.Object_Key === element.Object_Key);
    if (index !== -1) {
      let hovered = { ...this.datasetForRadarChart[index], borderDash: [5, 5], borderWidth: 2 };
      this.datasets.push(hovered);
      console.log(this.datasets);
      
      this.datasetsWithOriginalAndNormalized = await this.radarService.rangeEachRegion(this.datasets);
      this.radarChart.data.datasets = this.datasetsWithOriginalAndNormalized;
      this.radarChart.update();
    }
    e.target.setStyle(this.styleShape('yellow'));
  }
  //l.bindPopup("<span class='span2'> " + element.Object_Nam + ' </span> <br>').openPopup();
  this.storageService.setActiveLabel(element.Object_Nam);
}

async handleMouseOut(element: any, e: any, l: any) {

 // l.closePopup();
  if (!this.selectedRegions.includes(element.Object_Key)) {
    const indexToRemove = this.radarChart.data.datasets.findIndex((dataset: any) => dataset.Object_Key === element.Object_Key);
    console.log(indexToRemove);
    
    if (indexToRemove !== -1) {
      this.radarChart.data.datasets.splice(indexToRemove, 1);
      const toBeRemovedFromOriginalDatasetIndex = this.datasets.findIndex((dataset: any) => dataset.Object_Key === element.Object_Key);
      this.datasets.splice(toBeRemovedFromOriginalDatasetIndex, 1);
      console.log(this.datasets);
      
      this.datasetsWithOriginalAndNormalized = await this.radarService.rangeEachRegion(this.datasets);
      this.radarChart.data.datasets = this.datasetsWithOriginalAndNormalized;
      this.radarChart.update();
    }
    e.target.setStyle(this.styleShape('cornflowerblue'));
  }

  setTimeout(async () => {
     // l.closePopup();
  if (!this.selectedRegions.includes(element.Object_Key)) {
    const indexToRemove = this.radarChart.data.datasets.findIndex((dataset: any) => dataset.Object_Key === element.Object_Key);
    console.log(indexToRemove);
    
    if (indexToRemove !== -1) {
      this.radarChart.data.datasets.splice(indexToRemove, 1);
      const toBeRemovedFromOriginalDatasetIndex = this.datasets.findIndex((dataset: any) => dataset.Object_Key === element.Object_Key);
      this.datasets.splice(toBeRemovedFromOriginalDatasetIndex, 1);
      console.log(this.datasets);
      
      this.datasetsWithOriginalAndNormalized = await this.radarService.rangeEachRegion(this.datasets);
      this.radarChart.data.datasets = this.datasetsWithOriginalAndNormalized;
      this.radarChart.update();
    }
    e.target.setStyle(this.styleShape('cornflowerblue'));
  }
  }, 650);
}


  async mouseClickShape(element:any,e:any,l:any){
    const index = this.selectedRegions.indexOf(element.Object_Key);
    if (index > -1) {

      this.selectedRegions.splice(index, 1);

      this.regionSelectedInDialog = this.regionSelectedInDialog.map((item:any) => {
        if (item.Object_Key === element.Object_Key) {
          return { ...item, disabled: true }; 
        }
        return item;
      });

      let newChartDataset = this.datasetForRadarChart.filter((regionItem:any) => {
        return this.selectedRegions.some((filteredItem:any) => {
          return regionItem.Object_Key === filteredItem;
        });
      });
      newChartDataset.unshift(this.arrMittel);

      this.datasets=newChartDataset;
      this.datasetsWithOriginalAndNormalized = await this.radarService.rangeEachRegion(this.datasets);
      this.radarChart.data.datasets=this.datasetsWithOriginalAndNormalized;
      this.radarChart.update();
      e.target.setStyle(this.styleShape('cornflowerblue'));
    } else {
      if (this.selectedRegions.length<5) {
        this.regionSelectedInDialog = this.regionSelectedInDialog.map((item:any) => {
          if (item.Object_Key === element.Object_Key) {
            return { ...item, disabled: false }; 
          }
          return item;
        });

        this.selectedRegions.push(element.Object_Key);
        let newChartDataset = this.datasetForRadarChart.filter((regionItem:any) => {
          return this.selectedRegions.some((filteredItem:any) => {
            return regionItem.Object_Key === filteredItem;
          });
        });
        newChartDataset.unshift(this.arrMittel);

        this.datasets=newChartDataset;
        this.datasetsWithOriginalAndNormalized = await this.radarService.rangeEachRegion(this.datasets);
        this.radarChart.data.datasets=this.datasetsWithOriginalAndNormalized;
        this.radarChart.update();

        e.target.setStyle(this.styleShape('red'));
      }
    }
  }
}