<div class="main" *ngIf="step==1">
    <div>
        <h4>Ursprünglicher Plan</h4>

        <div class="Fahrplan">
            <h4>Fahrplan 53min</h4>
            <div class="transit-line">
            <!-- <h3>Bus 794 - Weißenfels, Busbahnhof</h3> -->
            <h4>Obernessa - Weißenfels, Busbahnhof</h4>
            <p><strong>Fahrtdauer:</strong> 22 min (6 Haltestellen)</p>
            
            <ul class="stop-list" style="color: orange;">
                <li><strong>5:35 AM</strong> - Obernessa</li>
                <li><strong>5:37 AM</strong> - Nessa, Ort</li>
                <li><strong>5:38 AM</strong> - Dippelsdorf</li>
                <li><strong>5:41 AM</strong> - Wernsdorf (Nessa) Ort - Teuchern</li>
                <li><strong>5:46 AM</strong> - Weißenfels, Kaserne</li>
                <li><strong>5:47 AM</strong> - Weißenfels, Zeitzer Str.</li>
                <li><strong>5:57 AM</strong> - Weißenfels, Busbahnhof</li>
            </ul>
            </div>
        
            <div class="transit-line">
            <h4>Weißenfels, Busbahnhof - Prittitz, Ort</h4>
            <p><strong>Fahrtdauer:</strong> 14 min (6 Haltestellen)</p>
        
            <ul class="stop-list" style="color: orangered;">
                <li><strong>6:00 AM</strong> - Weißenfels, Busbahnhof</li>
                <li><strong>6:03 AM</strong> - Weißenfels, Goethe-Gymnasium</li>
                <li><strong>6:04 AM</strong> - Weißenfels, Naumburger Str.</li>
                <li><strong>6:05 AM</strong> - Weißenfels, Krankenhaus</li>
                <li><strong>6:09 AM</strong> - Leißling, EKZ</li>
                <li><strong>6:13 AM</strong> - Prittitz, Abzweig Bahnhof</li>
                <li><strong>6:14 AM</strong> - Prittitz, Ort</li>
            </ul>
            </div>
        
            <div class="transit-line">
            <h4>Prittitz, Ort - Plotha, Schule (Prittitz)</h4>
            <p><strong  style="color: #cc0000;">Fußweg:</strong> Ungefähr 14 min, 1100 m</p>
            <p><strong>6:28 AM</strong> - Plotha, Schule (Prittitz)</p>
            </div>
        </div>
    </div>

    <div>
        <h4>Plan nach Fahrplananpassung</h4>

        <div class="Fahrplan">
            <h2>Fahrplan 40min</h2>
            <div class="transit-line">
        
            <h4>Obernessa - Prittitz, Ort</h4>
            <p><strong>Fahrtdauer:</strong> 40 min (14 Haltestellen)</p>
            
            <ul class="stop-list" style="color: orange;">
                <li><strong>6:50 AM</strong> - Obernessa</li>
                <li><strong>6:52 AM</strong> - Nessa, Ort</li>
                <li><strong>6:53 AM</strong> - Dippelsdorf</li>
                <li><strong>6:56 AM</strong> - Wernsdorf (Nessa) Ort - Teuchern</li>
                <li><strong>7:01 AM</strong> - Weißenfels, Kaserne</li>
                <li><strong>7:02 AM</strong> - Weißenfels, Zeitzer Str.</li>
                <li><strong>7:12 AM</strong> - Weißenfels, Busbahnhof</li>
                <li><strong>7:15 AM</strong> - Weißenfels, Goethe-Gymnasium</li>
                <li><strong>7:16 AM</strong> - Weißenfels, Naumburger Str.</li>
                <li><strong>7:17 AM</strong> - Weißenfels, Krankenhaus</li>
                <li><strong>7:21 AM</strong> - Leißling, EKZ</li>
                <li><strong>7:25 AM</strong> - Prittitz, Abzweig Bahnhof</li>
                <li><strong>7:27 AM</strong> - Plennschütz</li>
                <li><strong>7:28 AM</strong> - Plotha, Ort (Prittitz)</li>
                <li><strong>7:30 AM</strong> - Plotha, Schule (Prittitz)</li>
            </ul>
            </div>
        
        </div>
    </div>

    <div>
        <h4>Plan nach Hinzufugen einer neuen Route</h4>

        <div class="Fahrplan">
            <h2>Fahrplan 17min</h2>
            <div class="transit-line">
        
            <h4>Obernessa - Prittitz, Ort</h4>
            <p><strong>Fahrtdauer:</strong> 17 min (8 Haltestellen)</p>
            
            <ul class="stop-list" style="color: orange;">
                <li><strong>7:13 AM</strong> - Wernsdorf (Nessa), Ort</li>
                <li><strong>7:14 AM</strong> - Dippelsdorf</li>
                <li><strong>7:15 AM</strong> - Nessa, Ort</li>
                <li><strong>7:11 AM</strong> - Obernessa</li>
                <li><strong>7:26 AM</strong> - Prittitz, Ort</li>
                <li><strong>7:27 AM</strong> - Prittitz, Abzweig Bahnhof</li>
                <li><strong>7:28 AM</strong> - Plennschütz</li>
                <li><strong>7:29 AM</strong> - Plotha, Ort (Prittitz)</li>
                <li><strong>7:30 AM</strong> - Plotha, Schule (Prittitz)</li>
            </ul>
            </div>
        
        </div>
    </div>

    <!-- <div>

        <div>
            <h3> Antwort PVG:(Fahrplan anpassen)</h3>
            <p>Das Anlegen einer neuen Busroute ist vsl. mit einem finanziellen Mehraufwand ca. <span style="color: orangered;">100.000€</span> im Jahr verbunden.</p>

            <p> Kostenaufteilung:</p>

            <ul>
            <li>Zusätzliches Fahrzeug  mit Abschreibung, Finanzierung, Reparatur und Betriebskosten: <span style="color: orangered;">50.000€</span> </li>
            <li>Personalkosten für den Betrieb dieses zusätzlichen Fahrzeugs: ca. <span style="color: orangered;">50.000€</span></li>
            </ul>
        </div>

        <div>
            <h3> Antwort PVG:(neue Route hinzufügen)</h3>
            <p>Die geschätzten Kosten in Höhe von  lassen sich wie folgt aufschlüsseln:</p>
            <ul>
             <li><span style="color: orangered;">330.000€</span>pro Straßenkilometer bei einer Verbreiterung von derzeit 4.5m auf 6 m bei einer Straßenlänge von 6km:<span style="color: orangered;">1,96 Millionen</span> </li>
             <li>jährliche Aufwand für Winterdienst in Höhe von mindestens <span style="color: orangered;">6.820 €</span> (220 EUR pro Winterdienst-Tag, wobei im Schnitt 31 Winterdienst- Tage im Jahr anfallen)</li>
             <li>Schneefangzaun:</li>  
               <ul>
                 <li>einmalige Investition von <span style="color: orangered;">56.000€</span></li>
                 <li> jährliche Anbringungs- und Abbaukosten von <span style="color: orangered;">3.500€</span></li>
               </ul>
               <li>Die Beförderung muss mit zwei zusätzlichen Omnibussen erfolgen. Kosten: ca. <span style="color: orangered;">200.000€</span></li>
            </ul>
            <p>Die durchschnittlichen Jahreskosten über einen Zeitraum von 10 Jahren betragen <span style="color: orangered;">411.920€</span></p>
        </div>
    </div> -->


    <button  mat-raised-button color="primary"  (click)="step=2">
        zur Vergleichsgrafik
    </button>
</div>

<div class="main2" *ngIf="step==2" >
    <div style="width: 90%; height: 400px;">
        <canvas baseChart
                [data]="barChartData"
                [options]="barChartOptions"
                chartType="bar">
        </canvas>
    </div>

    <div class="costs-info">
        <p class="costs-title">Kosten Angepasster Plan:</p>
        <p class="costs-value adjusted-plan">100.000€</p>
      
        <p class="costs-title">Kosten Neuer Routenplan:</p>
        <p class="costs-value new-route-plan">
          Die durchschnittlichen Jahreskosten über einen Zeitraum von 10 Jahren betragen 411.920€
        </p>
        <button mat-raised-button color="primary">Report PDF erstellen<mat-icon>download</mat-icon> </button>
      </div>
      
   
</div>
