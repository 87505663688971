<div style="display: flex; justify-content: space-around; border-bottom: 3px solid gray; padding-bottom: 1vh; ">

    <div class="left">

        <div style="display: flex; justify-content: center; align-items: center;">
            <div class="pad2" style= "border-right: none"  (click)= "popType  = 'DOC'"[style.background-color]="popType  == 'DOC' ? 'lightgray' : '#D4E5C6'"  >   Ärzteszenario  
            </div>
    
            <div class="pad2"  (click)="popType  = 'POP'; createPopChart(0) "[style.background-color]="popType  == 'POP' ? 'lightgray' : '#D4E5C6'" > Populationen
            </div>

          
             
        </div>


        <div style=" width: 35vw; height: 30vh; display: flex; align-items: flex-start; justify-content: flex-start; flex-direction: column;" > 
 
             <div style="display: flex; width: 100%; align-items: center;   justify-content: space-between;" *ngIf=" popType  == 'DOC' ">
              <div *ngFor="let docscen of item.selectedScenarios" class="pad3"  (click)= "createCategoryBarChart(docscen.name)" [style.background-color]="selectedDocPop  ==  docscen.name  ? 'lightgray' : '#D4E5C6'"  >       {{ docscen.name }} 
              </div>
             </div>

             <div style="display: flex; width: 100%; align-items: center;   justify-content: space-between;" *ngIf="popType  == 'POP' ">
              <div *ngFor="let popType of chartPopAvailable?.charts_available; index as i"  class="pad3"  [style.background-color]="(i) ==  chartIndex  ? 'lightgray' : '#D4E5C6'" (click)= "createPopChart(i)" >       {{ popType }} 
              </div>
             </div>

          <div  style="width: 100%; height: 90%; " >
        <canvas id="PopChart" >
           
            {{ popChart }}
          </canvas>
          </div>

    
        
        </div>
    </div>

    <div class="right">

   <div style="display: flex;">
            <div class="pad2" style= "border-right: none"  [style.background-color]="chartType  == 'Absolute Nachfrage' ? 'lightgray' : '#D4E5C6'" (click)="createBarChart('Absolute Nachfrage')" >   Absolute Nachfrage   
            </div>
            <div class="pad2"  style=   "border-right: none" [style.background-color]="chartType  == 'Nachfrage je Einwohner' ? 'lightgray' : '#D4E5C6'"(click)="createBarChart('Nachfrage je Einwohner')" >Nachfrage/Einwohner
            </div>

            <div class="pad2"  style=   "border-right: none"  [style.background-color]="chartType  =='Nachfrage je lokale Einrichtung'? 'lightgray' : '#D4E5C6'"(click)="createBarChart('Nachfrage je lokale Einrichtung')" >Nachfrage/Einrichtung
            </div>

            <div class="pad2"  style=   "border-right: none"   value="pop" [style.background-color]="chartType  == 'Einwohner je lokale Einrichtung' ? 'lightgray' : '#D4E5C6'"  (click)="createBarChart('Einwohner je lokale Einrichtung')" >Einwohner/Einrichtung
            </div>

            <div class="pad2"   value="pop" [style.background-color]="chartType  == 'Arztdichte' ? 'lightgray' : '#D4E5C6'"  (click)="createBarChart('Arztdichte')" > Arztdichte
            </div>
            
   
        </div>

    

          <div style=" width: 40vw; height: 35vh; display: flex; align-items: center; justify-content: center;" > 

          <div  style="width: 100%; height: 100%" >
            <canvas id="IndiChart">
           
              {{ chart }}
            </canvas>
            </div>

      

            <button mat-fab extended style="background-color: #10684E; color: white; margin-right: 10px;" (click)="openDialog()">
              <mat-icon>   smart_toy </mat-icon> KI-Analyse
            </button>
          </div>

          







    </div>




</div>


<dialog id="ai-dialog">

  <div style="width: 1200px; display: flex; align-items: center; justify-content: space-between; flex-direction: row;"> 

  <button  mat-fab extended style="background-color: #10684E; color: white; margin-right: 10px;" disabled>
    <mat-icon>   smart_toy </mat-icon> KI-Analyse
  </button>
  <button style=" background-color: #10684E; color: white" mat-mini-fab
  aria-label="Example icon button with a home icon" (click)="closeDialog()">
  <mat-icon>close </mat-icon>
  </button>
</div>
  <div style="width: 1200px; display: flex; align-items: flex-start; justify-content: flex-start; flex-direction: column;">


    <div>
      <div class="loader-container" *ngIf="wait">

        <div class="bouncing-dots">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
    </div>

      <div id="message-line" *ngFor="let message of messages; let i = index"> 

        <div *ngIf="i !=  1 && i !=  messages.length- 1">

      <button *ngIf="message.role  == 'assistant'" mat-fab style="background-color: #10684E; color: white; margin-right: 10px;" >
        <mat-icon>   smart_toy </mat-icon> 
      </button>

      <button *ngIf="message.role  != 'assistant'" mat-fab style="background-color: #10684E; color: white; margin-right: 10px;" >
        <mat-icon>   face  </mat-icon> 
      </button>

      <div  [innerHTML] =  "transform(message.content)"> </div>
     
    
     

    </div>

    <div *ngIf="i ==  messages.length -1 && message.role  == 'assistant'">
      <button *ngIf="message.role  == 'assistant'" mat-fab style="background-color: #10684E; color: white; margin-right: 10px;" >
        <mat-icon>   smart_toy </mat-icon> 
      </button>



      <div  [innerHTML] =  "transform(displayedText)"> </div>

    </div>
       


    </div>
 
    
    </div>

 



   


<div style="width: 100%; display: flex; align-items: center; flex-direction: column;"  > 




<button *ngIf="checkIfDraw()"   style="background-color: #10684E; color: white;margin-top: 10px;" mat-fab extended 
(click)="finalStepAI()">
Durchschnittswert von Sachsen-Anhalt (2023) in den Graphen einzeichnen  </button>

<textarea id="adress" name="adress" [(ngModel)]="textinput" >

</textarea>

<button id="sendBtn" [disabled]="messages.length > 3 && messages[messages.length - 1].role !='assistant' && textinput != '' " mat-fab (click)="sendAnswer()">
  <mat-icon>   send </mat-icon> 
</button>
</div>





  </div></dialog>

