import {
  AfterViewInit,
  Component,
  OnInit,
  HostListener,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Chart, registerables } from 'chart.js';
import * as L from 'leaflet';
import { latLng } from 'leaflet';
import { RegioService } from 'src/app/service/regioservice/regioservice.component';
import proj4 from 'proj4';
import { Router } from '@angular/router';

@Component({
  selector: 'app-raster-schulen',
  templateUrl: './raster-schulen.component.html',
  styleUrls: ['./raster-schulen.component.css'],
})
export class RasterSchulenComponent implements OnInit, AfterViewInit {
  map: any;
  ortsteilChart: Chart | undefined;
  schulbezirkChart: Chart | undefined;
  ortsteilLabels: string[] = [];
  ortsteilData: number[] = [];
  schulbezirkLabels: string[] = [];
  schulbezirkData: number[] = [];
  rasterLayers: L.Layer[] = [];

  ortsteilNeueData: number[] = [];
  ortsteilAlteData: number[] = [];
  schulbezirkAlteData: number[] = [];
  schulbezirkNeueData: number[] = [];

  newScenario = 'initial';

  constructor(private regioService: RegioService, 
              private elRef: ElementRef,
              private router: Router) {
    Chart.register(...registerables);
  }

  async ngOnInit() {
    this.initializeMap();
    const data = await this.regioService.getmobilityRaster();
    console.log(data);
    this.plotgmkr(data.Initialscenario.Schulbezirk);
    this.plotBuffer(data.Initialscenario.Schulbuffer)
    this.plotRasterData(data?.Initialscenario?.Rasterdaten);
  }
  ngAfterViewInit() {
    this.loadChartData();
  }

  async loadChartData() {
    const data1 = await this.regioService.getmobilityRaster();
    const data = data1.Initialscenario.Plotdaten;
    this.ortsteilLabels = data.Ortsteil.map((item: any) => item.Ortsteil);
    this.ortsteilData = data.Ortsteil.map(
      (item: any) => item['Durchschnittliche Reisezeit (min)']
    );
    this.schulbezirkLabels = data.Schulbezirk.map(
      (item: any) => item.Schulname
    );
    this.schulbezirkData = data.Schulbezirk.map(
      (item: any) => item['Durchschnittliche Reisezeit (min)']
    );
    this.createOrtsteilChart(this.ortsteilLabels, this.ortsteilData);
  }

  onTabChange(event: any) {
    if (this.newScenario === 'initial') {
      switch (event.index) {
        case 0:
          if (this.schulbezirkChart) {
            this.schulbezirkChart.destroy();
            this.schulbezirkChart = undefined;
          }
          this.createOrtsteilChart(this.ortsteilLabels, this.ortsteilData);
          break;
        case 1:
          if (this.ortsteilChart) {
            this.ortsteilChart.destroy();
            this.ortsteilChart = undefined;
          }
          this.createSchulbezirkChart(
            this.schulbezirkLabels,
            this.schulbezirkData
          );
          break;
      }
    } else if (this.newScenario === 'new') {
      switch (event.index) {
        case 0:
          if (this.schulbezirkChart) {
            this.schulbezirkChart.destroy();
            this.schulbezirkChart = undefined;
          }
          this.createOrtsteilChart2(
            this.ortsteilLabels,
            this.ortsteilAlteData,
            this.ortsteilNeueData
          );
          break;
        case 1:
          console.log('vjvkv');

          if (this.ortsteilChart) {
            this.ortsteilChart.destroy();
            this.ortsteilChart = undefined;
          }
          this.createSchulbezirkChart2(
            this.schulbezirkLabels,
            this.schulbezirkAlteData,
            this.schulbezirkNeueData
          );
          break;
      }
    }
  }

  createOrtsteilChart(labels: string[], data: number[]) {
    const ctx = document.getElementById('ortsteilChart') as HTMLCanvasElement;
    if (ctx) {
        this.ortsteilChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [
                    {
                        label: 'Durchschnittliche Reisezeit (min)',
                        data: data,
                        backgroundColor: 'rgba(54, 162, 235, 0.6)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 1,
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true
                    }
                }
            },
            plugins: [{
                id: 'horizontalLine',
                afterDraw: (chart) => {
                    const yScale = chart.scales['y'];
                    const yValue = yScale.getPixelForValue(30); // y-coordinate for the line at y = 30
                    
                    const ctx = chart.ctx;
                    ctx.save();
                    ctx.strokeStyle = 'red';
                    ctx.lineWidth = 2;
                    ctx.beginPath();
                    ctx.moveTo(chart.chartArea.left, yValue);
                    ctx.lineTo(chart.chartArea.right, yValue);
                    ctx.stroke();
                    ctx.restore();
                }
            }]
        });
    }
}


  createSchulbezirkChart(labels: string[], data: number[]) {
    const ctx = document.getElementById(
      'schulbezirkChart'
    ) as HTMLCanvasElement;
    if (ctx) {
      this.schulbezirkChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Durchschnittliche Reisezeit (min)',
              data: data,
              backgroundColor: 'rgba(75, 192, 192, 0.6)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    }
  }

  private initializeMap(): void {
    const osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    const osmAttrib =
      '&copy; <a href="http://openstreetmap.org/copyright">OpenStreetMap</a> contributors';
    const osm = L.tileLayer(osmUrl, { maxZoom: 18, attribution: osmAttrib });

    this.map = L.map('map1', {
      center: latLng(51.165195078, 11.97547),
      zoom: 13,
      layers: [osm],
    });
  }

  private getColor(reisezeit: number): string {
    return reisezeit <= 10
      ? '#00FF00' // Green for 0-10 min
      : reisezeit <= 20
      ? '#ADFF2F' // Light green for 10-20 min
      : reisezeit <= 30
      ? '#FFD700' // Gold for 20-30 min
      : reisezeit <= 40
      ? '#FFA500' // Tomato for 30-40 min
      : reisezeit <= 50
      ? '#FF6347' // Orange-red for 40-50 min
      : reisezeit <= 60
      ? '#FF4500' // Orange for 50-60 min
      : '#FF0000'; // Red for 60+ min
  }

  private plotRasterData(rasterData: any[]): void {
    rasterData.forEach((entry: any) => {
      //console.log(entry);

      const reisezeit = entry['Reisezeit (min)'];
      const geometry = entry.geometry;

      if (geometry && geometry.type === 'Polygon') {
        const latlngs = geometry.coordinates[0].map((coord: number[]) => [
          coord[1],
          coord[0],
        ]);
        const color = this.getColor(reisezeit);

        L.polygon(latlngs, {
          color: color,
          fillColor: color,
          fillOpacity: 0.5,
        }).addTo(this.map).bindPopup(`
          <b>Schulname:</b> ${entry.Schulname}<br>
          <b>Ortsteil:</b> ${entry.Ortsteil}<br>
          <b>ReiseZeit (min):</b> ${entry['Reisezeit (min)']}
        `);
      }
    });
  }

  leafletPolygon: any;


plotBuffer(data:any){
console.log(data);

  data.forEach((school:any) => {
      const schoolName = school.Schulname;
      const coordinates = school.geometry.coordinates[0];
      const latLngs = coordinates.map((coord:any) => [coord[1], coord[0]]);

      const polygon = L.polygon(latLngs, {
          color: 'black',
          weight: 2,
          fillColor: 'black',
          fillOpacity: 0.5
      }).addTo(this.map);

      polygon.bindPopup(`<b>${schoolName}</b>`);
  });
}


  plotgmkr(data: any) {
    const schulbezirkData = data;

    schulbezirkData.forEach((item: any) => {
      const { geometry } = item;

      if (geometry.type === 'MultiPolygon') {
        geometry.coordinates.forEach((polygon: any) => {
          const switchedPolygon = polygon[0].map((coord: any) => {
            return [coord[1], coord[0]];
          });

          this.leafletPolygon = L.polygon(switchedPolygon, {
            color: '#1877F2',
            fillOpacity: 0,
            weight: 2,
          }).addTo(this.map);

          this.leafletPolygon.bindPopup(`
            <b>Ortsteil:</b> ${item.Ortsteil}<br>
          `);
        });
      }
    });
  }

  isCollapsed = false;

  @ViewChild('tabCharts', { static: true }) tabCharts!: ElementRef;

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;

    const chartContainer =
      this.tabCharts.nativeElement.querySelector('.chart-container');

    if (this.isCollapsed) {
      this.tabCharts.nativeElement.style.width = '100px';
      this.tabCharts.nativeElement.style.height = '40px';
      chartContainer.style.display = 'none';
    } else {
      this.tabCharts.nativeElement.style.width = '1000px';
      this.tabCharts.nativeElement.style.height = '550px';
      chartContainer.style.display = 'block';
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  isLoading = false;
  async changeSzenario() {
     this.toggleCollapse();
    this.isLoading = true;

      this.newScenario = 'new';
      await this.regioService.getmobilityRaster().then((data) => {
        if (this.schulbezirkChart) {
          this.schulbezirkChart.destroy();
          this.schulbezirkChart = undefined;
        }
        if (this.ortsteilChart) {
          this.ortsteilChart.destroy();
          this.ortsteilChart = undefined;
        }

        this.map.eachLayer((layer: any) => {
          if (layer instanceof L.TileLayer) {
            return;
          }
          this.map.removeLayer(layer);
        });

        setTimeout(() => {
          this.toggleCollapse();
          this.plotgmkr(data.Modifiedscenario.Schulbezirk);
          this.plotRasterData(data.Modifiedscenario.Rasterdaten);
          this.loadChartDataModified(data);
        }, 3400);
      });

    setTimeout(() => {

      this.isLoading = false;
    }, 4000);
  }

  async loadChartDataModified(data: any) {
    this.ortsteilLabels = data.Modifiedscenario.Plotdaten.Ortsteil.map(
      (item: any) => item.Ortsteil
    );
    this.ortsteilAlteData = data.Modifiedscenario.Plotdaten.Ortsteil.map(
      (item: any) => item['Alte durchschnittliche Reisezeit (min)']
    );
    this.ortsteilNeueData = data.Modifiedscenario.Plotdaten.Ortsteil.map(
      (item: any) => item['Neue durchschnittliche Reisezeit (min)']
    );

    this.schulbezirkLabels = data.Modifiedscenario.Plotdaten.Schulbezirk.map(
      (item: any) => item.Schulname
    );
    this.schulbezirkAlteData = data.Modifiedscenario.Plotdaten.Schulbezirk.map(
      (item: any) => item['Alte durchschnittliche Reisezeit (min)']
    );
    this.schulbezirkNeueData = data.Modifiedscenario.Plotdaten.Schulbezirk.map(
      (item: any) => item['Neue durchschnittliche Reisezeit (min)']
    );

    this.createOrtsteilChart2(
      this.ortsteilLabels,
      this.ortsteilAlteData,
      this.ortsteilNeueData
    );
    this.createSchulbezirkChart2(
      this.schulbezirkLabels,
      this.schulbezirkAlteData,
      this.schulbezirkNeueData
    );
  }
  createOrtsteilChart2(
    labels: string[],
    alteData: number[],
    neueData: number[]
  ) {
    const ctx = document.getElementById('ortsteilChart') as HTMLCanvasElement;
    if (ctx) {
      this.ortsteilChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Alte durchschnittliche Reisezeit (min)',
              data: alteData,
              backgroundColor: 'rgba(255, 99, 132, 0.6)', // Example color for old data
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 1,
            },
            {
              label: 'Neue durchschnittliche Reisezeit (min)',
              data: neueData,
              backgroundColor: 'rgba(54, 162, 235, 0.6)', // Example color for new data
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    }
  }

  createSchulbezirkChart2(
    labels: string[],
    alteData: number[],
    neueData: number[]
  ) {
    const ctx = document.getElementById(
      'schulbezirkChart'
    ) as HTMLCanvasElement;
    if (ctx) {
      this.schulbezirkChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Alte durchschnittliche Reisezeit (min)',
              data: alteData,
              backgroundColor: 'rgba(255, 99, 132, 0.6)', // Example color for old data
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 1,
            },
            {
              label: 'Neue durchschnittliche Reisezeit (min)',
              data: neueData,
              backgroundColor: 'rgba(54, 162, 235, 0.6)', // Example color for new data
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    }
  }

  private plotRasterData2(rasterData: any[]): void {
    // Define the projections
    const proj3035 = 'EPSG:3035';
    const proj4326 = 'EPSG:4326';

    rasterData.forEach((entry: any) => {
      console.log(entry);
      const reisezeit = entry['Reisezeit (min)'];
      const geometry = entry.geometry;

      if (geometry && geometry.type === 'Polygon') {
        // Transform coordinates from EPSG:3035 to EPSG:4326
        const latlngs = geometry.coordinates[0].map((coord: number[]) => {
          const [x, y] = coord; // coord[0] is the x (easting), coord[1] is the y (northing)
          const [lon, lat] = proj4(proj3035, proj4326, [x, y]); // Transform coordinates
          return [lat, lon]; // Return in [latitude, longitude] format
        });

        console.log(entry);

        const color = this.getColor(reisezeit);

        L.polygon(latlngs, {
          color: color,
          fillColor: color,
          fillOpacity: 0.5,
        }).addTo(this.map).bindPopup(`
          <b>Schulname:</b> ${entry.Schulname}<br>
          <b>Ortsteil:</b> ${entry.Ortsteil}<br>
          <b>ReiseZeit (min):</b> ${entry['Reisezeit (min)']}
        `);
      }
    });
  }

  Mob(){
      // Remove Leaflet map
      // if (this.map) {  // Assuming `this.map` is your Leaflet map instance
      //   this.map.eachLayer((layer: any) => {
      //     this.map.removeLayer(layer);
      //   });
      //   this.map.remove();
      //   this.map = null;
      // }
  
setTimeout(() => {
        this.router.navigateByUrl('/prognose/Schulentwicklungsplanung');
}, 500);
  } 
}
