import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { GlobalVariable } from 'global';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-llama',
  templateUrl: './llama.component.html',
  styleUrls: ['./llama.component.css']
})
export class LlamaComponent {

  private baseApiUrl = GlobalVariable.LLAMA_URL;

  constructor(private http: HttpClient) 
  {}

  async addMessages(messages : any) {

  
     var testBody = {
        "options": {
          "seed": 1337,
          "temperature": 0,
          "num_ctx": 8192
        },
 
       "model": "llama3.1:8b",
       "messages": messages,
       "stream": false
     }
 
     var url = this.baseApiUrl;
     console.warn(testBody)

     var result = await firstValueFrom(this.http.post(url, testBody ) );
     return result;
   }

  async addChat(search: string) {
   /* const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Basic ' + btoa('bunsenuser:babyschnaps')
      })
    }; */

    var testBody = {
      "model": "llama3.1:8b",
      "messages": [
        {
          "role": "user",
          "content": search
        }
      ],
      "stream": false
    }

    var url = this.baseApiUrl;
    var result = await firstValueFrom(this.http.post(url, testBody ) );
    return result;
  }

  
  async sendMessages(messages: any[]) {
 
     var testBody = {
       "model": "llama3.1:8b",
       "messages": messages,
       "format": "json",

       "options": {
        "temperature": 0, 
          "seed": 123,
          "num_ctx": 40960

      
      },
       "stream": false
     }

     var url = this.baseApiUrl; 
     var result = await firstValueFrom(this.http.post(url, testBody ) );
 
     return result;
   }


   async describeChart(chartData: any, indicatorName: string, chartType: string, unit:string): Promise<string> {
    
    try {
      const chartDescription =
        chartType === 'bar'
          ? `Bitte beschreibe ein Balkendiagramm (Bar Chart) basierend auf dem Indikator "${indicatorName}" in genau drei Sätzen.`
          : `Bitte beschreibe ein Liniendiagramm (Line Chart) basierend auf dem Indikator "${indicatorName}" in genau drei Sätzen.`;
  
      const prompt = `
        Daten: ${JSON.stringify(chartData)}
        ${chartDescription}
        Es ist wichtig, dass die Beschreibung prägnant bleibt und sich ausschließlich auf das Diagramm bezieht.
        JSON-Objekte dürfen niemals erwähnt werden. Die wichtigsten Trends und Werte des Diagramms sollen hervorgehoben werden.
        Finde heraus, welche Stadt das Maximum hat, und erwähne das. Die Einheit ist: ${unit}.
       Es handelt sich um Gebiete und nicht um Städte.  
Wenn du einen Gebietsnamen wie "Burgenlandkreis" siehst, dann sage „Gebiet“.
        Gebe die Antwort in Deutsch zurück.
      `;
  
      const testBody = {
        model: "llama3.1:8b",
        "options": {  
          "seed": 1337,
          "temperature": 0,
          "num_ctx": 8192
        },
        messages: [
          {
            role: "user",
            content: prompt
          }
        ],
        stream: false
      };
      const url = this.baseApiUrl;
      const result: any = await firstValueFrom(this.http.post(url, testBody));
  
      return result?.message?.content || 'Keine Beschreibung generiert.';
    } catch (error) {
      console.error('Fehler bei der Generierung der Beschreibung:', error);
      return 'Beschreibung konnte nicht generiert werden.';
    }
  }
  

  async reformSentence(sentence: string): Promise<string> {
    try {
      const prompt = `
      Satz: ${sentence}
      Formuliere diesen Satz vollständig um, sodass er dieselbe Länge wie der Originalsatz hat.
      Verwende neue Wörter und entferne möglichst viele der ursprünglichen Wörter.
      Die Bedeutung des Satzes soll jedoch erhalten bleiben.
    `;
    
    const testBody = {
      model: "llama3.1:8b",
      "options": {  
        "seed": 1337,
        "temperature": 0,
        "num_ctx": 8192
      },
      messages: [
        {
          //message!s
          role: "user",
          content: prompt
        }
      ],
      stream: false
    };
      const url = this.baseApiUrl;
      const result: any = await firstValueFrom(this.http.post(url, testBody));

      return result?.message?.content || sentence;

      
    } catch (error) {
      console.error('Fehler bei der Reformulierung:', error);
      return sentence;
    }
  }

}

