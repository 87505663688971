<div style="padding: 10px"> 
    <button mat-fab extended color="primary" *ngIf="newExtended"
  
  style=" position:absolute; top:20; left: 2;" (click)="newExtended = false">
  <mat-icon>backspace</mat-icon>
  
  </button>
  
  <br>
  <br>
  
  <h1 *ngIf="!docPopID"> Population auswählen</h1>
  
  
  
    <br>
    <br>
  
    <div style="display: flex; justify-content: space-between;">

  
   
  
  
    <div style="margin-left: 10px;margin-right: 10px; background-color: red" *ngIf="!newExtended">
  
        <input id="adress2" *ngIf="searchExtended" [(ngModel)]="searchBox" />
        <button mat-fab extended color="primary" *ngIf="!searchExtended" style="width: 150px;"
          (click)="searchExtended = !searchExtended">
          <mat-icon>search</mat-icon>
          Suche
        </button>
        <button mat-fab extended color="primary" *ngIf="searchExtended"
          style="width: 170px; position:absolute; top:20; right: 2.5%;">
          <mat-icon>search</mat-icon>
  
        </button>
  
      </div>
  
  
  
    </div>
  
  <br> 
  <br> 


  
  
   
  
  

  
  
  
    <div style="display: flex; align-items: center;" >
  
      <div id="tile-container">
  
        <ng-container *ngFor="let scen of pop">
            <div class="tile" (click)="setExistingScenario(scen)"
              *ngIf="( searchBox == '' || scen?.pop_name?.toLowerCase().includes(searchBox.toLowerCase()))">
              <h1> {{ scen.pop_name }} </h1>
  
  
              <i class="bi bi-pencil-fill">  {{ translateUserName(scen.createdBy) }} </i> <br>
  
              <i class="bi bi-calendar-week">
                {{scen.createdAt | date: 'dd-MM-yyyy'}}
              </i> <br>
  
  
  
            </div>
  
        </ng-container>
  
  
  
  
  
  
      </div>
  
  
    </div>
  
  </div>