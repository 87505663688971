<div class="message-container">

    <h3 *ngIf="!data.title"> Analyse {{ data.name }} teilen </h3>

    <h3 *ngIf="data.title">  {{ data.title }}  </h3>
    <div class="user-list">
      <div class="user-list-inner">
        <ng-container *ngFor="let user of userList">
          <mat-checkbox *ngIf="currentUser.id !== user.id" 
                        (change)="toggleUser(user.id, $event.checked)">
            {{ user.username }}
          </mat-checkbox>
        </ng-container>
      </div>
    </div>
  
    <h3>Nachricht an ausgewählte Personen XY senden</h3>
    
    <div class="message-content">
      <div [innerHTML]="formattedMessage"></div>
    </div>


      <mat-form-field>
    <mat-label>Population wählen</mat-label>
    <mat-select disableRipple>
      <mat-option value="1">pop 1</mat-option>
      <mat-option value="2">pop 2</mat-option>
      <mat-option value="3">pop 3</mat-option>
    </mat-select>
  </mat-form-field>
  
    <div class="additional-text">
      <mat-form-field appearance="fill" style="  width: 650px; height: 100px;">
        <mat-label>Zusätzlichen Text hinzufügen</mat-label>
        <textarea matInput [(ngModel)]="additionalText" placeholder="Geben Sie hier zusätzlichen Text ein"></textarea>
      </mat-form-field>
    </div>
    
    <div class="diaFooter">
      <button mat-raised-button color="primary" (click)="apply()">Senden</button>
      <button mat-raised-button color="warn" (click)="closeAll()">Schließen</button>
    </div>
  </div>
  