import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Chart } from 'chart.js';
import { ReachConfigComponent } from '../../popup/reach-config/reach-config.component';
import { DatahubService } from 'src/app/service/datahubservice/datahubservice.component';
import { Storageservice } from 'src/app/service/storageservice-component/storageservice-component.component';
import { RoutingserviceComponent } from 'src/app/service/routingservice/routingservice.component';
import { ReachMatrixComponent } from '../reach-matrix/reach-matrix.component';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { Observable, Subscribable, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DialogShareComponent } from '../../datahub2/data-regio/dashboards/dialog-share/dialog-share.component';
import { MatDialog } from '@angular/material/dialog';
import { SelectIndikatorenModalComponent } from '../../datahub2/data-regio/dashboards/select-indikatoren-modal/select-indikatoren-modal.component';
import { ReportDialogComponent } from '../../datahub2/data-regio/dashboards/report-dialog/report-dialog.component';
import * as htmlToImage from 'html-to-image';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { DialogReportComponent } from '../dialog-report/dialog-report.component';
import { DialogBreaksComponent } from '../../datahub2/data-regio/dashboards/dialog-breaks/dialog-breaks.component';
import { KeycloakOperationService } from 'src/app/service/keycloakService/keycloak-operation.service';
import { DialogDataComponent } from '../../datahub2/data-regio/dashboards/dialog-data/dialog-data.component';
import { DialogDownloadComponent } from '../dialog-download/dialog-download.component';
import { ShareDialogComponent } from '../share-dialog/share-dialog.component';
//const classyBrew = require('classybrew');
declare var require: any
const classyBrew = require('classybrew');

@Component({
  selector: 'result-app',
  templateUrl: './result-app.component.html',
  styleUrls: ['./result-app.component.css']
})
export class ResultAppComponent implements OnChanges, OnInit{
selectedSichtbarkeit: any;
  userID: any;
clickResult(arg0: any,arg1: any,arg2: any) {

  this.reachComponent.clickResult(arg0, arg1)
}
closeTransport() {
  //@ts-ignore
  document.getElementById("transportMode").close();


}
openTransport() {
  if(this.orgObj?.isTemp) {
    alert("Vor Änderung bitte speichern");
    return;
  }
  //@ts-ignore
  document.getElementById("transportMode").showModal();


}
  breaks: any;
  selectedTransport: any;
filterAndUpdateChart() {
    //@ts-ignore
    console.warn("chart", this.chart.data, this.chartLegend);



    var chartData =  this.convertToChartJsData(this.result.Meta_Einrichtungen);
    if(!chartData) {
      return;
    }
    console.warn("chart", chartData?.data.datasets)
    var activeIndex = [ 0 ];

    this.chartLegend.forEach((element: { disabled: any; label: any; }) => {
      if(!element.disabled){
       var index =  chartData?.data.labels.indexOf(element.label);
       if(index > - 1) {
        activeIndex.push(index)

       }
        
      }
      
    });

    chartData?.data.datasets.forEach(d => {
      d.data = activeIndex.map(x=>  d.data[x]);

    })
   
    chartData.data.labels = activeIndex.map(x=> chartData?.data.labels[x]);

    this.chart.data = chartData.data; 
   
    this.chart.update();

    this.updateChartWithBreaks(this.breaks);


}
  chosenBreaks: any;
  valuesArray: any = [];
  valuesStored: any = [];
  valuesBreaks: any = [];
  breaksAmount: number = 4;
  selectedChartObject: any;
  setBreaks: any;
  breaksFin: any;
saveItem() {

this.datahub.addAnalyseData(this.orgObj)
}
  pieChart: any;
  locationChange: boolean = false;
  chartImg: string | undefined;
updatePieChart() {

  if(this.pieChart) {this.pieChart.destroy()};
  if(this.breaksFin) {
    this.createPieChartWithBreaks(this.breaksFin)

  } else {
this.createPieChart(this.result); 
  }

}

openDialogBreaks() {
  var selectedChartObject : any = {};
  selectedChartObject.unit = this.orgObj?.Meta[0].Unit 

  console.warn("BREAKRES", this.result?.Einrichtungen_Naehe)
  this.result?.Einrichtungen_Naehe.forEach((ein: { Distanz: any; }) => {
    this.valuesStored.push(ein.Distanz)
  });

  const dialogRef =this.dialog.open(DialogBreaksComponent,{
    data: {chosenBreaks:this.chosenBreaks,valuesArray:this.valuesArray,valuesStored:this.valuesStored,valuesBreaks:this.valuesBreaks,breaksAmount:this.breaksAmount,selectedChartObject:selectedChartObject},
    width:'700px',
    height:'400px',
    panelClass:'min-max'
  });
  dialogRef.afterClosed().subscribe((result:any) => {
    console.warn("RES", result);
    if (result) {
      this.setBreaks=result.setBreaks;
      this.breaksAmount=parseInt(result.breaksAmount);
      this.valuesArray=result.valuesArray;
      this.valuesStored=result.valuesStored;
      this.valuesBreaks=result.valuesBreaks;
      setTimeout(() => {
        this.breaks = result; 
  
        this.updateChartWithBreaks(result); 
        }, 10);
    }
  });
}

  updateChartWithBreaks(result: any) {
    var breaksFin : any;
   switch(result.setBreaks) {
   case "Gleichmäßig":
    breaksFin = this.legendeEqual();
    break;
    case 'Benutzerdefiniert':
      breaksFin = this.legendeBenutzerDef();
      break;
    case 'jenks':
      breaksFin = this.legendeJenks();
      break;
    case 'quantile':
      breaksFin = this.legendeQuentile();
      break;
   }

   console.warn("BREAK", breaksFin)
    var newData : any = [];

    for (let i = 0; i < breaksFin.length - 1; i++) {
      var dataRow : any = []
      this.chart.data.labels.forEach((label: any) => { 
        if(label == 'All') {
          var count = this.result.Einrichtungen_Naehe.filter((el: any) => breaksFin[i] <= el.Distanz && el.Distanz < breaksFin[i + 1] )
          dataRow.push(count.length)
        } else {
        var count = this.result.Einrichtungen_Naehe.filter((el: any) => breaksFin[i] <= el.Distanz && el.Distanz < breaksFin[i + 1]  && el.Typ == label )
        dataRow.push(count.length)
        }
    })
    newData.push({label: breaksFin[i].toFixed(2) + " - " +breaksFin[i + 1].toFixed(2) + " " + this.orgObj?.Meta[0].Unit , data: dataRow})
      
  
      
  }
  
  console.warn("XXXX", newData)
 
  this.chart.data.datasets = newData
  this.createPieChartWithBreaks(breaksFin)
  this.breaksFin = breaksFin;
  this.chart.update(); 

 
  

  }

  legendeBenutzerDef(){
    let combinedValues: any = [];
    this.valuesBreaks.forEach((obj: { from: string; to: string; }) => {
      combinedValues.push(parseFloat(obj.from));
      combinedValues.push(parseFloat(obj.to));
    });
    let uniqueValues = Array.from(new Set(combinedValues));
    uniqueValues.sort((a: any, b: any) => a - b);
    console.log(uniqueValues);
    return uniqueValues;
  }

  legendeJenks(){
    console.log(this.valuesStored);
    const brew = new classyBrew();
    brew.setSeries(this.valuesStored);
    brew.setNumClasses(this.breaksAmount);
    brew.setColorCode("BuGn");
    brew.classify('jenks');
    const values: number[] = brew.getBreaks();
    return values
  }

  legendeQuentile(){
    console.log(this.valuesStored);
    const brew = new classyBrew();
    brew.setSeries(this.valuesStored);
    brew.setNumClasses(this.breaksAmount);
    brew.setColorCode("BuGn");
    brew.classify('quantile');
    const values: number[] = brew.getBreaks();
    return values
  }

  legendeEqual(){
    console.log(this.valuesStored);

    var a = Math.min.apply(null, this.valuesStored)
    , b = Math.max.apply(null, this.valuesStored)
    , c = []
    , d = a
    , e = (b - a) / this.breaksAmount;
  for (var i = 0; i <= this.breaksAmount; i++)
      c[i] = d,
      d += e;
  return c[this.breaksAmount] = b,

  c.sort(function(a, b) {
      return a - b
  }),
  c
  }

openDialogShare(){


  const dialogRef =this.dialog.open(ShareDialogComponent,{
    data: { name: this.selectedName , id: this.orgObj._id},
    width:'600px',
    height:'320px',
  });
  dialogRef.afterClosed().subscribe((result:any) => {
    console.log(result);
  
    if (result) {

    }
  });
}
public loadingN: boolean = false;
private subscriptions: Subscription = new Subscription();


public async showReport(): Promise < void > {


this.chartImg = await this.getChartImg('MyChart');
//onsole.warn("CHART", res)


//his.pieCh = await this.getChartImg('myPieChart');
//console.warn("PIECHART", res)
  //const topic = this.regioMetaData.find((r: any) => r.checked);
  var topic = { data: ''}

  //var mapImg = await this.getMapImg();
  //console.warn("Map", mapImg)
  
  this.showPDF([]); 
  return; 
  alert("hello ")
  if(topic) {
    const dialogRef = this.dialog.open(SelectIndikatorenModalComponent, {
      width: '1500px',
      height: 'auto',
      data: topic.data
    });

    this.subscriptions.add(dialogRef.componentInstance.confirm.subscribe((indikatoren: any[]) => {
      this.showPDF(indikatoren);
    }));
  }
}

public async showPDF(categories: any[]): Promise < void > {
  const data: any[] = [
    { name:"Test",
      filterName: "Filter",
      pageType: "chart",
      img: this.chartImg},
      { name:"Test 2",
        filterName: "Filter",
        pageType: "map",
        img: await this.reachComponent.getMapImg() },
        { name:"Test 3",
          filterName: "Filter",
          pageType: "map",
          img: await this.getChartImg('myPieChart')},
          { name:"Test 3",
            filterName: "Filter",
            pageType: "data",
            data: this.result.Einrichtungen_Naehe,
          
        
        }
  ];


  const selectedChart = this.chart;

 

  this.loadingN = true;

  for (var i = 0; i < 1; i++) {
    data.push(await this.getIndikatorReport(categories[i]));
    console.warn("DATA", data)
    if (i === 0) {
      const ReportDialogRef = this.dialog.open(DialogReportComponent, {
        width: '1500px',
        height: 'auto',
        data:  data,
      });

      this.subscriptions.add(ReportDialogRef.afterOpened().subscribe(() => {
        this.loadingN = false;
      }));

      this.subscriptions.add(ReportDialogRef.afterClosed().subscribe(async () => {
        this.loadingN = true;
       // this.selectedChartObject = selectedChart;
       // await this.showChart();
        this.loadingN = false;
      }));
    }
  }
}
  getIndikatorReport(arg0: any): any {
    return this.result
  }

selectedLabel: any;
storeItem() {
//@ts-ignore
document.getElementById("overwrite").showModal();


}

closeOverwrite() {
  //@ts-ignore
document.getElementById("overwrite").close();
}
checkEinrichtungFilter(einrichtung: any): any {


  if(this.chartType == 'bar') {
var filtered = this.chartLegend.filter((el: { label: any; disabled: boolean}) => el.label == einrichtung.Typ && !el.disabled );
  } else {

    return this.selectedLabel == 'All' || einrichtung.Typ ==  this.selectedLabel || !this.selectedLabel
  }
return filtered.length > 0

}


getNumberOfFiltered() {
  var count = 0; //this.result.Einrichtungen_Naehe.length;

  if(this.chartType == 'bar') {
    var filter = this.chartLegend.filter((el: { label: any; disabled: boolean}) => !el.disabled );
    
    filter.forEach((element: { label: any; }) => { 
      var filtered = this.result.Einrichtungen_Naehe.filter((el: { Typ: any; }) => el.Typ == element.label);
      count = count + filtered.length;
      
    });
      } else {
       if(this.selectedLabel == 'All'){ return this.result.Einrichtungen_Naehe.length }
       var filtered = this.result.Einrichtungen_Naehe.filter((el: { Typ: any; }) => el.Typ == this.selectedLabel);
       return filtered.length; 
     
      }
    return count;


}


openDialogSave() {
throw new Error('Method not implemented.');
}
async openDialogData() {
  var einrichtungen: any[] = [];
  var min = 1000000;
  var max = -100000;
  var sum  = 0;

  this.result?.Einrichtungen_Naehe.forEach((einrichtung: any) => {
    if(this.checkEinrichtungFilter(einrichtung)) {
      einrichtungen.push(einrichtung)
    }
    if(einrichtung.Distanz < min) {
      min = einrichtung.Distanz
    }
    if(einrichtung.Distanz > max) {
      max = einrichtung.Distanz
    }

    sum = sum + einrichtung.Distanz
    einrichtung.Unit = this.selectedUnit

    
    
  });

  var dataIndiTableValues = {
MinData: min + this.selectedUnit,
MaxData: max + this.selectedUnit,
       AverageData: sum / einrichtungen.length + this.selectedUnit,
  }

  var title = "Analyse " + this.selectedName 
  var subtitle =  this.selectedRadius +  " " + this.selectedUnit 
  + ' um ' + this.result?.Starting_Point[0].Street_Name 
  

  console.warn("EIN", einrichtungen)
  //  await this.getIndiYearsAndDataAndUnit2();
    const dialogRef =this.dialog.open(DialogDownloadComponent,{
      data: {loadingEr:true,title: title , subtitle: subtitle, einrichtungen: einrichtungen, dataIndiTableValues: dataIndiTableValues, dataIndiTable: einrichtungen},
      width:'1000px',
      height:'700px',
    });
  }
  myControl!: FormControl<any>;
showSave: any;
showTable: any;
chartType = 'bar'; 
scrollToElement($event: MatAutocompleteSelectedEvent) {
throw new Error('Method not implemented.');
}
chartLegend: any;
filteredOptions: Observable<undefined> | Subscribable<undefined> | Promise<undefined> | undefined;
filterRegion(_t106: any) {
throw new Error('Method not implemented.');
}
selectMode: any;
deselectAll(arg0: boolean) {
this.chartLegend.forEach((label: { disabled: boolean; }) => {
  label.disabled = arg0;
});
this.selectMode = arg0;
}




showShareChart: any;
showDownloadChart: any;

async changeType(type: string) {

this.chartType = type;
if(type == 'pie') {
  setTimeout(() => {
    this.selectedLabel = this.chartLegend[0].label
this.updatePieChart() 
    }, 100);



}


}


private getChartImg(id: string): Promise < string | undefined > {
  return new Promise((resolve, reject) => {
    const domChart = document.getElementById(id);
    if (domChart) {
      htmlToImage.toPng(domChart)
        .then(function (dataUrl) {
          resolve(dataUrl);
        })
        .catch(function (error) {
          console.error('Error:', error);
          reject();
        });
    }
  });

}
selectedChartIndex: any;
minValue: any;
maxValue: any;
  selectedMinYear!: number;
  selectedMaxYear!: number;
  selectedMinVal!: number;
  selectedMaxVal!: number;
maxYear: any;
minYear: any;
changeChartType(arg0: number) {
throw new Error('Method not implemented.');
}
checkForModification(): any {
 



return   this.orgObj?.Meta[0].Einrichtungen != this.selectedEinrichtungen ||
this.orgObj?.Meta[0].Distanz     != this.selectedRadius ||  this.reachComponent.markersObjRefMap.size > 1 || this.orgObj?.Meta[0].Transport_Mode != this.selectedTransport
}

checkForOverride(){
  if(!this.orgObj) {
    return false; 
  }
  return this.orgObj.Meta[0].Name != this.selectedName &&  
this.orgObj.Meta[0].Einrichtungen == this.selectedEinrichtungen &&
this.orgObj?.Meta[0].Distanz     == this.selectedRadius

}

checkForNew(){
  if(!this.orgObj) {
    return false; 
  }
  return this.orgObj.Meta[0].Name != this.selectedName &&  (
this.orgObj.Meta[0].Einrichtungen != this.selectedEinrichtungen ||
this.orgObj?.Meta[0].Distanz     != this.selectedRadius )

}
  selectedUnit: string = 'm';
addMode = false; 
showName(newMode: boolean) {
  if(this.orgObj?.isTemp && !newMode) {
    alert("Vor Änderung bitte speichern");
    return;
  }


    //@ts-ignore
    document.getElementById("name").showModal();
  }

closeName() {

  if(!this.addMode) {

 this.datahub.renameAnalyseData(this.orgObj.Meta[0].Name, this.selectedName);
 this.orgObj.Meta[0].Name = this.selectedName; 
 
}  else {
 
  this.orgObj.Meta[0].Name = this.selectedName; 
  var newObj = JSON.parse(JSON.stringify(this.orgObj))
  newObj.isTemp = false; 
  this.orgObj.isTemp = false; 
  newObj.Meta[0].visibility = this.selectedSichtbarkeit
  newObj.Meta[0].owner = this.userID;

  console.warn("NEW", newObj)

  this.datahub.addAnalyseData(newObj); 
  this.reachComponent.clickResult(undefined, newObj)

  this.orgObj = newObj;

  
 }

      //@ts-ignore
      document.getElementById("name").close();
    

}

  async addAnalyseData(){
  //store new document
  var t= await this.datahub.addAnalyseData(this.orgObj); 
  console.warn(t)
  //or: replace 

  

  //@ts-ignore
  document.getElementById("overwrite").close();


}
selectedName: any = '' ;
clickEinrichtung(ein: any) {
  if(ein == this.selectedEinrichtung) {
    this.selectedEinrichtung = undefined; 
    this.reachComponent.clickResult(this.result, this.orgObj)

  } else {
    this.selectedEinrichtung = ein;
  this.reachComponent.clickEinrichtung(ein, "Umkreissuche")
  }
}
scrollIntoView(id: string) {
  const element = document.getElementById(id);
  element?.scrollIntoView({
    behavior: 'smooth',
    block: 'center'
  });
}

formatLabel(value: number): string {
  return `${value}`;
}

  async overwrite() {
  //delete old object via name
  this.reachComponent.resultsFromDB =  this.reachComponent.resultsFromDB.filter((el: { Meta: { Name: any; }[]; }) => el.Meta[0].Name != this.orgObj?.root?.Meta[0].Name)
 

  await this.datahub.removeAnalyseData(this.orgObj?.root?.Meta[0].Name)
  //reset root from actual object

  this.orgObj.Meta[0].Name = this.orgObj?.root?.Meta[0].Name
  this.orgObj.root = undefined;
  this.orgObj.isTemp = false;
  //add new object

 await this.datahub.addAnalyseData(this.orgObj); 
 this.closeOverwrite()
}
async filter($event: Event, type: string, chart: any) {
 
  //@ts-ignore
  var value = Number($event.target.ariaValueText);
  if (type == 'timeMin')this.selectedMinYear = value;
  if (type == 'timeMax')this.selectedMaxYear = value;
  if (type == 'valMin')this.selectedMinVal = value;
  if (type == 'valMax')this.selectedMaxVal = value;
  await this.updateChartBasedOnIndex(this.selectedChartIndex);
  return;
}
  async updateChartBasedOnIndex(selectedChartIndex: any) {
   //TODO: Implement 
  }

  @Input() result: any;
  @Input() orgObj: any;
  @Input() clickedName: any;
  myChart: any;
  selectedStep: any;
  selectedEinrichtungen: any;
  meta: any;
  selectedNewEinrichtung: any;
  tempObj: any = {};
  umkreisMode: any;
  selectedRadius: any;
  selectedStandorte: any;
  modified: boolean = false;
  chart: any; 
  selectedEinrichtung: any; 





  constructor(    public dialog: MatDialog, private datahub: DatahubService, public datepipe: DatePipe,
    private keyCloakService: KeycloakOperationService, 
    public storageService: Storageservice, public reachComponent:ReachMatrixComponent,  private routingService: RoutingserviceComponent) {


  }
  ngOnChanges(changes: SimpleChanges): void {
    this.reset();
    this.chartLegend = []



    console.warn("RESULT", this.result)
    this.result.Meta_Einrichtungen.forEach((label: any) => {
      if(label.Typ != 'All') {
      this.chartLegend.push({
        label: label.Typ,
        disabled: false,
      })
    }
      
    });
    console.warn("INIT", this.chartLegend)
    console.warn("change", this.result)
    if(this.result?.Error) {

      this.selectedName = this.orgObj?.Meta[0].Name 
      this.selectedRadius = this.orgObj?.Meta[0].Distanz 
      this.selectedUnit = this.orgObj?.Meta[0].Unit 
      this.selectedTransport = this.orgObj?.Meta[0].Transport_Mode
      this.selectedEinrichtung =  ''
      alert(this.result.Error)
      
      return;
    }


    if(!this.result.Meta_Einrichtungen) {
      return; 
    }

    console.warn("change3")

    
    
    var chartData =  this.convertToChartJsData(this.result.Meta_Einrichtungen);
    console.warn("CHARTDATA", chartData); 
    if(!chartData) {
      return; 
    }

  
     if(!this.chart) {
      this.createChart(chartData)
     }

  //@ts-ignore
    this.chart.data = chartData.data; 
   
    this.chart.update();
    this.selectedLabel = "All"; 
    this.createPieChart(this.result)
    this.selectedEinrichtungen = this.orgObj.Meta[0].Einrichtungen 
    this.selectedRadius = this.orgObj?.Meta[0].Distanz    
    this.selectedTransport = this.orgObj?.Meta[0].Transport_Mode
 
    this.scrollIntoView(this.clickedName)

   
  }

  convertToChartJsData(metaEinrichtungen: any) {
    


    if(!metaEinrichtungen)
      {
        return;
      }
    const labels = metaEinrichtungen.map((einrichtung: any) => einrichtung.Typ);
    const categories: string[] = [];
    const categoryData: { [key: string]: number[] } = {};

    metaEinrichtungen.forEach((einrichtung: any) => {
      einrichtung.data.forEach((item: any) => {
        if (!categories.includes(item.category)) {
          categories.push(item.category);
          categoryData[item.category] = new Array(metaEinrichtungen.length).fill(0);
        }
        const index = labels.indexOf(einrichtung.Typ);
        categoryData[item.category][index] = parseFloat(item.share);
      });
    });
  


    var datasets = categories.map((category, index) => ({
      label: category,
      data: categoryData[category],
      backgroundColor: `rgba(${75 + index * 50}, ${192 - index * 50}, ${192 + index * 50}, 0.2)`,
      borderColor: `rgba(${75 + index * 50}, ${192 - index * 50}, ${192 + index * 50}, 1)`,
      borderWidth: 1,
    }));
 
    datasets = this.sortDatasetsByLabel(datasets); 
    return {
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    };
  }
  createChart(chartData: any){
  
    this.chart = new Chart("MyChart", {
      //@ts-ignore
      type: this.chartType, 

      data: chartData,
      options: {
        aspectRatio:2.5
      }
      
    });
  }

   sortDatasetsByLabel(datasets: any) {
    return datasets.sort((a: { label: string; }, b: { label: string; }) => {
        // Extrahiere die Startzeit aus den Labels
        const timeA = parseFloat(a.label.split('-')[0]);
        const timeB = parseFloat(b.label.split('-')[0]);

        // Vergleiche die Startzeiten, um die Sortierung zu bestimmen
        return timeA - timeB;
    });
}

  async ngOnInit(): Promise<void> {

    this.keyCloakService.getUserProfile().then(async (data: any) => {
      this.userID = data.id; })


    var data = {// values on X-Axis
      labels: ['Alle', 'Kardiologie', 'Krankenhaus','Physiotherapie'], 
       datasets: [
        {
          label: "7.5-10 km",
          data: ['467'],
          backgroundColor: 'blue'
        },
        {
          label: "5-7.5 km",
          data: ['542', '542', '536', '327', '17',
                 '0.00', '538', '541'],
          backgroundColor: 'limegreen'
        },
        {
          label: "2.5-5 km",
          data: ['542', '542', '536', '327', '17',
                 '0.00', '538', '541'],
          backgroundColor: 'limegreen'
        } ,
        {
          label: "0-2.5 km",
          data: ['542', '542', '536', '327', '17',
                 '0.00', '538', '541'],
          backgroundColor: 'limegreen'
        } ,
        
      ]
    }
    this.createChart(data);
  

    this.meta = await this.datahub.getEinrichtungenMeta();
    console.warn("META", this.meta);

  }

  reset() {
    this.selectedName = this.orgObj?.Meta[0].Name 
    this.selectedRadius = this.orgObj?.Meta[0].Distanz 
    this.selectedUnit = this.orgObj?.Meta[0].Unit 
    this.selectedEinrichtung =  this.orgObj?.Meta[0].Einrichtungen
    this.selectedTransport   = this.orgObj?.Meta[0].Transport_Mode

  }

  async startAnalyse() {

  //@ts-ignore
  document.getElementById("buffer").showModal(); 
 
    if(this.umkreisMode != undefined) {
    var filter = this.umkreisMode == 0 ?  "Distance" : "Time";
    } else {
         filter = this.orgObj.Meta[0].Filter;
    }

  var radius = this.selectedRadius; 
  var positionFormat: { X: any; Y: any; Name: any; Street_Name: any; }[] = [];

    //new markers
    for (const [key, value] of this.reachComponent.markersObjRefMap) {
      console.warn("IT", value.plain.latlng[0])

      positionFormat.push(
        {"X":value.plain.latlng[1], "Y": value.plain.latlng[0], "Name": value.plain.name,"Street_Name": value.plain.address}
 
      )
    }


    this.orgObj?.Results.forEach((startingpoint: any) => {
      startingpoint.Starting_Point.forEach((pos: any) => {
        var filtered = positionFormat.filter(el => el.Name == pos.Name)
        if(filtered.length == 0) {
        positionFormat.push(
         {"X":pos.geometry.coordinates[0], "Y": pos.geometry.coordinates[1], "Name": pos.Name,"Street_Name": pos.Street_Name}
  
       ) }
      })
      
    });

    var data = { 
      "Gesuchter_Typ": this.selectedEinrichtungen,
      "Mode": this.selectedTransport,
      "Position": positionFormat,
      "Radius": radius,
      "Type": "Umkreissuche",
      "Filter" : filter
    }

    console.log("DATA", data)

    if(this.orgObj?.root) {
      name = this.orgObj.root?.Meta[0]?.Name
      root = this.orgObj.root

    } else {
     //@ts-ignore
    if(this.orgObj?.Meta[0].Name  != this.selectedName){
      var name = this.selectedName
      var root = undefined;
    } else {
         //@ts-ignore
      name = this.orgObj?.Meta[0].Name 
      root = this.orgObj

    }

   }

    

  
    
    var res; 
    try{

   res = await this.routingService.getUmkreisAnalyse(data, name, root, true);
    } catch(e) {
      console.warn("ERRPR", e, data, name, root)
                          //@ts-ignore

      alert( e.message)
                    //@ts-ignore
  document.getElementById("buffer").close(); 


    }

 
     


    console.warn("result", res)
  

    this.reset()
  //@ts-ignore
    if(res?.Error) {
        //@ts-ignore
      alert(res?.Error)
              //@ts-ignore
  document.getElementById("buffer").close(); 
      return; 
    }

    this.storageService.addTempResult(res); 

     //@ts-ignore
     this.reachComponent.clickResult(res.Results[0], res)
           //@ts-ignore
  document.getElementById("buffer").close(); 
    }


    createPieChart(dataAll: any) {

      var filter = this.selectedLabel; 
      if(!filter) {
        filter = this.chartLegend[0].label
        this.selectedLabel = this.chartLegend[0].label
      }
  


      var data : any = []
      data.Meta_Einrichtungen = dataAll.Meta_Einrichtungen.filter((el: { Typ: string; }) => el.Typ == filter)
  
      const categories : any = {};
      data.Meta_Einrichtungen.forEach((meta: { data: any[]; }) => {
        meta.data.forEach(entry => {
          if (categories[entry.category]) {
            categories[entry.category] += parseFloat(entry.share);
          } else {
            categories[entry.category] = parseFloat(entry.share);
          }
        });
      });
  
      const labels = Object.keys(categories);
      const values = Object.values(categories);

      if(this.pieChart) {this.pieChart.destroy()};
     
     this.pieChart = new Chart("myPieChart", {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [{
            label: 'Share by Category',
            data: values,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'left',
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  const label = context.label || '';
                  const value = context.raw || '';
                  return `${label}: ${value}%`;
                }
              }
            }
          }
        }
      });
    }


    createPieChartWithBreaks(breaksFin: any) {


      var newData : any = [];
      var labels : any = []


      for (let i = 0; i < breaksFin.length - 1; i++) {
  
        var count = this.result.Einrichtungen_Naehe.filter((el: any) => breaksFin[i] <= el.Distanz && el.Distanz < breaksFin[i + 1] && el.Typ ==  this.selectedLabel);
        newData.push(count.length)

       labels.push(breaksFin[i].toFixed(2) + " - " +breaksFin[i + 1].toFixed(2) + " " + this.orgObj?.Meta[0].Unit )
        
    
        
    }
  


    

      if(this.pieChart) {this.pieChart.destroy()};
     
     this.pieChart = new Chart("myPieChart", {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [{
            label: 'Anteile für ' + this.selectedLabel,
            data: newData,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'right',
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  const label = context.label || '';
                  const value = context.raw || '';
                  return `${label}: ${value}%`;
                }
              }
            }
          }
        }
      });
    }
    

  async showEinrichtung() {
    if(this.orgObj?.isTemp) {
      alert("Vor Änderung bitte speichern");
      return;
    } else {
      this.meta = await this.datahub.getEinrichtungenMeta();
      this.selectedEinrichtungen = JSON.parse(JSON.stringify(this.orgObj?.Meta[0].Einrichtungen))

    //@ts-ignore
    document.getElementById("einrichtung").showModal();
    }
  }

  async saveEinrichtung() {


  }

  async closeEinrichtung() {
    //@ts-ignore
    document.getElementById("einrichtung").close();
  }


  async showUmkreis() {

    if(this.orgObj?.isTemp) {
      alert("Vor Änderung bitte speichern");
      return;
    }

    if (this.orgObj?.Meta[0].Unit == 'm') {
      this.umkreisMode = 0;
    } else {
      this.umkreisMode = 1;
    }
    //this.selectedRadius = JSON.parse(JSON.stringify(this.orgObj?.Meta[0].Distanz))
    //@ts-ignore
    document.getElementById("umkreis").showModal();
  }

  saveUmkreis() {

 
    if (this.umkreisMode == 0) {
      this.selectedUnit = 'm'
    } else {
      this.selectedUnit = 'min'
    }

  }

  async closeStandort() {

    //@ts-ignore
    document.getElementById("dialog_mini").close();
  }


  showStandortPopUp() {
      //@ts-ignore
      document.getElementById("dialog_mini").showModal();
  }
  async showStandort() {

    if(this.orgObj?.isTemp) {
      alert("Vor Änderung bitte speichern");
      return;
    }

    this.reachComponent.clearUmkreisResults();

    this.orgObj.Results.forEach((el: any) => {
      this.reachComponent.addMarkerWithMapRef(el.Starting_Point[0].geometry.coordinates[1], el.Starting_Point[0].geometry.coordinates[0], el.Starting_Point[0].Name, el.Starting_Point[0].Street_Name)

    })
   // this.reachComponent.addMarkerWithMapRef(this.result?.Starting_Point[0].geometry.coordinates[1], this.result?.Starting_Point[0].geometry.coordinates[0], this.result?.Starting_Point[0].Name, this.result?.Starting_Point[0].Street_Name)
    this.reachComponent.addMarker();
    this.reachComponent.newAnalyseMode = false;


  }

  saveStandort() {
    this.selectedStandorte = this.storageService.markers
    this.modified = true; 
  }

  async closeUmkreis() {


    //@ts-ignore
    document.getElementById("umkreis").close();
  }



  setValue($event: Event, arg1: string) {
    //@ts-ignore
    var val = Number($event.target.ariaValueText)

    switch (arg1) {

      case 'radius':
        this.selectedRadius = val;
        break;
      case 'time':
        this.selectedRadius = val;
        break;

    }
  }
}
