import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DatahubComponent } from './components/datahub/datahub.component';
import { MainComponent } from './components/main/main.component';
import { DaseinsvorsorgeComponent } from './components/daseinsvorsorge/daseinsvorsorge.component';
import { SimulationComponent } from './components/simulation-section/virusinfektion/covid/simulation/simulation.component';
import { RaumAnalysenComponent } from './components/raum-analysen/raum-analysen.component';
import { LoginComponent } from './components/login/login.component';
import { TileviewComponent } from './components/tileview/tileview.component';
import { Datahub2Component } from './components/datahub2/datahub2.component';
import { DataRegioComponent } from './components/datahub2/data-regio/data-regio.component';
import { DataSynthComponent } from './components/datahub2/data-synth/data-synth.component';
import { RaumMainComponent } from './components/raum-analysen/raum-main/raum-main.component';
import { ReachMatrixComponent } from './components/raum-analysen/reach-matrix/reach-matrix.component';
import { DataSynthOverviewComponent } from './components/datahub2/data-synth-overview/data-synth-overview.component';
import { DataSynthPopComponent } from './components/datahub2/data-synth-pop/data-synth-pop.component';
import { DaseinsvorsorgeOverviewComponent } from './components/daseinsvorsorge/daseinsvorsorge-overview/daseinsvorsorge-overview.component';
import { NachfrageberechnungComponent } from './components/daseinsvorsorge/nachfrageberechnung/nachfrageberechnung.component';
import { SimulationSectionComponent } from './components/simulation-section/simulation-section.component';
import { VirusinfektionComponent } from './components/simulation-section/virusinfektion/virusinfektion.component';
import { CovidComponent } from './components/simulation-section/virusinfektion/covid/covid.component';
import { EigenesSzenarioComponent } from './components/simulation-section/virusinfektion/covid/eigenes-szenario/eigenes-szenario.component';
import { InfluenzaComponent } from './components/simulation-section/virusinfektion/influenza/influenza.component';
import { InfrastukturComponent } from './components/datahub2/infrastuktur/infrastuktur.component';
import { DataDocComponent } from './components/datahub2/docs/data-doc/data-doc.component';
import { DataDocLeafletComponent } from './components/datahub2/docs/data-doc-leaflet/data-doc-leaflet.component'
import { AuthGuardService } from './guard/auth-guard.service';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { DashboardsComponent } from './components/datahub2/data-regio/dashboards/dashboards.component';
import { DatahubRessourcenModifizierenComponent } from './components/datahub2/data-regio/datahub-ressourcen-modifizieren/datahub-ressourcen-modifizieren.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { ComparisonPrognoseComponent } from './components/datahub2/data-synth/comparison-prognose/comparison-prognose.component';

import { NetworksComponent } from './components/datahub2/matsim/networks/networks.component';
import { ScenarioListComponent } from './components/datahub2/matsim/scenario-list/scenario-list.component';
import { HitzesimulationComponent } from './components/simulation-section/hitzesimulation/hitzesimulation.component';
import { GebieteComponent } from './components/datahub2/gebiete/gebiete.component';

import { NachfrageberechnungNewComponent } from './components/daseinsvorsorge/nachfrageberechnung-new/nachfrageberechnung-new.component';

import { PolarComponent } from './polar/polar.component';
import { MatsimSimulationComponent } from './components/datahub2/matsim/matsim-simulation/matsim-simulation.component';
import { MatsimResultsComponent } from './components/datahub2/matsim/matsim-results/matsim-results.component';
import { MatsimComponent } from './components/datahub2/matsim/matsim.component';
import { PreziComponent } from './prezi/prezi.component';
import { SchulEntiwicklungPlanungComponent } from './components/schul-entiwicklung-planung/schul-entiwicklung-planung.component';
import { NotfallinfrastrukturComponent } from './components/daseinsvorsorge/notfallinfrastruktur/notfallinfrastruktur.component';
import { AiChartComponent } from './components/ai-chart/ai-chart.component';
import { SchulplanungComponent } from './components/schulplanung/schulplanung.component';
import { SchulplanungGymComponent } from './components/schulplanung/schulplanung-gym/schulplanung-gym.component';
import { SchulplanungPrimComponent } from './components/schulplanung/schulplanung-prim/schulplanung-prim.component';
import { SchulplanungOverviewComponent } from './components/schulplanung/schulplanung-overview/schulplanung-overview.component';
import { RasterSchulenComponent } from './components/schul-entiwicklung-planung/raster-schulen/raster-schulen.component';
import { ViviComponent } from './components/vivi/vivi.component';
import { KrankenhausreformComponent } from './krankenhausreform/krankenhausreform.component';
import { TestComponent } from './test/test.component';
import { Test2Component } from './test2/test2.component';
import { TestReportComponent } from './test-report/test-report.component';



export const routes: Routes = [
  { path: 'test', component: TestComponent  ,canActivate: [AuthGuardService]},
  { path: 'playground', component: Test2Component  ,canActivate: [AuthGuardService]},


  { path: '', component: LoginComponent  ,canActivate: [AuthGuardService]},
  { path: 'start', component: MainComponent  ,canActivate: [AuthGuardService]},
  { path: 'datahub', component: DatahubComponent  ,canActivate: [AuthGuardService]},
  { path: 'datahub2', component: Datahub2Component  ,canActivate: [AuthGuardService]},
  { path: 'datahub2/infrastruktur', component: InfrastukturComponent ,canActivate: [AuthGuardService]},
  //{ path: 'datahub2/docs', component: DataDocComponent  ,canActivate: [AuthGuardService] },
  //{ path: 'datahub2/docs/:id', component: DataDocComponent  ,canActivate: [AuthGuardService] },
  { path: 'datahub2/docs', component: DataDocLeafletComponent  ,canActivate: [AuthGuardService] },
  { path: 'datahub2/gebiete', component: GebieteComponent  ,canActivate: [AuthGuardService] },
  { path: 'datahub2/docs/:id', component: DataDocLeafletComponent  ,canActivate: [AuthGuardService] },
  { path: 'dashboards/:id/:version', component: DashboardsComponent  ,canActivate: [AuthGuardService]},
  { path: 'dashboards', component:DashboardsComponent, canActivate: [AuthGuardService]},
  { path: 'datahub-resourcen-modifizieren', component:DatahubRessourcenModifizierenComponent, canActivate: [AuthGuardService]},
  { path: 'datahub2/data-regio', component: DataRegioComponent   ,canActivate: [AuthGuardService]},
  { path: 'datahub2/data-synth', component: DataSynthOverviewComponent  ,canActivate: [AuthGuardService]},
  { path: 'datahub2/data-synth/prognose', component: DataSynthComponent  ,canActivate: [AuthGuardService]},
  { path: 'datahub2/data-synth/comparison-prognose', component: ComparisonPrognoseComponent  ,canActivate: [AuthGuardService]},
  { path: 'datahub2/data-synth/pop', component: DataSynthPopComponent  ,canActivate: [AuthGuardService] },
  { path: 'prognosen', component: DaseinsvorsorgeOverviewComponent ,canActivate: [AuthGuardService] },
  { path: 'ai', component: AiChartComponent ,canActivate: [AuthGuardService] },

  //{ path: 'analysen/notfallinfrastruktur', component: DaseinsvorsorgeComponent  ,canActivate: [AuthGuardService]},
  { path: 'analysen/notfallinfrastruktur', component: NotfallinfrastrukturComponent  ,canActivate: [AuthGuardService]},
  { path: 'analysen/notfallinfrastruktur/:id', component: NotfallinfrastrukturComponent  ,canActivate: [AuthGuardService]},


  //{ path: 'analysen/n', component: NotfallinfrastrukturComponent  ,canActivate: [AuthGuardService]},
  { path: 'prognosen/schule', component: SchulplanungOverviewComponent  ,canActivate: [AuthGuardService]},
  { path: 'prognosen/gymnasium', component: SchulplanungGymComponent  ,canActivate: [AuthGuardService]},
  { path: 'prognosen/grundschulen', component: SchulplanungPrimComponent  ,canActivate: [AuthGuardService]},



  { path: 'prognosen/nachfrageberechnung', component: NachfrageberechnungNewComponent  ,canActivate: [AuthGuardService]},
  { path: 'prognosen/nachfrageberechnung/:id', component: NachfrageberechnungNewComponent  ,canActivate: [AuthGuardService]},

  { path: 'prognosen/nachfrageberechnung-alt', component: NachfrageberechnungComponent  ,canActivate: [AuthGuardService]},

  { path: 'analysen', component: RaumAnalysenComponent  ,canActivate: [AuthGuardService]},
  { path: 'analysen/infrastructure', component: RaumMainComponent  ,canActivate: [AuthGuardService]},
  { path: 'analysen/reachability', component: ReachMatrixComponent  ,canActivate: [AuthGuardService]},
  { path: 'analysen/reachability/:id', component: ReachMatrixComponent  ,canActivate: [AuthGuardService]},
  { path: 'analysen/Hospital', component: KrankenhausreformComponent  ,canActivate: [AuthGuardService]},


  { path: 'simulation-section/virusinfektion/covid/simulation', component: SimulationComponent  ,canActivate: [AuthGuardService]},
  { path: 'tileview', component: TileviewComponent },
  { path: 'simulation-section', component: SimulationSectionComponent  ,canActivate: [AuthGuardService]},
  { path: 'simulation-section/hitzesimulation', component: HitzesimulationComponent  ,canActivate: [AuthGuardService]},
  { path: 'simulation-section/virusinfektion', component: VirusinfektionComponent  ,canActivate: [AuthGuardService]},
  { path: 'simulation-section/virusinfektion/covid', component: CovidComponent  ,canActivate: [AuthGuardService]},
  { path: 'simulation-section/virusinfektion/covid/eigenes-szenario', component : EigenesSzenarioComponent ,canActivate: [AuthGuardService] },
  { path: 'simulation-section/virusinfektion/influenza', component : InfluenzaComponent ,canActivate: [AuthGuardService]},
  { path: 'datahub2/infrastructure/:uuid', component : InfrastukturComponent ,canActivate: [AuthGuardService]},
  { path: 'nachrichten', component : NotificationsComponent ,canActivate: [AuthGuardService]},
  { path: 'search', component: SearchResultsComponent, canActivate: [AuthGuardService] },
  { path: 'networks', component: NetworksComponent, canActivate: [AuthGuardService] },
  { path: 'result-network/:uuid/:simulationUIID', component: MatsimResultsComponent, canActivate: [AuthGuardService] },
  { path: 'matsim-simulation/:uuid/:simulationUIID', component: MatsimSimulationComponent, canActivate: [AuthGuardService] },
  { path: 'szenarien', component: ScenarioListComponent , canActivate: [AuthGuardService]},
  { path: 'polar', component: PolarComponent , canActivate: [AuthGuardService]},
  { path: 'datahub2/Mobilitätsnetzwerke', component: MatsimComponent, canActivate: [AuthGuardService] },
  { path: 'presentation', component: PreziComponent, canActivate: [AuthGuardService] },
  { path: 'vivi', component: ViviComponent, canActivate: [AuthGuardService] },

  { path: 'prognose/Schulentwicklungsplanung', component: SchulEntiwicklungPlanungComponent, canActivate: [AuthGuardService] },
  { path: 'test', component: SchulplanungComponent, canActivate: [AuthGuardService] },
  { path: 'prognose/schoolMobility', component: RasterSchulenComponent, canActivate: [AuthGuardService] },
  { path: 'test-report', component: TestReportComponent, canActivate: [AuthGuardService] },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }