import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RegioService } from 'src/app/service/regioservice/regioservice.component';
import { TransitService } from 'src/app/service/transitService/transit.service';

@Component({
  selector: 'app-transit-dialog',
  templateUrl: './transit-dialog.component.html',
  styleUrls: ['./transit-dialog.component.css']
})
export class TransitDialogComponent {
  stopFacilityTransitRoutes: any[];  // Array of route objects
  selectedRoute: string = "";
  departures: string[] = [];  // To hold the departures for the selected route
  selectedDeparture: string = "";
  popSpinner=false;

  
  displayedColumns: string[] = ['name', 'n'];
  dataSource: any[] = []; 
  constructor(
    public dialogRef: MatDialogRef<TransitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private regioService: RegioService,
    private transitService: TransitService
  ) {
    this.stopFacilityTransitRoutes = data.stopFacilityTransitRoutes || [];
  }

  async onRouteSelect(): Promise<void> {
    //const transit = await this.regioService.getTransitPlan();
    console.log(this.selectedRoute);
    
    const lineString = this.data.transit.features.find((feature: any) => {
      return feature.properties.transitRouteId === this.selectedRoute;
    });

    if (lineString) {
      this.departures = lineString.properties.departures.split(',');
    } else {
      console.error('Route not found!');
    }
  }

  async onConfirm(): Promise<void> {
    this.popSpinner=true;
    if (this.selectedRoute && this.selectedDeparture) {
      const result = await this.transitService.getTimeTable(this.selectedRoute, this.selectedDeparture);
      console.log(result);
      
      this.dataSource = result; 
    }
    this.popSpinner=false;
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
