
import { Component, Inject, OnDestroy } from '@angular/core';
import { DatahubService } from '../../service/datahubservice/datahubservice.component';
import * as XLSX from 'xlsx';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface Location {
  name: string;
  address: string;
  lat: number,
  lng: number
}

@Component({
  selector: 'app-location-picker',
  templateUrl: './location-picker.component.html',
  styleUrls: ['./location-picker.component.css']
})
export class LocationPickerComponent {

remove(loc: Location) {
  this.locations = this.locations.filter(
    (location) => 
      location != loc
  );

}

locations: Location[] = [{
  name: '',
  address: '',
  lat: 0,
  lng: 0
}];







uploaded: boolean = false;
upload: any[] = [];
convertToJson: string = '';
formData : any = 
{
  name: "",
  address: "",
  latlng: []
}
importData: any[] = [];
filteredAdressList: any[] = [];
showDropdown: boolean = false;
selectedIndex = 0; 
downloaded: any;
fileName: any;
selectedAddress: any;
checkName: boolean = false;
fileNames: any[] = [];


closeWLocation() {
  this.dialogRef.close({locations: this.locations});

}




onFileChange(event: any) {
  this.uploaded = true;
  const file = event.target.files[0];
  const reader = new FileReader();
  this.fileName = file.name;
  this.fileNames.push(file.name);
  reader.readAsBinaryString(file);
  reader.onload = (e: any) => {
    const data = e.target.result;
    const workbook = XLSX.read(data, { type: 'binary' });
    workbook.SheetNames.forEach((sheet) => {
      this.upload = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
      console.log(this.upload);




      this.upload.forEach((el: any) => {

        
        this.locations.push({
          name:  el.Name,
          address: el.Adresse,
          lat: 0,
          lng: 0
        })
       
        
      }

      
      
      
      
      
        ); 
      

  
     // this.convertToJson = JSON.stringify(this.upload, undefined, 4);
      //console.log("JSON", this.convertToJson);
    });
  };

  this.locations = this.locations.filter(loc => loc.address != '')

  this._snackBar.open(this.fileName + ' erfolgreich importiert!');
setTimeout(() => {this._snackBar.dismiss()}, 3000)

 

 
}



async completeDropNew(address: any, event: any) {
  this.filteredAdressList = [];

  if(event.key == 'Enter') {
    this.addRow(); 
    return; 
  }

  if(address?.length < 4) {
    return; 
  }

  var res = await this.datahub.getAutocompleteList(address);
  //@ts-ignore
  this.filteredAdressList = res.features; 
}

addRow() {
//when last entry is empty then do nothing
 if(!this.locations[this.locations.length - 1].address ) {
  return
 }

  this.locations.push({
    name: '',
    address: '',
    lat: 0,
    lng: 0
  })

}


exportData() {
  var dataEx = [['Name', 'Adresse']];
  const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataEx);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, 'eigene_daten.xlsx');
  this.downloaded = true;
}


constructor(private datahub: DatahubService, private _snackBar: MatSnackBar,    public dialogRef: MatDialogRef<LocationPickerComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any,

){

  this.locations = data.locations
  this.addRow();


}



}
