import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-route-dialog',
  templateUrl: './add-route-dialog.component.html',
  styleUrls: ['./add-route-dialog.component.css']
})
export class AddRouteDialogComponent {
  selectedPoints: any[] = []; 

  constructor(
    public dialogRef: MatDialogRef<AddRouteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) 
  {}

  addPointProperties(pointProperties: any): void {
    // Check if the point is already in the list before adding
    const exists = this.selectedPoints.some(point => point.stopFacilityId === pointProperties.stopFacilityId);
    if (!exists) {
      this.selectedPoints.push(pointProperties);
    }
  }


  closeDialog(): void {
    this.dialogRef.close(); // Close the dialog
  }
}
