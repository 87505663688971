<mat-icon style="margin: 5px;"(click)="    this.dialogRef.close({});" >close</mat-icon>



<div *ngIf="step == 0" style="display: flex; align-items: center; flex-direction: column; justify-content: space-around; height: 90%">


<div style="display: flex; align-items: center; flex-direction: column;">
    <button mat-fab extended color="primary" (click)="step = step + 1" style="font-size: 20px;"> Gefährdungsanalyse </button>

    <div style="display: flex;">
    <ng-container *ngFor="let gef of gefahrAnalyse"  >




        <div class='tile' (click)="countYears = gef.countYears ; sizeClassOutside =gef.sizeClassOutside ; sizeClassCenter = gef.sizeClassCenter; step = step + 1 " style="font-size: 15px;"> 
    
             {{ gef.name }} <br>
           {{ gef.countYears }} Jahre <br>
            
           Klassengröße: {{ gef.sizeClassOutside }} |  {{ gef.sizeClassCenter }} 
    
        </div>
    
    
        
    
    </ng-container>
</div>


</div>



<div>
    <button mat-fab extended color="primary" (click)="step = 3;getWithoutSch()" style="font-size: 20px;"> SuS-Verteilung neu berechnen
    </button>

</div>
<div>
    <button mat-fab extended color="primary" (click)="goToMobility()" style="font-size: 20px;"> Mobilitätsanalyse </button>

</div>







</div>




 
<div *ngIf="step == 1" style="display: flex; align-items: center; flex-direction: column; padding:20px; ">
    <div style="display: flex; align-items: center; flex-direction: column; ">
<h3>
    Gefährdungsanalyse - Parameter
</h3>

<div>
  
</div>

<div style="display: flex; justify-content: space-around;"> 

  
<div style=" width: 30%; display: flex;">
           
    <p> Mindestklassengröße (außerhalb Mittelzentren) </p>
    <div class="slider-box">
        <mat-slider class="year-slider"  min="12" max="20" step="1"
            showTickMarks discrete>
            <input matSliderThumb [(ngModel)]="sizeClassOutside">
        </mat-slider>
        <span class="year-display">{{ sizeClassOutside }} SuS</span>
    </div>
</div>


<div style="width: 30%; display: flex">
    <p> Mindestklassengröße (Mittel- & Oberzentren) </p>
    <div class="slider-box">
        <mat-slider class="year-slider"  min="20" max="30" step="1"
            showTickMarks discrete>
            <input matSliderThumb [(ngModel)]="sizeClassCenter">
        </mat-slider>
        <span class="year-display">{{ sizeClassCenter }} SuS</span>

    </div>
</div>

</div>

<div style="display: flex; align-items: center; justify-content: center; flex-direction: column;"> 
<p> Wenn eine Schule in {{ countYears }} Jahren Schülerzahlen unter „ Mindestklassengröße 1. Klasse für Fortbestand“ aufzeigt, ist sie gefährdet.
</p>
<div class="slider-box">
    <mat-slider class="year-slider"  min="1" max="4" step="1"
        showTickMarks discrete>
        <input matSliderThumb [(ngModel)]="countYears">
    </mat-slider>
    <span class="year-display">{{ countYears }} Jahre</span>
</div>
<br>
<div class="schule-display" style="width: 100%" >
    <div style="display: flex; flex-direction: column; justify-content: space-between;"> 
    <div class="left-schul">
    
      
            <input  style="margin-left: 10px; height: 5vh"  type="search" placeholder="Suche.." [(ngModel)]="schoolSearch" />
  
            <u style="margin-left: 2px"(click)="selectSchool()"> alle auswählen </u> 
            <u style="margin-left: 2px" (click)="deselectSchool()"> alle abwählen </u> 
     


      </div>
    <div class="right-schul"> 

        <div style="    height: 16vh; align-items: flex-start; justify-content: flex-start;display: flex; flex-direction: column; overflow-y: auto;">
          <div *ngFor="let school of data.schools; let i = index" style="display: flex; align-items: center;">

            <div  *ngIf="schoolSearch == '' || school.raw.properties.Schulname?.toLowerCase().includes(schoolSearch?.toLowerCase())" style="display: flex; align-items: center;"> 
              <mat-checkbox [(ngModel)]="data.schools[i].raw.checked" 
                 >
                
  
              </mat-checkbox>
           

              <a> {{ school.raw.properties.Schulname }} </a>

           
             
            </div>
          </div>
  
      </div>
    </div>
  
</div>

</div>

<div> 
    <br>
    <br>
    <br>
<button mat-fab extended color="primary" (click)="startAnalyse()" style="font-size: 20px;"> Analyse starten </button>
</div>
</div>
</div>
</div>

<div *ngIf="step == 2" style="display: flex; align-items: center; flex-direction: column;">

    <h3> Gefährdungsanalyse - gefährdete Schulen</h3>
<p> Folgende Schulen unterschreiten die Mindestklassengröße in mindestens {{ countYears }} Jahren </p>
<div style="display: flex; align-items: center; flex-direction: column;">
<div *ngFor="let school of data.schools; let i = index" >

    <div   style="display: flex; align-items: center;"  *ngIf="school.raw.inDanger">  

      <b  >   {{ school.raw.properties.Schulname }} </b>
     
    </div>
  </div>
</div>
<br> 
<br> 

<br> 


  <button mat-fab extended color="primary" (click)="endAnalyse()" style="font-size: 20px;"> Gefährdete Schulen auf Karten einzeichnen</button>

</div>


<div *ngIf="step == 3 && !data.stepIso" style="display: flex; align-items: center; flex-direction: column;">


    <div style="width: 80%; margin: auto;">
        <h2>Enrollment Numbers by School Year</h2>
        <canvas id="myChart"></canvas>
    </div>

    <div style="width: 80%; justify-content:space-between; margin-top:40px">
        <button mat-raised-button color="primary"  style="margin-right: 20px;"> Übernehmen</button>
        <button mat-raised-button color="warn" (click)="close()"> Abbruch</button>
    </div>
</div>

<div *ngIf="step == 3 && data.stepIso" style="display: flex; align-items: center; flex-direction: column;">


    <div style="width: 80%; margin: auto;">
        <h2>Enrollment Numbers by School Year</h2>
        <canvas id="myChart2"></canvas>
    </div>

    <div style="width: 80%; justify-content:space-between; margin-top:40px">
        <button mat-raised-button color="primary"  style="margin-right: 20px;" (click)="getNewSchoolPoints()"> Übernehmen</button>
        <button mat-raised-button color="warn" (click)="close()"> Abbruch</button>
    </div>
</div>


