import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/service/dashboardService/dashboard.service';
import { KeycloakOperationService } from 'src/app/service/keycloakService/keycloak-operation.service';
import { RegioService } from 'src/app/service/regioservice/regioservice.component';
import { Storageservice } from 'src/app/service/storageservice-component/storageservice-component.component';

interface Dashboard {
  id:string;
  savedName: string;
  created_At: Date;
  userId:string;
  version:number;
  availableVersions: number[];
}

@Component({
  selector: 'app-dialog-get-dash',
  templateUrl: './dialog-get-dash.component.html',
  styleUrls: ['./dialog-get-dash.component.css']
})
export class DialogGetDashComponent {

  dashboards: Dashboard[] = [];
  displayedColumns: string[] = ['savedName', 'created_At', 'userId','version'];
  regioMetaData = [];
  regioData:any;
  keys:any
  load=false;
  userProfileId:string='';
  creator:any;
  userNames: { [key: string]: string } = {};

  constructor(
    private keyCloakService: KeycloakOperationService,
    private dashboardService: DashboardService,
    private router:Router,
    public dialogRef: MatDialogRef<DialogGetDashComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.load=true;
    this.keyCloakService.getUserProfile().then((data: any) => {
      this.userProfileId = data.id;
      this.creator=data.firstName
      this.dashboardService.getDashboards(this.userProfileId).subscribe((res:any) => {
        
        const version1Data = res.data.filter((item:any) => item.version === 1);
        setTimeout(() => {
          this.dashboards = version1Data;
          
          this.dashboards.forEach(async dashboard => {        
            await this.getUSerBasedOnId(dashboard.userId);
            await this.loadAvailableVersions(dashboard);
          });
          this.load=false
        }, 400);
      })
    });


  }

  formatToGermanDate(timestamp:any) {
    const date = new Date(timestamp);
    
    if (isNaN(date.getTime())) {
      console.error('Invalid date value:', timestamp);
      return 'Ungültiges Datum';  // Return a fallback value if the date is invalid
    }
    const options :any= {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };
    const formattedDate = new Intl.DateTimeFormat('de-DE', options).format(date);
    return formattedDate.replace(',', ' um');
}

  selectDashboard(dashboard: Dashboard) {
    this.router.navigateByUrl('/dashboards/' + dashboard.id + '/'+dashboard.version);
    this.dialogRef.close();
  }

  closeAll(){
    this.dialogRef.close();
  }
  

  async getUSerBasedOnId(userId: any) {
    if (this.userNames[userId]) {
      return; 
    }
    this.keyCloakService.getUsersList().subscribe((users: any) => {
      const foundUser = users.find((user: any) => user.id === userId);
      if (foundUser) {
        const fullName = `${foundUser.firstName} ${foundUser.lastName}`;
        this.userNames[userId] = fullName;
      } else {
        this.userNames[userId] = "Ersteller kann nicht identifiziert werden";
      }
    });
  }

  showAvalableVerson(dashboardId:any){
    this.dashboardService.getVersions(dashboardId).subscribe((data: number[]) => { 
       return data
    });
  }

  async loadAvailableVersions(dashboard: Dashboard) {
    this.dashboardService.getVersions(dashboard.id).subscribe((data: number[]) => {
      dashboard.availableVersions = data;
    });
  }

  updateDashboardVersion(dashboard: Dashboard, newVersion: number) {
    dashboard.version = newVersion;
  }


}
