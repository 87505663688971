<app-loading-overlay *ngIf="isLoading"></app-loading-overlay>





<div class="map-container" style="height: 100%">


  <re-map (clickOnLayer)="layerClicked($event)" (clickOnMarker)="markerClicked($event)" (moveendEvent)="mapMoved()"
    (clickOnBtn1)="startUmkreisanalyse($event, undefined)"> </re-map>
</div>


<button class="btn" style="top: 80px; width: 250px" (click)="openKrankheiten()"> Spezialisierungsanalyse starten
</button>




<button class="btn" style="top: 160px; width: 250px;" (click)="openPop()">
  <ng-container *ngIf="!selectedPop.pop_name">
    Population auswählen

  </ng-container>

  <ng-container *ngIf="selectedPop.pop_name">
    Population: {{ selectedPop.pop_name }}

  </ng-container>
</button>


<button class="btn" *ngIf = " hideChart" style="top: 230px; width: 250px;" (click)="hideChart = false">
     Graph anzeigen

</button>

<button  *ngIf = "(spezIniResult || spezResult) &&    hideResultChart"  class="btn"style="right: 5px; bottom: 60px; width: 250px;" (click)="hideResultChart = false">
  Graph anzeigen

</button>



<div id="chartContainer" [hidden]="hideResultChart">

  <button mat-mini-fab style="background: #10684e; color: white; margin-right: auto;" (click)="hideResultChart = true">
    <mat-icon>close</mat-icon>
  </button>

  <mat-tab-group *ngIf='spezResult' (selectedIndexChange)="updateResultChart($event)">
    <mat-tab label="Fallzahlen Krankenhaus/Krankheit"> </mat-tab>
    <mat-tab label="Intra-Krankenhaus-Spezialisierung"> </mat-tab>
    <mat-tab label="Inter-Krankenhaus-Spezialisierung"> </mat-tab>
    <mat-tab label="Inter-Krankenhaus-Spezialisierung (bundesweit)"> </mat-tab>
  </mat-tab-group>

  <canvas baseChart #chartCanvasResult [data]="chartResultConfig.data" [options]="chartResultConfig.options"
    [type]="chartResultConfig.type">
  </canvas>

</div>


<div id="chartContainer" [hidden]="hideChart">
  <button mat-mini-fab style="background: #10684e; color: white; margin-right: auto;" (click)="hideChart = true">
    <mat-icon>close</mat-icon>
  </button>

  <button mat-mini-fab style="background: #10684e; color: white; margin-right: auto;" (click)="updateChart(selectedChartIndex)">
    <mat-icon>refresh</mat-icon>
  </button>





  <mat-tab-group (selectedIndexChange)="updateChart($event)">
    <mat-tab label="Betten"> </mat-tab>
    <mat-tab label="Fallzahlen(ambulant)"> </mat-tab>
    <mat-tab label="Fachabteilungen"> </mat-tab>
    <mat-tab label="Fallzahlen(stationär)"> </mat-tab>
  </mat-tab-group>



  <re-chart> </re-chart>
</div>

<div [hidden]='!umkreisResult' id="resultContainer">
  <div style="display: flex; align-items: flex-end; padding: 10px">
    <div class="pad2">

      <mat-icon> track_changes</mat-icon>


      {{ umkreisResult?.radius }} {{ umkreisResult?.unit }}





    </div>
    <button mat-mini-fab style="background: #10684e; color: white; margin-left: auto;"
      (click)="umkreisResult = undefined; remap.removeAllLayers(); mapInit(); hideChart = false">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <!--*ngIf = '!editUmkreis'-->
  <app-hospital-umkreis [json]="umkreisResult"> </app-hospital-umkreis>
  <div style="display: flex; align-items: center; justify-content: center;">
    <div> <!--*ngIf = 'editUmkreis'-->

      <div class="section">
        <h2>Umkreis ändern</h2>
        <div class="detail">
          <label for="radius-input" class="label">Umkreis:</label>
          <div class="input-container scrollable">
            <!-- Input Field with Two-Way Binding -->
            <input type="number" id="radius-input" class="input" min="1" [(ngModel)]="radius" />

            <!-- Dropdown with Two-Way Binding -->
            <select id="unit-dropdown" class="dropdown" [(ngModel)]="unit" (change)='unitchanged()'>
              <option value="m">Meter</option>
              <option value="min">Minuten</option>
            </select>
          </div>


        </div>
      </div>


    </div>
    <button mat-mini-fab style="background-color: #10684e; color: white" (click)=" updateUmkreisanalyse()">
      <mat-icon>{{ 'chevron_right' }}</mat-icon>
    </button>

  </div>

</div>

<div [hidden]='!spezResult' id="resultContainer2">

  <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 5px; gap: 5px; height: 50vh;">

    <div class="section" style="text-align: center">
      <b>Spezialisierungsanalyse </b>
      <br>
      <br>

      <b>Krankheiten: </b> <span style="text-align: center;">
        {{ spezResult?.diseases }}

      </span>

      <br>
      <br>


      <b>Krankenhäuser: </b>

      <div *ngIf="spezResult?.hospitals?.length">
        <span *ngFor="let hospital of spezResult?.hospitals | slice:0:5; let last = last">
          {{ hospital }}<span *ngIf="!last">, </span>
        </span>
        <span *ngIf="spezResult.hospitals.length > 5">
          und {{ spezResult.hospitals.length - 5 }} mehr...
        </span>
      </div>

      <button class="btn" style="left: 18px; width: 250px" (click)="openInitiative()"> Spezialisierungsinitiative planen
      </button>

    </div>
  </div>


  <!--
    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 5px; gap: 5px; height: 50vh;">
     
        <div class="section"  style="text-align: center" >
            <b>Spezialisierungsanalyse </b>
            <br>
            <br>

            Krankheiten:  
    <span style="text-align: center;" >
      {{ spezResult?.diseases }} 

    </span>
            <br>

            Krankenhäuser:  

            <div *ngFor="let h of spezResult?.Specialization | slice:0:6 ; let i = index " style="text-align: start;  font-size:10px" >
          {{  h.name  }}

            </div>
            <a *ngIf="  spezResult?.colors.length > 6 "> .. und {{ spezResult?.colors.length - 6 }} mehr </a>



                       

          </div>
        <div class="section" style="text-align: center;">
          <b>Intra_Hospital_Specialization</b>
          <canvas id="intraChart" width="200" height="200"></canvas>
        </div>
        <div class="section" style="text-align: center;">
          <b>Inter_Hospital_Specialization</b>
          <canvas id="interChart" width="200" height="200"></canvas>
        </div>

        <div>      <button mat-mini-fab style="background: #10684e; color: white; margin-left: auto;" 
            (click)="spezResult = undefined; remap.removeAllLayers(); mapInit(); hideChart = false">
            <mat-icon>close</mat-icon>
        </button> </div>
       
      
        <div class="section" style="text-align: center;">
          <b>Total_Relevant_Cases_Hospital</b>
          <canvas id="totalChart" width="200" height="200"></canvas>
        </div>
        <div class="section" style="text-align: center;">
          <b>Fallzahlen</b>
          <canvas id="fallzahlChart" width="200" height="200"></canvas>
        </div>
        <div class="section" style="text-align: center;">
            <b>Global_Specialization</b>
            <canvas id="globalChart" width="200" height="200"></canvas>
          </div>
       
      </div>-->




</div>



<div [hidden]='!spezIniResult' id="resultContainer3">

  <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 5px; gap: 5px; height: 50vh;">

    <div class="section" style="text-align: center; height: 380px">
      <b>Spezialisierungsinitiative </b>
      <br>
      <br>

      <b> Bevölkerung </b> <span style="text-align: center;">
        {{ spezIniResult?.Meta[0]?.Pop }} ( {{ spezIniResult?.Meta[0]?.Age_From }} - {{ spezIniResult?.Meta[0]?.Age_To
        }})

      </span>

      <br>
      <br>


      <b>Selektierte Krankenhäuser für Spezialisierungsinitiative: </b>

   


      <div *ngIf="spezIniResult?.hospitals?.length">
        <span *ngFor="let hospital of spezIniResult?.hospitals | slice:0:5; let last = last">
          {{ hospital }}<span *ngIf="!last">, </span>
        </span>
        <span *ngIf="spezIniResult.hospitals.length > 5">
          und {{ spezIniResult.hospitals.length - 5 }} mehr...
        </span>
      </div>
<br>
<br>
      <b>Betroffene Krankheiten/Behandlungen: </b>

      <div *ngIf="spezIniResult?.diseases?.length">
        <span *ngFor="let d of spezIniResult?.diseases | slice:0:5; let last = last">
          {{  d }}<span *ngIf="!last">, </span>
        </span>
        <span *ngIf="spezIniResult.diseases.length > 5">
          und {{ spezIniResult.diseases.length - 5 }} mehr...
        </span>
      </div>

      
      <span style="text-align: center;">
        {{ spezResultDiseasesBuffer }}

      </span>



    </div>
  </div>




</div>
<div class="legend-container" *ngIf="clusterMeta">

  <div class="legend-row">
    <b>  {{ clusterMetatTitle }} </b> 
    <div *ngFor="let item of clusterMeta; let i = index" class="legend-item"
         [class.second-row]="i >= clusterMeta.length / 2">
      <div class="color-box" [style.background-color]="item.color_map"></div>
      <div class="color-label">
        <b>{{ item.class_map }}</b>
      </div>
    </div>
  </div>
</div>



<div id="bottom-container" *ngIf="spezIniResult">


  <button class="btn" style="position: relative"
  [ngStyle]="{ 'background-color': selRaster === 1 ? 'grey' : '', 'color': selRaster === 1 ? 'white' : '' }"

    (click)="plotRasterLayer(spezIniResult?.Results.before, 'Fahrzeit in Minuten zum nächsten Spezialisierungs-Krankenhaus'); selRaster = 1"> Erreichbarkeit "nächstes Krankenhaus"
    Initial </button>

  <button class="btn" style="position: relative"
  [ngStyle]="{ 'background-color': selRaster === 2 ? 'grey' : '', 'color': selRaster === 2 ? 'white' : '' }"

    (click)="plotRasterLayer(spezIniResult?.Results.after, 'Fahrzeit in Minuten zum nächsten Spezialisierungs-Krankenhaus'); selRaster = 2"> Erreichbarkeit "nächstes Krankenhaus" nach
    Spezialisierungsinitiative </button>

  <button class="btn" 
  [ngStyle]="{ 'background-color': selRaster == 3 ? 'grey' : '', 'color': selRaster == 3 ? 'white' : '' }"

  style="position: relative" (click)="selRaster = 3;plotRasterLayer(spezIniResult?.Results.dif, 'Differenz Fahrzeit in Minuten zum nächsten Spezialisierungs-Krankenhaus zwischen Initial und Modifikationsszenario');">
    Erreichbarkeit "nächstes Krankenhaus" Vergleich </button>



</div>

<div id="bottom-container1" *ngIf="spezIniResult">




  <button class="btn" style="position: relative; width: 300px"

    (click)="spezIniResult = undefined; clusterMeta = undefined; remap.removeAllLayers(); mapInit();selRaster = 0; hideResultChart = true; hideChart = false">
    Spezialisierungsinitiative verlassen </button>

</div>





<div id="playContainer">



  <div id="slide-container">

    <input type="range" id="customRange1" value="1" min="2024" max="2034" (change)="updateNext($event)" />

    <div class="ticks">


      <ng-container *ngFor="let e of [].constructor(11);
    let i = index;">

        <span class="tick" [ngStyle]="{'color': getColorForTicks(i) }"> {{ 2025 + i }} </span>
      </ng-container>


    </div>

  </div>

  <button class="zoomBtn">

    <i [ngClass]=" 'bi bi-trash'"></i>
  </button>

  <button class="zoomBtn">

    <i [ngClass]=" 'bi bi-trash'"></i>
  </button>

  <button class="zoomBtn">


    <i [ngClass]=" 'bi bi-floppy-fill'"></i>
  </button>

</div>