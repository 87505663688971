<div class="main-container">
  <div class="row">
    <ng-container *ngIf="title">
      <h1 class="title">{{ title }}</h1>
    </ng-container>

    <div class="panel-container">
      <mat-expansion-panel *ngIf="panelTitle || panelText">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="panel-title">{{ panelTitle }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <span class="panel-text">{{ panelText }}</span>
      </mat-expansion-panel>
    </div>
  </div>

  <div class="row2">
    <div class="tile-container">
      <div *ngFor="let text of boxTexts; let i = index" class="datahub-tile" (click)="navigate(i)">
        <span class="tile-text">{{ text }}</span>
      </div>
    </div>
  </div>
</div>
