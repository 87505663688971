import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HospitalService } from 'src/app/service/hospitalService/hospital.service';
import { RegioService } from 'src/app/service/regioservice/regioservice.component';
import { RoutingserviceComponent } from 'src/app/service/routingservice/routingservice.component';
import { Storageservice } from 'src/app/service/storageservice-component/storageservice-component.component';



@Component({
  selector: 'app-krankheiten-dialog',
  templateUrl: './krankheiten-dialog.component.html',
  styleUrls: ['./krankheiten-dialog.component.css']
})
export class KrankheitenDialogComponent {

  krankheiten: any[] = [];
  selectedKrankheiten: any[] = [];
  oberkategorie: any[] = []


  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<KrankheitenDialogComponent>,
    private hospitalService:HospitalService,
    private routingService: RoutingserviceComponent,
    private storageService: Storageservice,
    private regioService: RegioService
    
    ) {
      this.krankheiten = data.krankheiten
      console.log(this.krankheiten)

      const uniqueMainCategories = new Set();

      // Iteriere über jedes Objekt und füge die Hauptkategorie zum Set hinzu
      this.krankheiten.forEach((item: any) => {
        uniqueMainCategories.add({
          value: item.Hauptkategorie,
        checked: false});
      });
    
      // Wandelt das Set zurück in ein Array, um es einfacher weiter zu verwenden
      this.oberkategorie = Array.from(uniqueMainCategories);
      
    }


    getCheckedItems() {
     return this.krankheiten.filter(el=> el.checked == true)

    }
confirmSelection() {

  this.dialogRef.close(this.getCheckedItems());


}
  

  
    // Speichert die Auswahl der Hauptkategorie und Unterkategorie
    selectedMainCategory: any[] = [];
    selectedSubCategories: { [key: string]: boolean } = {};
  
    // Funktion zum Verwalten der Auswahl der Unterkategorien
    onSubCategoryChange(subCategory: any, isChecked: boolean) {
    
      if (isChecked) {
        subCategory.checked = true;
      } else {
        subCategory.checked = false;

      }
    }

    selectAll(category: any,isChecked: boolean) {

      
      let krankheiten = this.getFilteredKrankheiten(category)
krankheiten.forEach(subCategory => {
    
         subCategory.checked = isChecked;
      }
    )
  
  
  }

    getFilteredKrankheiten(cat: string){
      return this.krankheiten.filter(el => el.Hauptkategorie == cat)
    }
  
    // Dynamische Unterkategorien, die basierend auf der ausgewählten Hauptkategorie angezeigt werden
    getSubCategories() {
    //  return this.categories[this.selectedMainCategory] || [];
    }
  }
  