<div *ngIf="json" class="container">

  <div class="grid">

    <!-- Bevölkerungsdetails -->
    <div class="section"
      *ngIf="json.Population_Naehe || json.Betten_pro_Einwohner_Naehe || json.Herzkranke_Einwohner_Naehe">
      <h2>Ergebnisse</h2>
      <div class="detail" *ngIf="json.Population_Naehe">
        <p>Gesamtbevölkerung: {{ json.Population_Naehe }}</p>
      </div>
      <div class="detail" *ngIf="json.Betten_pro_Einwohner_Naehe">
        <p>Betten pro Einwohner: {{ json.Betten_pro_Einwohner_Naehe }}</p>
      </div>
      <div class="detail" *ngIf="json.Herzkranke_Einwohner_Naehe">
        <p>Herzkranke Einwohner: {{ json.Herzkranke_Einwohner_Naehe }}</p>
      </div>

      <div class="detail" *ngIf="json.Einrichtungen_Naehe && json.Einrichtungen_Naehe.length > 0">
        <h4>Einrichtungen in Nähe</h4>

        <div class="facility-card" *ngFor="let facility of json.Einrichtungen_Naehe">
          <p>Typ: {{ facility.Typ }} (Anzahl: {{ facility.n }}) </p>
        </div>
      </div>
    </div>
    <div *ngIf="!json.Population_Naehe && !json.Betten_pro_Einwohner_Naehe && !json.Herzkranke_Einwohner_Naehe">
      <p>Keine Bevölkerungsdetails verfügbar.</p>
    </div>

    <!-- Krankenhausdetails -->
    <div class="section" *ngIf="json && json.Starting_Point && json.Starting_Point[0]">
      <h4 *ngIf="json.hospital.name">{{ json.hospital.name }}
        <!--{{ json.radius }} {{ json.unit }} -->

      </h4>



      <div class="detail" *ngIf="json.hospital.Street_Name">
        <p>Straßenname:
          {{ json.hospital.Street_Name }}</p>
      </div>

      <div class="detail" *ngIf="json.hospital.anzahl_betten_total">
        <p> Anzahl Betten :
          {{ json.hospital.anzahl_betten_total }} </p>
        <p> Anzahl Fachabteilung:
          {{ json.hospital.anzahl_fachabteilungen }}

        </p>
        <p> Anzahl Fallzahlen Total:
          {{ json.fallzahlen_total }}
        </p>


        <p>Fallzahlen stationär: {{ json['ambulant_fallzahlen_stationär'] }}</p>
        <p>Fallzahlen ambulant: {{ json.ambulant_fallzahlen_ambulant }}</p>

    <p> Betten pro Einwohner: {{ json.Betten_Pro_Einwohner_Naehe }}</p>






      </div>

   







    </div>
    <div *ngIf="!json.Starting_Point[0] || (!json.Starting_Point[0].Name && !json.Starting_Point[0].Street_Name)">
      <p>Keine Krankenhausdetails verfügbar.</p>
    </div>


  </div>
</div>