import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DatahubService } from 'src/app/service/datahubservice/datahubservice.component';

@Component({
  selector: 'app-save-dialog',
  templateUrl: './save-dialog.component.html',
  styleUrls: ['./save-dialog.component.css']
})
export class SaveDialogComponent {
  userList: any;
  selectedSichtbarkeit: any = "public";
  popName: any = "Neue Schulanalyse";
  isGym: any = false;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private datahubService: DatahubService,  public dialogRef: MatDialogRef<SaveDialogComponent>) {
    console.warn("data", data)
    this.userList = data.userList
    //alert(data.isGym)
    this.isGym = data.isGym
  }
  async save() {

    var canRead: string[] = [];
    var canWrite: string[] = []

    this.userList.forEach((us: { canRead: any; canWrite: any; id: string; }) => {
      if (us.canRead || us.canWrite) {
        canRead.push(us.id)
      }
      if (us.canWrite) {
        canWrite.push(us.id)
      }

    })


    //permission check:

    if (!this.popName) {
      alert("Name darf nicht leer sein!");
      return;
    }



    var obj = {
      name: this.popName,
      createdAt: Date.now(),
      createdFrom: this.data.userID,
      access: this.selectedSichtbarkeit,
      canRead: canRead,
      canWrite: canWrite,
      schools: this.extractRawData(this.data.schools),
      bezirke: this.extractRawData(this.data.bezirke),
      analyse: this.data.gefahrAnalyseResults
    }

    if(this.isGym) {
    
      await this.datahubService.addSchulprognoseGym(obj)


    } else {
      await this.datahubService.addSchulprognose(obj)

    }


    this.dialogRef.close({ savedData: obj
    
     })
  
  }


  extractRawData(data: any[]) {
     if(!data) return
    return data.map(item => item.raw);
  };

}
