import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { firstValueFrom, map, Observable } from 'rxjs';
import metaData from '../../../assets/json/meta_new.json'; 
import { GlobalVariable } from '../../../../global';

@Component({
  selector: 'app-regioservice',
  templateUrl: './regioservice.component.html',
  styleUrls: ['./regioservice.component.css']
})
export class RegioService {
  private baseApiUrl = GlobalVariable.DATAHUB_URL;
  
  async getIndicatorData(objList: any[], indiList: any[]) {
    var url = this.baseApiUrl + '/getDataByIndAndKeyNew'; 

    var body = {"obj_keys": objList,
                "indi_keys": indiList}; 
    var result = await firstValueFrom(this.post(url, body)); 
    return result; 
  }

  constructor( 
    // Angular Modules 
    private http: HttpClient 
    ) { } 
    public get(url: string, options?: any) { 
    return this.http.get(url, options); 
    } 
    public post(url: string, data: any, options?: any) { 
    return this.http.post(url, data, options); 
    } 
    public put(url: string, data: any, options?: any) { 
    return this.http.put(url, data, options); 
    } 
    public delete(url: string, options?: any) { 
    return this.http.delete(url, options); 
    } 

    async getShapefilesLandkreisSA() {
      var results = [];
      var url = this.baseApiUrl + '/getShapeFileSALandkreis'; 
    
      try{
  
      var result = await firstValueFrom(this.http.get(url)); }
      catch(error) {
        return[]; 
      }
      results.push(result); 
      return results;
    }


    async getIndicatorMeta() {
      const nw = JSON.parse(JSON.stringify(metaData))          
      return nw; 
    }


    async getIndicatorMeta2(): Promise<any> {
      const url = this.baseApiUrl + '/getIndicatorMeta'; 
      const result = await firstValueFrom(this.http.get(url)); 
      const data = {data:result}
      return data;
    }

  
    async getShapefileByIdAndLevel(place: string, selected: number) {

      var results = [];
      var url = this.baseApiUrl + '/getShapeFileByLevel/' + place + '/' + selected; 
    
      try{
  
      var result = await firstValueFrom(this.http.get(url)); }
      catch(error) {
        return[]; 
      }
      results.push(result); 
      return results;
    }

    async  getRegioDataByKey(obj_keys: string[]) {
      var url = this.baseApiUrl + '/getRegioDataByKey/'
      var body = {"Object_Key": obj_keys}; 
      var result = await firstValueFrom(this.post(url, body));    
      return result;
    }

    async getYearlyChangeChartData(body: any){

      var url = this.baseApiUrl + '/getYearlyChange/'
     
      var result = await firstValueFrom(this.post(url, body)); 

      
      return result;
  
     }



     async getCompareChartData(body: any){

      var url = 'https://api2.cephlabs.de/comparison_metrics/calculate_comparison_stats'
     
      var result = await firstValueFrom(this.post(url, body));    
      return result;
  
     }
  

   async getGeneralChartData(body: any){

    var url = this.baseApiUrl + '/getGeneralChartData/'   
    var result = await firstValueFrom(this.post(url, body));    
    return result;

   }

   async getChangeRateChart(body: any){

    var url = this.baseApiUrl + '/getChangeRateChart/'
   
    var result = await firstValueFrom(this.post(url, body));    
    return result;

   }


    async  getCompGraphData() {
      var url = 'http://api2.cephlabs.de/comparison_metrics/calculate_comparison_stats'
      var body =  {
        "startYear": 2017,
        "endYear": 2019,
        "selectedAdminLevel": 3,
        "selectedLandkreisKey": "15084",
         "Key": "Barmer1"
      }
      var result = await firstValueFrom(this.post(url, body));    
      return result;
    }



    async  getRegioDataByKeyAndInd(obj_keys: string[], wvk: string) {
      var url = this.baseApiUrl + '/getDataByIndAndKeys'
      var body = {"Object_Key": obj_keys, 'indicators' : [ wvk ]} ; 
      var result = await firstValueFrom(this.post(url, body));    
      return result;
    }



  async getDistanceMatrix(starter: any, markers: any = []) : Promise<number[]> {

    var objList = []; 
  
    objList.push([starter.getLatLng().lng,starter.getLatLng().lat]); 
    markers.forEach((element: { geometry: { coordinates: any[]; }; }) => {objList.push([element.geometry.coordinates[0],element.geometry.coordinates[1]]); });

    var url =  'https://ors.bunsencloud.de/ors/v2/matrix/driving-car';     
    var body = {"locations": objList}; 
    var result = await firstValueFrom(this.post(url, body)); 

   //@ts-ignore
    var res = result.durations[0].shift()
    //@ts-ignore
    return result.durations[0];

  }

  async getYearsIndi(body:any){
    var url = this.baseApiUrl + '/getYearsIndi/'
     //var url = 'http://localhost:3000' + '/getYearsIndi/'
    var result = await firstValueFrom(this.post(url, body));    
    return result;
  }


  async getSchoolPoints(): Promise<any> {
    //const baseApiUrl = 'http://localhost:3000';
    const url = `${this.baseApiUrl}/getschool?t=${new Date().getTime()}`;  // Avoid caching with timestamp
    const result = await firstValueFrom(this.http.post(url, {})); // Use firstValueFrom for the observable
    return result;
  }

  // Check schools inside the polygon
  async checkSchoolsInPolygon(polygonGeoJSON: any): Promise<any> {
    //const baseApiUrl = 'http://localhost:3000';
    const url = `${this.baseApiUrl}/checkschoolsinpolygon?t=${new Date().getTime()}`;  // Avoid caching
    const body = { polygon: polygonGeoJSON.geometry };
    const result = await firstValueFrom(this.http.post(url, body)); // Use firstValueFrom for the observable
    return result;
  }

  async getSchulBezirk(): Promise<any> {
    const url = '../../assets/schulbezirk1.geojson';  // Avoid caching
    const result = await firstValueFrom(this.http.get(url)); // Use firstValueFrom for the observable
    return result;
  }

  async getTransitPlan(): Promise<any> {
    //const url = '../../assets/blk_network.json'; 
    const url = '../../assets/schedule.geojson'; 
    const result = await firstValueFrom(this.http.get(url)); // Use firstValueFrom for the observable
    return result;
  }

  async getBlkNetwork(): Promise<any> {
    const url = '../../assets/detailled_blk.json'; 
    const result = await firstValueFrom(this.http.get(url)); // Use firstValueFrom for the observable
    return result;
  }

  async getmobilityRaster(): Promise<any> {
    const url = '../../assets/school_mobility_raster_analysis5.geojson'; 
    const result = await firstValueFrom(this.http.get(url)); // Use firstValueFrom for the observable
    return result;
  }

  private graphqlUrl = 'https://rs7.otp.bunsencloud.de/otp/gtfs/v1';
  
  trip(
    from: { coordinates: { latitude: number; longitude: number } },
    to: { coordinates: { latitude: number; longitude: number } },
    dateTime: string,
    arriveBy: boolean,
    modes: { directMode: string },
    numTripPatterns?: number,
    searchWindow?: number,
    itineraryFiltersDebug?: any,
    pageCursor?: string
  ): Observable<any> {
    const query = `
      query trip($from: Location!, $to: Location!, $arriveBy: Boolean, $dateTime: DateTime, $numTripPatterns: Int, $searchWindow: Int, $modes: Modes, $itineraryFiltersDebug: ItineraryFilterDebugProfile, $pageCursor: String) {
        trip(
          from: $from
          to: $to
          arriveBy: $arriveBy
          dateTime: $dateTime
          numTripPatterns: $numTripPatterns
          searchWindow: $searchWindow
          modes: $modes
          itineraryFilters: {debug: $itineraryFiltersDebug}
          pageCursor: $pageCursor
        ) {
          previousPageCursor
          nextPageCursor
          tripPatterns {
            aimedStartTime
            aimedEndTime
            expectedEndTime
            expectedStartTime
            duration
            distance
            legs {
              id
              mode
              aimedStartTime
              aimedEndTime
              expectedEndTime
              expectedStartTime
              realtime
              distance
              duration
              fromPlace {
                name
                quay {
                  id
                }
              }
              toPlace {
                name
                quay {
                  id
                }
              }
              toEstimatedCall {
                destinationDisplay {
                  frontText
                }
              }
              line {
                publicCode
                name
                id
              }
              authority {
                name
                id
              }
              pointsOnLink {
                points
              }
              interchangeTo {
                staySeated
              }
              interchangeFrom {
                staySeated
              }
            }
            systemNotices {
              tag
            }
          }
        }
      }
    `;

    const variables = {
      from,
      to,
      dateTime,
      arriveBy,
      modes,
      numTripPatterns,
      searchWindow,
      itineraryFiltersDebug,
      pageCursor,
    };

    return this.http.post<any>(this.graphqlUrl, { query, variables })
      .pipe(map(response => response.data.trip));
  }


  async getWithoutSch(): Promise<any> {
    const url = this.baseApiUrl + '/getWithoutSch'; 
    const result = await firstValueFrom(this.http.get(url)); // Use firstValueFrom for the observable
    return result;
  }

  async getWithtSch(): Promise<any> {
    const url = this.baseApiUrl + '/getWithtSch'; 
    const result = await firstValueFrom(this.http.get(url)); // Use firstValueFrom for the observable
    return result;
  }


  async getIso(): Promise<any> {
    const url = '../../assets/json/isochrone_plotha.json'; 
    const result = await firstValueFrom(this.http.get(url)); 
    return result;
  }


  async getNewSchoolPoints(): Promise<any> {
    const url = '../../assets/json/plothaWithSch'; 
    const result = await firstValueFrom(this.http.get(url)); // Use firstValueFrom for the observable
    return result;
  }

  async getQuellen() {
    const url = this.baseApiUrl + '/quellen'; 
    const result = await firstValueFrom(this.http.get(url)); 
    return result;
  }

  async getGermanyLayers(){
    const url = this.baseApiUrl + '/getGermanyLayers'; 
    const result = await firstValueFrom(this.http.get(url)); 
    return result;
  }

  async getLKLayers(Object_Key: string) {
    const url = `${this.baseApiUrl}/getLKLayers?Object_Key=${Object_Key}`;
    const result = await firstValueFrom(this.http.get(url));
    return result;
  }

  getJsonData(): Observable<any> {
    return this.http.get<any>('../../assets/json_ags.json');
  }

  async storeRepotData(payload:any) {
    const url = this.baseApiUrl + '/storeRepotData';   
    const result = await firstValueFrom(this.http.post(url, payload)); 
    return result;
  }

  async getStoredReports() {
    const url = this.baseApiUrl + '/getStoredReports'; 
    const result = await firstValueFrom(this.http.get(url));
    return result;
  }

  async fetchReports() {
    const url = this.baseApiUrl + '/reports'; 
    const result = await firstValueFrom(this.http.get(url));
    return result;
  }

  async fetchReportDetails(reportId: string) {
    const url = this.baseApiUrl + `/reports/${reportId}`; 
    const result = await firstValueFrom(this.http.get(url));
    return result;
  }


  // async storeRepotDataKI(displayedDescriptions:any) {
  //   const url = this.baseApiUrl + '/storeRepotDataKI';      
  //   const result = await firstValueFrom(this.http.post(url, displayedDescriptions)); 
  //   return result;
  // }

  // async getStoredReportsKI() {
  //   const url = this.baseApiUrl + '/getStoredReportsKI'; 
  //   const result = await firstValueFrom(this.http.get(url));
  //   return result;
  // }


}
